import React, { useEffect, useState } from 'react';

import Button from 'components/button';
import Checkbox from 'components/checkbox';
import Modal from 'components/modal';

import { useBodyScroll } from '../../../hooks/useBodyScroll';

import cross from 'assets/icons/cross-gray.svg';
import dotIcon from 'assets/images/dotsIcon.svg';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import style from './columns-modal.module.scss';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ColumnModal = ({
  isPink,
  columns,
  setColumns,
  onColumnsReset,
  setChoseColModal,
  choseColModal = false,
}) => {
  useBodyScroll(choseColModal);

  const [checkAllcolumnsShouldBeDisplayed, setAllColumnsShouldBeDisplayed] =
    useState(false);
  const [columnsCopy, setColumnsCopy] = useState([...columns]);

  useEffect(() => {
    setColumnsCopy([...columns]);
    checkIfAllTheColumnsAreCheckedOrUnChecked(columns);
  }, [columns]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = reorder(
      [...columnsCopy],
      result.source.index,
      result.destination.index
    );
    setColumnsCopy([...items]);
  };

  const checkedAllColumnsHandleEvent = (event) => {
    let _columns = [];

    if (event?.target?.checked) {
      setAllColumnsShouldBeDisplayed(true);
      _columns = columnsCopy?.map((column) => {
        return { ...column, hidden: false };
      });
    } else {
      setAllColumnsShouldBeDisplayed(false);
      _columns = columnsCopy?.map((column) => {
        return { ...column, hidden: true };
      });
    }
    setColumnsCopy(_columns);
  };

  const saveColumnChanges = async () => {
    await setColumns([...columnsCopy]);
    setChoseColModal(false);
  };

  const selectSingleColumn = (value, columnKey) => {
    let _columns = [];
    _columns = columnsCopy?.map((x) => {
      return {
        ...x,
        hidden: x?.key === columnKey ? !value : x?.hidden,
      };
    });

    checkIfAllTheColumnsAreCheckedOrUnChecked(_columns);
    setColumnsCopy(_columns);
  };

  const checkIfAllTheColumnsAreCheckedOrUnChecked = (columns) => {
    const checkedAllColumns = columns?.every(
      (column) => column?.hidden === false
    );
    if (checkedAllColumns) setAllColumnsShouldBeDisplayed(true);
    else setAllColumnsShouldBeDisplayed(false);
  };

  const columnsHidden = [...columnsCopy.map((x) => x.hidden)];
  columnsHidden.pop();
  const isDisabled = !columnsHidden?.includes(false);

  return (
    <div>
      <Modal
        open={choseColModal}
        handleClose={() => setChoseColModal(false)}
        className={style.mainDiv}
        bodyClass={style.modalBody}
      >
        <div className={style.crossImg}>
          <img src={cross} alt="" onClick={() => setChoseColModal(false)} />
        </div>
        <span className={isPink ? style.modalTitlePink : style.modalTitle}>
          Change Columns
        </span>
        <div className={style.selectAllDiv}>
          <Checkbox
            type="checkbox"
            id="selectAll"
            label="Select All"
            className={style.checkbox1}
            containerClass={style.checkboxClass}
            handleChange={checkedAllColumnsHandleEvent}
            checked={checkAllcolumnsShouldBeDisplayed}
          />
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-id">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={style.upperDiv}
              >
                {columnsCopy?.map((ele, index) => {
                  if (ele?.key !== 'actions') {
                    return (
                      <Draggable
                        key={ele.key}
                        draggableId={ele.key}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            key={ele.key}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={style.rowDiv}
                          >
                            <Checkbox
                              type="checkbox"
                              id={ele.key}
                              label={ele.name}
                              className={style.checkbox}
                              checked={!ele?.hidden}
                              handleChange={(event) =>
                                selectSingleColumn(
                                  event?.target?.checked,
                                  ele?.key
                                )
                              }
                            />
                            <img {...provided.dragHandleProps} src={dotIcon} />
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={style.mainBtnDiv}>
          <Button
            text={'Confirm Changes'}
            disabled={isDisabled}
            btnClass={isPink ? style.btnClassModalPink : style.btnClassModal}
            type="button"
            handleClick={saveColumnChanges}
          />
          <Button
            text={'Reset to Default'}
            btnClass={isPink ? style.btnResetPink : style.btnReset}
            type="button"
            handleClick={onColumnsReset}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ColumnModal;
