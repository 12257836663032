import chroma from 'chroma-js';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';

import Tags from './../tags/index';

import style from './box.module.scss';
import './style.scss';

const formatOptionLabel = (
  { label, value, color, checkbox, box },
  { context, selectValue },
  badge
) => {
  return (
    <>
      {!_.isEmpty(selectValue) &&
      label === 'showLine' &&
      value === 'showLine' ? (
        <div
          style={{
            display: 'block',
            height: '1px',
            border: '0',
            borderTop: '1px solid #ccc',
            margin: '4px 0',
            padding: '',
            cursor: 'pointer !important',
          }}
        ></div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          {checkbox && context !== 'value' && (
            <input
              checked={selectValue?.find((e) => e.value == value) ?? false}
              type={'checkbox'}
              style={{ marginRight: '5px', cursor: 'pointer' }}
              onChange={() => {}}
            />
          )}
          {context !== 'value' && box && (
            <div
              style={{
                background: color,
                width: '13px',
                height: '13px',
                borderRadius: '2px',
                margin: '0px 5px',
              }}
            />
          )}
          {badge && context === 'value' ? (
            <Tags color={color} text={label}></Tags>
          ) : (
            <div
              style={{
                fontWeight: 600,
                ...(context === 'value' && {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }),
              }}
            >
              {label}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const useOutsideAlerter = (ref, setIsMenuOpen) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // setIsMenuOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

const SelectBox = ({
  label,
  name,
  badge,
  isMulti,
  isDemo,
  showSelected,
  control,
  disabled,
  showNumber,
  errorMessage,
  dynamicClass,
  options = [],
  hideIndicator,
  numberBadgeColor,
  showValueOnlyOnDisabled,
  placeholder = 'None Selected',
  isEditMode = false,
}) => {
  const [tooltip, setTooltip] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setIsMenuOpen);

  return (
    <>
      <div className={style.wraper} ref={wrapperRef}>
        <label className={style.lbl} style={{ marginBottom: '20px' }}>
          {label}
        </label>
        <div
          className={style.selectBox}
          style={{
            cursor: disabled ? 'unset' : '',
          }}
        >
          {control && (
            <Controller
              name={name}
              control={control}
              render={({ field }) => {
                const tooltipText = _.isArray(field?.value)
                  ? field?.value.join(', ')
                  : _.isObject(field?.value)
                  ? field?.value?.barStatus
                  : field?.value;

                return showValueOnlyOnDisabled && disabled ? (
                  <div className={style.displayValueOnly}>{field.value}</div>
                ) : (
                  <div>
                    <Select
                      menuIsOpen={isMenuOpen}
                      closeMenuOnSelect={!isMulti || !isMenuOpen}
                      closeMenuOnScroll={true}
                      isOptionDisabled={(option) => option.isdisabled}
                      onMenuOpen={() => setIsMenuOpen(true)}
                      onMenuClose={() => setIsMenuOpen(false)}
                      isMulti={isMulti}
                      components={{
                        ...(hideIndicator && {
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }),
                        ...(showNumber && {
                          MultiValue: (props) => {
                            const { getValue, data } = props;
                            const selectedOptions = getValue();
                            const currentOptionIdx = selectedOptions.findIndex(
                              (option) => option.value === data.value
                            );
                            if (selectedOptions.length > 1) {
                              return currentOptionIdx === 0 ? (
                                <Tags
                                  isCircularNumber={true}
                                  color={numberBadgeColor}
                                  numberCircular={selectedOptions?.length}
                                ></Tags>
                              ) : (
                                <></>
                              );
                            } else {
                              return currentOptionIdx === 0 ? (
                                <Tags
                                  text={data.label}
                                  color={numberBadgeColor}
                                  isCircular={true}
                                ></Tags>
                              ) : (
                                <></>
                              );
                            }
                          },
                        }),
                      }}
                      formatOptionLabel={(data, metaData) =>
                        formatOptionLabel(data, metaData, badge, showNumber)
                      }
                      hideSelectedOptions={showSelected ? false : true}
                      options={options}
                      styles={colourStyles(isEditMode, errorMessage)}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          text: 'blue',
                          primary25: 'pink',
                          primary: 'black',
                        },
                      })}
                      className={`${style.selectClass} ${dynamicClass}`}
                      {...field}
                      isDisabled={disabled}
                      value={setDafaultValueInSelectBox(field?.value, options)}
                      onChange={(selectedOption) => {
                        field.onChange(
                          isMulti
                            ? selectedOption.map((e) => e.value)
                            : selectedOption
                            ? selectedOption.value
                            : null
                        );
                        setTooltip(false);
                      }}
                      placeholder={placeholder}
                      classNamePrefix="your-selector"
                      isClearable
                    />
                    {!isMenuOpen && tooltip && tooltipText ? (
                      <div className={style.tooltip}>
                        <div className={style.tooltipChild}>
                          <p className={style.tooltipText}>{tooltipText}</p>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              }}
            ></Controller>
          )}
        </div>
        {errorMessage ? (
          <span className={style.errorMessage}>{errorMessage}</span>
        ) : (
          ''
        )}
      </div>
      {isMenuOpen && (
        <div
          className={style.backdropDiv}
          onClick={() => setIsMenuOpen(false)}
        ></div>
      )}
    </>
  );
};

export default SelectBox;

const setDafaultValueInSelectBox = (value, options) => {
  if (_.isString(value))
    return options.find((c) => c.value === value || c?.label === value);
  if (_.isArray(value))
    return options.filter((option) => value.includes(option.value));
  if (_.isObject(value))
    return options.find((c) => c.value === value?.barStatus);
  return null;
};

const colourStyles = (isEditMode, errorMessage) => {
  return {
    control: (styles, state) => ({
      ...styles,
      background: isEditMode ? '#ffffff' : 'transparent !important',
      border: errorMessage
        ? '1px solid red !important'
        : '1px solid rgb(230, 231, 232) !important',
      boxShadow: 'none',
      borderRadius: '6px',
      display: 'flex !important',
      alignItems: 'center !important',

      padding: '0px 10px',
      minHeight: '41px',
      cursor: 'pointer',
      '&:hover': {
        outline: state.isFocused ? 0 : 0,
      },
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma('#333333');
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? '#fff'
          : isFocused
          ? color.alpha(0.1).css()
          : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? chroma.contrast(color, '#333333') > 2
            ? 'white'
            : '#333333'
          : '#333333',

        cursor: isDisabled ? 'not-allowed' : '#fff',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? '#fff'
              : color.alpha(0.3).css()
            : '#fff',
          zIndex: '5000 !important',
        },
      };
    },

    placeholder: (styles) => ({
      ...styles,
      fontSize: '14px',
      color: '#9da1aa',
      fontWeight: 600,
    }),

    multiValue: (styles, { data }) => {
      const color = chroma(data?.color || 'black');
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css(),
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data?.color || '#333333',
      overflow: 'hidden',
      maxWidth: '150px ',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data?.color || 'black',
      ':hover': {
        backgroundColor: data?.color || 'black',
        color: 'white',
      },
    }),
    dropdownIndicator: (_, context) => {
      return {
        transform: `rotate(${
          context.selectProps.menuIsOpen ? '180deg' : '0deg'
        })`,
        transition: 'transform 0.2s',
        color: 'var(--primary-color)',
      };
    },
    clearIndicator: () => {
      return {
        padding: 0,
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        paddingLeft: 0,
        paddingRight: 0,
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        zIndex: !isEditMode ? 3 : 1,
      };
    },
  };
};
