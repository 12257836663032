import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextField from 'components/textfield';
import {
  BAR_FAILS_OPTIONS,
  BAR_STATUS_OPTIONS,
  DEMO_OPTIONS,
  GENDER_OPTIONS,
} from 'constants';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { moveSelctedDropdownStatusToTop } from '../../helper';
import style from './reset.module.scss';

const ResetPopup = ({ control, reset, register, watch }) => {
  const { errors } = useForm({ reset });
  const { locations, states } = useSelector((state) => state?.systemValues);
  const { lawSchools, clientNames } = useSelector((state) => state?.attorney);

  const [demoOptions, setDemoOptions] = useState(
    DEMO_OPTIONS?.map((e) => ({
      ...e,
      checkbox: true,
    }))
  );

  const [barFailsOptions, setBarFailsOptions] = useState(BAR_FAILS_OPTIONS);

  const [barLocationOptions, setBarLocationOptions] = useState(
    states &&
      states?.map((x) => {
        return { label: x?.barState, value: x?.barState, checkbox: true };
      })
  );

  const [lastBarStatusOptions, setLastBarStatusOptions] = useState(
    BAR_STATUS_OPTIONS?.map((x) => {
      return { ...x, checkbox: true };
    })
  );

  const [genderOptions, setGenderOptions] = useState(
    GENDER_OPTIONS?.map((x) => {
      return { ...x, checkbox: true };
    })
  );
  const [locationOptions, setLocationOptions] = useState(
    locations &&
      locations?.map((e) => ({
        label: e.location,
        value: e.location,
        checkbox: true,
      }))
  );

  const [schoolOptions, setSchoolOptions] = useState(
    lawSchools?.map((e) => ({
      label: e,
      value: e,
      checkbox: true,
    }))
  );

  const [clientNamesOptions, setClientNamesOptions] = useState(
    clientNames?.map((x) => {
      return {
        label: x?.clientName,
        value: x?.clientName,
        checkbox: true,
      };
    })
  );

  useEffect(() => {
    setDemoOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('attorneyDemo') ?? [],
        DEMO_OPTIONS?.map((e) => ({
          ...e,
          checkbox: true,
        }))
      ),
    ]);

    setBarFailsOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('barFails') ?? [],
        BAR_FAILS_OPTIONS
      ),
    ]);

    setGenderOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('gender') ?? [],
        GENDER_OPTIONS?.map((x) => {
          return { ...x, checkbox: true };
        })
      ),
    ]);

    setLocationOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('city') ?? [],
        locations &&
          locations?.map((e) => ({
            label: e.location,
            value: e.location,
            checkbox: true,
          }))
      ),
    ]);

    setSchoolOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('attorneyLawSchool') ?? [],
        lawSchools?.map((e) => ({
          label: e,
          value: e,
          checkbox: true,
        }))
      ),
    ]);

    setClientNamesOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('clientName') ?? [],
        clientNames?.map((x) => {
          return {
            label: x?.clientName,
            value: x?.clientName,
            checkbox: true,
          };
        })
      ),
    ]);

    setLastBarStatusOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('lastBarStatus') ?? [],
        BAR_STATUS_OPTIONS?.map((x) => {
          return { ...x, checkbox: true };
        })
      ),
    ]);

    setBarLocationOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('barLocation') ?? [],
        states &&
          states?.map((x) => {
            return { label: x?.barState, value: x?.barState, checkbox: true };
          })
      ),
    ]);
  }, [
    watch('barLocation'),
    watch('attorneyDemo'),
    watch('gender'),
    watch('city'),
    watch('attorneyLawSchool'),
    watch('clientName'),
    watch('lastBarStatus'),
    watch('barFails'),
  ]);

  return (
    <form className={style.resetPopUp}>
      <div className={style.grid}>
        <div className={style.textField}>
          <SelectBox
            label={'Demo'}
            control={control}
            name="attorneyDemo"
            options={demoOptions}
            isMulti
            numberBadgeColor={'#39695b'}
            dynamicClass={style.zDynamicState4}
            showNumber
            showSelected
          />
        </div>

        <div className={style.datePicker}>
          <SelectBox
            options={genderOptions}
            label={'Gender'}
            control={control}
            name={'gender'}
            errorMessage={errors?.gender?.message}
            isMulti
            numberBadgeColor={'#39695b'}
            dynamicClass={style.zDynamicState4}
            showNumber
            showSelected
          />
        </div>

        <div className={style.textField}>
          <SelectBox
            options={locationOptions}
            label={'City'}
            name={'city'}
            control={control}
            numberBadgeColor={'#39695b'}
            dynamicClass={style.zDynamicState4}
            showNumber
            showSelected
            isMulti
          />
        </div>

        <div className={style.statusBar}>
          <SelectBox
            label={'School'}
            control={control}
            name="attorneyLawSchool"
            options={schoolOptions}
            isMulti
            numberBadgeColor={'#39695b'}
            dynamicClass={style.zDynamicState4}
            showNumber
            showSelected
          />
        </div>
        <div className={style.datePicker}>
          <DatePicker
            label={'Grad Year'}
            name="attorneyGradYear"
            control={control}
            placeholder="Now"
            showYearPicker
            inputClass={style.date}
          />
        </div>
        <div className={style.statusBar}>
          <TextField
            label="Parker Score"
            className={style.field}
            name="attorneyParkerScore"
            type="number"
            register={register}
            errorMessage={errors?.attorneyParkerScore?.message}
          />
        </div>

        <div className={style.statusBar}>
          <SelectBox
            name="clientName"
            control={control}
            isMulti
            options={clientNamesOptions}
            label={'Client Names'}
            numberBadgeColor={'#39695b'}
            dynamicClass={style.zDynamicState4}
            showNumber
            showSelected
          />
        </div>

        <div className={style.statusBar}>
          <SelectBox
            name="barLocation"
            control={control}
            badge
            isMulti
            options={barLocationOptions}
            label={'Bar Location'}
            register={register}
            numberBadgeColor={'#39695b'}
            dynamicClass={style.zDynamicState4}
            showNumber
            showSelected
          />
        </div>
        <div className={style.statusBar}>
          <SelectBox
            name="lastBarStatus"
            control={control}
            badge
            options={lastBarStatusOptions}
            label={'Last Bar Status'}
            isMulti
            numberBadgeColor={'#39695b'}
            dynamicClass={style.zDynamicState4}
            showNumber
            showSelected
          />
        </div>

        <div className={style.statusBar}>
          <SelectBox
            name="barFails"
            control={control}
            options={barFailsOptions ?? []}
            label={'Bar Fails'}
            numberBadgeColor={'#39695b'}
            dynamicClass={style.zDynamicState4}
            showSelected
          />
        </div>
      </div>
    </form>
  );
};

export default ResetPopup;
