import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Container from 'components/container';

import Chevron from 'assets/icons/Chevron.svg';
import ChevronInactive from 'assets/icons/ChevronInactive.svg';
import logo from 'assets/images/logo.svg';

import style from './attorney-menu.module.scss';

const AttorneyMenu = () => {
  const location = useLocation();
  const userData = useSelector((state) => state.user.userData);
  return (
    <>
      <div className={style.wraper}>
        <Container>
          <div className={style.menuBar}>
            <div className={style.logoImg}>
              <img src={logo} alt="logo" width={165} height={47} />
            </div>

            <div>
              <div className={style.menuSection}>
                {links.map((link, index) => {
                  const isActive = !links
                    .map((e) => e.link)
                    .splice(0, index)
                    .includes(location.pathname);
                  return (
                    <div
                      key={link.link}
                      style={{
                        textDecoration: 'none',
                        color: '#304059',
                        marginBottom: -3,
                        zIndex: 4,
                      }}
                    >
                      <div
                        className={`${style.innerDiv} ${
                          location.pathname === link.link ? style.activeDiv : ''
                        }`}
                        style={{
                          borderBottom: isActive ? '3px solid #F58020' : '',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              color: isActive ? '#F58020' : '#9DA1AA',
                              fontSize: 14,
                              fontWeight: 400,
                              marginBottom: 5,
                            }}
                          >
                            {link.description}
                          </span>
                          <strong
                            style={{
                              color: isActive ? '#304059' : '#9DA1AA',
                            }}
                          >
                            {link.label}
                          </strong>
                        </div>
                        <img
                          className={style.chevron}
                          src={isActive ? Chevron : ChevronInactive}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className={style.bottomLine}></div>
            </div>
          </div>
        </Container>
      </div>
      <div>
        <Container>
          <p className={style.p}>
            Welcome {userData.firstName} {userData.lastName} to <br />
            your Legal Innovators profile
          </p>
          <p className={style.p2}>
            {location.pathname == '/' &&
              `Please fill out the fields on all screens.`}
          </p>
        </Container>
      </div>
    </>
  );
};

const links = [
  {
    description: 'Profile Set-Up',
    label: 'Attorney Profile',
    link: '/',
  },
  {
    description: 'Bar Records',
    label: 'Bar History',
    link: '/barhistoryForm',
  },
  {
    description: 'Courses',
    label: 'Training History',
    link: '/trainingHistoryForm',
  },
];

export default AttorneyMenu;
