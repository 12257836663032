/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Button from 'components/button';
import Loading from 'components/loading';
import TextField from 'components/textfield';

import loginLogo from 'assets/icons/auth.png';
import mainLogo from 'assets/icons/Legal-logo.svg';

import style from './code.module.scss';

function ConfirmationCode() {
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = () => {
    alert('code send');
    navigation('/reset-password');
  };

  return (
    <>
      {isLoading ? (
        <div className={style.loadingDiv}>
          <Loading loaderClass={style.loading} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={style.mainDiv}>
            <div className={style.leftInnerDiv}>
              <img src={mainLogo} alt="" className={style.logo} />
              <h2>Reset Password</h2>
              <p className={style.para}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus
                ac elementum id pellentesque pretium. Dolor, velit vitae sed.
              </p>
              <TextField
                label="Code"
                type="number"
                className={style.field}
                name="email"
                register={register}
                placeholder="Enter Your Code"
                errorMessage={errors?.email?.message}
              />
              <div className={style.buttonDiv}>
                <Button
                  type={'submit'}
                  text="Confirm code"
                  btnClass={style.loginButton}
                  onClick={() => onSubmit()}
                  isLoading={isLoading}
                />
              </div>
            </div>
            <div className={style.rightInnerDiv} style={{ width: '100%' }}>
              <img src={loginLogo} alt="" />
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default ConfirmationCode;

const schema = yup
  .object({
    email: yup.string().required('Code is required '),
  })
  .required();
