import React from 'react';

import PermissionRestrict from 'hoc/PermissionRestrict';
import { ROLES } from 'permissions/constants';

import style from './card-comment.module.scss';

const CardComment = ({
  salesforce,
  date,
  name,
  para,
  editIcon,
  deleteIcon,
  elipsisMode,
  handleEdit,
  handleDelete,
  mainCustom,
  className,
}) => {
  return (
    <div className={`${style.main} ${mainCustom}`}>
      <div className={`${style.innerFlex} ${className}`}>
        <div style={{ width: '100%' }}>
          {date && (
            <div className={style.inner}>
              <p>
                Last updated on <span>{date}</span> by <span>{name}</span>
              </p>
            </div>
          )}

          {elipsisMode ? (
            <div className={style.rounded}>
              <span className={style?.divElipsis}>
                <div>{para}</div>
              </span>
              {para && para.length > 30 && (
                <div className={style.tooltip}>
                  <div className={style.tooltipChild}>
                    <p className={style.tooltipText}>{para}</p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <p
              style={{
                wordBreak: 'break-all',
                marginRight: '50px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {para}
            </p>
          )}
        </div>
        {salesforce === true ? (
          <div className={style.textSalesforce}>
            <p> Edit/Delete in Salesforce</p>
          </div>
        ) : (
          <div className={style.icons}>
            <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
              <img src={editIcon} alt="" onClick={handleEdit} />
              <img src={deleteIcon} alt="" onClick={handleDelete} />
            </PermissionRestrict>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardComment;
