import React from 'react';
import { useNavigate } from 'react-router-dom';

const TableCell = ({
  id,
  column,
  control,
  watch,
  index,
  row,
  register,
  rowColor,
  isEditMode,
  onRowClick,
  inputName,
  isDragable = false,
  setValue,
  errors,
  editRowLoading,
}) => {
  const navigate = useNavigate();
  let message = '';
  if (Object.keys(errors).length && errors?.data[index])
    message = errors?.data[index][column.key]?.message;

  if (column.render) {
    return (
      <>
        <td
          style={{
            padding: '5px 10px',
            ...(column?.centered && { textAlign: 'center' }),
            backgroundColor: row?.checked
              ? '#EAFCFF'
              : rowColor && rowColor(index)
              ? rowColor(index)
              : '#FFFFFF',
            overflowWrap: 'anywhere',
            ...(isDragable && {
              minWidth: '50px',
              maxWidth: 'fit-content',
              overflowWrap: 'anywhere',
            }),
          }}
          onClick={() => !isEditMode && onRowClick(row)}
        >
          {column?.render(
            {
              value: row[column.key],
              errorMessage: message,
              register,
              inputName,
              control,
              row,
              isDisable: column.editable ? false : true,
              watch,
              navigate,
              index,
              setValue,
              id,
              editRowLoading,
            },
            null,
            isEditMode !== false && column.editable ? isEditMode : false,
            column?.showToolTipOnUnEditableField
          )}
        </td>
      </>
    );
  } else {
    return (
      <td style={{ padding: '5px 10px' }}>
        {isEditMode && column.editable ? (
          <>
            <input type={column.type} {...register(inputName)} />
          </>
        ) : (
          row[column.key]
        )}
      </td>
    );
  }
};

export default React.memo(TableCell);
