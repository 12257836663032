/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { createNotification } from 'components/react-notification';
import { userService } from 'services/users';

import Button from 'components/button';
import TextField from 'components/textfield';

import mainLogo from 'assets/icons/Legal-logo.svg';
import loginLogo from 'assets/icons/auth.png';
import crossIcon from 'assets/icons/cross-gray.svg';

import 'react-notifications/lib/notifications.css';
import style from './forgotPassword.module.scss';

function ForgotPassword() {
  const navigation = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { email } = data;

    try {
      const result = await userService.forgotPassword({
        email: email,
      });
      if (result?.status === 200) {
        createNotification('success', 'Success', result?.data?.message, 3500);
        navigation('/login');
      }
    } catch (err) {
      createNotification(
        'error',
        'Forgot Password Error',
        err?.response?.data?.error,
        3500
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.mainDiv}>
        <div className={style.leftInnerDiv}>
          <img src={mainLogo} alt="" className={style.logo} />
          <h2>Reset Password</h2>
          <TextField
            label="Email"
            className={style.field}
            name="email"
            register={register}
            placeholder="Enter Your Email"
            errorMessage={errors?.email?.message}
            icon={crossIcon}
            onClick={() => reset({ email: '' })}
            iconClass={style.crossIcon}
          />
          <div className={style.buttonDiv}>
            <Button
              type={'submit'}
              text="Send Link"
              btnClass={style.loginButton}
              onClick={() => onSubmit()}
              isLoading={isSubmitting}
            />
          </div>
        </div>
        <div className={style.rightInnerDiv}>
          <img src={loginLogo} alt="" />
        </div>
      </div>
    </form>
  );
}

export default ForgotPassword;

const schema = yup
  .object({
    email: yup
      .string()
      .required('Email is required ')
      .email('Please enter valid email'),
  })
  .required();
