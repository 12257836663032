import React from 'react';

import Button from 'components/button';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { ROLES } from 'permissions/constants';

import NoRecordsIcon from 'assets/icons/noRecords.svg';
import addIcon from 'assets/images/whiteAddIcon.svg';

import style from './noRecords.module.scss';

function NoRecords({
  icon,
  btnText,
  onClick,
  btnClass,
  spanText,
  noRecord,
  mainClass,
  hideButton,
  singleRowMode,
  extraPermissions = [],
}) {
  return (
    <div
      className={`${style.main} ${mainClass}`}
      style={{
        height: singleRowMode && '80px',
        paddingTop: singleRowMode && '20px',
      }}
    >
      <div className={style.innerdiv}>
        <div
          className={style.magnigyingGlass}
          style={{
            marginBottom: singleRowMode && '5px',
          }}
        >
          {icon && (
            <img
              src={NoRecordsIcon}
              height={singleRowMode ? 25 : 56}
              width={singleRowMode ? 25 : 56}
            />
          )}
        </div>
        <p
          className={style.emptyText}
          style={{ fontSize: singleRowMode && '14px' }}
        >
          {noRecord}
          {spanText && <span className={style.spanText}>{spanText}</span>}
        </p>
        <PermissionRestrict
          roles={[ROLES.ADMIN, ROLES.EDITOR, ...extraPermissions]}
        >
          {!hideButton && (
            <Button
              text={btnText}
              btnClass={btnClass}
              iconStart={addIcon}
              handleClick={onClick}
            />
          )}
        </PermissionRestrict>
      </div>
    </div>
  );
}

export default NoRecords;
