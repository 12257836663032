import React from 'react';

import Button from 'components/button';
import Modal from 'components/modal';

import refresh from 'assets/icons/refresh-purple.svg';

import style from './rest-modal.module.scss';

const RestoreModal = ({
  restoreModal,
  setRestoreModal,
  setVersionModal,
  handleRestoreVersion,
}) => {
  return (
    <div>
      <Modal
        open={restoreModal}
        handleClose={() => setRestoreModal(false)}
        className={style.mainDiv}
      >
        <div className={style.iconRefresh}>
          <img src={refresh} alt="" />
        </div>
        <p className={style.modalTitle}>Restore this version?</p>
        <p className={style.p}>Would you like restore {restoreModal?.name}?</p>
        <div className={style.mainBtnDiv}>
          <Button
            text="Restore"
            btnClass={style.btnClassModal}
            handleClick={() => {
              handleRestoreVersion(restoreModal?._id);
              setRestoreModal(false);
            }}
          />
          <Button
            text="Cancel"
            btnClass={style.btnClassUncheckModal}
            handleClick={() => {
              setVersionModal(true);
              setRestoreModal(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RestoreModal;
