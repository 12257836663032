import React from 'react';
import ProfileTopPositions from './profile-top-positions';

const PlacementPositionProfile = () => {
  return (
    <div>
      <ProfileTopPositions />
    </div>
  );
};

export default PlacementPositionProfile;
