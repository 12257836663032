import moment from 'moment';
import React, { useEffect, useState } from 'react';

import Avatar from 'components/avatar';
import DeleteModal from 'components/delete-modal';
import Switch from 'components/switch';
import EditAddTable from './edit-add';
import ResetPassword from './reset-password';

import { userService } from 'services/users';

import warning from 'assets/icons/Warning.png';
import edit from 'assets/icons/blue-edit.svg';
import del from 'assets/icons/del-blue.svg';
import lock from 'assets/icons/lock.svg';

import SortingIndicator from 'components/sorting-indicator';
import style from './setting-table.module.scss';

const SettingsTable = ({
  editPopUp,
  setEditPopUp,
  userData,
  setUserData,
  onFormSubmit,
  search,
}) => {
  const [delPopUp, setDelPopUp] = useState(false);
  const [activePopUp, setActivePopUp] = useState(false);
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);
  const [sortBy, setSortBy] = useState({
    key: 'firstName',
    order: 'asc',
  });

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    let timeout;

    timeout = setTimeout(() => {
      getUserData(search);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [search, sortBy]);

  const getUserData = async (search = '') => {
    try {
      const res = await userService.getUser({
        params: {
          name: search,
          sortBy: sortBy,
        },
      });
      setUserData(res?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const updateUser = async (updated, id) => {
    try {
      const res = await userService.setUser(updated, id);

      setUserData((u) => [
        ...u.map((x) => (x._id === id ? { ...res?.data?.user } : x)),
      ]);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteUser = async () => {
    try {
      await userService.deleteUser(delPopUp);
      setUserData([...userData.filter((ele) => ele._id !== delPopUp)]);
    } catch (err) {
      console.error(err);
    }
    setDelPopUp(false);
  };

  const handleSort = (key) => {
    if (key === sortBy.key)
      setSortBy({
        key,
        order: sortBy.order === 'asc' ? 'desc' : 'asc',
      });
    else {
      setSortBy({
        key,
        order: 'asc',
      });
    }
  };

  return (
    <>
      <div className={style.maxWidthClass}>
        <div className={style.mainTable}>
          <div className={style.tableHeader}>
            <p onClick={() => handleSort('firstName')}>
              First Name
              <SortingIndicator
                show={sortBy.key == 'firstName'}
                sort={sortBy.order}
              />
            </p>
            <p onClick={() => handleSort('lastName')}>
              Last Name{' '}
              <SortingIndicator
                show={sortBy.key == 'lastName'}
                sort={sortBy.order}
              />
            </p>
            <p onClick={() => handleSort('email')} className={style.pTagEmail}>
              Email
              <SortingIndicator
                show={sortBy.key == 'email'}
                sort={sortBy.order}
              />
            </p>
            <p
              onClick={() => handleSort('userType')}
              style={{ flexBasis: '250px' }}
            >
              Role
              <SortingIndicator
                show={sortBy.key == 'userType'}
                sort={sortBy.order}
              />
            </p>
            <p style={{ flexBasis: '250px' }}>Notifications</p>
            <p style={{ flexBasis: '250px' }}>Account Status</p>
            <p>Activation Date</p>
            <p className={style.emptyDiv}></p>
          </div>
          <div
            className={`${style.rowsBody} rowsBody`}
            style={editPopUp === true ? { paddingBottom: 270 } : {}}
          >
            <div className={style.innerHeight}>
              {userData?.map((ele, index) => (
                <React.Fragment key={ele._id}>
                  {index === editPopUp ? (
                    <EditAddTable
                      onFormSubmit={onFormSubmit}
                      setEditPopUp={setEditPopUp}
                      className={`${style.gridClass}`}
                      classNameMain={`${
                        index === userData.length - 1 ? style.morePadding : ''
                      }`}
                      data={ele}
                      index={index}
                      getUserData={getUserData}
                    />
                  ) : (
                    <div className={style.rows} key={index}>
                      <div className={style.imgDiv}>
                        <Avatar
                          circleSize={40}
                          className={style.avatar}
                          icon={ele?.profilePicture}
                          initials={`${ele.firstName || ''} ${
                            ele.lastName || ''
                          }`}
                        />
                        <p className={style.pTagAvatar}>{ele.firstName}</p>
                        {ele.firstName.length > 15 && (
                          <span>{ele.firstName}</span>
                        )}
                      </div>
                      <div className={style.toolTool}>
                        <p className={style.pTag}>{ele.lastName}</p>
                        {ele.lastName.length > 24 && (
                          <span>{ele.lastName}</span>
                        )}
                      </div>
                      <div
                        className={style.toolTool}
                        style={{ width: '450px' }}
                      >
                        <p className={style.pTagEmail}>{ele.email}</p>
                        {ele.email.length > 35 && <span>{ele.email}</span>}
                      </div>
                      <div className={style.flexSwitch}>
                        <p>{ele.userType}</p>
                      </div>
                      <div className={style.flexSwitch}>
                        <p>{ele.notification ? 'On' : 'Off'}</p>
                      </div>
                      <div className={style.flexSwitch}>
                        <Switch
                          title={ele.status ? 'Active' : 'Inactive'}
                          checked={ele.status ? true : false}
                          onChange={(e) =>
                            !e.target.checked
                              ? setActivePopUp(ele)
                              : updateUser(
                                  { status: e.target.checked },
                                  ele._id
                                )
                          }
                        />
                      </div>
                      <p>
                        {ele.status ? `Active` : 'Inactive'} since:
                        <p>
                          {moment(ele.statusDate).format('MM/DD/YYYY hh:mm:ss')}
                        </p>
                      </p>
                      <div className={style.emptyDiv}>
                        <img
                          src={edit}
                          alt=""
                          onClick={() => setEditPopUp(index)}
                        />
                        <img
                          src={lock}
                          alt=""
                          onClick={() => {
                            setUpdatePasswordModal(ele._id);
                          }}
                        />
                        <img
                          src={del}
                          alt=""
                          onClick={() => setDelPopUp(ele._id)}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          {activePopUp && (
            <DeleteModal
              openDelModal={activePopUp}
              iconSize={48}
              setOpenDelModal={setActivePopUp}
              delText="Deactivate User"
              paraText={`Are you sure you want to deactivate ${activePopUp.firstName} ${activePopUp.lastName}?`}
              className={style.modal}
              handleClick={async () => {
                await updateUser({ status: false }, activePopUp._id);
                setActivePopUp(false);
              }}
              btnText={'Yes, deactivate them'}
              btn1Text={'No, I made a mistake'}
              del={warning}
            />
          )}
          {delPopUp && (
            <DeleteModal
              iconSize={48}
              openDelModal={delPopUp}
              setOpenDelModal={setDelPopUp}
              del={del}
              delText="Delete User"
              paraText="Are you sure you want to delete this profile?"
              className={style.modal}
              handleClick={handleDeleteUser}
              btnText="Yes, it was nice while it lasted."
              btn1Text="Whoops, not so quick!"
            />
          )}
          {updatePasswordModal && (
            <ResetPassword
              updatePasswordModal={updatePasswordModal}
              setUpdatePasswordModal={setUpdatePasswordModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SettingsTable;
