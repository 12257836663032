import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/button';
import DeleteModal from 'components/delete-modal';
import Layout from 'components/layout';
import Loader from 'components/loader';
import Spinner from 'components/spinner';
import PermissionRestrict from 'hoc/PermissionRestrict';
import AddAttorney from './add-attorney';
import AttorneyTable from './attorney-table';
import ColumnModal from './choose-columns-modal';
import AttorneyHeader from './header';

import { ATTRONEY_STATUS_OPTIONS } from 'constants';
import { A01_SCREEN_MESSAGES } from 'data/userFacingMessages';
import { ROLES } from 'permissions/constants';
import { setClientNames, setSchools } from 'redux/attorney/attorney-actions';
import {
  setAttorneyTableFilter,
  setCheckedRowsData,
} from 'redux/filters/filters-actions';
import { getClientPosition } from 'services/attorney-profile';
import {
  deleteAttorneyById,
  getAttorneyCalculations,
  updateAllAttorneysDirectory,
} from 'services/attorny-directory-table';
import { getTableColumns, updateTableColumns } from 'services/utils';
import {
  downloadAttorneyDirectoryIntoCsv,
  getAllAttorneysData,
  getDefaultColumnOfAttorneyTable,
  getSideBarLinksWithCalculation,
  getUpdateAndCreateLastBarStatusArray,
} from './helper.js';

import delIcon from 'assets/icons/del-green.svg';
import exportIcon from 'assets/icons/download-green.svg';
import columnsImg from 'assets/images/green-columns.svg';
import crossIcon from 'assets/images/greenCrossIcon.svg';
import edit from 'assets/images/greenEditIcon.svg';
import saveIcon from 'assets/images/save.svg';

import style from './directory.module.scss';
import ChipsFilter from 'components/chips-filter';

const AttorneyDirectory = () => {
  const tableName = 'attorneyDirectory';

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userData._id);
  const { lawSchools } = useSelector((state) => state?.attorney);
  const { locations } = useSelector((state) => state?.systemValues);
  const { attorneyTableFilter, attorneyDirectoryChecked } = useSelector(
    (state) => state?.pageFilters
  );

  const ref = useRef();
  const headerRef = useRef();
  const isFirstRender = useRef(true);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [copyData, setCopyData] = useState([]);
  const [openArrow, setOpenArrow] = useState(0);
  const [noMatch, setNoMatch] = useState(false);
  const [resetChip, setResetChip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState('');
  const [sideBarLinks, setSideBarLinks] = useState([]);
  const [choseColModal, setChoseColModal] = useState(false);
  const [sort, setSort] = useState({ sortBy: '', order: '' });
  const [pagination, setPagination] = useState(initialPagination);
  const [tableLoadingOnFiltering, setTableLoadingOnFiltering] = useState(false);

  const checkRowIds = attorneyDirectoryChecked || [];
  const filters = attorneyTableFilter?.filters || {};
  const search = attorneyTableFilter?.filters?.mainFilters?.search ?? '';
  const currentValue = attorneyTableFilter?.currentValue || 'Now';
  const calculationSelected = attorneyTableFilter?.calculationSelected
    ? attorneyTableFilter?.calculationSelected
    : attorneyTableFilter?.calculationSelected === ''
    ? ''
    : 'All';
  const dateRange = attorneyTableFilter?.dateRange || [null, null];

  const [directoryFilters, setDirectoryFilters] = useState({
    page: 1,
    filters: {
      mainFilters: filters?.mainFilters || {},
      statsFilters: getStatsFilters({
        calculationSelected,
      }),
    },
    dateRange,
    checkRowIds,
    pageSize: 30,
    currentValue,
    order: sort?.order,
    sortBy: sort?.sortBy,
    extraParams: { showLoaderOnFullScreen: true, attorneyCalculation: true },
  });

  const fetchAttorneys = async ({
    page,
    pageSize = 30,
    sortBy,
    order,
    filters,
    currentValue = 'Now',
    showLoaderOnFullScreen = true,
    attorneyCalculation = true,
    dateRange = [null, null],
    attorneyDirectoryChecked = [],
  }) => {
    showLoaderOnFullScreen
      ? setLoading(true)
      : setTableLoadingOnFiltering(true);
    filters = {
      mainFilters: {
        ...(filters?.mainFilters || {}),
        ...(_.size(filters?.mainFilters?.Li_Start_Date) > 0 && {
          Li_Start_Date: [
            moment(filters?.mainFilters?.Li_Start_Date[0]).format('YYYY-MM-DD'),
            moment(
              filters?.mainFilters?.Li_Start_Date[1] !== null
                ? filters?.mainFilters?.Li_Start_Date[1]
                : filters?.mainFilters?.Li_Start_Date[0]
            ).format('YYYY-MM-DD'),
          ],
        }),
        ...(_.size(filters?.mainFilters?.Placement_Date) > 0 && {
          Placement_Date: [
            moment(filters?.mainFilters?.Placement_Date[0]).format(
              'YYYY-MM-DD'
            ),
            moment(
              filters?.mainFilters?.Placement_Date[1] !== null
                ? filters?.mainFilters?.Placement_Date[1]
                : filters?.mainFilters?.Placement_Date[0]
            ).format('YYYY-MM-DD'),
          ],
        }),
      },
      statsFilters: filters?.statsFilters || {},
    };
    if (attorneyCalculation && page === 1) {
      await fetchCalculations({
        dateRange,
        currentValue,
        filters: { mainFilters: filters?.mainFilters || {} },
        checkedRowIds: attorneyDirectoryChecked,
      });
    }

    const {
      attorneyArrayWithCustomCheckField,
      totalPages,
      currentPage,
      totalDocuments,
    } = await getAllAttorneysData(
      pageSize,
      page,
      sortBy ?? '',
      order ?? '',
      _.isEmpty(sortBy) && _.isEmpty(order) ? false : true,
      calculationSelected === 'Converted (Cumulative)'
        ? 'Converted'
        : calculationSelected,
      currentValue,
      dateRange,
      filters,
      checkRowIds,
      attorneyDirectoryChecked
    );

    const newAttorneysMap = attorneyArrayWithCustomCheckField?.reduce(
      (acc, curr) => {
        acc[curr._id] = true;
        return acc;
      },
      {}
    );

    if (
      !checkRowIds.length ||
      checkRowIds.filter((x) => newAttorneysMap[x]).length
    ) {
      setNoMatch(false);
    } else {
      setNoMatch(true);
    }

    !_.isEmpty(data) && page == 1 && ref.current && (ref.current.scrollTop = 0);

    if (_.isEmpty(sortBy) && _.isEmpty(order)) {
      if (!_.isEmpty(filters) && page === 1) {
        !_.isEmpty(data) && ref.current && (ref.current.scrollTop = 0);
        setData([...(attorneyArrayWithCustomCheckField || [])]);
      } else if (page === 1 && _.isEmpty(filters)) {
        setData([...attorneyArrayWithCustomCheckField]);
      } else {
        if (_.isEmpty(copyData)) {
          setData((prev) => [...prev, ...attorneyArrayWithCustomCheckField]);
        } else {
          //TODO scroll issue there
          setData((prev) => {
            const prevUncheckValue = prev?.filter(
              (x) => !checkRowIds.includes(x?._id)
            );

            return [
              ...copyData,
              ...prevUncheckValue,
              ...attorneyArrayWithCustomCheckField,
            ];
          });
        }
      }
    } else {
      if (!_.isEmpty(sortBy) && !_.isEmpty(order))
        setData([...attorneyArrayWithCustomCheckField]);
      else {
        if (_.isEmpty(copyData)) {
          page === 1
            ? setData([...attorneyArrayWithCustomCheckField])
            : setData((prev) => [
                ...prev,
                ...attorneyArrayWithCustomCheckField,
              ]);
        } else {
          const setSortingDataChecked = attorneyArrayWithCustomCheckField?.map(
            (x) => {
              const find = copyData?.find(
                (y) => y?._id === x?._id && y?.checked
              );
              if (_.isEmpty(find)) return x;
              else return { ...x, checked: true };
            }
          );
          setData([...setSortingDataChecked]);
        }
      }
    }

    if ((totalPages && totalDocuments, currentPage)) {
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          totalPages,
          totalDocuments,
        };
      });

      showLoaderOnFullScreen
        ? setLoading(false)
        : setTableLoadingOnFiltering(false);
    }

    setData((prev) => {
      let prevCopy = [...prev];
      for (const el of prevCopy)
        if (checkRowIds.includes(el?._id)) el.checked = true;
      return prevCopy;
    });
  };

  const fetchCalculations = async ({
    filters,
    dateRange,
    currentValue,
    checkedRowIds = [],
  }) => {
    const { data } = await getAttorneyCalculations({
      filters,
      calculationSelected,
      type: currentValue,
      _ids: checkedRowIds.join(',') ?? '',
      startDate: dateRange && dateRange[0] && dateRange[0],
      endDate: dateRange && dateRange[1] && dateRange[1],
    });
    const sideBarLinksWithCalculation = getSideBarLinksWithCalculation(
      data?.calculations,
      currentValue
    );
    setSideBarLinks(sideBarLinksWithCalculation);
  };

  const downloadAttorneyDirectoryIntoCsvHandleEvent = async () =>
    await downloadAttorneyDirectoryIntoCsv(
      copyData,
      sort,
      calculationSelected,
      currentValue,
      dateRange,
      filters,
      columns
    );

  const getNextAttorneyData = async (showLoader) => {
    const currentPage = (pagination.page += 1);
    if (currentPage <= pagination?.totalPages) {
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          page: currentPage,
        };
      });

      await fetchAttorneys({
        page: currentPage,
        sortBy: sort?.sortBy,
        order: sort?.order,
        filters,
        showLoaderOnFullScreen: false,
        currentValue: currentValue,
      });
    }
  };

  const setCheckedRowData = async (rowData, allData) => {
    const checkedRowIds = [...(allData || rowData)]
      ?.filter((x) => x?.checked)
      ?.map((y) => y?._id);
    setCopyData([]);
    let _rowData = [...rowData];
    setCopyData(_rowData);
    dispatch(setCheckedRowsData(checkedRowIds, 'ATTORNEY'));
    dispatch(
      setAttorneyTableFilter({
        ...attorneyTableFilter,
        lastQuery: {
          ...attorneyTableFilter?.lastQuery,
          attorneyDirectoryChecked: checkedRowIds,
        },
      })
    );

    await fetchCalculations({ currentValue, dateRange, checkedRowIds });
  };

  const getSelectedSortingHandleEvent = async (sortObject) => {
    const _columns = [...columns];
    let findColumnIndex = _columns.findIndex(
      (column) =>
        column.sortKey === sortObject.sortBy || column.key === sortObject.sortBy
    );
    _columns[findColumnIndex] = {
      ..._columns[findColumnIndex],
      icon:
        sortObject?.order === 'asc'
          ? 'up'
          : sortObject.order === 'desc'
          ? 'down'
          : 'up',
    };
    setColumns([..._columns]);
    setSort(sortObject);
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        page: 1,
      };
    });

    await fetchAttorneys({
      page: 1,
      dateRange,
      currentValue,
      order: sortObject?.order,
      attorneyCalculation: false,
      sortBy: sortObject?.sortBy,
      showLoaderOnFullScreen: false,
      filters: attorneyTableFilter?.lastQuery?.filters,
    });
  };

  const setColumnsHandle = async (modifiedColumns) => {
    const checkedAllColumnsNotDisplayed = modifiedColumns?.every(
      (column) => column?.hidden === true
    );
    const columnsWithActionRowNotHidden = modifiedColumns.map((column) => {
      if (column?.key === 'actions') return { ...column, hidden: false };
      else return { ...column };
    });

    if (!checkedAllColumnsNotDisplayed) {
      await updateTableColumns({
        userId,
        tableName,
        prevColumns: columns.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
        columns: columnsWithActionRowNotHidden?.map((x) => {
          return { key: x?.key, hidden: x?.hidden };
        }),
      });
      setColumns(columnsWithActionRowNotHidden);
    } else {
      let prevColumns = [];
      setColumns((prev) => {
        prevColumns = [...prev];
        return [...prev];
      });
      await updateTableColumns({
        userId,
        tableName,
        prevColumns: columns.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
        columns: prevColumns?.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
      });
    }
  };

  const onColumnsReset = async () => {
    const data = [];
    const allClientName = [];
    const defaultColumns = getDefaultColumnOfAttorneyTable(
      allClientName,
      data,
      setDelModal,
      locations ?? [],
      lawSchools ?? [],
      setDeleteRowId
    );
    await updateTableColumns({
      userId,
      tableName,
      columns: defaultColumns.map(({ key, hidden }) => ({
        key,
        hidden,
      })),
      prevColumns: columns.map(({ key, hidden }) => ({
        key,
        hidden,
      })),
    });
    setColumns([...defaultColumns]);
    setChoseColModal(false);
  };

  const getDataFromAttorneyAfterApplyingFilter = async ({
    filterData,
    newCalculation,
    currentValue = 'Now',
  }) => {
    setResetChip(false);
    setDirectoryFilters((prev) => ({
      ...prev,
      page: 1,
      dateRange,
      currentValue,
      filters: filterData,
      extraParams: {
        showLoaderOnFullScreen: false,
      },
    }));
    dispatch(
      setAttorneyTableFilter({
        ...attorneyTableFilter,
        currentValue,
        filters: filterData,
        calculationSelected: newCalculation,
      })
    );
  };
  const convertSelectedStatsTableCalculationToFilter = async (value) => {
    let _currentValue = currentValue;
    let _dateRange = dateRange;
    let getCalculations = false;

    const _filters = getStatsFilters({
      calculationSelected: value,
    });

    if (value?.includes('(Cumulative)')) {
      getCalculations = currentValue === 'Cumulative' ? false : true;
      _currentValue = 'Cumulative';
      _dateRange = [null, null];
    }

    dispatch(
      setAttorneyTableFilter({
        ...attorneyTableFilter,
        calculationSelected: value,
        filters: {
          mainFilters: filters?.mainFilters || {},
          statsFilters: _filters,
        },
        currentValue: _currentValue,
        dateRange: _dateRange,
      })
    );

    setDirectoryFilters((prev) => ({
      ...prev,
      page: 1,
      filters: {
        mainFilters: filters?.mainFilters || {},
        statsFilters: _filters,
      },
      currentValue: _currentValue,
      extraParams: {
        attorneyCalculation: getCalculations,
        showLoaderOnFullScreen: false,
      },
    }));
  };

  const updateAttorneyDataHandleEvent = async (updatedRows) => {
    setTableLoadingOnFiltering(true);
    const { createBarHistory, updateBarHistory } =
      getUpdateAndCreateLastBarStatusArray(updatedRows, data);

    const dataForUpdate = updatedRows.map((x) => {
      const matchingLocation = locations.find(
        (location) => location.location === x?.attorneyLocation
      );
      return {
        ...x,
        firstName: x?.firstName || '',
        lastName: x?.lastName || '',
        liStatus: x?.liStatus || '',
        attorneyDemo: x?.attorneyDemo || [],
        attorneyGender: x?.attorneyGender || '',
        attorneyStartDate: x?.attorneyStartDate || '',
        attorneyLawSchool: x?.attorneyLawSchool || '',
        attorneyLocation:
          (x?.attorneyLocation && [matchingLocation?._id]) || [],
        attorneyGradYear: _.isEmpty(x?.attorneyGradYear)
          ? moment(x?.attorneyGradYear).format('YYYY')
          : undefined,
        attorneyParkerScore: (x?.attorneyParkerScore || '').toString(),
      };
    });

    await updateAllAttorneysDirectory(
      dataForUpdate,
      createBarHistory,
      updateBarHistory
    );

    await fetchCalculations({ currentValue, dateRange });
    setTableLoadingOnFiltering(false);
    setEditTable(false);
  };

  const unCheckAll = () => {
    setNoMatch(false);
    setResetChip(true);
    dispatch(setCheckedRowsData([], 'ATTORNEY'));
    setCopyData((prev) => {
      let prevCopy = [...prev];
      for (const el of prevCopy) {
        el.checked = false;
      }
      return prevCopy;
    });
    ref.current?.clearAllCheckRows();

    setDirectoryFilters((prev) => ({
      ...prev,
      extraParams: {
        showLoaderOnFullScreen: false,
        attorneyCalculation: true,
      },
    }));
  };

  const clearFilters = () => {
    setNoMatch(false);
    headerRef.current?.clearAllFiltersWhenNoRowIsMatched(currentValue);
  };

  const deleteAttorneys = async () => {
    await deleteAttorneyById(deleteRowId);
    unCheckAll();
    setDelModal(false);
    setDeleteRowId(null);
    let newDataRowAfterDeleteAttorney = [...data];
    newDataRowAfterDeleteAttorney = newDataRowAfterDeleteAttorney?.filter(
      (x) => x?._id !== deleteRowId
    );
    setData([...newDataRowAfterDeleteAttorney]);
    setPagination((prev) => {
      return {
        ...prev,
        totalDocuments: prev?.totalDocuments - 1,
      };
    });

    await fetchCalculations({ currentValue, dateRange });
  };

  const setColumnFieldDisabledBasedOnCurrentValue = () => {
    const _columns = [...columns];
    let newColumn = [];
    if (editTable) {
      if (currentValue === 'Now') {
        newColumn = _columns?.map((column) => {
          if (column.key === 'lastBarStatus')
            return { ...column, editable: true };
          if (column.key === 'attorneyLocation')
            return { ...column, editable: true };
          if (column.key === 'liStatus')
            return {
              ...column,
              editable: true,
              showToolTipOnUnEditableField: false,
            };

          if (column.key === 'barLocations')
            return { ...column, showToolTipOnUnEditableField: false };

          return column;
        });
      } else if (currentValue === 'Cumulative' || currentValue === 'Custom') {
        newColumn = _columns?.map((column) => {
          if (column.key === 'lastBarStatus')
            return {
              ...column,
              editable: false,
              showToolTipOnUnEditableField: true,
            };
          if (column.key === 'attorneyLocation')
            return {
              ...column,
              editable: false,
              showToolTipOnUnEditableField: true,
            };
          if (column.key === 'liStatus')
            return {
              ...column,
              showToolTipOnUnEditableField: true,
              editable: false,
            };
          if (column.key === 'barLocations')
            return { ...column, showToolTipOnUnEditableField: true };
          return column;
        });
      }
    } else
      newColumn = _columns?.map((column) => {
        if (column.key === 'lastBarStatus')
          return {
            ...column,
            editable: true,
            showToolTipOnUnEditableField: false,
          };
        if (column.key === 'attorneyLocation')
          return {
            ...column,
            editable: true,
            showToolTipOnUnEditableField: false,
          };
        if (column.key === 'liStatus')
          return { ...column, showToolTipOnUnEditableField: false };
        if (column.key === 'barLocations')
          return { ...column, showToolTipOnUnEditableField: false };

        return column;
      });

    if (newColumn?.length > 0) {
      setColumns([...newColumn]);
      ref.current && (ref.current.scrollTop = 0);
      ref.current && (ref.current.scrollLeft = 0);
    }
  };

  useEffect(() => {
    (async () => {
      dispatch(setSchools);
      dispatch(setClientNames);
      const { data } = await getClientPosition();
      const allClientName =
        data?.map((x) => {
          return {
            label: x?.clientName,
            value: x?._id,
          };
        }) || [];

      const _attorneyTableColumns = await getTableColumns({
        userId,
        tableName,
      });

      const defaultColumns = getDefaultColumnOfAttorneyTable(
        allClientName,
        data, // all positions
        setDelModal,
        locations ?? [],
        lawSchools ?? [],
        setDeleteRowId
      );
      if (_.size(_attorneyTableColumns) > 0) {
        let defaultColumnsCopy = defaultColumns?.reduce((acc, curr) => {
          acc[curr.key] = curr;
          return acc;
        }, {});
        let defaultColumnStoreSorting = [];
        _attorneyTableColumns?.map((v) => {
          if (defaultColumnsCopy[v.key])
            defaultColumnStoreSorting.push({
              ...defaultColumnsCopy[v.key],
              hidden: v.hidden,
            });
        });
        setColumns(defaultColumnStoreSorting);
      } else {
        await updateTableColumns({
          userId,
          tableName,
          columns: defaultColumns.map(({ key, hidden }) => ({
            key,
            hidden,
          })),
          prevColumns: _attorneyTableColumns.map(({ key, hidden }) => ({
            key,
            hidden,
          })),
        });
        setColumns([...defaultColumns]);
      }
    })();
  }, []);

  useEffect(() => {
    const isFirst = isFirstRender.current;

    const lastQuery =
      isFirstRender.current && !_.isEmpty(attorneyTableFilter?.lastQuery)
        ? attorneyTableFilter?.lastQuery
        : directoryFilters;

    const newQuery = {
      attorneyDirectoryChecked,
      page: directoryFilters?.page,
      order: directoryFilters?.order,
      sortBy: directoryFilters?.sortBy,
      filters: directoryFilters?.filters,
      dateRange: directoryFilters?.dateRange,
      currentValue: directoryFilters?.currentValue,
      attorneyCalculation: directoryFilters?.extraParams?.attorneyCalculation,
      showLoaderOnFullScreen: false,
    };

    const currentQuery = {
      ...(isFirst ? lastQuery : newQuery),
      ...(isFirst && {
        attorneyCalculation: true,
        showLoaderOnFullScreen: true,
      }),
    };

    dispatch(
      setAttorneyTableFilter({
        ...attorneyTableFilter,
        lastQuery: currentQuery,
      })
    );

    fetchAttorneys(currentQuery);

    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [directoryFilters]);

  useEffect(() => {
    setColumnFieldDisabledBasedOnCurrentValue();
  }, [currentValue, editTable]);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#39695b');
    document.documentElement.style.setProperty(
      '--primary-color-of-column-change',
      '#39695b'
    );
    document.documentElement.style.setProperty(
      '--primary-color-of-stats',
      '#39695b'
    );
  }, []);

  return (
    <>
      {loading && (
        <div
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100%',
            height: '100vh',
            background: '#F8FFFD',
            zIndex: 50000,
          }}
        >
          <Loader
            cStyles={{
              backgroundColor: '#F8FFFD',
            }}
          />
        </div>
      )}
      <Layout
        sidebarLinks={sideBarLinks}
        current={calculationSelected}
        setCurrent={convertSelectedStatsTableCalculationToFilter}
        openArrow={openArrow}
        setOpenArrow={setOpenArrow}
      >
        {data.length > -1 ||
        Object.values(filters).filter((x) => x).length !== 0 ||
        dateRange?.filter((x) => x).length !== 0 ||
        tableLoadingOnFiltering ? (
          <>
            <AttorneyHeader
              {...{
                search,
                filters,
                dateRange,
                unCheckAll,
                openFilter,
                setResetChip,
                setOpenFilter,
                ref: headerRef,
                setDirectoryFilters,
                getDataFromAttorneyAfterApplyingFilter,
                setPageSize: (page) => {
                  setPagination((prev) => {
                    return {
                      ...prev,
                      page,
                    };
                  });
                },
              }}
            />
            <div className={style.btnClass}>
              <p className={style.all}>
                Results (
                {(!noMatch && checkRowIds?.length < data?.length
                  ? checkRowIds?.length
                  : data?.length) || data?.length}
                )
              </p>

              <div className={style.btn}>
                {editTable ? (
                  <>
                    <Button
                      text={'Cancel'}
                      iconStart={crossIcon}
                      btnClass={style.cancelBtn}
                      handleClick={() => setEditTable(false)}
                    />
                    <Button
                      text={'Save'}
                      iconStart={saveIcon}
                      type="submit"
                      form="overview-form"
                      btnClass={style.defaultClass}
                    />
                  </>
                ) : (
                  <>
                    <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                      <Button
                        iconStart={edit}
                        text="Edit"
                        handleClick={() => setEditTable(true)}
                        btnClass={style.chooseColumnBtn}
                      />
                    </PermissionRestrict>
                    <Button
                      type="button"
                      iconStart={exportIcon}
                      text="Export"
                      handleClick={async () =>
                        await downloadAttorneyDirectoryIntoCsvHandleEvent()
                      }
                      btnClass={style.chooseColumnBtn}
                    />
                    <Button
                      iconStart={columnsImg}
                      text="Change Columns"
                      handleClick={() => setChoseColModal(true)}
                      btnClass={style.chooseColumnBtn}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={style.btnClass} style={{ marginTop: '20px' }}>
              <div
                className={style.chipsResultDiv}
                style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
              >
                <ChipsFilter
                  chipsData={filters?.mainFilters}
                  resetChip={resetChip}
                />
              </div>
            </div>

            {data.length == 0 ? (
              !tableLoadingOnFiltering ? (
                <>
                  {noMatch &&
                    data
                      .map((x) => x._id)
                      .filter((x) => checkRowIds.includes(x)).length === 0 && (
                      <div className={style.noMatch}>
                        <span
                          style={{
                            marginBottom: '5px',
                          }}
                        >
                          Oops! Looks like no checked rows match your filters.
                        </span>
                        <Button
                          text="Uncheck All"
                          handleClick={unCheckAll}
                          style={{
                            marginRight: '10px',
                          }}
                        />
                        <Button
                          text="Clear Filters"
                          handleClick={clearFilters}
                        />
                      </div>
                    )}
                  <AddAttorney modalHeight="400px" showLabel={false} />
                </>
              ) : (
                <Spinner pageLoader={true} diffHeight="501" />
              )
            ) : (
              <>
                {noMatch &&
                  data.map((x) => x._id).filter((x) => checkRowIds.includes(x))
                    .length === 0 && (
                    <div className={style.noMatch}>
                      <span
                        style={{
                          marginBottom: '5px',
                        }}
                      >
                        Oops! Looks like no checked rows match your filters.
                      </span>
                      <Button
                        text="Uncheck All"
                        handleClick={unCheckAll}
                        style={{
                          marginRight: '10px',
                        }}
                      />
                      <Button text="Clear Filters" handleClick={clearFilters} />
                    </div>
                  )}
                <AttorneyTable
                  {...{
                    rows: data,
                    ref,
                    columns,
                    openFilter,
                    setOpenFilter,
                    filters,
                    noMatch,
                    editTable,
                    setCheckedRowData,
                    order: sort?.order,
                    sortBy: sort?.sortBy,
                    page: pagination?.page,
                    formId: 'overview-form',
                    getNextData: getNextAttorneyData,
                    loading: tableLoadingOnFiltering || loading,
                    getSelectedSorting: getSelectedSortingHandleEvent,
                    updateAttorneyData: updateAttorneyDataHandleEvent,
                  }}
                />
              </>
            )}
          </>
        ) : (
          <AddAttorney />
        )}
      </Layout>
      <ColumnModal
        choseColModal={choseColModal}
        setChoseColModal={setChoseColModal}
        columns={[...columns]}
        setColumns={setColumnsHandle}
        onColumnsReset={onColumnsReset}
      />
      <DeleteModal
        openDelModal={delModal}
        setOpenDelModal={setDelModal}
        del={delIcon}
        color={style.colorText}
        btnYes={style.btnYes}
        btnNo={style.btnNo}
        handleClick={deleteAttorneys}
        iconSize="48px"
        delText={A01_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.body}
        btnText={A01_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.yes}
        btn1Text={A01_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.no}
      />
    </>
  );
};
export default React.memo(AttorneyDirectory);

const getStatsFilters = ({ calculationSelected }) => {
  let _filter = {};

  const filters = {
    All: {
      barFails: null,
      A_Latest_LIStatus: [],
    },
    Unplaced: {
      A_Latest_LIStatus: ['Not Interviewing', 'Interviewing_LI'],
    },
    'LI Attorneys': {
      A_Latest_LIStatus: ['Not Interviewing', 'Interviewing_LI', 'Placed'],
    },
    Pipeline: {
      A_Latest_LIStatus: ['Pipeline', 'Interviewing_P'],
    },
    'Converted (Cumulative)': {
      A_Latest_LIStatus: ['Converted'],
    },
    'Pipeline (Total)': { A_Latest_LIStatus: ['Pipeline'] },
    'Pipeline (Pure)': { A_Latest_LIStatus: ['Pipeline'] },
    'Bar Fails (2)': { barFails: '2' },
    'Bar Fails (1)': { barFails: '1' },
    'Returned (Cumulative)': { A_Latest_LIStatus: [] },
  };

  if (!_.isEmpty(calculationSelected)) {
    if (filters[calculationSelected]) {
      _filter = filters[calculationSelected?.trim()];
    } else if (
      ATTRONEY_STATUS_OPTIONS.map(({ value }) => value).includes(
        calculationSelected
      )
    ) {
      _filter = { A_Latest_LIStatus: [calculationSelected] };
    }
  }

  return _filter;
};

const initialPagination = {
  pageSize: 30,
  page: 1,
  totalPages: 1,
  totalDocuments: 0,
};
