import React from 'react';

import Button from 'components/button';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { ROLES } from 'permissions/constants';

import addIcon from 'assets/images/addIcon.svg';
import addIconBlue from 'assets/images/addIconBlue.svg';
import NoRecordsIcon from 'assets/icons/noRecords.svg';

import style from './empty-history.module.scss';

const EmptyHistory = ({ btnText, handleClick, isSmall, isBlue }) => {
  return (
    <div className={`${style.wraper} ${isSmall ? style.small : ''}`}>
      <PermissionRestrict
        fallback={
          <div className={style.noRecord}>
            <img
              src={NoRecordsIcon}
              height={56}
              width={56}
              className={style.noRecord}
            />
            <p>No records</p>
          </div>
        }
        roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.ATTORNEY]}
      >
        <Button
          text={btnText}
          handleClick={handleClick}
          btnClass={`${style.btnWraper} ${isBlue ? style.isBlue : ''}`}
          className={`${style.btnClass} ${isBlue ? style.isBlueText : ''}`}
          iconStart={isBlue ? addIconBlue : addIcon}
        />
      </PermissionRestrict>
    </div>
  );
};

export default EmptyHistory;
