import moment from 'moment';

import * as Actions from './constants';
import { createNotification } from 'components/react-notification';
import { systemValuesService } from 'services/system-values';

export const setLocations = () => {
  return async (dispatch) => {
    const response = await systemValuesService.getSystemLocations({});
    dispatch({
      type: Actions.SET_LOCATIONS,
      payload: response.data.locations,
    });
    return {
      type: Actions.SET_LOCATIONS,
      payload: response.data.locations,
    };
  };
};

export const setPracticeGroups = () => {
  return async (dispatch) => {
    const response = await systemValuesService.getSystemPracticeGroups({});
    dispatch({
      type: Actions.SET_PRACTICE_GROUPS,
      payload: response.data.practiceGroups,
    });
    return {
      type: Actions.SET_PRACTICE_GROUPS,
      payload: response.data.practiceGroups,
    };
  };
};

export const setStates = () => {
  return async (dispatch) => {
    const response = await systemValuesService.getSystemBarStates({});
    dispatch({
      type: Actions.SET_STATES,
      payload: response.data.barStates,
    });
    return {
      type: Actions.SET_STATES,
      payload: response.data.barStates,
    };
  };
};

export const setWeights = () => {
  return async (dispatch) => {
    const response = await systemValuesService.getSystemWeights({});
    dispatch({
      type: Actions.SET_WEIGHTS,
      payload: response.data.systemWeights,
    });
    return {
      type: Actions.SET_WEIGHTS,
      payload: response.data.systemWeights,
    };
  };
};

export const updateLocations = (item, id) => {
  return async (dispatch) => {
    try {
      const response = await systemValuesService.addSystemLocations(item, id);
      dispatch({
        type: Actions.UPDATE_LOCATION,
        payload: response.data.status,
      });
    } catch (err) {
      createNotification('error', 'Error', err?.response?.data?.msg, 3000);
    }
  };
};

export const updatePracticeGroup = (item, id) => {
  return async (dispatch) => {
    try {
      const response = await systemValuesService.addSystemPracticeGroups(
        item,
        id
      );
      dispatch({
        type: Actions.UPDATE_PRACTICE_GROUP,
        payload: response.data.practice,
      });
    } catch (err) {
      createNotification('error', 'Error', err?.response?.data?.msg, 3000);
    }
  };
};

export const updateState = (item, id) => {
  return async (dispatch) => {
    try {
      const response = await systemValuesService.addSystemBarStates(item, id);
      dispatch({
        type: Actions.UPDATE_STATE,
        payload: response.data.bar,
      });
    } catch (err) {
      createNotification('error', 'Error', err?.response?.data?.msg, 3000);
    }
  };
};

export const updateWeight = (weights, weightId) => {
  return async (dispatch) => {
    const response = await systemValuesService.editAlgoWeights(
      weightId,
      weights
    );
    createNotification('success', 'Success', response?.data?.message, 3500);
    dispatch({
      type: Actions.UPDATE_WEIGHT,
      payload: response.data.systemWeight,
    });
  };
};

export const deleteLocation = (_id, exportCSV) => {
  return async (dispatch) => {
    const res = await systemValuesService.deleteSystemLocation(_id, exportCSV);
    downloadAttorneyAndPositionsCSVs(res);
    dispatch({
      type: Actions.DELETE_LOCATION,
      payload: _id,
    });
  };
};

const downloadAttorneyAndPositionsCSVs = (res) => {
  const currentDate = moment().format('MMM DD, YYYY HH:mm:ss a');
  if (res?.data[0]) {
    const fileURL = window.URL.createObjectURL(new Blob([res?.data[0]]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute(
      'download',
      `Deleted-Attorney-Records-${currentDate}.csv`
    );
    fileLink.setAttribute('target', '_blank');
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }
  if (res?.data[1]) {
    const fileURL = window.URL.createObjectURL(new Blob([res?.data[1]]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute(
      'download',
      `Deleted-Position-Records-${currentDate}.csv`
    );
    fileLink.setAttribute('target', '_blank');
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }
};

export const deletePracticeGroup = (_id, exportCSV) => {
  return async (dispatch) => {
    const res = await systemValuesService.deleteSystemPracticeGroups(
      _id,
      exportCSV
    );
    downloadAttorneyAndPositionsCSVs(res);

    dispatch({
      type: Actions.DELETE_PRACTICE_GROUP,
      payload: _id,
    });
  };
};

export const deleteState = (_id, exportCSV) => {
  return async (dispatch) => {
    const res = await systemValuesService.deleteSystemBarStates(_id, exportCSV);
    downloadAttorneyAndPositionsCSVs(res);
    dispatch({
      type: Actions.DELETE_STATE,
      payload: _id,
    });
  };
};

export const addLocation = (item) => {
  return async (dispatch) => {
    try {
      const response = await systemValuesService.addSystemLocations(item);
      dispatch({
        type: Actions.ADD_LOCATION,
        payload: response.data.location,
      });
    } catch (err) {
      createNotification('error', 'Error', err?.response?.data?.msg, 3000);
    }
  };
};

export const addPracticeGroup = (item) => {
  return async (dispatch) => {
    try {
      const response = await systemValuesService.addSystemPracticeGroups(item);
      dispatch({
        type: Actions.ADD_PRACTICE_GROUP,
        payload: response.data.practiceGroup,
      });
    } catch (err) {
      createNotification('error', 'Error', err?.response?.data?.msg, 3000);
    }
  };
};

export const addState = (item) => {
  return async (dispatch) => {
    try {
      const response = await systemValuesService.addSystemBarStates(item);
      dispatch({
        type: Actions.ADD_STATE,
        payload: response.data.barState,
      });
    } catch (err) {
      createNotification('error', 'Error', err?.response?.data?.msg, 3000);
    }
  };
};
