import moment from 'moment';
import React, { Fragment } from 'react';

import Button from 'components/button';
import Loader from 'components/loader';
import Modal from 'components/modal';
import NoRecords from 'components/no-records';
import PermissionRestrict from 'hoc/PermissionRestrict';
import style from './comment.module.scss';
import EditComments from './edit-comments';

import { P02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import { ROLES } from 'permissions/constants';
import useHelper from './helper';

import editIcon from 'assets/icons/edit-purple.svg';
import deleteIcon from 'assets/images/purpleDelIcon.svg';
import addIcon from 'assets/images/whiteAddIcon.svg';

const Comments = ({
  active,
  comments,
  isLoading,
  setDisable,
  getPositionCommentData,
}) => {
  const {
    edit,
    open,
    onAdd,
    toggle,
    onEdit,
    setOpen,
    setEdit,
    setToggle,
    isDeleting,
    handleDelete,
  } = useHelper({ active, getPositionCommentData, setDisable });

  return (
    <div className={style.wraper}>
      {isLoading ? (
        <Loader />
      ) : comments.length < 1 ? (
        !toggle && (
          <NoRecords
            noRecord={P02_SCREEN_MESSAGES.emptyPlaceholder.comments}
            icon={true}
            btnText={'Add Comment'}
            onClick={() => {
              setToggle(true);
              setDisable(true);
            }}
          />
        )
      ) : (
        <>
          <span className={style.comment}>Comments</span>
          <div className={style.scrollDiv}>
            <div className={style.innerScrollDiv}>
              {comments?.map((item, index) => {
                return (
                  <Fragment key={item._id}>
                    {index === edit ? (
                      <EditComments
                        data={item}
                        index={index}
                        setEdit={setEdit}
                        onSubmit={onEdit}
                        comments={comments}
                        setToggle={setToggle}
                        setDisable={setDisable}
                        getPositionCommentData={getPositionCommentData}
                      />
                    ) : (
                      <div className={style.mainTab}>
                        <div style={{ width: '1500px' }}>
                          <div className={style.header}>
                            {' '}
                            <span className={style.dateSec}>
                              Last Updated on
                            </span>{' '}
                            <span
                              className={style.dateSec}
                              style={{ marginLeft: '5px' }}
                            >
                              {`${moment(item?.updatedAt).format('MM/DD/YY')}`}
                            </span>
                            <span
                              className={style.dateSec}
                              style={{ marginLeft: 5 }}
                            >
                              by {item.userName}
                            </span>
                          </div>
                          <div
                            style={{
                              paddingTop: '4px',
                              paddingRight: '32px',
                              overflowWrap: 'anywhere',
                            }}
                          >
                            <span
                              className={style.commentVal}
                              style={{
                                whiteSpace: 'pre-line',
                              }}
                            >
                              {item.comment}
                            </span>
                          </div>
                        </div>
                        <div className={style.btnSection}>
                          <PermissionRestrict
                            roles={[ROLES.ADMIN, ROLES.EDITOR]}
                          >
                            <img
                              onClick={() => {
                                setEdit(index);
                                setDisable(true);
                                setToggle(false);
                              }}
                              src={editIcon}
                              width={25}
                              style={{ marginRight: '10px' }}
                            />
                            <img
                              src={deleteIcon}
                              onClick={() => setOpen(item._id)}
                              width={20}
                            />
                          </PermissionRestrict>
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </>
      )}
      {toggle && (
        <EditComments
          setDisable={setDisable}
          setEdit={setEdit}
          open={open}
          comments={comments}
          setToggle={setToggle}
          onSubmit={onAdd}
          getPositionCommentData={getPositionCommentData}
        />
      )}
      {!isLoading && comments?.length >= 1 && (
        <div style={{ margin: '24px 0px' }}>
          {!toggle && (
            <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
              <Button
                text={'Add Comment'}
                btnClass={style.btnWraper}
                iconStart={addIcon}
                isLoading={isLoading}
                handleClick={() => {
                  setEdit(false);
                  setToggle(true);
                  setDisable(true);
                }}
              />
            </PermissionRestrict>
          )}
        </div>
      )}
      <Modal open={open} handleClose={() => setOpen(false)}>
        <div className={style.modalHeader}>
          <img src={deleteIcon} height={48} width={48} />
          <h3 className={style.deleteText}>Delete Comment</h3>
          <p>Are you sure you want to delete this comment?</p>
          <Button
            btnClass={style.buttonStyle}
            text={P02_SCREEN_MESSAGES.popups.deleteConfirm.comment.yes}
            handleClick={handleDelete}
            isLoading={isDeleting}
            width="100%"
          />
          <h3 className={style.cancelText} onClick={() => setOpen(false)}>
            {P02_SCREEN_MESSAGES.popups.deleteConfirm.comment.no}
          </h3>
        </div>
      </Modal>
    </div>
  );
};

export default Comments;
