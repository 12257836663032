import React from 'react';

import Button from 'components/button';
import Modal from 'components/modal';

import del from 'assets/icons/double-tick.svg';

import style from './remove-confirm-modal.module.scss';

const RemoveConfirm = ({ removeStatusConfirm, setRemoveStatusConfirm }) => {
  return (
    <div>
      <Modal
        open={removeStatusConfirm}
        handleClose={() => setRemoveStatusConfirm(false)}
        className={style.mainDiv}
      >
        <div className={style.iconRefresh}>
          <img src={del} alt="" />
        </div>
        <p className={style.modalTitle}>
          The {removeStatusConfirm?.deletedStatus} status for{' '}
          {removeStatusConfirm?.attorneyName} has been removed.
        </p>

        <div className={style.mainBtnDiv}>
          <Button
            text={'OK'}
            btnClass={style.btnClassModal}
            handleClick={() => {
              setRemoveStatusConfirm(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RemoveConfirm;
