import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { systemValuesService } from 'services/system-values';
import {
  addState,
  setStates,
  addLocation,
  updateState,
  deleteState,
  setLocations,
  deleteLocation,
  updateLocations,
  addPracticeGroup,
  setPracticeGroups,
  deletePracticeGroup,
  updatePracticeGroup,
} from 'redux/system-values/system-values-actions';

import ImportData from './import-data';
import Loading from 'components/loading';
import Container from 'components/container';
import SystemValuesTab from './system-values-tab';
import DefaultAlgoWeight from './default-algo-weight';

import style from './system-value.module.scss';

const SystemValues = () => {
  const dispatch = useDispatch();
  const values = useSelector((state) => state.systemValues);

  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [addPopUp, setAddPopUp] = useState(false);
  const [editPopUp, setEditPopUp] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false);
  const sections = [...sections1({ dispatch })];

  useEffect(() => {
    setEditPopUp(false);
    setAddPopUp(false);
  }, [active]);

  return (
    <>
      <div className={style.mainHeight}>
        <Container>
          <div className={style.main}>
            <p className={style.heading}>System Values</p>
            <div className={style.mainTabs}>
              <div className={style.tabsDiv}>
                <p
                  className={style.p}
                  style={{
                    color: active === 0 ? '#304059' : '',
                    borderBottom: active === 0 ? '1px solid #F58020' : '',
                  }}
                  onClick={() => setActive(0)}
                >
                  Practice Groups
                </p>
                <p
                  className={style.p}
                  style={{
                    color: active === 1 ? '#304059' : '',
                    borderBottom: active === 1 ? '1px solid #F58020' : '',
                  }}
                  onClick={() => setActive(1)}
                >
                  Locations
                </p>
                <p
                  className={style.p}
                  style={{
                    color: active === 2 ? '#304059' : '',
                    borderBottom: active === 2 ? '1px solid #F58020' : '',
                  }}
                  onClick={() => setActive(2)}
                >
                  Bar States
                </p>
                <p
                  className={style.p}
                  style={{
                    color: active === 3 ? '#304059' : '',
                    borderBottom: active === 3 ? '1px solid #F58020' : '',
                  }}
                  onClick={() => setActive(3)}
                >
                  Default Algo Weights
                </p>
                <p
                  className={style.p}
                  style={{
                    color: active === 4 ? '#304059' : '',
                    borderBottom: active === 4 ? '1px solid #F58020' : '',
                  }}
                  onClick={() => setActive(4)}
                >
                  Import Data
                </p>
              </div>
            </div>
          </div>
          <div className={style.mainWrapper}>
            {loading && (
              <div className={style.loader}>
                <Loading loaderClass={style.loading} />
              </div>
            )}
            {active < 3 && (
              <SystemValuesTab
                {...sections[active]}
                editPopUp={editPopUp}
                setEditPopUp={setEditPopUp}
                addPopUp={addPopUp}
                setAddPopUp={setAddPopUp}
                openDelModal={openDelModal}
                setOpenDelModal={setOpenDelModal}
                setLoading={setLoading}
                values={values[sections[active].reduxKey]}
                active={active}
              />
            )}

            {active === 3 && (
              <DefaultAlgoWeight
                editPopUp={editPopUp}
                setLoading={setLoading}
                values={values.weights}
                setEditPopUp={setEditPopUp}
              />
            )}
            {active === 4 && (
              <ImportData
                editPopUp={editPopUp}
                setLoading={setLoading}
                values={values.weights}
                setEditPopUp={setEditPopUp}
              />
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default SystemValues;

const sections1 = ({ dispatch }) => [
  {
    getData: () => dispatch(setPracticeGroups()),
    fetchData: systemValuesService.getSystemPracticeGroups,
    fetchKey: 'practiceGroups',
    deleteHandler: deletePracticeGroup,
    addHandler: addPracticeGroup,
    editHandler: updatePracticeGroup,
    title: 'Practice Groups',
    valueKey: 'practiceGroup',
    placeholder: 'e.g., Litigation',
    label: 'Practice Group',
    deleteLabel: 'practice group',
    buttonText: 'Add Practice Group',
    reduxKey: 'practiceGroups',
  },
  {
    getData: () => dispatch(setLocations()),
    fetchData: systemValuesService.getSystemLocations,
    fetchKey: 'locations',
    deleteHandler: deleteLocation,
    addHandler: addLocation,
    editHandler: updateLocations,
    title: 'Locations',
    valueKey: 'location',
    placeholder: 'e.g., New York',
    label: 'City',
    addLabel: 'City',
    deleteLabel: 'location',
    buttonText: 'Add Location',
    reduxKey: 'locations',
  },
  {
    getData: () => dispatch(setStates()),
    fetchData: systemValuesService.getSystemBarStates,
    fetchKey: 'barStates',
    deleteHandler: deleteState,
    addHandler: addState,
    editHandler: updateState,
    title: 'Bar States',
    valueKey: 'barState',
    placeholder: 'e.g., NY',
    label: 'Bar State',
    addLabel: 'Bar State',
    deleteLabel: 'bar state',
    buttonText: 'Add Bar State',
    reduxKey: 'states',
  },
];
