import {
  getAttorneyByEmail,
  getClientName,
  setAttorneyUpdated,
} from 'services/attorney-profile';
import { getSchools } from 'services/attorny-directory-table';
import * as Actions from './constants';

export const getAttorneyReduxData = (email) => async (dispatch) => {
  const response = await getAttorneyByEmail(email);
  dispatch(setAttorneyReduxData(response.data));
};

export const setSchools = async (dispatch) => {
  const { data } = await getSchools();
  dispatch({
    type: Actions.SET_SCHOOL_DATA,
    payload: data?.lawSchools ?? [],
  });
};

export const setClientNames = async (dispatch) => {
  const { data } = await getClientName();
  dispatch({
    type: Actions.SET_CLIENT_DATA,
    payload: data?.clientData ?? [],
  });
};

export const setAttorneyUpdatedRedux = (id, email) => async (dispatch) => {
  await setAttorneyUpdated(id);
  dispatch(getAttorneyReduxData(email));
};

export const setAttorneyReduxData = (item) => ({
  type: Actions.SET_ATTORNEY_DATA,
  payload: item,
});
