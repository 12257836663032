import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import Button from 'components/button';
import TextArea from 'components/textarea';

import { createNotification } from 'components/react-notification';
import { setComments } from 'services/attorney-profile';

import purpleCrossIcon from 'assets/images/purpleCrossIcon.svg';
import saveIcon from 'assets/images/save.svg';

import style from './comment.module.scss';

const EditComments = ({
  data,
  index,
  setEdit,
  setToggle,
  setDisable,
  onSubmit: submitted,
  getPositionCommentData,
}) => {
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { ...data },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { comment } = data;
    try {
      const response = await setComments(
        {
          comment,
          commentFor: id,
          commentType: 'placements',
        },
        data._id
      );
      submitted(response.data.comment);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error('err', err.response.data);
    }
    setEdit(false);
    setToggle(false);
    await getPositionCommentData();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={style.addCmntSection}
      key={index}
    >
      <TextArea
        label={'Comment'}
        placeholder={'Start typing'}
        name="comment"
        register={register}
        errorMessage={errors?.comment?.message}
      />

      <div className={style.btnSection}>
        <img
          src={purpleCrossIcon}
          onClick={() => {
            setToggle(false);
            setEdit(false);
            setDisable(false);
          }}
        />
        <Button
          text={'Save'}
          type={'submit'}
          width="100px"
          iconStart={saveIcon}
          btnClass={style.btnClass}
          isLoading={isSubmitting}
          onClick={() => {
            setDisable(false);
          }}
        />
      </div>
    </form>
  );
};

export default EditComments;

const schema = yup
  .object({
    comment: yup.string().required('Comment is required'),
  })
  .required();
