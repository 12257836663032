import { UserState } from './attorney-state';
import * as Actions from './constants';

const INITIAL_STATE = new UserState();

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Actions.SET_ATTORNEY_DATA: {
      return {
        ...state,
        attorneyData: action.payload,
      };
    }

    case Actions.SET_SCHOOL_DATA: {
      return {
        ...state,
        lawSchools: action.payload,
      };
    }

    case Actions.SET_CLIENT_DATA: {
      return {
        ...state,
        clientNames: action.payload,
      };
    }

    default:
      return state;
  }
}
