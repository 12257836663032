import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

import { useOutsideClickHook } from 'hooks/useOnClickOutsideEvent';

import dateLeftBlue from 'assets/icons/date-icons/left-blue.svg';
import dateLeftGreen from 'assets/icons/date-icons/left-green.svg';
import dateLeftPink from 'assets/icons/date-icons/left-pink.svg';
import dateLeftPurple from 'assets/icons/date-icons/left-purple.svg';
import dateRightBlue from 'assets/icons/date-icons/right-blue.svg';
import dateRightGreen from 'assets/icons/date-icons/right-green.svg';
import dateRightPink from 'assets/icons/date-icons/right-pink.svg';
import dateRightPurple from 'assets/icons/date-icons/right-purple.svg';
import datePickerPink from 'assets/icons/pink-date.svg';
import datePickerIconPurple from 'assets/icons/purple-date.svg';
import datePickerIcon from 'assets/images/datePickerIcon.svg';
import datePickerIconBlue from 'assets/images/datePickerIconBlue.svg';
import 'react-datepicker/dist/react-datepicker.css';
import style from './date.module.scss';
import './index.css';

const DatePicker = ({
  id,
  name,
  label,
  isblue,
  isPink,
  control,
  maxDate,
  minDate,
  isPurple,
  monthYear,
  monthDate,
  className,
  popparBack,
  defaultVal,
  inputClass,
  placeholder,
  handleChange,
  errorMessage,
  showYearPicker,
  isDisable = false,
  isClearable = false,
  selectsRange = false,
  reactDatePickerClass,
}) => {
  const wrapperRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    if (selectsRange && _.isEmpty(defaultVal)) {
      setDateRange([null, null]);
    }
  }, [defaultVal]);

  useOutsideClickHook(wrapperRef, () => {
    setIsOpen(false);
  });

  return (
    <>
      <div className={`${style.main} ${className}`} ref={wrapperRef}>
        {label && <label className={style.label}>{label}</label>}
        <div
          className={`${style.inpDiv} ${inputClass}`}
          style={{
            border: errorMessage
              ? '1px solid #FF5050'
              : '1px solid rgb(230, 231, 232)',
            position: 'relative',
          }}
        >
          <Controller
            key={id}
            control={control}
            name={name}
            render={({ field: { onChange, value, name } }) => {
              return (
                <ReactDatePicker
                  popperClassName={`${
                    monthYear &&
                    'reactDatepickerMonthContainer' &&
                    'reactDatepickerPoperMarginLeft'
                  } ${popparBack}`}
                  selectsRange={selectsRange}
                  startDate={selectsRange && startDate}
                  open={isOpen}
                  endDate={selectsRange && endDate}
                  onFocus={() => {
                    setIsOpen(true);
                  }}
                  disabled={isDisable}
                  id={id || 'id5'}
                  className={`${style.datePicker} ${reactDatePickerClass}`}
                  onChange={(e) => {
                    if (selectsRange) setDateRange(e);
                    handleChange && handleChange(e, name);
                    onChange(e);
                    if (selectsRange) {
                      const [start, end] = e;
                      start !== null && end !== null && setIsOpen(false);
                    } else {
                      setIsOpen(false);
                    }
                  }}
                  selected={
                    !selectsRange &&
                    (_.isNumber(value)
                      ? moment().year(value).toDate()
                      : value
                      ? new Date(value)
                      : null)
                  }
                  placeholderText={placeholder || 'mm/dd/yyyy'}
                  autoComplete="off"
                  minDate={minDate && minDate}
                  maxDate={maxDate && maxDate}
                  dropdownMode="select"
                  popperPlacement="bottom"
                  {...(isClearable && value && { isClearable })}
                  popperModifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['bottom'],
                        allowedAutoPlacements: ['bottom'],
                      },
                    },
                  ]}
                  renderCustomHeader={({
                    date,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                    prevYearButtonDisabled,
                    nextYearButtonDisabled,
                    increaseYear,
                    decreaseYear,
                  }) => (
                    <div className={style.iconsDiv}>
                      <p className={style.p}>
                        {!showYearPicker ? months[date.getMonth()] : ''}{' '}
                        {monthDate ? '' : date.getFullYear()}
                      </p>
                      <div>
                        <button
                          type={'button'}
                          onClick={
                            showYearPicker ? decreaseYear : decreaseMonth
                          }
                          disabled={
                            showYearPicker
                              ? prevYearButtonDisabled
                              : prevMonthButtonDisabled
                          }
                        >
                          <img
                            src={
                              isblue
                                ? dateLeftBlue
                                : isPink
                                ? dateLeftPink
                                : isPurple
                                ? dateLeftPurple
                                : dateLeftGreen
                            }
                            alt=""
                          />
                        </button>
                        <button
                          type={'button'}
                          onClick={
                            showYearPicker ? increaseYear : increaseMonth
                          }
                          disabled={
                            showYearPicker
                              ? nextYearButtonDisabled
                              : nextMonthButtonDisabled
                          }
                        >
                          <img
                            src={
                              isblue
                                ? dateRightBlue
                                : isPink
                                ? dateRightPink
                                : isPurple
                                ? dateRightPurple
                                : dateRightGreen
                            }
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  )}
                  showMonthYearPicker
                  {...(monthYear
                    ? {
                        dateFormat: 'MM/yyyy',
                        showMonthYearPicker: true,
                      }
                    : showYearPicker
                    ? {
                        dateFormat: 'yyyy',
                        showYearPicker,
                      }
                    : monthDate
                    ? {
                        dateFormat: 'MM/dd',
                        showMonthYearPicker: false,
                      }
                    : {
                        dateFormat: 'MM/dd/yyyy',
                        showMonthYearPicker: false,
                      })}
                />
              );
            }}
          />

          <img
            src={
              isblue
                ? datePickerIconBlue
                : isPurple
                ? datePickerIconPurple
                : isPink
                ? datePickerPink
                : datePickerIcon
            }
            style={{ marginRight: '10px', cursor: 'pointer' }}
            alt="datePickerIcon"
            onClick={() => !isDisable && setIsOpen(!isOpen)} // Add an onClick event to toggle the isOpen state
          />
        </div>
        {errorMessage ? (
          <span className={style.errorMessage}>{errorMessage}</span>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
export default DatePicker;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
