import React, { useEffect } from 'react';

import Container from 'components/container';
import Comments from './comments';
import Contact from './contact';
import HeaderSection from './header-section';
import Overview from './overview';

import ClientProfileProvider, {
  useClientProfile,
} from 'contexts/client-profile';
import style from './client.module.scss';

const ClientProfile = () => {
  const {
    clientHistory,
    active,
    onSubmit,
    handleSubmit,
    commentLength,
    comments,
    contactLength,
    disable,
    setDisable,
    setActive,
    setComments,
    getContactData,
  } = useClientProfile();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className={style.bgColor}>
        <div className={style.innerBg}>
          <Container>
            <form onSubmit={handleSubmit(onSubmit)}>
              <HeaderSection
                setDisable={setDisable}
                disable={disable}
                clientHistory={clientHistory}
              />
            </form>
          </Container>
          <div className={style.borderClass}>
            <Container>
              <div className={style.main}>
                <div className={style.flexClass}>
                  <p
                    className={active === 0 ? style.activeClass : ''}
                    onClick={() => {
                      !disable ? setActive(0) : null;
                    }}
                  >
                    Overview
                  </p>
                  <p
                    onClick={() => {
                      !disable ? setActive(1) : null;
                    }}
                    className={active === 1 ? style.activeClass : ''}
                  >
                    Contacts {!contactLength ? '(0)' : `(${contactLength})`}
                  </p>
                  <p
                    onClick={() => {
                      !disable ? setActive(2) : null;
                    }}
                    className={active === 2 ? style.activeClass : ''}
                  >
                    Comments {!commentLength ? '(0)' : `(${commentLength})`}
                  </p>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <Container>
          {active === 0 && <Overview setDisable={setDisable} />}
          {active === 1 && <Contact />}
          {active === 2 && (
            <Comments
              setDisable={setDisable}
              setComments={setComments}
              comments={comments}
            />
          )}
        </Container>
      </div>
    </>
  );
};

const MainPageWithContext = (props) => (
  <ClientProfileProvider>
    <ClientProfile {...props} />
  </ClientProfileProvider>
);
export default MainPageWithContext;
