/* eslint-disable no-unused-vars */
import moment from 'moment';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import NowTab from './now-tab';
import Button from 'components/button';
import Loading from 'components/loading';
import Container from 'components/container';
import CustomDatePicker from 'components/custom-date-picker';

import { userService } from 'services/users';
import { getPaylocityEmployees } from 'services/data-sync';
import { SET_DASHBOARD } from 'redux/system-values/constants';
import { getSalesForceClientsAndPositions } from 'services/data-sync';
import { setDashBoardFilter } from 'redux/filters/filters-actions';

import eyeIcon1 from 'assets/eyeIcon.svg';
import dateIcon from 'assets/images/date.svg';
import dateIcon1 from 'assets/icons/date.svg';
import eyeIcon from 'assets/images/eyeIcon.svg';
import cumulativeIcon from 'assets/images/cumulative.svg';
import cumulativeIcon1 from 'assets/icons/cumulative.svg';
import style from './home.module.scss';

function Home() {
  const ref = useRef(null);
  const isFirstRender = useRef(true);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const { dashboardData } = useSelector((store) => store.systemValues);
  const { dashBoardFilter } = useSelector((state) => state?.pageFilters);

  const dateRange = dashBoardFilter?.dateRange || [null, null];
  const queryType = dashBoardFilter?.queryType || 'Now';
  const [startDate, endDate] = dateRange;

  const [directoryFilters, setDirectoryFilters] = useState({
    queryType,
    dateRange,
  });

  useEffect(() => {
    // if (queryType !== 'Custom') getUserData();
    if (startDate && endDate) {
      setIsOpen(false);
      getUserData();
    }
  }, [queryType, dateRange]);

  const getUserData = async (params) => {
    setLoading(true);
    try {
      const { data } = await userService.getDashBoard({
        ...(params?.queryType && { queryType: params?.queryType }),
        ...(params?.dateRange && {
          dateRange: {
            startDate: params?.dateRange?.[0],
            endDate: params?.dateRange?.[1],
          },
        }),
      });
      dispatch({
        type: SET_DASHBOARD,
        payload: data,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const isFirst = isFirstRender.current;

    const lastQuery =
      isFirstRender.current && !_.isEmpty(dashBoardFilter?.lastQuery)
        ? dashBoardFilter?.lastQuery
        : directoryFilters;

    const newQuery = {
      queryType: directoryFilters?.queryType,
      dateRange: directoryFilters?.dateRange,
    };

    const currentQuery = {
      ...(isFirst ? lastQuery : newQuery),
    };

    dispatch(
      setDashBoardFilter({
        ...dashBoardFilter,
        lastQuery: isFirst ? lastQuery : newQuery,
      })
    );

    getUserData(currentQuery);

    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [directoryFilters]);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#304059');
  }, []);

  const [palDate, palTime, palAmPm] = (
    dashboardData?.paylocityLastSync
      ? moment(dashboardData?.paylocityLastSync).format('L h:mm A')
      : ''
  ).split(' ');
  const [salDate, salTime, salAmPm] = (
    dashboardData?.salesForceLastSync
      ? moment(dashboardData?.salesForceLastSync).format('L h:mm A')
      : ''
  ).split(' ');
  const [ffDate, ffTime, ffAmPm] = (
    dashboardData?.flatFileLastSync
      ? moment(dashboardData?.flatFileLastSync).format('L h:mm A')
      : ''
  ).split(' ');

  const handlePaylocitySync = async () => {
    await getPaylocityEmployees(dispatch);
  };

  const handleSalesforceSync = async () => {
    await getSalesForceClientsAndPositions(dispatch);
  };

  return (
    <div ref={ref}>
      <div className={style.main}>
        <Container>
          <div className={`${style.header} ${style.header1}`}>
            <div>
              <h3 className={style.h3}>Overview</h3>
            </div>
            <div className={style.buttonView}>
              <Button
                type={'submit'}
                text="Now"
                btnClass={
                  queryType === 'Now' ? style.nowButton : style.cumulativeButton
                }
                iconStart={queryType === 'Now' ? eyeIcon : eyeIcon1}
                handleClick={() => {
                  const filter = { dateRange: [null, null], queryType: 'Now' };
                  setDirectoryFilters(filter);
                  dispatch(
                    setDashBoardFilter({
                      ...dashBoardFilter,
                      ...filter,
                    })
                  );
                }}
                className={
                  queryType === 'Now' ? style.btnTitle1 : style.btnTitle
                }
              />
              <Button
                type={'submit'}
                text="Cumulative"
                btnClass={
                  queryType === 'Cumulative'
                    ? style.nowButton
                    : style.cumulativeButton
                }
                iconStart={
                  queryType === 'Cumulative' ? cumulativeIcon1 : cumulativeIcon
                }
                className={
                  queryType === 'Cumulative' ? style.btnTitle1 : style.btnTitle
                }
                handleClick={() => {
                  const filter = {
                    dateRange: [null, null],
                    queryType: 'Cumulative',
                  };
                  setDirectoryFilters(filter);
                  dispatch(
                    setDashBoardFilter({
                      ...dashBoardFilter,
                      ...filter,
                    })
                  );
                }}
              />
              <Button
                type={'submit'}
                text={
                  queryType === 'Custom' &&
                  !_.isEmpty(dateRange?.filter((x) => x)) &&
                  !_.isNull(dateRange[0]) &&
                  !_.isNull(dateRange[1])
                    ? `
                    ${
                      dateRange[0] && moment(dateRange[0]).format('MM/DD/YYYY')
                    } - ${
                        dateRange[1] &&
                        moment(dateRange[1]).format('MM/DD/YYYY')
                      }
        
                    `
                    : 'Custom Date'
                }
                btnClass={
                  queryType === 'Custom'
                    ? style.nowButton
                    : style.cumulativeButton
                }
                iconStart={queryType === 'Custom' ? dateIcon1 : dateIcon}
                className={
                  queryType === 'Custom' ? style.btnTitle1 : style.btnTitle
                }
                handleClick={() => {
                  setIsOpen((prev) => !prev);
                  setDirectoryFilters((prev) => ({
                    ...prev,
                    queryType: 'Custom',
                  }));
                  dispatch(
                    setDashBoardFilter({
                      ...dashBoardFilter,
                      queryType: 'Custom',
                    })
                  );
                }}
              />
            </div>
          </div>

          <div className={style.header}>
            <div className={style.sync}>
              {palDate && palTime && palAmPm ? (
                <span onClick={handlePaylocitySync} className={style.clickable}>
                  Paylocity last synced on {palDate} at {palTime} {palAmPm}
                </span>
              ) : (
                <span onClick={handlePaylocitySync} className={style.clickable}>
                  No previously synced data from Paylocity
                </span>
              )}
              <span>&nbsp;|&nbsp;</span>
              {salDate && salTime && salAmPm ? (
                <span
                  onClick={handleSalesforceSync}
                  className={style.clickable}
                >
                  Salesforce last synced on {salDate} at {salTime} {salAmPm}
                </span>
              ) : (
                <span onClick={handlePaylocitySync} className={style.clickable}>
                  No previously synced data from Salesforce
                </span>
              )}
              <span>&nbsp;|&nbsp;</span>
              {ffDate && ffTime && ffAmPm ? (
                <span className={style.faketext}>
                  ViGlobal last synced on {ffDate} at {ffTime} {ffAmPm}
                </span>
              ) : (
                <span className={style.faketext}>
                  No previously uploaded data from ViGlobal
                </span>
              )}
            </div>
            <div className={style.buttonView}></div>
          </div>
          <div className={style.snapShotDiv}>
            <div className={style.snapCard}>
              <span>Snapshot Summary</span>
              <div className={style.snapInnerDiv}>
                <div className={style.snapBox}>
                  <span className={style.snapValue}>
                    {dashboardData?.attorneys?.summaryCurrentlyPlaced || 0}
                  </span>
                  <p className={style.snapTitle}>Currently Placed</p>
                </div>
                <div className={style.snapBox}>
                  <span className={style.snapValue}>
                    {dashboardData?.attorneys?.historicalPlacedAttorneys || 0}
                  </span>
                  <p className={style.snapTitle}>Historically Placed</p>
                </div>
                <div className={style.snapBox}>
                  <div>
                    <span className={style.snapValue}>
                      {dashboardData?.attorneys?.convertedAttorney || 0}
                    </span>
                    <span className={style.snapPerc}>{` (${
                      dashboardData?.attorneys?.convertedPercentage || 0
                    }%)`}</span>
                  </div>
                  <p className={style.snapTitle}>Converted (Cumulative)</p>
                </div>
                <div className={style.snapBox}>
                  <div>
                    <span className={style.snapValue}>
                      {dashboardData?.attorneys?.returnedAttorney || 0}
                    </span>
                    <span className={style.snapPerc}>{` (${
                      dashboardData?.attorneys?.returnedPercentage || 0
                    }%)`}</span>
                  </div>
                  <p className={style.snapTitle}>Returned (Cumulative)</p>
                </div>
              </div>
            </div>
          </div>

          {!loading ? (
            <>
              {queryType === 'Now' && (
                <NowTab
                  dashboardData={dashboardData}
                  currentValue={queryType}
                />
              )}
              {queryType === 'Cumulative' && (
                <NowTab
                  dashboardData={dashboardData}
                  currentValue={queryType}
                />
              )}
              {queryType === 'Custom' && (
                <NowTab
                  dashboardData={dashboardData}
                  currentValue={'Custom'}
                  startDate={startDate || null}
                  endDate={endDate || null}
                />
              )}
            </>
          ) : (
            <div className={style.loadingDiv}>
              <Loading loaderClass={style.loading} />
            </div>
          )}
        </Container>
      </div>

      {isOpen && (
        <div
          style={{
            position: 'fixed',
            inset: 0,
            backgroundColor: 'transparent',
          }}
          onClick={() => setIsOpen(false)}
        ></div>
      )}
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '150px',
            right: 0,
            marginRight: '50px',
          }}
        >
          <CustomDatePicker
            {...{
              isblue: true,
              value: dateRange,
              open: isOpen,
              setOpen: setIsOpen,
              custom: false,
              onChange: (update) => {
                const dateRange = [
                  update[0] && moment(update[0]).format('YYYY-MM-DDTHH:mm:ss'),
                  update[1] && moment(update[1]).format('YYYY-MM-DDTHH:mm:ss'),
                ];
                setDirectoryFilters((prev) => ({
                  ...prev,
                  dateRange,
                }));
                dispatch(
                  setDashBoardFilter({
                    ...dashBoardFilter,
                    dateRange,
                  })
                );
              },
            }}
          />
        </div>
      )}
    </div>
  );
}
export default Home;
