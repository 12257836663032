/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { userService } from 'services/users';

import Button from 'components/button';
import AvatarUpload from 'components/edit-avatar';
import SelectBox from 'components/select-box';
import Switch from 'components/switch';
import TextField from 'components/textfield';

import closeIcon from 'assets/icons/blue-cross.svg';
import delIcon from 'assets/icons/del-blue.svg';
import uploadBlue from 'assets/icons/uploadBlue.svg';
import saveIcon from 'assets/images/save.svg';

import { createNotification } from 'components/react-notification';
import style from './edit.module.scss';

const EditAddTable = ({
  getUserData,
  setEditPopUp,
  className,
  index,
  classNameMain,
  responsiveBtnClass,
  data,
  onFormSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [deletingPhoto, setDeletingPhoto] = useState(false);

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: data
      ? {
          ...data,
        }
      : {
          notification: false,
          status: false,
        },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    setIsLoading(true);
    const {
      firstName,
      lastName,
      email,
      userType,
      notification,
      profilePicture,
      status,
    } = values;

    try {
      const response = await userService.setUser(
        {
          firstName,
          lastName,
          email,
          notification,
          status,
          userType,
          profilePicture: profilePicture
            ? profilePicture?.startsWith('https')
              ? undefined
              : profilePicture
            : undefined,
        },
        data?._id ? data?._id : null
      );
      onFormSubmit(response.data.user);

      setEditPopUp(false);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
    }
    setIsLoading(false);
  };

  const avatarDelete = async () => {
    const {
      firstName,
      lastName,
      email,
      userType,
      notification,
      profilePicture,
      status,
    } = data;

    try {
      const response = await userService.setUser(
        {
          firstName,
          lastName,
          email,
          notification,
          status,
          userType,
          profilePicture: null,
        },
        data?._id ? data?._id : null
      );
      if (response?.status === 200) {
        setValue('profilePicture', null);
        getUserData();
        setEditPopUp(false);
        setDeletingPhoto(false);
      }
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
    }
  };

  const notification = watch('notification');
  const status = watch('status');

  return (
    <>
      <div className={`${style.mainEdit} ${classNameMain}`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${style.innerDiv} ${className}`}>
            <div className={style.camDiv}>
              <AvatarUpload
                deletingPhoto={deletingPhoto}
                setDeletingPhoto={setDeletingPhoto}
                control={control}
                delIcon={delIcon}
                name="profilePicture"
                loading={isLoading}
                userMode={true}
                color={'#223149'}
                uploadIcon={uploadBlue}
                initials={data ? `${data.firstName} ${data.lastName}` : ''}
              />
            </div>
            <TextField
              label="First Name"
              name="firstName"
              register={register}
              placeholder="e.g., John"
              errorMessage={errors?.firstName?.message}
            />
            <TextField
              label="Last Name"
              name="lastName"
              register={register}
              placeholder="e.g., Doe"
              errorMessage={errors?.lastName?.message}
            />
            <TextField
              label="Email"
              name="email"
              register={register}
              placeholder="e.g., johndoe@company.com"
              type="email"
              errorMessage={errors?.email?.message}
            />
            <div className={style.roleDiv}>
              <SelectBox
                options={options}
                label={'Role'}
                control={control}
                name={'userType'}
                errorMessage={errors?.userType?.message}
                dynamicClass={style.zDynamic}
              />
            </div>
            <Switch
              label="Notifications"
              title={notification ? 'On' : 'Off'}
              name="notification"
              register={register}
              className={style.switch}
              errorMessage={errors?.notification?.message}
            />
            <Switch
              label="Account Status"
              title={status ? 'Active' : 'Inactive'}
              name="status"
              register={register}
              className={style.switch}
              errorMessage={errors?.status?.message}
            />
          </div>
          <div className={`${style.btnSection} ${responsiveBtnClass}`}>
            <img
              src={closeIcon}
              className={style.img}
              onClick={() => {
                setEditPopUp(null);
                setDeletingPhoto(false);
              }}
            />
            {deletingPhoto ? (
              <Button
                type={'button'}
                text={'Save'}
                handleClick={() => avatarDelete()}
                iconStart={saveIcon}
                btnClass={style.btnClass}
                isLoading={isLoading}
              />
            ) : (
              <Button
                type={'submit'}
                text={'Save'}
                iconStart={saveIcon}
                btnClass={style.btnClass}
                isLoading={isLoading}
              />
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddTable;

const schema = yup.object({
  firstName: yup.string().required('First Name is required.'),
  lastName: yup.string().required('Last Name is required.'),
  email: yup
    .string()
    .email('Please input a valid email.')
    .required('Email is required.'),
  userType: yup
    .string('User type is required.')
    .nullable()
    .required('User type is required.'),
  status: yup.boolean().optional(),
  notification: yup.boolean().optional(),
});

const options = [
  { value: 'Administrator', label: 'Administrator' },
  { value: 'Editor', label: 'Editor' },
  { value: 'Viewer', label: 'Viewer' },
  { value: 'Attorney', label: 'Attorney' },
];
