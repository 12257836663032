import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/button';
import Modal from 'components/modal';

import del from 'assets/icons/del-purple.svg';
import style from './del-modal.module.scss';

const DuplicatePositionModal = ({
  reset,
  onSubmit,
  duplicatePositionModal,
  setDuplicatePositionModal,
}) => {
  const navigate = useNavigate();
  const handleDeleteAttorney = async () => {
    onSubmit({ ...duplicatePositionModal, force: true });
  };

  return (
    <div>
      <Modal
        open={duplicatePositionModal}
        handleClose={() => setDuplicatePositionModal(false)}
        className={style.mainDiv}
      >
        <div className={style.iconRefresh}>
          <img src={del} alt="" />
        </div>
        <p className={style.modalTitle} style={{ marginBottom: '10px' }}>
          Duplicate Record Already Exists
        </p>
        <p className={style.p}>
          There is already a Client Placement for this SOW. Do you want to save
          this as a duplicate?
        </p>
        <div className={style.mainBtnDiv}>
          <Button
            btnClass={style.btnClassModal}
            handleClick={handleDeleteAttorney}
            text={'Yes, save it as a duplicate.'}
          />
          <Button
            text={'No, delete it.'}
            btnClass={style.btnClassUncheckModal}
            handleClick={() => {
              reset({ placementQuantity: 0 });
              navigate('/positions');
              setDuplicatePositionModal(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DuplicatePositionModal;
