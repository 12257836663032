import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'components/button';
import Modal from 'components/modal';
import Spinner from 'components/spinner';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { ROLES } from 'permissions/constants';
import { getAllMatchVersions } from 'services/positions';

import currentVersion from 'assets/icons/current-version.svg';
import edit from 'assets/icons/edit-purple.svg';
import noRecordsIcon from 'assets/icons/noRecords.svg';
import refresh from 'assets/icons/refresh-purple.svg';

import style from './version-modal.module.scss';

const VersionModal = ({
  setEditModal,
  matchVersion,
  versionModal,
  setVersionModal,
  setRestoreModal,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [versions, setVersions] = useState([]);

  const handleGetAllMatchVersions = async () => {
    await getAllMatchVersions({
      params: { positionId: id },
      setVersions,
      setLoading,
    });
  };

  useEffect(() => {
    handleGetAllMatchVersions();
  }, []);

  return (
    <div>
      <Modal
        bodyPadding="0"
        open={versionModal}
        className={style.mainDiv}
        handleClose={() => setVersionModal(false)}
      >
        <div style={{ padding: '30px' }}>
          <p className={style.modalTitle}>Version History</p>
          {loading ? (
            <div className={style.modalLoader}>
              <Spinner />
            </div>
          ) : (
            <>
              {versions.length > 0 ? (
                <div className={style.table}>
                  <div style={{ width: '700px' }}>
                    <div className={style.header}>
                      <p>Version</p>
                      <p>Saved At</p>
                      <p>Saved By</p>
                      <p style={{ color: 'transparent' }}>actions</p>
                    </div>
                    <div className={style.body}>
                      <div style={{ height: '145px' }}>
                        {versions?.map((version, index) => {
                          const { name, userId, updatedAt } = version;
                          const isCurrentVersion =
                            matchVersion === version?._id;
                          return (
                            <div className={style.innerDiv} key={index}>
                              <div className={style.title}>
                                <p>{name}</p>
                              </div>
                              <p style={{ paddingLeft: '5px' }}>
                                {updatedAt
                                  ? moment(updatedAt).format('L h:mm a')
                                  : ''}
                              </p>
                              <p
                                style={{ paddingLeft: '10px' }}
                              >{`${userId?.firstName} ${userId?.lastName}`}</p>
                              <div className={style.div}>
                                <img
                                  style={{
                                    cursor: isCurrentVersion
                                      ? 'unset'
                                      : 'pointer',
                                  }}
                                  alt=""
                                  src={
                                    isCurrentVersion ? currentVersion : refresh
                                  }
                                  onClick={() => {
                                    if (!isCurrentVersion) {
                                      setRestoreModal(version);
                                      setVersionModal(false);
                                    }
                                  }}
                                />
                                <PermissionRestrict
                                  roles={[ROLES.ADMIN, ROLES.EDITOR]}
                                >
                                  <img
                                    src={edit}
                                    alt=""
                                    onClick={() => {
                                      setEditModal(version);
                                      setVersionModal(false);
                                    }}
                                  />
                                </PermissionRestrict>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={style.noRecord}>
                  <div className={style.magnigyingGlass}>
                    <img src={noRecordsIcon} height={56} width={56} />
                  </div>
                  <p className={style.emptyText}>No Record!</p>
                </div>
              )}
            </>
          )}
          <div className={style.mainBtnDiv}>
            <Button
              text="Close"
              btnClass={style.btnClassUncheckModal}
              handleClick={() => setVersionModal(false)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VersionModal;
