//system-values
export const SET_PRACTICE_GROUPS = 'SET_PRACTICE_GROUPS';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_STATES = 'SET_STATES';
export const SET_WEIGHTS = 'SET_WEIGHTS';
export const ADD_PRACTICE_GROUP = 'ADD_PRACTICE_GROUP';
export const ADD_LOCATION = 'ADD_LOCATION';
export const ADD_STATE = 'ADD_STATE';
export const DELETE_PRACTICE_GROUP = 'DELETE_PRACTICE_GROUP';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_STATE = 'DELETE_STATE';
export const UPDATE_PRACTICE_GROUP = 'UPDATE_PRACTICE_GROUP';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const UPDATE_STATE = 'UPDATE_STATE';
export const UPDATE_WEIGHT = 'UPDATE_WEIGHT';
export const SET_DASHBOARD = 'SET_DASHBOARD';
