import React from 'react';

import style from './textarea.module.scss';

const TextArea = ({
  label,
  name,
  register,
  placeholder,
  errorMessage,
  onChange,
  isDisable,
  value,
  rows,
}) => {
  return (
    <>
      <div className={style.note}>
        {label && (
          <label style={{ color: errorMessage ? '#ff5050' : '#2d2d32' }}>
            {label}
          </label>
        )}
        <textarea
          style={{
            border: errorMessage
              ? '1.2px solid #ff5050'
              : ' 1.2px solid #e2e2ea',
          }}
          placeholder={placeholder}
          name={name}
          rows={rows || 6}
          onChange={onChange}
          value={value}
          {...(register && register(name))}
          disabled={isDisable || false}
        ></textarea>
        {errorMessage ? (
          <span className={style.errorMessage}>{errorMessage}</span>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default TextArea;
