/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import React from 'react';
import * as yup from 'yup';

import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextArea from 'components/textarea';
import TextField from 'components/textfield';
import Button from 'components/button';

import { CLE_SUBJECTS, CLE_SUBJECTS_MATTER, STATES } from 'constants';
import { setAttorneyTrainingHistory } from 'services/attorney-profile';
import { useAttoreyProfile } from 'contexts/attorney-profile';
import { createNotification } from 'components/react-notification';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { ROLES } from 'permissions/constants';

import saveIcon from 'assets/images/save.svg';
import greenCrossIcon from 'assets/images/greenCrossIcon.svg';
import crossIcon1 from 'assets/icons/blue-cross.svg';

import style from './add-traning.module.scss';

const AddTrainingHistory = ({
  setToggle,
  setEdit,
  data,
  setDisable,
  onSubmit: submitted,
}) => {
  const { id } = useParams();
  const { getData } = useAttoreyProfile();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: !data
      ? {}
      : {
          ...data,
          courseCompletedDate:
            data.courseCompletedDate && new Date(data.courseCompletedDate),
        },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const {
      courseName,
      courseCompletedDate,
      courseHours,
      liveHours,
      credits,
      state,
      subject,
      courseSubjMatter,
      myFeedback,
      cloFeedback,
      cloFeedbackInternal,
    } = data;

    try {
      const res = await setAttorneyTrainingHistory(
        {
          attorneyId: id,
          courseName,
          courseCompletedDate,
          courseHours,
          liveHours,
          credits,
          state,
          subject,
          courseSubjMatter,
          cloFeedback,
          myFeedback,
          cloFeedbackInternal,
        },
        data?._id
      );
      submitted(res.data.training);
      setToggle(false);
      await getData();
      setEdit(false);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error('err', err);
    }
  };

  return (
    <form
      id="trainingHistory-form"
      onSubmit={handleSubmit(onSubmit)}
      className={style.wraper}
    >
      <div className={style.sectionOne}>
        <div className={style.trainingEditFields}>
          <TextField
            label={'Course Name'}
            name="courseName"
            register={register}
            errorMessage={errors?.courseName?.message}
          />
        </div>
        <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}>
          <>
            <DatePicker
              label="Date Completed"
              name={'courseCompletedDate'}
              id="1"
              placeholder="mm/dd/yyyy"
              control={control}
              className={style.zDynamic3}
              errorMessage={errors?.courseCompletedDate?.message}
            />
          </>
        </PermissionRestrict>
        <PermissionRestrict roles={[ROLES.ATTORNEY]}>
          <div className={style.trainingEditFields}>
            <DatePicker
              label="Date Completed"
              name={'courseCompletedDate'}
              id="1"
              placeholder="mm/dd/yyyy"
              control={control}
              className={style.zDynamic3}
              errorMessage={errors?.courseCompletedDate?.message}
            />
          </div>
        </PermissionRestrict>
        <div>
          {' '}
          <div className={style.trainingEditFields}>
            <TextField
              label={'Hours'}
              name="courseHours"
              register={register}
              type="number"
              errorMessage={errors?.courseHours?.message}
            />
          </div>
        </div>
        <div>
          {' '}
          <div className={style.trainingEditFields}>
            <TextField
              label={'Live'}
              placeholder={''}
              type="number"
              name="liveHours"
              register={register}
              errorMessage={errors?.liveHours?.message}
            />
          </div>
        </div>
        <div>
          {' '}
          <div className={style.trainingEditFields}>
            <TextField
              label={'CLE Credits'}
              type="number"
              name="credits"
              register={register}
              errorMessage={errors?.credits?.message}
            />
          </div>
        </div>
        <div className={style.trainingEditFields}>
          <SelectBox
            options={STATES}
            placeholder={'Select'}
            label={'State'}
            name={'state'}
            control={control}
            dynamicClass={style.zDynamic2}
            errorMessage={errors?.state?.message}
          />
        </div>
        <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}>
          <div className={style.trainingEditFields}>
            <SelectBox
              options={CLE_SUBJECTS}
              placeholder={'Select'}
              label={'CLE Subject'}
              name={'subject'}
              control={control}
              dynamicClass={style.zDynamic}
              errorMessage={errors?.subject?.message}
            />
          </div>
          <div className={style.trainingEditFields}>
            <SelectBox
              placeholder={'Select'}
              options={CLE_SUBJECTS_MATTER}
              label={'Subject Matter'}
              name={'courseSubjMatter'}
              control={control}
              errorMessage={errors?.courseSubjMatter?.message}
            />
          </div>
        </PermissionRestrict>
      </div>
      <PermissionRestrict roles={[ROLES.ATTORNEY]}>
        <div className={style.cleSectionAttorney}>
          <div className={style.trainingEditFields}>
            <SelectBox
              options={CLE_SUBJECTS}
              placeholder={'Select'}
              label={'CLE Subject'}
              name={'subject'}
              control={control}
              dynamicClass={style.zDynamic}
              errorMessage={errors?.subject?.message}
            />
          </div>
          <div className={style.trainingEditFields}>
            <SelectBox
              placeholder={'Select'}
              options={CLE_SUBJECTS_MATTER}
              label={'Subject Matter'}
              name={'courseSubjMatter'}
              control={control}
              errorMessage={errors?.courseSubjMatter?.message}
            />
          </div>
        </div>
      </PermissionRestrict>
      <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}>
        <div className={style.sectionTwo}>
          <div className={style.trainingEditFields}>
            <TextArea
              label={'My Feedback'}
              name="myFeedback"
              placeholder={'Start typing'}
              register={register}
              errorMessage={errors?.myFeedback?.message}
            />
          </div>
          <div>
            <TextArea
              placeholder={'Start typing'}
              label={'CLO Feedback for Attorney'}
              name="cloFeedback"
              register={register}
              errorMessage={errors?.cloFeedback?.message}
            />
          </div>
          <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
            <div>
              <TextArea
                placeholder={'Start typing'}
                label={'CLO Feedback for Internal Use'}
                name="cloFeedbackInternal"
                register={register}
                errorMessage={errors?.cloFeedbackInternal?.message}
              />
            </div>
          </PermissionRestrict>
        </div>
      </PermissionRestrict>
      <PermissionRestrict roles={[ROLES.ATTORNEY]}>
        <div className={style.sectionTwoAttorney}>
          <div className={style.trainingEditFields}>
            <TextArea
              label={'My Feedback'}
              name="myFeedback"
              placeholder={'Start typing'}
              register={register}
              errorMessage={errors?.myFeedback?.message}
            />
          </div>
        </div>
      </PermissionRestrict>

      <div className={style.btnSection}>
        <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}>
          <img
            src={greenCrossIcon}
            onClick={() => {
              setToggle(false);
              setEdit(false);
              setDisable(false);
            }}
          />
        </PermissionRestrict>
        <PermissionRestrict roles={[ROLES.ATTORNEY]}>
          <img
            src={crossIcon1}
            onClick={() => {
              setToggle(false);
              setEdit(false);
              setDisable(false);
            }}
          />
        </PermissionRestrict>
        <Button
          text={'Save'}
          type={'submit'}
          width="100px"
          onClick={() => {
            setDisable(false);
          }}
          form="trainingHistory-form"
          iconStart={saveIcon}
          btnClass={style.btnClass}
          isLoading={isSubmitting}
        />
      </div>
    </form>
  );
};

export default AddTrainingHistory;

const schema = yup
  .object({
    courseName: yup.string().required('Course name is required'),
    courseCompletedDate: yup.date().nullable(),
    courseHours: yup.string().required('Course hours are required'),
    liveHours: yup.string().required('Live hours are required'),
    credits: yup.string().required('Credits are required'),
    state: yup.string().nullable().required('State is required'),
    subject: yup.string().nullable().required('Subject is required'),
    courseSubjMatter: yup
      .string()
      .nullable()
      .required('Subject matter is required'),
  })
  .required();
