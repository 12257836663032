import React, { useEffect, useState } from 'react';

import Button from 'components/button';
import Modal from 'components/modal';
import SearchSelect from 'components/search-select';

import style from './add-modal.module.scss';

const AddAttorneyModal = ({
  setMatches,
  addAttorney,
  setAddAttorney,
  ineligibleAttorneys,
}) => {
  const [selected, setSelected] = useState([]);

  const handleAddAttorneys = () => {
    let selectedAttorneys = ineligibleAttorneys.filter((x) =>
      selected.includes(x._id)
    );

    selectedAttorneys = selectedAttorneys.map((x) => {
      return { ...x, score: 0, slot: -1 };
    });

    setMatches((prev) => {
      prev.matches = [
        ...(prev && prev.matches && prev.matches.length > 0
          ? prev.matches
          : []),
        ...selectedAttorneys,
      ];

      return { ...prev };
    });
    setSelected([]);
    setAddAttorney(false);
  };

  useEffect(() => {
    setSelected([]);
  }, [addAttorney]);

  return (
    <div>
      <Modal
        open={addAttorney}
        handleClose={() => setAddAttorney(false)}
        className={style.mainDiv}
      >
        <span className={style.modalTitle}>Add Attorneys</span>

        <SearchSelect
          selected={selected}
          placeholder="Search"
          setSelected={setSelected}
          options={ineligibleAttorneys}
        />

        <div className={style.mainBtnDiv}>
          <Button
            text={'Add Attorney'}
            disabled={selected.length === 0}
            btnClass={style.btnClassModal}
            handleClick={handleAddAttorneys}
          />
          <Button
            text={'Cancel'}
            btnClass={style.btnClassUncheckModal}
            handleClick={() => setAddAttorney(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddAttorneyModal;
