import { sortAlphabetically } from 'utils/helpers';

export const SYSTEM_VALUES_DOCUMENT =
  process.env.REACT_APP_SYSTEM_VALUES_DOCUMENT_NAME;

export const ATTRONEY_STATUS_COLORS = {
  Interviewing_LI: '#2475D0',
  Interviewing_P: '#2475D0',
  Placed: '#304059',
  Pipeline: '#784DB0',
  Terminated: '#CE4343',
  Voluntary: '#F58020',
  Converted: '#30A25D',
  Inactive: '#9DA1AA',
  'Not Interviewing': '#80658C',
  'Nontraditional Placement': '#836565',
};

export const ATTRONEY_STATUS_OPTIONS = [
  {
    value: 'Pipeline',
    label: 'Pipeline',
    color: ATTRONEY_STATUS_COLORS.Pipeline,
    box: true,
  },
  {
    value: 'Not Interviewing',
    label: 'Not Interviewing',
    color: ATTRONEY_STATUS_COLORS['Not Interviewing'],
    box: true,
  },
  {
    value: 'Interviewing_LI',
    label: 'Interviewing_LI',
    color: ATTRONEY_STATUS_COLORS.Interviewing_LI,
    box: true,
  },
  {
    value: 'Interviewing_P',
    label: 'Interviewing_P',
    color: ATTRONEY_STATUS_COLORS.Interviewing_P,
    box: true,
  },

  {
    value: 'Placed',
    label: 'Placed',
    color: ATTRONEY_STATUS_COLORS.Placed,
    box: true,
  },
  {
    value: 'Converted',
    label: 'Converted',
    color: ATTRONEY_STATUS_COLORS.Converted,
    box: true,
  },
  {
    value: 'Voluntary',
    label: 'Voluntary',
    color: ATTRONEY_STATUS_COLORS.Voluntary,
    box: true,
  },
  {
    value: 'Terminated',
    label: 'Terminated',
    color: ATTRONEY_STATUS_COLORS.Terminated,
    box: true,
  },
  {
    value: 'Nontraditional Placement',
    label: 'Nontraditional Placement',
    color: ATTRONEY_STATUS_COLORS['Nontraditional Placement'],
    box: true,
  },
  {
    value: 'Inactive',
    label: 'Inactive',
    color: ATTRONEY_STATUS_COLORS.Inactive,
    box: true,
  },
];

export const CLIENT_TYPE_OPTIONS = sortAlphabetically(
  [
    {
      value: 'Law Firm',
      label: 'Law Firm',
    },
    {
      value: 'Head Hunter',
      label: 'Head Hunter',
    },

    {
      value: 'Corporate',
      label: 'Corporate',
    },

    {
      value: 'Hybrid',
      label: 'Hybrid',
    },
  ],
  'value'
);
export const CLIENT_STATUS_COLORS = {
  'Active opportunity': '#2475D0',
  'Active client': '#30A15D',
  'Inactive opportunity': '#696F7A',
  'Former client': '#80658C',
};

export const CLIENT_STATUS_OPTIONS = [
  {
    value: 'Active opportunity',
    label: 'Active Opportunity',
    color: CLIENT_STATUS_COLORS['Active opportunity'],
    box: true,
  },
  {
    value: 'Active client',
    label: 'Active Client',
    color: CLIENT_STATUS_COLORS['Active client'],
    box: true,
  },
  {
    value: 'Inactive opportunity',
    label: 'Inactive Opportunity',
    color: CLIENT_STATUS_COLORS['Inactive opportunity'],
    box: true,
  },

  {
    value: 'Former client',
    label: 'Former Client',
    color: CLIENT_STATUS_COLORS['Former client'],
    box: true,
  },
];

export const ATTORNEY_STATUS_ORDER = [
  'Pipeline',
  'Not Interviewing',
  'Interviewing',
  'Placed',
  'Voluntary',
  'Terminated',
  'Converted',
  'Returned',
  'Inactive',
  'Nontraditional Placement',
];

export const ATTORNEY_CLIENT_STATUS_COLORS = {
  Placed: '#D0D2FF',
  Ejected: '#E0C3A8',
  Rejected: '#FFCCCC',
  Returned: '#E6E7E8',
  Withdrawn: '#ECA064',
  Converted: '#DEFFEB',
  Voluntary: '#F8DCC3',
  Terminated: '#DAA6AB',
  Interviewing: '#DFEEFF',
  'Midway Return': '#FFF2CD',
  'Placed Elsewhere': '#8D8BA3',
};

export const BAR_STATUS_COLORS = {
  Studying: '#CFEEFF',
  Tested: '#F9E5B1',
  Failed: '#EAAEAE',
  Passed: '#C1EBD2',
};

export const BAR_STATUS_OPTIONS = [
  {
    value: 'Studying',
    label: 'Studying',
    color: BAR_STATUS_COLORS.Studying,
    box: true,
  },

  {
    value: 'Tested',
    label: 'Tested',
    color: BAR_STATUS_COLORS.Tested,
    box: true,
  },

  {
    value: 'Failed',
    label: 'Failed',
    color: BAR_STATUS_COLORS.Failed,
    box: true,
  },

  {
    value: 'Passed',
    label: 'Passed',
    color: BAR_STATUS_COLORS.Passed,
    box: true,
  },
];

export const ATTORNEY_CLIENT_STATUS = [
  {
    value: 'Interviewing',
    label: 'Interviewing',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Interviewing,
    box: true,
  },
  {
    value: 'Rejected',
    label: 'Rejected',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Rejected,
    box: true,
  },
  {
    value: 'Ejected',
    label: 'Ejected',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Ejected,
    box: true,
  },
  {
    value: 'Withdrawn',
    label: 'Withdrawn',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Withdrawn,
    box: true,
  },
  {
    value: 'Placed Elsewhere',
    label: 'Placed Elsewhere',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Ejected,
    box: true,
  },

  {
    value: 'Placed',
    label: 'Placed',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Placed,
    box: true,
  },

  {
    value: 'Converted',
    label: 'Converted',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Converted,
    box: true,
  },

  {
    value: 'Midway Return',
    label: 'Midway Return',
    color: ATTORNEY_CLIENT_STATUS_COLORS['Midway Return'],
    box: true,
  },

  {
    value: 'Returned',
    label: 'Returned',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Returned,
    box: true,
  },

  {
    value: 'Voluntary',
    label: 'Voluntary',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Voluntary,
    box: true,
  },

  {
    value: 'Terminated',
    label: 'Terminated',
    color: ATTORNEY_CLIENT_STATUS_COLORS.Terminated,
    box: true,
  },
];

export const DEMO_OPTIONS = sortAlphabetically(
  [
    { value: 'Asian', label: 'Asian' },
    { value: 'Black or African American', label: 'Black or African American' },
    { value: 'Latinx', label: 'Latinx' },
    { value: 'American Indian', label: 'American Indian' },
    { value: 'LGBTQIA', label: 'LGBTQIA' },
    { value: 'Veteran', label: 'Veteran' },
    { value: 'Caucasian', label: 'Caucasian' },
    {
      value: 'Socioeconomically Less Privileged [SLP]',
      label: 'Socioeconomically Less Privileged [SLP]',
    },
    { value: 'People with Disabilities', label: 'People with Disabilities' },
    { value: 'Other Race', label: 'Other Race' },
    { value: 'Other Pacific Islander', label: 'Other Pacific Islander' },
    { value: 'Two or More Races', label: 'Two or More Races' },
  ],
  'value'
);

export const GENDER_OPTIONS = sortAlphabetically(
  [
    {
      value: 'male',
      label: 'Male',
    },
    {
      value: 'female',
      label: 'Female',
    },
    {
      value: 'trans Woman',
      label: 'Trans Woman',
    },
    {
      value: 'trans Man',
      label: 'Trans Man',
    },
    {
      value: 'non-binary or Third Gender',
      label: 'Non-binary or Third Gender',
    },
  ],
  'value'
);

export const CLE_SUBJECTS = [
  {
    value: 'Diversity',
    label: 'Diversity',
  },
  {
    value: 'Ethics',
    label: 'Ethics',
  },

  {
    value: 'General',
    label: 'General',
  },
  {
    value: 'Inclusion and Elimination of Bias',
    label: 'Inclusion and Elimination of Bias',
  },

  {
    value: 'Implicit Bias',
    label: 'Implicit Bias',
  },
  {
    value: 'Skills/Competence Issues',
    label: 'Skills/Competence Issues',
  },
];

export const CLE_SUBJECTS_MATTER = [
  {
    value: 'Corporate',
    label: 'Corporate',
  },

  {
    value: 'Litigation',
    label: 'Litigation',
  },

  {
    value: 'Professional',
    label: 'Professional',
  },

  {
    value: 'Skills',
    label: 'Skills',
  },
];

export const BAR_FAILS_OPTIONS = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
].map((state) => ({ label: state, value: state }));

export const clientTypeOptionsKeys = [
  {
    value: 'LF',
    label: 'Law Firm',
  },
  {
    value: 'HH',
    label: 'Head Hunter',
  },

  {
    value: 'C',
    label: 'Corporate',
  },

  {
    value: 'H',
    label: 'Hybrid',
  },
];
