import { StatsTableState } from './stats-table-state';
import * as Actions from './constants';

const INITIAL_STATE = new StatsTableState();

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Actions.SET_STATS_TABLE: {
      return {
        ...state,
        open: action.payload,
      };
    }
    default:
      return state;
  }
}
