import TextArea from 'components/textarea';
import React, { useState } from 'react';

import saveIcon from 'assets/images/save.svg';

import Button from 'components/button';
import { setComments } from 'services/client-page';
import style from './card-edit.module.scss';

import { createNotification } from 'components/react-notification';
import { useParams } from 'react-router-dom';

const CardCommentEdit = ({
  setToggle,
  setDisable,
  btnClass,
  setEdit,
  closeIcon,
  data,
  index,
  onSubmit: submitted,
}) => {
  const { id } = useParams();
  const [comment, setComment] = useState(data?.comment);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      if (!comment) {
        setError('Comment is required');
        setIsLoading(false);
        return;
      }
      const response = await setComments(
        {
          comment,
          commentFor: id,
          commentDate: new Date(),
          commentType: 'clients',
        },
        data?._id
      );
      submitted(response.data?.comment);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error('err', err.response.data);
    }
    setEdit(false);
    setToggle(false);
    setDisable(false);
    setIsLoading(false);
  };

  return (
    <>
      <div className={style.main} key={index}>
        <TextArea
          label="Comment"
          placeholder={'Start typing'}
          name="comment"
          errorMessage={error}
          onChange={(e) => {
            if (e.target.value) {
              setError();
            }
            setComment(e.target.value);
          }}
          value={comment}
        />
        <div className={style.btnSection}>
          <img
            src={closeIcon}
            onClick={() => {
              setEdit(false);
              setDisable(false);
              setToggle(false);
            }}
            className={style.img}
          />
          <Button
            handleClick={onSubmit}
            type={'button'}
            text={'Save'}
            isLoading={isLoading}
            iconStart={saveIcon}
            btnClass={btnClass}
          />
        </div>
      </div>
    </>
  );
};

export default CardCommentEdit;
