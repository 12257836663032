/* eslint-disable no-undef */
import React from 'react';

import style from './card-view.module.scss';

const CardView = ({
  img,
  topText,
  topCount,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  className,
  data,
  bottomImg,
  bottomCount,
  bottomText,
  propStyle,
  gridStyle,
  gridStyle2,
  iconStart,
  iconStart2,
  parentDivClick,
  p2MinWidth = '',
  childDiv1,
  childDiv2,
  childDiv3,
  bottomTextClick,
  showPointerOnParent = false,
}) => {
  return (
    <div className={`${style.attorneyDiv} ${className}`}>
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className={style.topDiv}>
          <img src={img} alt="" width={25} height={25} />
          <p
            className={style.text}
            style={{
              ...propStyle,
              cursor: showPointerOnParent ? 'pointer' : 'default',
            }}
            onClick={parentDivClick && parentDivClick}
          >
            {topText}
          </p>
        </div>
        <h1
          style={{ ...propStyle, cursor: 'pointer' }}
          onClick={parentDivClick && parentDivClick}
        >
          {topCount}
        </h1>
      </div>

      <div className={style.gridSection}>
        {(p1 || p2) && (
          <div
            className={style.gridView}
            style={{ ...gridStyle, cursor: 'pointer' }}
            onClick={childDiv1 && childDiv1}
          >
            <p className={style.p1}>{p1}</p>
            <div className={style.iconView}>
              {iconStart && (
                <img src={iconStart} alt="" style={{ marginRight: 10 }} />
              )}
              <p
                className={style.p2}
                style={{ minWidth: p2MinWidth ? p2MinWidth : '' }}
              >
                {p2}
              </p>
            </div>
          </div>
        )}
        {(p3 || p4) && (
          <div
            className={style.gridView}
            style={{ ...gridStyle2, cursor: 'pointer' }}
            onClick={childDiv2 && childDiv2}
          >
            <p className={style.p1}>{p3}</p>
            <div className={style.iconView}>
              {iconStart2 && (
                <img src={iconStart2} alt="" style={{ marginRight: 10 }} />
              )}
              <p className={style.p2}>{p4}</p>
            </div>
          </div>
        )}
        {(p5 || p6) && (
          <div
            className={style.gridView}
            style={{ ...gridStyle, cursor: 'pointer' }}
            onClick={childDiv3 && childDiv3}
          >
            <p className={style.p1}>{p5}</p>
            <p className={style.p2}>{p6}</p>
          </div>
        )}
      </div>

      {data && (
        <div
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div className={style.topDiv}>
            <img src={bottomImg} alt="" width={25} height={25} />
            <p
              className={style.text}
              style={{ ...propStyle, cursor: 'pointer' }}
              onClick={bottomTextClick && bottomTextClick}
            >
              {bottomText}
            </p>
          </div>
          <h1
            style={{ ...propStyle, cursor: 'pointer' }}
            onClick={bottomTextClick && bottomTextClick}
          >
            {bottomCount}
          </h1>
        </div>
      )}
    </div>
  );
};

export default CardView;
