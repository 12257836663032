import React from 'react';

import Button from 'components/button';
import Modal from 'components/modal';

import delIcon from 'assets/icons/del-purple.svg';
import style from './del-modal.module.scss';

const DeleteRecordModal = ({ deleteRecord, setDeleteRecord, setHistories }) => {
  return (
    <div>
      <Modal
        open={deleteRecord !== false}
        handleClose={() => setDeleteRecord(false)}
        className={style.mainDiv}
        bodyClass={style.modalBody}
      >
        <div className={style.iconRefresh}>
          <img src={delIcon} height="48px" alt="" />
        </div>
        <h6 className={style.modalTitle}>Remove the record</h6>
        <p className={style.para}>
          Are you sure you want to remove this record from the display?
        </p>
        <div className={style.mainBtnDiv}>
          <Button
            text={'Yes, remove'}
            btnClass={style.btnClassModal}
            handleClick={() => {
              setHistories((prev) =>
                prev.filter((x, index) => index !== deleteRecord)
              );
              setDeleteRecord(false);
            }}
          />
          <Button
            text={'No, don’t remove'}
            btnClass={style.btnClassUncheckModal}
            handleClick={() => setDeleteRecord(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DeleteRecordModal;
