import { ROLES } from 'permissions/constants';
import { getAttorneyReduxData } from 'redux/attorney/attorney-actions';
import { userService } from 'services/users';
import * as Actions from './constants';

export const getUserReduxData = () => async (dispatch) => {
  const response = await userService.checkAuth();
  if (response?.data?.userType === ROLES.ATTORNEY) {
    await dispatch(getAttorneyReduxData(response.data.email));
  }
  setTimeout(
    () =>
      dispatch({
        type: Actions.SET_USER_DATA,
        payload: response.data,
      }),
    500
  );
};
export const setUserReduxData = (item) => ({
  type: Actions.SET_USER_DATA,
  payload: item,
});
