import moment from 'moment';
import axios from 'utils/axios';
import { API_URLS } from './url-paths';

let source = axios.CancelToken.source();
export const getAttorneys = async (params) => {
  let url = `${API_URLS.GET_ATTORNEYS}`;
  return axios.get(url, {
    params: params || {},
  });
};

export const getAttorneysDirectory = async (params) => {
  let url = `${API_URLS.GET_ATTORNEYS_DIRECTORY}`;
  return axios.get(url, {
    params: params || {},
  });
};

export const exportAttorneysDirectoryToCSV = async (
  checkedRowIds,
  sort,
  calculationType,
  queryType,
  dateRange,
  filters,
  columns
) => {
  let url = `${API_URLS.GET_ATTORNEYS_DIRECTORY_CSV}`;
  return axios.get(url, {
    params: {
      _ids: checkedRowIds.join(',') ?? '',
      sortBy: sort?.sortBy,
      order: sort?.order,
      calculationType,
      queryType,
      filters,
      columns,
      dateRange: {
        startDate:
          dateRange[0] && moment(dateRange[0]).format('YYYY-MM-DDTHH:mm:ss'),
        endDate:
          dateRange[1] && moment(dateRange[1]).format('YYYY-MM-DDTHH:mm:ss'),
      },
    },
  });
};
let checkIfApiCall = false;

export const getAttorneyCalculations = async (params) => {
  if (checkIfApiCall) {
    source.cancel('Your Previous api is cancelled!!!');
    source = axios.CancelToken.source();
  }
  checkIfApiCall = true;
  const response = await axios.get(API_URLS.GET_ATTORNEY_CALCULATIONS, {
    cancelToken: source.token,
    params,
  });
  checkIfApiCall = false;
  return response;
};

export const getSchools = async () => {
  let url = `${API_URLS.GET_LAW_SCHOOLS}`;
  return axios.get(url);
};

export const updateAllAttorneysDirectory = async (
  data,
  createBarHistory,
  updateBarHistory
) => {
  let url = `${API_URLS.UPDATE_ATTORNEYS_DIRECTORY}`;
  return axios['post'](url, {
    attorneys: data,
    createBarHistory,
    updateBarHistory,
  });
};

export const updateACHAndLIForNotInterviewing = async (data) => {
  let url = `${API_URLS.UPDATE_A_LI_AND_A_CH_NI}`;
  return axios['put'](url, {
    ...data,
  });
};
export const updateACHAndLIForPlaced = async (data) => {
  let url = `${API_URLS.UPDATE_A_LI_AND_A_CH_P}`;
  return axios['put'](url, {
    ...data,
  });
};
export const updateAttorneyLiStatus = async (data) => {
  let url = `${API_URLS.UPDATE_ATTORNEYS_LISTATUS}`;
  return axios['put'](url, {
    ...data,
  });
};

export const deleteAttorneyById = async (id) => {
  let url = `${API_URLS.DELETE_ATTORNEY}/${id}`;
  return axios.delete(url);
};
