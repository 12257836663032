import Tags from 'components/tags';
import style from './history-section.module.scss';

const HistoryTab = ({
  title,
  value,
  isBlue,
  tagColor,
  dateMode,
  tagText,
  trainingWidget,
}) => {
  return (
    <div className={style.historyMainSection}>
      <div className={style.titleDiv}>
        <span
          className={style.title}
          style={{ color: isBlue ? '#304059' : null }}
        >
          {title}
        </span>
        <span className={style.value} style={{ overflowWrap: 'anywhere' }}>
          {value}
        </span>
      </div>
      <div className={style.btnClass}>
        <Tags
          dateMode={dateMode}
          color={tagColor}
          trainingWidget={trainingWidget}
          text={tagText}
          circular={true}
        />
      </div>
    </div>
  );
};

export default HistoryTab;
