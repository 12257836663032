export const SET_DASHBOARD_FILTER = 'SET_DASHBOARD_FILTER';
export const SET_ATTORNEY_TABLE_FILTER = 'SET_ATTORNEY_TABLE_FILTER';
export const SET_CLIENTS_TABLE_FILTER = 'SET_CLIENTS_TABLE_FILTER';
export const SET_POSITIONS_TABLE_FILTER = 'SET_POSITIONS_TABLE_FILTER';

export const SET_ATTORNEY_TABLE_COLUMNS = 'SET_ATTORNEY_TABLE_COLUMNS';

export const SET_ATTORNEY_DIRECTORY_CHECKED = 'SET_ATTORNEY_DIRECTORY_CHECKED';
export const SET_CLIENT_DIRECTORY_CHECKED = 'SET_CLIENT_DIRECTORY_CHECKED';
export const SET_POSITION_DIRECTORY_CHECKED = 'SET_POSITION_DIRECTORY_CHECKED';
