import React from 'react';

import Button from 'components/button';
import Modal from 'components/modal';

import { P02_SCREEN_MESSAGES } from 'data/userFacingMessages';

import del from 'assets/icons/del-purple.svg';
import style from './del-modal.module.scss';

const DeleteAttorneyModal = ({
  setMatches,
  deleteAttorney,
  setDeleteAttorney,
}) => {
  const handleDeleteAttorney = async () => {
    setMatches((prev) => {
      const filteredMatches = prev.matches.filter(
        (x) => x._id !== deleteAttorney
      );
      prev.matches = [...filteredMatches];
      return { ...prev };
    });
    setDeleteAttorney(false);
  };

  return (
    <div>
      <Modal
        open={deleteAttorney}
        handleClose={() => setDeleteAttorney(false)}
        className={style.mainDiv}
      >
        <div className={style.iconRefresh}>
          <img src={del} alt="" />
          <p className={style.modalTitle}>Delete Attorney</p>
        </div>
        <p className={style.modalText}>
          Are you sure you want to delete this record?
        </p>
        <div className={style.mainBtnDiv}>
          <Button
            btnClass={style.btnClassModal}
            handleClick={handleDeleteAttorney}
            text={P02_SCREEN_MESSAGES.popups.deleteConfirm.attorney.yes}
          />
          <Button
            text={P02_SCREEN_MESSAGES.popups.deleteConfirm.attorney.no}
            btnClass={style.btnClassUncheckModal}
            handleClick={() => setDeleteAttorney(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAttorneyModal;
