import { useMemo } from 'react';
import moment from 'moment';

import { useAttoreyProfile } from 'contexts/attorney-profile';

import editStyle from '../main-page.module.scss';
import readStyle from '../main-page-read.module.scss';

const CommentsWidget = () => {
  const { attorney, edit } = useAttoreyProfile();

  const { attorneyComments } = attorney;

  //Use different style for edit and read mode
  const style = useMemo(() => (edit ? editStyle : readStyle), [edit]);

  return attorneyComments.map((ele, index) => (
    <div key={index} className={[style.commentSection]}>
      <div className={style.commentSectionHeader}>
        <span className={style.commentHead}>{ele.userName}</span>
        <span className={style.date} key={index}>
          {moment(ele.commentDate).format('MM/DD/YYYY')}
        </span>
      </div>
      <div style={{ marginRight: '0px' }}>
        <span className={style.commentValue} key={index}>
          {ele.comment}
        </span>
      </div>
    </div>
  ));
};

export default CommentsWidget;
