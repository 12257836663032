/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useForm } from 'react-hook-form';
import { useDimensions } from '../../hooks/useDimensions';
import TableCell from './table-cell';

import fileGray from 'assets/icons/file-gray.svg';
import deleteIcon from 'assets/images/GreenDelIcon.svg';
import editIcon from 'assets/images/greenEditIcon.svg';
import { ReactComponent as DownIcon } from 'assets/images/sortDownIcon.svg';
import { ReactComponent as UpIcon } from 'assets/images/sortUpIcon.svg';
import Checkbox from 'components/checkbox';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { ROLES } from 'permissions/constants';
import { placeObjects } from 'utils/helpers';

/*
Please note if you try to pass both isDraggable & isEditMode true then it might break. 
I'm assuming this will not be the case in this project
*/

const GenericTable = (
  {
    columns = [],
    setEditTable,
    dataSource,
    isEditMode = false,
    onSaveSubmit = (data) => {},
    dragColWidth = false,
    schema = null,
    onSort = (sort) => {},
    renderNoDataFound = null,
    height = '100%',
    width = '100%',
    onScrollBottom = () => {},
    formId = 'hook-form',
    isDragable = false,
    selectable = false,
    renderSelector = null,
    classes = null,
    onRowClick = (id) => {},
    checkedAllRows = () => {},
    loading = false,
    checkbox = true,
    empty = false,
    sortBy,
    order,
    rowColor,
    onDragEnd = () => {},
    handlePinnedRows,
    singleEditMode,
    handleSingleDelete,
    renderPins,
  },
  ref
) => {
  const [rows, setRows] = useState([]);
  const [sort, setSort] = useState({});
  const [editRowLoading, setEditRowLoading] = useState(false);
  const genericTableRef = useRef();
  const currentSize = useDimensions();
  const methods = useForm({
    ...(schema && { resolver: yupResolver(schema) }),
  });

  const checkedArr = [...new Set(rows.map((x) => x.checked))];
  const allChecked =
    checkedArr.length === 1 && checkedArr[0] === true ? true : false;

  useEffect(() => {
    setRows([...dataSource]);
    methods.reset({ data: [...dataSource] });
  }, [JSON.stringify(dataSource)]);

  const onSubmit = async (data) => {
    if (singleEditMode) {
      setEditRowLoading(true);
      await onSaveSubmit(data.data[isEditMode]);
      setEditRowLoading(false);
    } else {
      await onSaveSubmit([...data.data]);
    }
  };

  useEffect(() => {
    if (!isEditMode) methods.reset({ data: [...dataSource] });
  }, [isEditMode]);

  const handleSort = (sortBy) => {
    if (sortBy !== 'actions') {
      let tempSort = {};
      if (_.isEmpty(sort) || sort.sortBy !== sortBy) {
        tempSort = {
          sortBy,
          order: 'asc',
        };
      } else if (sort.sortBy === sortBy) {
        tempSort = {
          sortBy,
          order:
            sort.order === 'asc'
              ? 'desc'
              : sort.order === 'desc'
              ? 'default'
              : 'asc',
        };
      }
      setSort(tempSort);
      onSort(tempSort);
    }
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(rows);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    if (tempData?.[0]?.slot > -2) {
      const ordered = placeObjects(tempData);
      setRows(ordered);
      onDragEnd({ data: ordered });
    } else {
      setRows(tempData);
      onDragEnd({ data: tempData });
    }
  };

  const sortArrowsOnTableHead = (name, icon, displayIcon) => {
    return (
      <>
        {displayIcon &&
          (icon === 'up' ? (
            <UpIcon
              style={{
                cursor: 'pointer',
                marginRight: '5px',
                color: 'var(--primary-color-of-stats)',
              }}
            />
          ) : (
            <DownIcon
              style={{
                cursor: 'pointer',
                marginRight: '5px',
                color: 'var(--primary-color-of-stats)',
              }}
            />
          ))}
        {name}
      </>
    );
  };

  useEffect(() => {
    const element = document?.getElementById('table-id-unique');

    const calculateElementHeight = () => {
      // Get the viewport height
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Specify the desired bottom margin
      const desiredBottomMargin = 4; // You can change this value to your desired margin

      // Calculate the element height
      const elementHeight =
        viewportHeight - element.offsetTop - desiredBottomMargin;

      // Set the calculated height to the element
      element.style.height = `${elementHeight}px`;
    };

    // Initial height calculation
    calculateElementHeight();

    const handleResize = () => {
      // Recalculate element height on screen size change
      calculateElementHeight();
    };

    // Add event listener for screen resize
    window.addEventListener('resize', handleResize);

    return () => {
      // Remove event listener when the component unmounts
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      style={{
        height,
        overflowY: 'auto',
        width,
      }}
      id="table-id-unique"
      ref={ref}
      onScroll={(e) => {
        if (
          e.target.clientHeight + e.target.scrollTop >=
          e.target.scrollHeight - 10
        ) {
          setTimeout(() => {
            onScrollBottom(true);
          }, 100);
        }
      }}
    >
      <form id={formId} onSubmit={methods.handleSubmit(onSubmit)}>
        <div style={{ position: 'relative' }}>
          <div
            style={{
              width: `${genericTableRef?.current?.offsetWidth}px`,
            }}
            className={
              loading ? classes?.tableOnLoading : classes?.tableOffLoading
            }
          ></div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <table
              ref={genericTableRef}
              className={
                methods?.formState?.errors?.data
                  ? classes?.errorTable || classes?.table
                  : classes?.table
              }
              style={{
                tableLayout: 'fixed',
                position: 'relative',
              }}
            >
              <thead className={classes?.thead}>
                <tr>
                  {isDragable && !isEditMode && (
                    <th
                      className={classes?.th}
                      width={dragColWidth || '60px'}
                    ></th>
                  )}
                  {checkbox && selectable && !isEditMode && (
                    <th
                      className={classes?.th}
                      width="46px"
                      style={{ minWidth: '46px' }}
                    >
                      <Checkbox
                        checked={allChecked}
                        handleChange={(e) => checkedAllRows(e?.target?.checked)}
                      />
                    </th>
                  )}
                  {empty && (
                    <th
                      className={classes?.th}
                      width="46px"
                      style={{ minWidth: '46px' }}
                    ></th>
                  )}

                  {columns?.map(
                    (
                      {
                        name,
                        key,
                        sortKey,
                        widthAndHeight = {},
                        widthInEditMode,
                        hidden,
                        icon = '',
                        displayIcon,
                        customHeaderClass,
                        showPointer = false,
                      },
                      i
                    ) => {
                      return (
                        !hidden && (
                          <th
                            onClick={() =>
                              !isEditMode && handleSort(sortKey ?? key)
                            }
                            key={key}
                            className={`${classes?.th} ${customHeaderClass}`}
                            style={{
                              minWidth:
                                currentSize !== 'xs' &&
                                currentSize !== 'sm' &&
                                columns.length < 7 &&
                                i === columns?.length - 1
                                  ? '100%'
                                  : isEditMode
                                  ? widthInEditMode?.width
                                  : widthAndHeight?.width,
                              width:
                                currentSize !== 'xs' &&
                                currentSize !== 'sm' &&
                                columns.length < 7 &&
                                i === columns?.length - 1
                                  ? '100%'
                                  : isEditMode
                                  ? widthInEditMode?.width
                                  : widthAndHeight?.width,

                              cursor:
                                !isEditMode && showPointer
                                  ? 'pointer'
                                  : 'default',
                            }}
                          >
                            <>
                              {(sortKey ?? key) === sortBy && !isEditMode
                                ? sortArrowsOnTableHead(name, icon, displayIcon)
                                : name}
                            </>
                          </th>
                        )
                      );
                    }
                  )}
                </tr>
              </thead>
              <Droppable
                droppableId="droppable-1"
                isDropDisabled={!isDragable || isEditMode}
              >
                {(provider) => (
                  <tbody
                    className="text-capitalize"
                    ref={provider.innerRef}
                    {...(isDragable &&
                      !isEditMode && {
                        ...provider.droppableProps,
                      })}
                  >
                    {rows.length > 0 ? (
                      rows?.map((row, key) => (
                        <Draggable
                          key={`${row._id}-${key}`}
                          draggableId={row._id?.toString()}
                          index={key}
                          isDragDisabled={!isDragable || isEditMode}
                        >
                          {(provider) => (
                            <tr
                              className={classes?.tableBody}
                              key={row._id}
                              {...(isDragable &&
                                !isEditMode && {
                                  ...provider.draggableProps,
                                })}
                              ref={provider.innerRef}
                            >
                              {isDragable && !isEditMode && (
                                <td
                                  style={{
                                    backgroundColor: rowColor && rowColor(key),
                                  }}
                                >
                                  <div style={{ display: 'flex' }}>
                                    <img
                                      style={{
                                        padding: '5px 10px',
                                      }}
                                      src={fileGray}
                                      alt=""
                                      {...(row?.slot > -1
                                        ? {}
                                        : provider.dragHandleProps)}
                                    />
                                    {renderPins && renderPins(row)}
                                  </div>
                                </td>
                              )}
                              {selectable && !isEditMode && (
                                <td
                                  style={{
                                    padding: '5px 10px',
                                    backgroundColor: row?.checked
                                      ? '#EAFCFF'
                                      : rowColor && rowColor(key)
                                      ? rowColor(key)
                                      : '#FFFFFF',
                                  }}
                                >
                                  {renderSelector(
                                    row._id,
                                    row?.checked ?? false
                                  )}
                                </td>
                              )}
                              {columns.map(
                                (col) =>
                                  !col.hidden && (
                                    <TableCell
                                      id={row._id}
                                      register={methods.register}
                                      control={methods.control}
                                      watch={methods.watch}
                                      setValue={methods.setValue}
                                      column={col}
                                      key={`data.${key}.${col.key}`}
                                      isEditMode={isEditMode}
                                      row={row}
                                      inputName={`data.${key}.${col.key}`}
                                      index={key}
                                      rowColor={rowColor}
                                      onRowClick={() =>
                                        !isEditMode && onRowClick(row)
                                      }
                                      isDragable={isDragable}
                                      errors={methods?.formState?.errors}
                                      editRowLoading={editRowLoading}
                                    />
                                  )
                              )}
                              {singleEditMode && isEditMode === false && (
                                <div
                                  style={{
                                    display: 'flex',
                                    marginLeft: '-25px',
                                    position: 'relative',
                                    padding: '10px 10px',
                                    backgroundColor: 'transparent',
                                    justifyContent: 'flex-end',
                                    marginTop: '6px',
                                  }}
                                >
                                  <PermissionRestrict
                                    roles={[
                                      ROLES.ADMIN,
                                      ROLES.EDITOR,
                                      ROLES.ATTORNEY,
                                    ]}
                                  >
                                    <img
                                      src={editIcon}
                                      width={15}
                                      onClick={() => {
                                        setEditTable(key);
                                      }}
                                      style={{
                                        marginRight: '10px',
                                        cursor: 'pointer',
                                      }}
                                    />
                                    <img
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      width={15}
                                      onClick={() => {
                                        handleSingleDelete(row);
                                      }}
                                      src={deleteIcon}
                                    />
                                  </PermissionRestrict>
                                </div>
                              )}
                            </tr>
                          )}
                        </Draggable>
                      ))
                    ) : renderNoDataFound ? (
                      renderNoDataFound()
                    ) : (
                      <>
                        <tr>
                          <td colSpan={columns.length}>No Data Found</td>
                        </tr>
                      </>
                    )}

                    {provider.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </DragDropContext>
        </div>
      </form>
    </div>
  );
};

export default React.memo(forwardRef(GenericTable));
