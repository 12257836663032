import axios from 'utils/axios';
import { API_URLS } from './url-paths';

//contact By ID
export const getContactById = async (id) => {
  let url = `${API_URLS.GET_CONTACTS_BY_ID}/${id}`;
  return axios.get(url);
};
export const setContact = (obj, id, update = false) => {
  let url = API_URLS.SET_CONTACTS;
  if (id) url = `${API_URLS.SET_CONTACTS}/${id}`;
  return axios[update ? 'put' : 'post'](url, obj);
};

//delete contact
export const deleteContact = (id) => {
  let url = `${API_URLS.SET_CONTACTS}/${id}`;
  return axios.delete(url);
};
