import * as yup from 'yup';
import GenericTable from 'components/generic-table';
import Loading from 'components/loading';
import NoRecords from 'components/no-records';
import Tags from 'components/tags';
import { C02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import moment from 'moment/moment';
import { columns } from 'pages/positions-directory/helper';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './view.module.scss';

const ViewTable = ({
  attorneys,
  singleEditMode,
  setEditTable,
  editTable,
  columns,
  isLoading,
  onSaveSubmit,
  handleSingleDelete,
}) => {
  return (
    <>
      <div className={style.tableWidth}>
        {isLoading && <Loading />}
        <GenericTable
          schema={schema}
          setEditTable={setEditTable}
          isEditMode={editTable}
          columns={columns}
          dataSource={attorneys}
          singleEditMode={singleEditMode}
          handleSingleDelete={handleSingleDelete}
          onSaveSubmit={onSaveSubmit}
          classes={{
            test: style.test,
            table: `${style.table} ${
              editTable !== false ? style.paddingBottom : ''
            }`,
            thead: style.thead,
            th: style.th,
            tableBody: style.tableRow,
            tableOnLoading: style.genericTableOnLoading,
            tableOffLoading: style.genericTableOffLoading,
          }}
          renderNoDataFound={() => (
            <tr>
              <div className={style.noDataDiv}>
                <span>No Record</span>
              </div>
            </tr>
          )}
        />
      </div>
    </>
  );
};

const schema = yup.object({
  data: yup.array().of(
    yup
      .object({
        barStatus: yup
          .string()
          .required('Status is required')
          .typeError('Status is required'),
        barId: yup.string().typeError('Bar ID is required'),
        barTestedDate: yup
          .string()
          .required('Date is required')
          .typeError('Date is required'),
        barState: yup
          .string()
          .required('Bar state is required')
          .typeError('Bar state is required'),
      })
      .required()
  ),
});
export default ViewTable;
