import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { setAppDispatch } from '../../utils/dispatch';

export default function RegisterAppDispatch() {
  const dispatch = useDispatch();

  useEffect(() => {
    setAppDispatch(dispatch);
  }, [dispatch]);

  return null;
}
