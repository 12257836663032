import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/button';
import Modal from 'components/modal';

import { P02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import { deletePosition } from 'services/positions';

import del from 'assets/icons/del-purple.svg';
import style from './del-modal.module.scss';

const DeleteRecordModal = ({ deleteRecord, setDeleteRecord }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleDeletePosition = async () => {
    setLoading(true);
    await deletePosition({ id, navigate });
    setDeleteRecord(false);
    setLoading(false);
  };

  return (
    <div>
      <Modal
        open={deleteRecord}
        handleClose={() => setDeleteRecord(false)}
        className={style.mainDiv}
      >
        <div className={style.iconRefresh}>
          <img src={del} alt="" />
          <p className={style.modalTitle}>Delete Placement</p>
        </div>
        <p className={style.modalText}>
          Are you sure you want to delete this record?
        </p>
        <div className={style.mainBtnDiv}>
          <Button
            isLoading={loading}
            btnClass={style.btnClassModal}
            handleClick={handleDeletePosition}
            text={P02_SCREEN_MESSAGES.popups.deleteConfirm.placementRecord.yes}
          />
          <Button
            btnClass={style.btnClassUncheckModal}
            handleClick={() => setDeleteRecord(false)}
            text={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  {
                    P02_SCREEN_MESSAGES.popups.deleteConfirm.placementRecord
                      .no[0]
                  }
                </span>
                <span>
                  {
                    P02_SCREEN_MESSAGES.popups.deleteConfirm.placementRecord
                      .no[1]
                  }
                </span>
              </div>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default DeleteRecordModal;
