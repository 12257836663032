import _ from 'lodash';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

import Comments from './comments';
import AttorneyFiles from './files';
import BarHistory from './bar-history';
import Loader from 'components/loader';
import Container from 'components/container';
import ClientHistory from './client-history';
import ProfileTop from 'components/profile-top';
import TrainingHistory from './training-history';
import OverView from 'pages/attorney-profile/overview';
import PermissionRestrict from 'hoc/PermissionRestrict';
import AttorneyProfileProvider from 'contexts/attorney-profile';
import ChangeStatusToPlacedModal from 'components/change-status-modals/change-status-to-placed';
import PlacedStatusPopup from 'components/change-status-modals/add-status-modal/PlacedStatusPopup';
import ChangeStatusInterviewingToNotInterviewingModal from 'components/change-status-modals/change-status-interviewing-to-not-interviewing';

import {
  getClientPosition,
  getAttorneyClientHistory,
} from 'services/attorney-profile';
import { ROLES } from 'permissions/constants';
import { useAttoreyProfile } from 'contexts/attorney-profile';

import uploadIcon from 'assets/images/upload-blue.svg';
import style from './main-page-read.module.scss';

const MainPageReadOnly = () => {
  const {
    id,
    edit,
    Comp,
    watch,
    active,
    disable,
    setEdit,
    getData,
    attorney,
    onSubmit,
    setValue,
    setDisable,
    setAttorney,
    clientsName,
    handleSubmit,
  } = useAttoreyProfile();

  const userType = useSelector((state) => state.user.userData?.userType);
  let liStatus = attorney && attorney?.attorney && attorney?.attorney?.liStatus;
  const [loading, setLoading] = useState(false);
  const [clientNames, setClientNames] = useState([]);
  const [clientPositions, setClientPositions] = useState([]);
  const [currentClientNames, setCurrentClientNames] = useState([]);
  const [currentClientPositions, setCurrentClientPositions] = useState([]);
  const [previousAttorneyClientHistory, setPreviousAttorneyClientHistory] =
    useState([]);
  const [
    openModalWhenLIStatusChangeToNotInterviewing,
    setOpenModalWhenLIStatusChangeToNotInterviewing,
  ] = useState(false);
  const [
    openModalWhenLIStatusChangePlacedToNotInterviewing,
    setOpenModalWhenLIStatusChangePlacedToNotInterviewing,
  ] = useState(false);
  const [
    openModalWhenLIStatusChangedToPlaced,
    setOpenModalWhenLIStatusChangedToPlaced,
  ] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await getClientPosition();
      const allClientName =
        data?.map((x) => {
          return {
            label: x?.clientName,
            value: x?._id,
          };
        }) || [];

      setClientNames(allClientName);
      setClientPositions(data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (edit && id !== 'new' && liStatus !== watch('liStatus')) {
        if (
          (liStatus === 'Interviewing_LI' || liStatus === 'Interviewing_P') &&
          watch('liStatus') === 'Not Interviewing'
        ) {
          const { data } = await getAttorneyClientHistory(id);
          const filterInterviewing = data?.history?.filter(
            (x) => x?.status === 'Interviewing'
          );
          if (_.size(filterInterviewing) > 0) {
            setOpenModalWhenLIStatusChangeToNotInterviewing(true);
            setPreviousAttorneyClientHistory(filterInterviewing);
          }
        } else if (
          liStatus === 'Placed' &&
          watch('liStatus') === 'Not Interviewing'
        ) {
          const { data } = await getAttorneyClientHistory(id);
          if (data?.history[0]?.status === 'Placed') {
            setPreviousAttorneyClientHistory(data?.history[0]);
            setOpenModalWhenLIStatusChangePlacedToNotInterviewing(true);
          }
        } else if (watch('liStatus') === 'Placed') {
          const { data } = await getAttorneyClientHistory(id);
          const filterInterviewing = data?.history?.filter(
            (x) => x?.status === 'Interviewing'
          );
          if (_.size(filterInterviewing) > 0) {
            setOpenModalWhenLIStatusChangedToPlaced(true);
            const uniqueClientIds = [
              ...new Set(filterInterviewing.map((x) => x.clientId)),
            ];
            const uniqueClientPositions = uniqueClientIds.map((clientId) => ({
              _id: clientId,
              clientName: filterInterviewing.find(
                (x) => x.clientId === clientId
              ).clientName,
              positions: filterInterviewing
                .filter((x) => x.clientId === clientId)
                .map((x) => ({
                  value: x.positionId._id,
                  label: x.positionName,
                })),
            }));
            setCurrentClientPositions(uniqueClientPositions);
            setCurrentClientNames(
              uniqueClientPositions.map((x) => ({
                label: x?.clientName,
                value: x?._id,
              }))
            );
            setPreviousAttorneyClientHistory(filterInterviewing);
          }
        }
      }
      setLoading(false);
    })();
  }, [watch('liStatus')]);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#39695b');
  }, []);

  return (
    <>
      <Comp
        {...(edit && {
          id: 'overview-form',
          onSubmit: handleSubmit(onSubmit),
        })}
        style={{
          positiom: 'relative',
        }}
        {...(userType === ROLES.ATTORNEY && { className: style.attorneyForm })}
      >
        {attorney ? (
          <div
            className={style.wraper}
            style={{
              backgroundColor: '#f8fffd',
              minHeight: 'calc(100vh - 74px)',
              overflowY: 'auto',
            }}
          >
            <ProfileTop
              disable={disable}
              uploadIcon={uploadIcon}
              color={'#39695b'}
              setDisable={setDisable}
            />

            <div>
              <Container>
                <div hidden={active !== 0}>
                  <OverView setDisable={setDisable} id={id} />
                </div>
                <PermissionRestrict
                  roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}
                >
                  <>
                    {id !== 'new' && (
                      <>
                        <div hidden={active !== 1}>
                          <BarHistory setDisable={setDisable} />
                        </div>
                        <div hidden={active !== 2}>
                          <ClientHistory
                            setDisable={setDisable}
                            clientsName={clientsName}
                          />
                        </div>
                        <div hidden={active !== 3}>
                          <Comments setDisable={setDisable} />
                        </div>
                        <div hidden={active !== 4}>
                          <TrainingHistory setDisable={setDisable} />
                        </div>
                        <div
                          hidden={active !== 5}
                          style={{ padding: '0px 10px' }}
                        >
                          <AttorneyFiles setDisable={setDisable} />
                        </div>
                      </>
                    )}
                  </>
                </PermissionRestrict>
                <PermissionRestrict roles={[ROLES.ATTORNEY]}>
                  <>
                    {id !== 'new' && (
                      <>
                        <div hidden={active !== 1}>
                          <BarHistory />
                        </div>
                        <div hidden={active !== 2}>
                          <TrainingHistory />
                        </div>
                      </>
                    )}
                  </>
                </PermissionRestrict>
              </Container>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </Comp>

      {openModalWhenLIStatusChangedToPlaced && !loading && (
        <ChangeStatusToPlacedModal
          {...{
            attorneyId: id,
            showStatus: true,
            previousAttorneyClientHistory,
            changeValue: watch('liStatus'),
            clientNames: currentClientNames,
            openModal: openModalWhenLIStatusChangedToPlaced,
            setOpenModal: setOpenModalWhenLIStatusChangedToPlaced,
            clientNamesWithPositions: currentClientPositions,
            handleCancel: () => {
              setOpenModalWhenLIStatusChangedToPlaced(false);
              setAttorney((prev) => ({
                ...prev,
                liStatus: liStatus,
              }));
              setValue('liStatus', liStatus);
            },
            getUpdatedData: async () => {
              await getData();
              setDisable(false);
              setEdit(false);
            },
          }}
        />
      )}
      {openModalWhenLIStatusChangeToNotInterviewing && !loading && (
        <ChangeStatusInterviewingToNotInterviewingModal
          {...{
            attorneyId: id,
            showStatus: true,
            previousAttorneyClientHistory,
            changeValue: watch('liStatus'),
            openModal: openModalWhenLIStatusChangeToNotInterviewing,
            setOpenModal: setOpenModalWhenLIStatusChangeToNotInterviewing,
            getUpdatedData: async () => {
              await getData();
              setDisable(false);
              setEdit(false);
            },
            handleCancel: () => {
              setOpenModalWhenLIStatusChangeToNotInterviewing(false);
              setAttorney((prev) => ({
                ...prev,
                liStatus: liStatus,
              }));
              setValue('liStatus', liStatus);
            },
          }}
        />
      )}
      {openModalWhenLIStatusChangePlacedToNotInterviewing && !loading && (
        <PlacedStatusPopup
          clientNames={clientNames}
          clientNamesWithPositions={clientPositions}
          changeValue={watch('liStatus')}
          getUpdatedData={async () => {
            await getData();
            setDisable(false);
            setEdit(false);
          }}
          defaultLiStatus={liStatus}
          attorneyId={id}
          cancelBtnEvent={() => {
            setOpenModalWhenLIStatusChangePlacedToNotInterviewing(false);
            setAttorney((prev) => ({
              ...prev,
              liStatus: liStatus,
            }));
            setValue('liStatus', liStatus);
          }}
          previousAttorneyClientHistory={previousAttorneyClientHistory}
          updateOnlyACLatest
        />
      )}
    </>
  );
};

const MainPageWithContext = (props) => (
  <AttorneyProfileProvider>
    <MainPageReadOnly {...props} />
  </AttorneyProfileProvider>
);
export default MainPageWithContext;
