import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';

import dateLeftBlue from 'assets/icons/date-icons/left-blue.svg';
import dateLeftGreen from 'assets/icons/date-icons/left-green.svg';
import dateLeftPink from 'assets/icons/date-icons/left-pink.svg';
import dateLeftPurple from 'assets/icons/date-icons/left-purple.svg';
import dateRightBlue from 'assets/icons/date-icons/right-blue.svg';
import dateRightGreen from 'assets/icons/date-icons/right-green.svg';
import dateRightPink from 'assets/icons/date-icons/right-pink.svg';
import dateRightPurple from 'assets/icons/date-icons/right-purple.svg';
import 'react-datepicker/dist/react-datepicker.css';
import style from './date.module.scss';
import './index.css';

const CustomDatePicker = ({
  open,
  isPink,
  isblue,
  isPurple,
  setOpen,
  value,
  onChange,
  custom = true,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    setOpen && setOpen(!open);
  };

  return (
    <div
      style={{ padding: '8px 16px', position: 'relative' }}
      onClick={(e) => e.stopPropagation()}
    >
      {custom && (
        <p
          style={{
            padding: 0,
          }}
          onClick={handleClick}
        >
          Custom
        </p>
      )}
      {(open || isOpen) && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            right: 0,
          }}
        >
          <ReactDatePicker
            selectsRange={true}
            withPortal
            inline
            selectsStart
            selectsEnd
            selected={(value?.[0] && new Date(value?.[0])) || null}
            startDate={(value?.[0] && new Date(value?.[0])) || null}
            endDate={(value?.[1] && new Date(value?.[1])) || null}
            onChange={(update) => {
              onChange && onChange(update);
              if (update[1]) {
                setIsOpen(false);
                setOpen && setOpen(false);
              }
            }}
            isClearable={true}
            renderCustomHeader={({
              date,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className={style.iconsDiv}>
                <div className={style.dateHeader}>
                  <button
                    type={'button'}
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <img
                      src={
                        isblue
                          ? dateLeftBlue
                          : isPink
                          ? dateLeftPink
                          : isPurple
                          ? dateLeftPurple
                          : dateLeftGreen
                      }
                      alt=""
                    />
                  </button>
                  <p className={style.p}>
                    {months[date.getMonth()]} {date.getFullYear()}
                  </p>
                  <button
                    type={'button'}
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <img
                      src={
                        isblue
                          ? dateRightBlue
                          : isPink
                          ? dateRightPink
                          : isPurple
                          ? dateRightPurple
                          : dateRightGreen
                      }
                      alt=""
                    />
                  </button>
                </div>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
