/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { Routes as Switch, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MenuBar from 'components/menu';
import { authRoutes, PrivateRoutes } from './helper';
import {
  setLocations,
  setPracticeGroups,
  setStates,
  setWeights,
} from 'redux/system-values/system-values-actions';
import { getUserReduxData } from 'redux/user/user-actions';
import { ROLES } from 'permissions/constants';
import AttorneyMenu from 'components/menu/attorney-menu';
import { getAttorneyReduxData } from 'redux/attorney/attorney-actions';
import AttorrneyProfile from 'pages/attorney-profile';
import { useNetwork } from 'hooks/useNetwork';

function Router() {
  const loggedIn = useSelector((state) => state.auth.userLogin);
  const userData = useSelector((state) => state.user.userData);
  const attorneyData = useSelector((state) => state.attorney.attorneyData);
  const dispatch = useDispatch();
  const isOnline = useNetwork();

  useEffect(() => {
    if (loggedIn) {
      dispatch(setLocations());
      dispatch(setStates());
      dispatch(setPracticeGroups());
      dispatch(setWeights());
      dispatch(getUserReduxData());
    }
  }, [loggedIn]);

  useEffect(() => {
    if (userData?.userType === ROLES.ATTORNEY) {
      dispatch(getAttorneyReduxData(userData.email));
    }
  }, [userData]);

  return (
    <>
      {!userData ? (
        <Switch>
          {authRoutes?.map(({ path, component }, index) => (
            <Route path={path} element={component} key={index} />
          ))}
          <Route path="*" element={<Navigate to={'/login'} />} />
        </Switch>
      ) : (
        <>
          {userData.userType === ROLES.ATTORNEY &&
          !attorneyData?.attorney?.updatedOnce ? (
            <AttorneyMenu />
          ) : (
            <MenuBar />
          )}
          <Switch>
            {userData.userType === ROLES.ATTORNEY &&
            attorneyData?.attorney?.updatedOnce ? (
              <>
                <Route element={<AttorrneyProfile />} path={'/attorneys/:id'} />
                <Route
                  path="*"
                  element={
                    <Navigate
                      to={'/attorneys/' + attorneyData?.attorney?._id}
                    />
                  }
                />
              </>
            ) : (
              <>
                {PrivateRoutes?.map(
                  ({ path, component, roles }, index) =>
                    roles.includes(userData.userType) && (
                      <Route path={path} element={component} key={index} />
                    )
                )}
                <Route path="*" element={<Navigate to={'/'} />} />
              </>
            )}
          </Switch>
        </>
      )}
      {!isOnline && (
        <div
          style={{
            width: '100vw',
            background: 'red',
            position: 'fixed',
            bottom: 0,
            padding: '5px',
          }}
        >
          <p style={{ color: 'white', textAlign: 'center' }}>
            You are offline 🥺 Please check your internet connection
          </p>
        </div>
      )}
    </>
  );
}
export default Router;
