import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAttoreyProfile } from 'contexts/attorney-profile';
import {
  getAttorneyTrainingHistory,
  deleteTrainingHistory,
} from 'services/attorney-profile';
import { createNotification } from 'components/react-notification';

const useHelper = ({
  completedCourse,
  completedCourseHours,
  totalCle,
  barStates,
  liveCle,
}) => {
  const { id } = useParams();
  const { getData, active, add } = useAttoreyProfile();

  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trainingHistory, setTrainingHistory] = useState([]);
  const [traingingCalculations, setTraingingCalculations] = useState({});

  useEffect(() => {
    getTrainingData();
  }, []);

  useEffect(() => {
    if (active === 4) {
      setToggle(false);
      setEdit(false);
      setOpen(false);
      setOpenEdit(false);
    }
  }, [active]);

  const getTrainingData = async () => {
    setIsLoading(true);
    try {
      const res = await getAttorneyTrainingHistory(id);
      setTrainingHistory([...res.data.trainings]);
      setTraingingCalculations({
        trainings: undefined,
        ...res.data,
      });
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
    }
    setIsLoading(false);
  };

  // Set Toggle to false on new training histories
  useEffect(() => {
    setToggle(false);
  }, [trainingHistory]);

  // Set Open Edit true on "when user comes from Overview Add button"
  useEffect(() => {
    setOpenEdit(add);
  }, [add]);

  //delete
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteTrainingHistory(open);
      getTrainingData();
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
    getData();
    setOpen(null);
  };

  const onAdd = () => {
    getTrainingData();
  };

  const onEdit = () => {
    getTrainingData();
  };

  return {
    onAdd,
    onEdit,
    completedCourse,
    completedCourseHours,
    totalCle,
    barStates,
    liveCle,
    toggle,
    openEdit,
    open,
    isLoading,
    trainingHistory,
    edit,
    deleteTrainingHistory,
    handleDelete,
    setToggle,
    setOpenEdit,
    setOpen,
    setEdit,
    traingingCalculations,
    getTrainingData,
  };
};

export default useHelper;
