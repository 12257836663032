/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CardView from 'components/card-view';

import {
  setAttorneyTableFilter,
  setCLientTableFilter,
  setPositionTableFilter,
} from 'redux/filters/filters-actions';

import attorneyIcon from 'assets/images/attorney.svg';
import clientIcon from 'assets/images/client.svg';
import opportunityIcon from 'assets/images/opportunity.svg';
import positionIcon from 'assets/images/position.svg';

import style from './home.module.scss';

const NowTab = ({
  dashboardData,
  endDate = null,
  startDate = null,
  currentValue = 'Now',
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const attorneyClientRest = {
    currentValue,
    dateRange: [startDate, endDate],
    filters: { mainFilters: {}, statsFilters: {} },
    newQuery: true,
  };

  const positionRest = {
    filters: {},
    currentValue,
    newQuery: true,
    dateRange: [startDate, endDate],
  };

  return (
    <>
      <div className={style.parentDiv}>
        <div className={style.section1}>
          <CardView
            img={attorneyIcon}
            topText="Active Attorneys"
            topCount={String(dashboardData?.attorneys?.activeAttorneys)}
            p1={dashboardData?.attorneys?.placedlineAttorney}
            p2="Placed"
            p3={String(dashboardData?.attorneys?.unplacedlineAttorney)}
            p4="Unplaced"
            p5={String(dashboardData?.attorneys?.interviewingLIAttorney)}
            p6="Interviewing"
            parentDivClick={() => {
              dispatch(
                setAttorneyTableFilter({
                  calculationSelected: 'All',
                  ...attorneyClientRest,
                })
              );
              navigate(`/attorneys`);
            }}
            childDiv1={() => {
              dispatch(
                setAttorneyTableFilter({
                  calculationSelected: 'Placed',
                  ...attorneyClientRest,
                })
              );
              navigate(`/attorneys`);
            }}
            childDiv2={() => {
              dispatch(
                setAttorneyTableFilter({
                  calculationSelected: 'Unplaced',
                  ...attorneyClientRest,
                })
              );
              navigate(`/attorneys`);
            }}
            childDiv3={() => {
              dispatch(
                setAttorneyTableFilter({
                  calculationSelected: 'Interviewing_LI',
                  ...attorneyClientRest,
                })
              );
              navigate(`/attorneys`);
            }}
            showPointerOnParent
          />
          <CardView
            img={clientIcon}
            topText="Active Clients"
            topCount={dashboardData?.clients?.activeClients}
            p1={dashboardData?.clients?.lawFirms}
            p2="Law Firms"
            p3={dashboardData?.clients?.corporate}
            p4="Corporate"
            data
            bottomCount={dashboardData?.clients?.clientWithOpenPosition}
            bottomText="Clients with Open Positions"
            bottomImg={clientIcon}
            propStyle={{ color: '#A93E71' }}
            gridStyle={{ backgroundColor: '#FFFAFC' }}
            gridStyle2={{ backgroundColor: '#FFFAFC' }}
            parentDivClick={() => {
              dispatch(
                setCLientTableFilter({
                  calculationSelected: 'Clients',
                  ...attorneyClientRest,
                })
              );
              navigate(`/clients`);
            }}
            childDiv1={() => {
              dispatch(
                setCLientTableFilter({
                  calculationSelected: 'Law Firms',
                  ...attorneyClientRest,
                })
              );
              navigate(`/clients`);
            }}
            childDiv2={() => {
              dispatch(
                setCLientTableFilter({
                  calculationSelected: 'Corporate',
                  ...attorneyClientRest,
                })
              );
              navigate(`/clients`);
            }}
            bottomTextClick={() => {
              dispatch(
                setCLientTableFilter({
                  calculationSelected: 'Clients with Open Positions',
                  ...attorneyClientRest,
                })
              );
              navigate(`/clients`);
            }}
            showPointerOnParent
          />
          <CardView
            img={positionIcon}
            topText="Open Positions"
            topCount={dashboardData?.positions?.openPositions}
            p1={dashboardData?.positions?.positionNotYetAddressed}
            p2="Positions Not Yet Addressed"
            gridStyle={{ backgroundColor: '#FCFAFF', width: '100%' }}
            propStyle={{ color: '#673E9B' }}
            parentDivClick={() => {
              dispatch(
                setPositionTableFilter({
                  ...positionRest,
                  calculationSelected: 'Open Positions',
                  filters: { statsFilters: { openPositions: { $gt: 0 } } },
                })
              );
              navigate(`/positions`);
            }}
            childDiv1={() => {
              dispatch(
                setPositionTableFilter({
                  ...positionRest,
                  calculationSelected: 'Positions Not Addressed',
                  filters: { statsFilters: { totalNotAddressed: { $gt: 0 } } },
                })
              );
              navigate(`/positions`);
            }}
            showPointerOnParent
          />
        </div>
        <div className={style.section2}>
          <CardView
            img={attorneyIcon}
            topText="Pipeline"
            p1={dashboardData?.attorneys?.pipelineAttorney}
            p2="Total"
            p3={String(dashboardData?.attorneys?.interviewingPAttorney)}
            p4="Interviewing"
            parentDivClick={() => {
              dispatch(
                setAttorneyTableFilter({
                  calculationSelected:
                    currentValue === 'Cumulative'
                      ? 'Pipeline (Total)'
                      : 'Pipeline',
                  ...attorneyClientRest,
                })
              );
              navigate(`/attorneys`);
            }}
            childDiv1={() => {
              dispatch(
                setAttorneyTableFilter({
                  calculationSelected:
                    currentValue === 'Cumulative'
                      ? 'Pipeline (Total)'
                      : 'Pipeline',
                  ...attorneyClientRest,
                })
              );
              navigate(`/attorneys`);
            }}
            childDiv2={() => {
              dispatch(
                setAttorneyTableFilter({
                  calculationSelected: 'Interviewing_P',
                  ...attorneyClientRest,
                })
              );
              navigate(`/attorneys`);
            }}
            showPointerOnParent
          />

          <CardView
            img={opportunityIcon}
            topText="Opportunities"
            p1={
              dashboardData?.clients?.opportunityPositions === null
                ? '-'
                : String(dashboardData?.clients?.opportunityPositions)
            }
            p2="Client Opportunities"
            p3={String(dashboardData?.positions?.opportunityPositions || 0)}
            p2MinWidth="155px"
            p4="Opportunity Positions"
            propStyle={{ color: '#304059' }}
            gridStyle={{ backgroundColor: '#FFFAFC' }}
            gridStyle2={{ backgroundColor: '#FCFAFF' }}
            iconStart={clientIcon}
            iconStart2={positionIcon}
            childDiv1={() => {
              dispatch(
                setCLientTableFilter({
                  calculationSelected: 'Opportunities',
                  ...attorneyClientRest,
                })
              );
              navigate(`/clients`);
            }}
            childDiv2={() => {
              dispatch(
                setPositionTableFilter({
                  ...positionRest,
                  calculationSelected: 'Client Requests',
                  filters: {
                    clientStatus: ['Active opportunity'],
                  },
                })
              );
              navigate(`/positions`);
            }}
          />
        </div>
        <div className={style.section3} style={{ marginBottom: '30px' }}>
          <CardView
            img={attorneyIcon}
            topText={
              !_.isNull(startDate) &&
              !_.isNull(endDate) &&
              !_.isUndefined(startDate) &&
              !_.isUndefined(endDate)
                ? `${moment(startDate).format('MM/DD/YYYY')}  -   ${moment(
                    endDate
                  ).format('MM/DD/YYYY')} `
                : 'Cumulative'
            }
            p1={dashboardData?.attorneys?.convertedAttorney}
            p2="Converted"
            p3={String(dashboardData?.attorneys?.returnedAttorney)}
            p4="Returned"
            childDiv2={() => {
              dispatch(
                setAttorneyTableFilter({
                  ...attorneyClientRest,
                  calculationSelected: 'Returned (Cumulative)',
                  currentValue: 'Cumulative',
                })
              );
              navigate(`/attorneys`);
            }}
            childDiv1={() => {
              dispatch(
                setAttorneyTableFilter({
                  ...attorneyClientRest,
                  calculationSelected: 'Converted (Cumulative)',
                  currentValue: 'Cumulative',
                })
              );
              navigate(`/attorneys`);
            }}
          />
        </div>
      </div>
    </>
  );
};
export default NowTab;
