/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextArea from 'components/textarea';
import Button from 'components/button';

import { ATTORNEY_CLIENT_STATUS } from 'constants';
import { setAttorneyClientHistory } from 'services/attorney-profile';
import { useAttoreyProfile } from 'contexts/attorney-profile';

import saveIcon from 'assets/images/save.svg';
import greenCrossIcon from 'assets/images/greenCrossIcon.svg';

import style from './add-client.module.scss';
import { createNotification } from 'components/react-notification';
import { useEffect } from 'react';
import moment from 'moment';
import { resetServerContext } from 'react-beautiful-dnd';

const AddClient = ({
  setToggle,
  clientPosition,
  setEdit,
  data,
  setDisable,
  clientNames,
  onSubmit: submitted,
  onEdit,
}) => {
  const { id } = useParams();
  const { getData } = useAttoreyProfile();
  const [positionsBasedOnClientName, setpositionsBasedOnClientName] = useState(
    []
  );
  const {
    watch,
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: !data
      ? {}
      : {
          ...data,
          statusDate: moment(data?.statusDate?.split('T')?.[0]).format('L'),
          positions: data?.positionId?._id,
        },
    resolver: yupResolver(onEdit ? editSchema : schema),
  });

  useEffect(() => {
    const clientName = watch('clientName');
    const findClientPositions = clientPosition?.find(
      (x) => x?._id === clientName
    );
    setValue('positions', null);
    setpositionsBasedOnClientName(
      findClientPositions?.positions?.map((x) => {
        return {
          label: x?.name,
          value: x?._id,
        };
      })
    );
  }, [watch('clientName')]);

  const onSubmit = async (values) => {
    const { status, statusDate, feedback, clientName, positions } = values;

    try {
      const response = await setAttorneyClientHistory(
        {
          attorneyId: id,
          clientId: data?.clientId ? data?.clientId : clientName,
          positionId: data?.positionId?._id ? data?.positionId?._id : positions,
          statusDate: moment(new Date(statusDate)).format('YYYY-MM-DD'),
          feedback,
          status,
          edit:
            moment(data?.statusDate?.split('T')?.[0]).format('YYYY-MM-DD') ===
              moment(new Date(statusDate)).format('YYYY-MM-DD') &&
            status === data?.status,
        },
        data?._id
      );
      onEdit && onEdit();
      submitted && (await submitted(response?.data.history));
      setToggle(null);
      await getData();
      setEdit(null);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.msg || err?.message || 'Server Error',
        5000
      );
      console.error('err', err);
    }
    setToggle(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={style.wraper}>
      <div className={style.body}>
        <div className={style.getInfo}>
          <div className={style.datesPicker}>
            <div>
              <SelectBox
                options={clientNames?.map((e) => ({
                  label: e.clientName,
                  value: e.clientId,
                }))}
                label={'Client Name'}
                name={'clientName'}
                dynamicClass={style.zDynamicState2}
                control={control}
                errorMessage={errors?.clientName?.message}
                disabled={data?._id}
                showValueOnlyOnDisabled
              />
            </div>
            <div>
              {onEdit ? (
                <>
                  <span className={style.lbl} style={{ marginBottom: '20px' }}>
                    Position
                  </span>
                  <p
                    className={style.lbl}
                    style={{
                      marginTop: '10px',
                      overflowWrap: 'anywhere',
                      width: '246px',
                    }}
                  >
                    {` ${
                      data?.positionId?.practiceGroup[0]?.practiceGroup
                        ? data?.positionId?.practiceGroup[0]?.practiceGroup
                        : '-'
                    } | ${
                      data?.positionId?.locations[0]?.location
                        ? data?.positionId?.locations[0]?.location
                        : '-'
                    } | ${
                      data?.positionId?.sowStartDate
                        ? moment(data?.positionId?.sowStartDate).format(
                            'MM/DD/YYYY'
                          )
                        : ''
                    }-${
                      data?.positionId?.sowEndDate
                        ? moment(data?.positionId?.sowEndDate).format(
                            'MM/DD/YYYY'
                          )
                        : ''
                    }`}
                  </p>
                </>
              ) : (
                <SelectBox
                  label={'Position'}
                  options={positionsBasedOnClientName}
                  disabled={data?.positionId?._id}
                  control={control}
                  name={'positions'}
                  dynamicClass={style.zDynamicState}
                  showValueOnlyOnDisabled
                  errorMessage={errors?.positions?.message}
                />
              )}
            </div>
          </div>

          <div className={style.datesPickerLower}>
            <div>
              <SelectBox
                label={'Client Status'}
                placeholder={'Select'}
                options={ATTORNEY_CLIENT_STATUS}
                control={control}
                name={'status'}
                errorMessage={errors?.status?.message}
              />
            </div>
            <div>
              <DatePicker
                label="Status Date"
                name="statusDate"
                id="1"
                placeholder="mm/dd/yyyy"
                control={control}
                errorMessage={errors?.statusDate?.message}
              />
            </div>
          </div>
        </div>
        <div className={style.feedbackSection}>
          <TextArea
            name="feedback"
            label={'Feedback'}
            register={register}
            placeholder={'Start typing'}
            errorMessage={errors?.feedback?.message}
          />
        </div>
      </div>
      <div className={style.btnSection}>
        <img
          src={greenCrossIcon}
          onClick={() => {
            setToggle(false);
            setEdit(null);
            setDisable(false);
          }}
        />
        <Button
          text={'Save'}
          width="100px"
          type={'submit'}
          iconStart={saveIcon}
          btnClass={style.btnClass}
          isLoading={isSubmitting}
          onClick={() => {
            setDisable(false);
          }}
        />
      </div>
    </form>
  );
};

export default AddClient;
const schema = yup
  .object({
    clientName: yup.string().nullable().required('Client name is required'),
    status: yup.string().nullable().required('Status is required'),
    positions: yup.string().nullable().required('Position is required'),
    statusDate: yup
      .string()
      .typeError('Please enter a valid date')
      .required('Status date is required'),
  })
  .required();

const editSchema = yup
  .object({
    clientName: yup.string().nullable().required('Client name is required'),
    status: yup.string().nullable().required('Status is required'),
    statusDate: yup
      .string()
      .typeError('Please enter a valid date')
      .required('Status date is required'),
  })
  .required();
