import React, { useEffect } from 'react';

import Container from 'components/container';

import ComparisonCard from 'components/comparison-card';

import style from './report-print.module.scss';

const PositionComparisonReportPrint = ({ histories }) => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--primary-color',
      'rgb(103, 62, 155)'
    );
  }, []);

  return (
    <>
      <div className={style.main}>
        <style>{getPageMargins()}</style>
        <Container>
          <div className={style.header}>
            <div className={style.innerFlex}>
              <h6 className={style.headingTitle}>
                Positions Comparison Report
              </h6>
            </div>
          </div>
          <div className={style.grid}>
            {histories.length > 0 &&
              histories?.map((history, index) => (
                <ComparisonCard
                  key={index}
                  history={history}
                  cardClass={style.card}
                  verticalScroll={style.verticalScroll}
                  style={{
                    gridRowEnd: `span ${
                      Math.floor(history?.placementQuantity / 9) === 0
                        ? 1
                        : Math.floor(history?.placementQuantity / 9)
                    }`,
                  }}
                />
              ))}
          </div>
        </Container>
      </div>
    </>
  );
};

export default PositionComparisonReportPrint;

const getPageMargins = () => {
  return `@page { margin: 20px 0px 20px 0px !important; }`;
};
