/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';

import Button from 'components/button';
import Dropzone from 'components/dropzone';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createNotification } from 'components/react-notification';

import { excelRegEx } from 'pages/attorney-profile/files/helper';
import { uploadFlatFile } from 'services/data-sync';

import uploadFile from 'assets/icons/uploadBlue.svg';
import fileIcon from 'assets/icons/blue-file.svg';
import crossBlue from 'assets/icons/cross-blue.svg';
import style from './import-data.module.scss';
import { useDispatch } from 'react-redux';

let controller = new AbortController();

const ImportData = ({ setEditPopUp, editPopUp, values }) => {
  const dispatch = useDispatch();
  const progressRef = useRef();
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    setShowProgress(true);

    try {
      const data = new FormData();
      data.append('file', values.file);
      uploadFlatFile({ body: data }, dispatch);

      setTimeout(() => {
        setShowProgress(false);
        reset({});
      }, 400);
    } catch (err) {
      if (err.name === 'CanceledError') {
        setShowProgress(false);
        reset({});
        return;
      }
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
    }
  };

  const startProgress = (speed) => {
    progressRef.current = setTimeout(() => {
      setProgress((prev) => prev + 5);
      startProgress(speed * 2);
    }, speed);
  };

  const fileState = watch('file');

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#304059');
    if (!fileState) {
      controller.abort();
      controller = new AbortController();
    }
  }, [fileState]);

  return (
    <form className={style.form} onSubmit={handleSubmit(onSubmit)}>
      <h3>Import Data</h3>
      <Dropzone
        name={'file'}
        control={control}
        error={errors.file?.message}
        showProgress={showProgress}
        progress={progress}
        uploadIcon={uploadFile}
        cFileIcon={fileIcon}
        cCrossIcon={crossBlue}
      />
      <div className={style.mainBtnDiv}>
        <Button
          text={'Upload'}
          btnClass={
            errors.file || !fileState ? style.disableClass : style.btnYes
          }
          disabled={errors.file || !fileState}
          isLoading={isSubmitting}
        />
      </div>
    </form>
  );
};

export default ImportData;

const schema = yup.object().shape({
  file: yup
    .mixed()
    .required('Please select a file.')
    .test('required', 'Please select a file.', (value) => {
      return value;
    })
    .test('fileSize', 'The file size can’t be larger than 50MB', (file) => {
      return file && file?.size < 50000000;
    })
    .test(
      'fileType',
      'Only the following file type can be uploaded: .CSV',
      (file) => {
        return excelRegEx.test(file?.name);
      }
    ),
});
