import caretUp from 'assets/icons/caret-up.svg';

import style from 'components/sorting-indicator/sorting-indicator.module.scss';

const SortingIndicator = ({ show, sort }) =>
  show ? (
    <div className={style['sorting-container']}>
      <img
        src={caretUp}
        style={{
          transform: `rotate(${sort === 'asc' ? '0' : '180'}deg)`,
        }}
      />
    </div>
  ) : null;

export default SortingIndicator;
