/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Avatar from 'components/avatar';
import Button from 'components/button';
import Container from 'components/container';
import AvatarUpload from 'components/edit-avatar';
import Modal from 'components/modal';
import SelectBox from 'components/select-box';
import Tab from 'components/tabs';
import Tags from 'components/tags';
import TextArea from 'components/textarea';
import TextField from 'components/textfield';

import DatePicker from 'components/date-picker';
import MenuBar from 'components/menu';

import FilterSvg from 'assets/icons/Filter.svg';
import Warning from 'assets/icons/warning.svg';
import AvatarIcon from 'assets/images/Avatar.svg';
import icon1 from 'assets/images/Edit.svg';
import style from './components-page.module.scss';

function ComponentPage() {
  const { control } = useForm();

  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);
  return (
    <Container>
      <Link style={{ textDecoration: 'none', color: 'blue' }} to="/login">
        Back!
      </Link>
      <h2>Data Table</h2>
      <h2>Avatar Edit</h2>
      <AvatarUpload name="pic" control={control} initials={'ff gg'} />
      <h2>Icons</h2>
      <img src={FilterSvg} height={30} width={30} />
      <h2>Avatar</h2>
      <div style={{ display: 'flex' }}>
        <Avatar color={'pink'} icon={AvatarIcon} />
        <Avatar color={'pink'} initials={'Hassan Baig'} />
      </div>
      <h2>Tags</h2>
      <div style={{ display: 'flex' }}>
        <div>
          <Tags
            cross={true}
            circular={true}
            text={'Placed'}
            color={'#D0D2FF'}
          />
          <br />
          <Tags
            cross={true}
            circular={true}
            text={'Studying'}
            color={'#CFEEFF'}
          />
          <br />
          <Tags
            cross={true}
            circular={true}
            text={'Rejected'}
            color={'#FFCCCC'}
          />
          <br />
          <Tags circular={true} text={'Returned'} color={'#E6E7E8'} />
          <br />
          <Tags circular={true} text={'Interviewing'} color={'#DFEEFF'} />
          <br />
          <Tags circular={true} text={'Converted'} color={'#DEFFEB'} />
          <br />
        </div>
        <div style={{ marginRight: 30 }} />

        <div>
          <Tags text={'Interviewing'} color={'#2475D0'} />
          <br />
          <Tags text={'Placed'} color={'#304059'} />
          <br />
          <Tags text={'Terminated'} color={'#CE4343'} />
          <br />
          <Tags cross={true} text={'Pipeline'} color={'#784DB0'} />
          <br />
          <Tags cross={true} text={'Converted'} color={'#30A25D'} />
          <br />
          <Tags cross={true} text={'Inacive'} color={'#9DA1AA'} />
          <br />
        </div>
      </div>
      <h2>Nav Bar</h2>
      <MenuBar />
      <h2>Text Field</h2>
      <div style={{ display: 'flex' }}>
        <TextField label={'First Name'} placeholder={'First Name'} />
        <TextField label={'Last Name'} wraperClass={style.inputWraper} />
        <TextField
          label={'Text Field'}
          errorMessage={'error message'}
          wraperClass={style.inputWraper}
        />
      </div>
      <br />
      <TextArea label="Text Area" />
      <h2>DropDown</h2>
      <h3>Select Box/DropDown with multi value</h3>
      <div style={{ display: 'flex' }}>
        {' '}
        <SelectBox
          label={'Drop Down'}
          options={options}
          isMulti
          control={control}
          name={'test'}
        />
        <div style={{ marginRight: 30 }} />
        <SelectBox
          label={'without checkboxes '}
          options={options2}
          isMulti
          control={control}
          name={'test'}
        />
      </div>
      <h3>Select Box/DropDown with single select value</h3>
      <div style={{ display: 'flex' }}>
        <SelectBox
          label={'Drop Down'}
          options={options}
          control={control}
          name={'test'}
        />
        <div style={{ marginRight: 30 }} />

        <SelectBox
          label={'DropDown badges'}
          options={options}
          control={control}
          name={'test'}
          badge
        />
      </div>
      <h2>Tab</h2>
      <Tab active={active} onChange={setActive} tabs={tabs} color={'red'} />
      <div hidden={active !== 0} style={{ margin: '20px 10px' }}>
        OverView
      </div>
      <div hidden={active !== 1} style={{ margin: '20px 10px' }}>
        Bar History
      </div>
      <div hidden={active !== 2} style={{ margin: '20px 10px' }}>
        Clients
      </div>
      <div hidden={active !== 3} style={{ margin: '20px 10px' }}>
        Comments
      </div>
      <div hidden={active !== 4} style={{ margin: '20px 10px' }}>
        Training History
      </div>
      <div hidden={active !== 5} style={{ margin: '20px 10px' }}>
        Files
      </div>
      <h2>Button</h2>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button text={'Button 1'} iconStart={icon1} />
        <Button text={'Button 2'} iconEnd={icon1} btnClass={style.btnClass1} />
        <Button
          text={'Button 3'}
          btnClass={style.btnClass}
          className={style.btntext}
        />
        <Button text={'Button 4'} btnClass={style.btnClass4} />
        <Button text={'Button 5'} btnClass={style.btnClass5} />
      </div>

      <br />
      <h2>Date Picker</h2>
      <DatePicker
        label="Date"
        name="date"
        id="1"
        placeholder="Date"
        control={control}
      />
      <br />
      <br />

      <h2>Modal</h2>
      <Button text={'Cancel Progress'} handleClick={() => setOpen(true)} />
      <Modal open={open} handleClose={() => setOpen(false)}>
        <div className={style.header}>
          <img src={Warning} height={48} width={48} />
          <p> You will loose your progress</p>
          <h3 className={style.warningText}>
            All information will be lost if you exit without saving
          </h3>
          <Button
            btnClass={style.buttonStyle}
            text={'Yes, Delete it'}
            handleClick={() => alert('deleted')}
          />
          <h3 className={style.bottomText} onClick={() => setOpen(false)}>
            Whoops, No, cancel this.
          </h3>
        </div>
      </Modal>
    </Container>
  );
}

export default ComponentPage;

const options = [
  { value: 'chocolate', label: 'red', color: 'red', checkbox: true },
  { value: 'strawberry', label: 'blue', color: 'blue', checkbox: true },
  { value: 'vanilla', label: 'green', color: 'green', checkbox: true },
];
const options2 = [
  { value: 'chocolate', label: 'red', color: 'red', checkbox: false },
  { value: 'strawberry', label: 'blue', color: 'blue', checkbox: false },
  { value: 'vanilla', label: 'green', color: 'green', checkbox: false },
];

const tabs = [
  { name: 'overview ' },
  { name: 'bar history (2)' },
  { name: 'client history' },
  { name: 'comments (4)' },
  { name: 'training history' },
  { name: 'files' },
];
