import React from 'react';

import Button from 'components/button';
import Modal from 'components/modal';

import { P02_SCREEN_MESSAGES } from 'data/userFacingMessages';

import cross from 'assets/icons/close-purple.svg';
import refresh from 'assets/icons/refresh-order.svg';

import style from './rest-modal.module.scss';

const ResetModal = ({
  resetModal,
  setLoading,
  setResetModal,
  handleCreateVersion,
  handleGenerateMatches,
}) => {
  return (
    <div>
      <Modal
        open={resetModal}
        handleClose={() => setResetModal(false)}
        className={style.mainDiv}
      >
        <div className={style.crossImg}>
          <img src={cross} alt="" onClick={() => setResetModal(false)} />
        </div>
        <div className={style.iconRefresh}>
          <img src={refresh} alt="" />
        </div>
        <p className={style.modalTitle}>Reset the ordering of attorneys</p>
        <p className={style.p}>
          Are you sure you want to reset the ordering of attorneys to the
          initial order?
        </p>
        <div className={style.mainBtnDiv}>
          <Button
            text="Yes, reset and save the current ordering as a new version"
            btnClass={style.btnClassModal}
            handleClick={async () => {
              setLoading(true);
              const loadTimeOut = setTimeout(() => {
                setLoading(
                  P02_SCREEN_MESSAGES?.loader?.[Math.floor(Math.random() * 2)]
                );
              }, 3000);
              setResetModal(false);
              await handleCreateVersion();
              handleGenerateMatches({ loadTimeOut });
            }}
          />
          <Button
            text="Yes, reset but DON’T save current ordering"
            btnClass={style.btnClassUncheckModal}
            handleClick={() => {
              setLoading(true);
              const loadTimeOut = setTimeout(() => {
                setLoading(
                  P02_SCREEN_MESSAGES?.loader?.[Math.floor(Math.random() * 2)]
                );
              }, 3000);
              handleGenerateMatches({ loadTimeOut });
              setResetModal(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ResetModal;
