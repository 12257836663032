import React, { useState } from 'react';

import Button from 'components/button';
import Modal from 'components/modal';
import RemoveConfirm from '../remove-confirm';

import back from 'assets/arrow-left-grey.svg';
import del from 'assets/icons/del-purple.svg';

import style from './remove-modal.module.scss';

const RemoveStatusHistory = ({
  removeStatus,
  setRemoveStatus,
  setSelectStatus,
}) => {
  const [loading, setLoading] = useState(false);
  const [removeStatusConfirm, setRemoveStatusConfirm] = useState(false);

  return (
    <div>
      <Modal
        open={removeStatus}
        handleClose={() => setRemoveStatus(false)}
        className={style.mainDiv}
      >
        <div className={style.back}>
          <img
            src={back}
            alt=""
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectStatus(removeStatus?.selectStatus);
              setRemoveStatus(false);
            }}
          />
        </div>
        <div className={style.iconRefresh}>
          <img src={del} alt="" />
        </div>
        <p className={style.modalTitle}>Remove Status History</p>
        <p className={style.p}>
          Would you like to remove this {removeStatus?.deletedStatus} status
          history for {removeStatus?.attorneyName}?
        </p>
        <div className={style.mainBtnDiv}>
          <Button
            text={'Yes, remove status history'}
            btnClass={style.btnClassModal}
            isLoading={loading}
            handleClick={async () => {
              setLoading(true);
              await removeStatus.handleStatusUpdate({
                body: removeStatus?.body,
                selectStatus: removeStatus?.selectStatus,
                setSelectStatus: removeStatus?.setSelectStatus,
                deletePrev: true,
              });
              setRemoveStatusConfirm(removeStatus);
              setRemoveStatus(false);
              setLoading(false);
            }}
          />
          <Button
            text="No, keep status history"
            btnClass={style.btnClassUncheckModal}
            handleClick={async () => {
              await removeStatus.handleStatusUpdate({
                body: removeStatus?.body,
                selectStatus: removeStatus?.selectStatus,
                setSelectStatus: removeStatus?.setSelectStatus,
              });

              setRemoveStatus(false);
            }}
          />
        </div>
      </Modal>
      <RemoveConfirm
        removeStatusConfirm={removeStatusConfirm}
        setRemoveStatusConfirm={setRemoveStatusConfirm}
      />
    </div>
  );
};

export default RemoveStatusHistory;
