import React, { useState } from 'react';

import Button from 'components/button';
import Checkbox from 'components/checkbox';
import TextField from 'components/textfield';

import { ATTRONEY_STATUS_COLORS } from 'constants';

import search from 'assets/icons/search-select.svg';
import style from './search-select.module.scss';

const SearchSelect = ({
  label,
  options,
  selected,
  className,
  placeholder,
  setSelected,
}) => {
  const [open, setOpen] = useState(false);
  const [list, setList] = useState(options);
  const [selectValue, setSelectValue] = useState('');

  const handleSearch = (e) => {
    setSelectValue(e.target.value);
    if (e.target.value === '') {
      setOpen(false);
      setList(options);
    } else {
      setOpen(true);
      setList(
        options?.filter(
          (ele) =>
            ele.firstName
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            ele.lastName.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    }
  };

  return (
    <div className={`${style.searchSelect} ${className}`}>
      <TextField
        label={label}
        placeholder={placeholder}
        value={selectValue}
        type="text"
        icon={search}
        searchClearToggle={true}
        onChange={(e) => handleSearch(e)}
        onClickhandle={() => setOpen(!open)}
        onClear={() => {
          setSelectValue('');
          setList(options);
        }}
        className={style.field}
      />
      <>
        <div className={style.innerDiv1}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '125px',
            }}
          >
            <Checkbox
              className={style.checkbox}
              checked={options.every((x) => selected.includes(x._id))}
              handleChange={(e) => {
                if (e.target.checked) {
                  setSelected(options.map((x) => x._id));
                } else {
                  setSelected([]);
                }
              }}
            />
            <p className={style.pName}>Name</p>
          </div>

          <h6 className={style.pName}>Status</h6>
          <p></p>
        </div>
        <div className={style.searchDropdown}>
          <div style={{ height: '130px' }}>
            {list?.map(({ _id, firstName, lastName, liStatus }) => (
              <div className={style.innerDiv} key={_id}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '230px',
                  }}
                >
                  <Checkbox
                    className={style.checkbox}
                    checked={selected.includes(_id)}
                    handleChange={(e) => {
                      if (e.target.checked) {
                        setSelected((prev) => [...prev, _id]);
                      } else {
                        const temp = selected.filter((x) => x !== _id);
                        setSelected(temp);
                      }
                    }}
                  />
                  <p>{`${firstName} ${lastName}`}</p>
                </div>
                <Button
                  text={liStatus}
                  btnClass={style.liStatus}
                  style={{
                    backgroundColor:
                      liStatus === 'Interviewing'
                        ? '#2475D0'
                        : ATTRONEY_STATUS_COLORS[liStatus],
                  }}
                />
                <p></p>
              </div>
            ))}
          </div>
        </div>
      </>
    </div>
  );
};

export default SearchSelect;
