/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Button from 'components/button';
import TextField from 'components/textfield';
import SelectBox from 'components/select-box';
import DatePicker from 'components/date-picker';
import Modal from 'components/modal';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { GENDER_OPTIONS, DEMO_OPTIONS } from 'constants';
import { A02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import { useAttoreyProfile } from 'contexts/attorney-profile';
import { ROLES } from 'permissions/constants';

import deleteIcon from 'assets/images/delete.svg';
import Trash from 'assets/icons/Trash.svg';

import style from '../main-page.module.scss';

const OverViewEditMode = ({ id }) => {
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { register, control, errors, watch, handleDelete } =
    useAttoreyProfile();
  const { practiceGroups, locations } = useSelector(
    (state) => state.systemValues
  );

  const praticeGroup = watch('attorneyPracticeGroup') || [];
  const [choice1, choice2] = praticeGroup;

  const attorneyLocations = watch('attorneyLocation') || [];
  const [optionA, optionB, optionC] = attorneyLocations;

  return (
    <div style={{ padding: '20px 0px' }}>
      <h3 style={{ color: '#39695B', margin: '0px', paddingBottom: '20px' }}>
        Attorney Profile
      </h3>
      <div className={style.profileSectionWraper}>
        <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
          <div className={style.dates}>
            <div>
              <DatePicker
                label="Start Date"
                name="attorneyStartDate"
                id="3"
                placeholder="mm/dd/yyyy"
                control={control}
                errorMessage={errors?.attorneyStartDate?.message}
                className={style.zDynamicDate}
              />
            </div>
            <div className={style.setMargin}>
              <DatePicker
                label="End Date"
                name="attorneyEndDate"
                id="2"
                placeholder="mm/dd/yyyy"
                control={control}
              />
            </div>
            <div className={style.setMargin}>
              <DatePicker
                label="Birth Month/Day"
                name="attorneyBirthdate"
                id="1"
                placeholder="mm/dd"
                control={control}
                monthDate
                errorMessage={errors?.attorneyBirthdate?.message}
                popparBack={style.birthDatePopper}
              />
            </div>
            <div className={style.setMargin}>
              <SelectBox
                label={'AmLaw50'}
                name="attorneyAmLaw50"
                placeholder="Select One"
                control={control}
                options={amLawOptions}
                errorMessage={errors?.attorneyAmLaw50?.message}
              />
            </div>
          </div>
          <div className={style.lawSection}>
            <div>
              <TextField
                label={'Law School'}
                name="attorneyLawSchool"
                errorMessage={errors?.attorneyLawSchool?.message}
                register={register}
              />
            </div>
            <div>
              <DatePicker
                label={'Grad Year'}
                name="attorneyGradYear"
                errorMessage={errors?.attorneyGradYear?.message}
                control={control}
                placeholder=" "
                showYearPicker
              />
            </div>
            <div className={style.setMargin}>
              <TextField
                label={'GPA'}
                name="attorneyGPA"
                type="number"
                register={register}
                errorMessage={errors?.attorneyGPA?.message}
              />
            </div>

            <div className={style.setMargin}>
              <TextField
                label={'Class Rank'}
                register={register}
                type="number"
                name="attorneyClassRank"
                errorMessage={errors?.attorneyClassRank?.message}
              />
            </div>
          </div>
          <div className={style.lawSection}>
            <div>
              <SelectBox
                isDemo
                label={'Demo'}
                control={control}
                name="attorneyDemo"
                options={DEMO_OPTIONS}
                isMulti
                placeholder={'Select all that apply'}
                dynamicClass={style.zDynamic}
                errorMessage={id !== 'new' && errors?.attorneyDemo?.message}
              />
            </div>
            <div className={style.setMargin}>
              <SelectBox
                label={'Gender'}
                name={'attorneyGender'}
                options={GENDER_OPTIONS}
                placeholder={'Select One'}
                control={control}
                errorMessage={errors?.attorneyGender?.message}
              />
            </div>
            <div className={style.setMargin}>
              <TextField
                label={'Parker Score'}
                name="attorneyParkerScore"
                type="number"
                register={register}
                errorMessage={errors?.attorneyParkerScore?.message}
              />
            </div>
            <div className={style.setMargin}>
              <TextField
                label={'Writing Score'}
                name="attorneyWritingScore"
                register={register}
                errorMessage={errors?.attorneyWritingScore?.message}
              />
            </div>
          </div>
        </PermissionRestrict>
        <PermissionRestrict roles={[ROLES.ATTORNEY]}>
          <div className={style.attorneySectionOne}>
            <div>
              <SelectBox
                label={'Demo'}
                control={control}
                name="attorneyDemo"
                options={DEMO_OPTIONS}
                placeholder="Select One"
                isMulti
                dynamicClass={style.zDynamic}
                errorMessage={errors?.attorneyDemo?.message}
              />
            </div>
            <div className={style.setMargin}>
              <SelectBox
                label={'Gender'}
                name={'attorneyGender'}
                options={GENDER_OPTIONS}
                placeholder="Select One"
                control={control}
                dynamicClass={style.zDynamic}
                errorMessage={errors?.attorneyGender?.message}
              />
            </div>
            <div className={style.setMargin}>
              <TextField
                label={'Class Rank'}
                register={register}
                type="number"
                name="attorneyClassRank"
                errorMessage={errors?.attorneyClassRank?.message}
              />
            </div>
          </div>
        </PermissionRestrict>
      </div>

      <h3 style={{ color: '#39695B', margin: '20px 0px' }}>
        {' '}
        Placement Preferences
      </h3>

      <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
        <div className={style.placePreference}>
          <span className={style.preferance}>Practice Group</span>
          <div className={style.choices}>
            <div>
              <SelectBox
                hideIndicator={true}
                options={practiceGroups
                  ?.filter((el) => el?._id !== choice2)
                  ?.map((e) => ({
                    label: e.practiceGroup,
                    value: e._id,
                  }))}
                label={'1st Choice'}
                name="attorneyPracticeGroup.0"
                control={control}
                placeholder="Select One"
                errorMessage={errors?.attorneyPracticeGroup?.message}
              />
            </div>
            <div>
              <SelectBox
                hideIndicator={true}
                options={practiceGroups
                  ?.filter((el) => el?._id !== choice1)
                  ?.map((e) => ({
                    label: e.practiceGroup,
                    value: e?._id,
                  }))}
                label={'2nd Choice'}
                name="attorneyPracticeGroup.1"
                control={control}
                placeholder="Select One"
              />
            </div>
          </div>
          <span className={style.preferance}> Location</span>
          <div className={style.choices}>
            <div>
              <SelectBox
                hideIndicator={true}
                options={locations
                  ?.filter((el) => el?._id !== optionC && el?._id !== optionB)
                  ?.map((e) => ({
                    label: e.location,
                    value: e?._id,
                  }))}
                label={'1st Choice (Current)'}
                name="attorneyLocation.0"
                control={control}
                placeholder="Select One"
                errorMessage={errors?.attorneyLocation?.message}
              />
            </div>
            <div>
              <SelectBox
                hideIndicator={true}
                options={locations
                  ?.filter((el) => el?._id !== optionA && el?._id !== optionC)
                  ?.map((e) => ({
                    label: e.location,
                    value: e._id,
                  }))}
                label={'2nd Choice'}
                name="attorneyLocation.1"
                control={control}
                placeholder="Select One"
              />
            </div>
            <div>
              <SelectBox
                hideIndicator={true}
                options={locations
                  ?.filter((el) => el?._id !== optionA && el?._id !== optionB)
                  ?.map((e) => ({
                    label: e.location,
                    value: e?._id,
                  }))}
                label={'3rd Choice'}
                name="attorneyLocation.2"
                control={control}
                dynamicClass={style.zDynamicState2}
                placeholder="Select One"
              />
            </div>
          </div>
        </div>
      </PermissionRestrict>

      <PermissionRestrict roles={[ROLES.ATTORNEY]}>
        <div className={style.placePreference}>
          <span className={style.preferanceGreen}>Practice Group</span>
          <div className={style.choices}>
            <div>
              <SelectBox
                hideIndicator={true}
                options={practiceGroups
                  ?.filter((el) => el?._id !== choice2)
                  ?.map((e) => ({
                    label: e.practiceGroup,
                    value: e._id,
                  }))}
                label="1st Choice*"
                name="attorneyPracticeGroup.0"
                control={control}
                placeholder="Select One"
                errorMessage={errors?.attorneyPracticeGroup?.message}
                showSelected={true}
              />
            </div>
            <div>
              <SelectBox
                hideIndicator={true}
                options={practiceGroups
                  .filter((el) => el?._id !== choice1)
                  ?.map((e) => ({
                    label: e.practiceGroup,
                    value: e._id,
                  }))}
                label={'2nd Choice'}
                name="attorneyPracticeGroup.1"
                control={control}
                placeholder="Select One"
                showSelected={true}
              />
            </div>
          </div>
          <span className={style.preferanceGreen}> Location</span>
          <div className={style.choices}>
            <div>
              <SelectBox
                hideIndicator={true}
                options={locations
                  ?.filter((el) => el?._id !== optionC && el?._id !== optionB)
                  ?.map((e) => ({
                    label: e.location,
                    value: e._id,
                  }))}
                label="1st Choice (Current)*"
                name="attorneyLocation.0"
                control={control}
                placeholder="Select One"
                errorMessage={errors?.attorneyLocation?.message}
              />
            </div>
            <div>
              <SelectBox
                hideIndicator={true}
                options={locations
                  ?.filter((el) => el?._id !== optionA && el?._id !== optionC)
                  ?.map((e) => ({
                    label: e.location,
                    value: e._id,
                  }))}
                label={'2nd Choice'}
                name="attorneyLocation.1"
                control={control}
                placeholder="Select One"
              />
            </div>
            <div>
              <SelectBox
                hideIndicator={true}
                options={locations
                  ?.filter((el) => el?._id !== optionA && el?._id !== optionB)
                  ?.map((e) => ({
                    label: e.location,
                    value: e._id,
                  }))}
                label={'3rd Choice'}
                name="attorneyLocation.2"
                control={control}
                dynamicClass={style.zDynamicState2}
                placeholder="Select One"
              />
            </div>
          </div>
        </div>
      </PermissionRestrict>

      <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
        {id !== 'new' && (
          <Button
            text={'Delete Attorney'}
            btnClass={style.btnWraper}
            className={style.btnText}
            iconStart={deleteIcon}
            type="button"
            handleClick={() => setOpenDeleteModal(true)}
          />
        )}
      </PermissionRestrict>
      <Modal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
      >
        <div className={style.modalHeader}>
          <img src={Trash} height={48} width={48} />
          <h3 className={style.deleteText}>Delete Attorney</h3>
          <p>Are you sure you want to delete this profile?</p>
          <Button
            btnClass={style.buttonStyle}
            text={A02_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.yes}
            handleClick={() => {
              handleDelete();
              navigate('/attorneys');
            }}
          />
          <h3
            className={style.cancelText}
            onClick={() => setOpenDeleteModal(false)}
          >
            {A02_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.no}
          </h3>
        </div>
      </Modal>
    </div>
  );
};

export default OverViewEditMode;

const amLawOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];
