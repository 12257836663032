import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from 'components/button';
import ComparisonCard from 'components/comparison-card';
import Container from 'components/container';
import DatePicker from 'components/date-picker';

import arrow from 'assets/icons/blue-select.svg';
import exportImg from 'assets/icons/purple-export.svg';
import Spinner from 'components/spinner';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { getComparisonReport } from 'services/positions';
import DeleteRecordModal from './delete-record-modal';
import PositionComparisonReportPrint from './print-view';
import style from './report.module.scss';

const PositionComparisonReport = () => {
  const componentRef = useRef();
  const navigate = useNavigate();

  const { control, watch, setValue, reset, register } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [histories, setHistories] = useState([]);
  const [totalStatuses, setTotalStatuses] = useState({});
  const [deleteRecord, setDeleteRecord] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const onPrint = () => {
    setTimeout(() => {
      handlePrint();
    }, 100);
  };

  const handleGetComparisonReport = async ({ params }) => {
    setLoading(true);
    await getComparisonReport({
      params:
        Object.keys(params)?.length > 0
          ? // ? params
            {
              ...params,
              statuses: 'Placed,Interviewing',
            }
          : {
              statuses: 'Placed,Interviewing',
              maxDate: searchParams.get('maxDate'),
              minDate: searchParams.get('minDate'),
              positions: searchParams.get('positions'),
            },
      setHistories,
      setTotalStatuses,
    });
    setLoading(false);
  };

  useEffect(() => {
    reset({
      minDate: searchParams.get('minDate'),
      maxDate: searchParams.get('maxDate'),
    });
    document.documentElement.style.setProperty(
      '--primary-color',
      'rgb(103, 62, 155)'
    );
  }, []);

  useEffect(() => {
    let params = {};
    const minDate = watch('minDate');
    const maxDate = watch('maxDate');

    setSearchParams((prev) => {
      const prevParams = {};
      for (const [key, value] of prev.entries()) {
        prevParams[key] = value;
      }
      params = {
        ...prevParams,
        minDate: minDate && moment(minDate).format('MM/DD/YYYY'),
        maxDate: maxDate && moment(maxDate).format('MM/DD/YYYY'),
      };
      return params;
    });

    handleGetComparisonReport({ params });
  }, [watch('minDate'), watch('maxDate')]);

  return (
    <>
      <div className={style.main}>
        <Container>
          <div className={style.header}>
            <div className={style.innerFlex}>
              <img
                src={arrow}
                alt=""
                className={style.arrow}
                onClick={() => {
                  navigate('/positions');
                }}
              />
              <h6 className={style.headingTitle}>
                Positions Comparison Report
              </h6>
            </div>
            <Button
              text={'Export'}
              btnClass={style.bgClass}
              className={style.text}
              iconStart={exportImg}
              handleClick={onPrint}
            />
          </div>
          <div className={style.dateHeader}>
            <div className={`${style.innerFlex} ${style.responsiveClass}`}>
              <DatePicker
                id="1"
                isPurple
                name={'minDate'}
                control={control}
                label="Start Date"
                isClearable={true}
                placeholder="mm/dd/yyyy"
                inputClass={style.date}
                popparBack={style.popper1}
                className={style.datePicker}
              />
              <DatePicker
                id="2"
                isPurple
                name={'maxDate'}
                label="End Date"
                control={control}
                isClearable={true}
                placeholder="mm/dd/yyyy"
                inputClass={style.date}
                popparBack={style.popper}
                className={style.datePicker}
              />
            </div>
          </div>
          {loading ? (
            <Spinner pageLoader={true} diffHeight={270} />
          ) : (
            <div className={style.grid}>
              {histories?.length > 0 &&
                histories?.map((history, index) => (
                  <ComparisonCard
                    key={index}
                    index={index}
                    history={history}
                    setDeleteRecord={setDeleteRecord}
                    removeAble={true}
                  />
                ))}
            </div>
          )}
        </Container>
      </div>
      <div style={{ position: 'fixed', top: '100vh' }}>
        <div ref={componentRef}>
          {<PositionComparisonReportPrint histories={histories} />}
        </div>
      </div>
      <DeleteRecordModal
        setHistories={setHistories}
        deleteRecord={deleteRecord}
        setDeleteRecord={setDeleteRecord}
      />
    </>
  );
};

export default PositionComparisonReport;
