/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  getFiles,
  deleteFile,
  setFileName,
  downloadFileData,
} from 'services/attorney-profile';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from 'components/button';
import TextField from 'components/textfield';
import NoRecords from 'components/no-records';
import DeleteModal from 'components/delete-modal';
import AddEditModal from './add-edit';

import addIcon from 'assets/images/whiteAddIcon.svg';
import edit from 'assets/icons/edit-green.svg';
import del from 'assets/icons/del-green.svg';
import greenCrossIcon from 'assets/images/greenCrossIcon.svg';
import uploadFile from 'assets/icons/download-green.svg';
import saveIcon from 'assets/images/save.svg';
import SearchGreen from 'assets/icons/search-green.svg';
import crossIcon from 'assets/icons/cross.svg';
import Trash from 'assets/icons/Trash.svg';

import style from './files.module.scss';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { ROLES } from 'permissions/constants';
import { createNotification } from 'components/react-notification';
import SortingIndicator from 'components/sorting-indicator';
import { imageRegex } from './helper';
import Loading from 'components/loading';
import Modal from 'components/modal';
import { useAttoreyProfile } from 'contexts/attorney-profile';

const AttorneyFiles = ({ setDisable }) => {
  const { id } = useParams();
  const timeout = useRef();
  const { getData, attorney } = useAttoreyProfile();

  const [editFile, setEditFile] = useState(false);
  const [editName, setEditName] = useState();
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [cantDownload, setCantDownload] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [fileId, setFileId] = useState('');
  const [files, setFiles] = useState([]);
  const [sort, setSort] = useState({
    sortby: '_id',
    sortOrder: 'desc',
  });
  const [downloadFiles, setDownloadFiles] = useState([]);

  useEffect(() => {
    timeout.current = setTimeout(() => {
      getFilesData(search);
    }, 200);
    return () => {
      clearTimeout(timeout.current);
    };
  }, [search]);

  useEffect(() => {
    getFilesData('', setDownloadFiles);
  }, []);

  const getFilesData = async (name, setter) => {
    try {
      const res = await getFiles(id, {
        params: {
          search: name,
          ...sort,
        },
      });
      setter ? setter([...res.data.files]) : setFiles([...res.data.files]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
  };

  const downloadFile = async (id, fileName) => {
    setDownloadLoader(id);
    try {
      await downloadFileData(id, fileName);
    } catch (err) {
      console.error(err);
    }
    setDownloadLoader(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { ...files },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { fileName } = data;
    const extensionChunks = files[editName].fileName.split('.');
    const extension = extensionChunks[extensionChunks.length - 1];

    try {
      const response = await setFileName(
        {
          fileName: fileName + '.' + extension,
        },
        files[editName]._id
      );

      getFilesData(search);
      setEditName(false);
      setDisable(false);
      reset({});
      getData();
    } catch (err) {
      console.error('err', err.response.files);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteFile(fileId);
      setFiles([...files.filter((ele) => ele._id !== fileId)]);
      setDownloadFiles([...downloadFiles.filter((ele) => ele._id !== fileId)]);
      await getData();
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
    }
    setIsLoading(false);
    setDelModal(false);
  };

  useEffect(() => {
    getFilesData(search);
  }, [sort]);

  const onSort = (name) =>
    setSort((prev) => ({
      sortby: name,
      sortOrder:
        prev.sortby == name
          ? prev.sortOrder == 'desc'
            ? 'asc'
            : 'desc'
          : 'asc',
    }));

  const onAdd = (value) => {
    getData();
    getFilesData(search);

    setDownloadFiles((prev) => [value, ...prev]);
  };

  return (
    <div className={style.topDiv}>
      {downloadFiles.length > 0 && (
        <>
          <div className={style.barStateMain}>
            <div className={style.textField}>
              <TextField
                icon={SearchGreen}
                placeholder="Search"
                value={search}
                className={style.field}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className={style.btnDiv}>
              <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                <Button
                  text="Add File"
                  iconStart={addIcon}
                  handleClick={() => setEditFile(true)}
                />
              </PermissionRestrict>
            </div>
          </div>
        </>
      )}
      {files.length < 1 ? (
        !editFile && (
          <NoRecords
            icon={true}
            noRecord="No Files"
            btnText={downloadFiles.length == 0 && 'Add Files'}
            hideButton={downloadFiles.length}
            onClick={() => setEditFile(true)}
          />
        )
      ) : (
        <>
          <div className={style.maxWidthClass}>
            <div className={style.main}>
              <div className={style.tableHeader}>
                <p
                  className={style.practiceText1}
                  onClick={() => onSort('createdAt')}
                >
                  Date Added
                  <SortingIndicator
                    show={sort.sortby === 'createdAt'}
                    sort={sort.sortOrder}
                  />
                </p>
                <p
                  className={style.practiceText2}
                  onClick={() => onSort('fileName')}
                >
                  File Name
                  <SortingIndicator
                    show={sort.sortby === 'fileName'}
                    sort={sort.sortOrder}
                  />
                </p>
                <p
                  className={style.practiceText2}
                  onClick={() => onSort('fileSize')}
                >
                  File Size
                  <SortingIndicator
                    show={sort.sortby === 'fileSize'}
                    sort={sort.sortOrder}
                  />
                </p>
                <p className={style.emptyDiv}></p>
              </div>
              <div
                style={{
                  height: '55vh',
                  overflowY: 'scroll',
                  paddingBottom: '140px',
                }}
              >
                {files.map((ele, index) => {
                  const Wrapper = (props) =>
                    !imageRegex.test(ele.fileName) ? (
                      <div onClick={() => setCantDownload(true)} {...props} />
                    ) : (
                      <a
                        href={ele.url}
                        rel="noreferrer"
                        target={'_blank'}
                        {...props}
                      />
                    );

                  return (
                    <Fragment key={ele._id}>
                      <div className={style.card}>
                        {index === editName ? (
                          <form
                            onSubmit={handleSubmit(onSubmit)}
                            key={index}
                            className={style.innerChildEdit}
                          >
                            <div className={style.practiceTextField}>
                              <TextField
                                name="fileName"
                                label={'File Name'}
                                register={register}
                                errorMessage={errors?.fileName?.message}
                              />
                            </div>
                            <div className={style.imgDiv}>
                              <img
                                src={greenCrossIcon}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setEditName(false);
                                  setDisable(false);
                                }}
                              />
                              <Button
                                text={'Save'}
                                type={'submit'}
                                iconStart={saveIcon}
                                isLoading={isSubmitting}
                                btnClass={style.btnClass}
                              />
                            </div>
                          </form>
                        ) : (
                          <div className={style.innerChild}>
                            <p className={style.practiceText1}>
                              {moment(ele.createdAt).format('MM/DD/YYYY')}
                            </p>
                            <span
                              className={style.practiceText2}
                              style={{ fontWeight: '400' }}
                            >
                              <Wrapper>{ele.fileName}</Wrapper>
                            </span>
                            <p className={style.practiceText2}>
                              {ele.fileSize}
                            </p>
                            <div className={style.imgDiv}>
                              <PermissionRestrict
                                roles={[ROLES.ADMIN, ROLES.EDITOR]}
                              >
                                <img
                                  src={edit}
                                  alt=""
                                  onClick={() => {
                                    const chunks = ele.fileName.split('.');
                                    setEditName(index);
                                    setDisable(true);
                                    reset({
                                      fileName: chunks
                                        .slice(0, chunks.length - 1)
                                        .join('.')
                                        .replace(
                                          `${attorney.attorney.firstName} ${attorney.attorney.lastName}`,
                                          ''
                                        ),
                                    });
                                  }}
                                />
                                {downloadLoader === ele._id ? (
                                  <Loading loaderClass={style.loader} />
                                ) : (
                                  <img
                                    src={uploadFile}
                                    alt=""
                                    onClick={() => {
                                      downloadFile(ele._id, ele.fileName);
                                    }}
                                  />
                                )}
                                <img
                                  src={del}
                                  alt=""
                                  onClick={() => {
                                    setFileId(ele._id), setDelModal(true);
                                  }}
                                />
                              </PermissionRestrict>
                            </div>
                          </div>
                        )}
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
      <AddEditModal
        onAdd={onAdd}
        editFile={editFile}
        setEditFile={setEditFile}
      />

      <Modal open={delModal} handleClose={() => setDelModal(false)}>
        <div className={style.modalHeader}>
          <img src={Trash} height={48} width={48} />
          <h3 className={style.deleteText}>Delete File</h3>
          <p>Are you sure you want to delete this file?</p>
          <Button
            btnClass={style.buttonStyle}
            text={'Yes, delete this file'}
            handleClick={async () => {
              await handleDelete();
            }}
            isLoading={isLoading}
            width="100%"
          />
          <h3 className={style.cancelText} onClick={() => setDelModal(false)}>
            {'No, keep this file'}
          </h3>
        </div>
      </Modal>
      <Modal open={cantDownload} handleClose={() => setCantDownload(false)}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '14px',
          }}
        >
          <img
            src={crossIcon}
            onClick={() => setCantDownload(false)}
            style={{
              height: '12px',
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p className={style.modalTitle}>
            No viewer is available for this file. Please download it first to
            view.
          </p>
          <Button
            handleClick={() => setCantDownload(false)}
            text="Ok"
            btnClass={style.okButton}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AttorneyFiles;

const schema = yup
  .object({
    fileName: yup.string().required(),
  })
  .required();
