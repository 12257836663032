/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { useContext, useState, useEffect, createContext, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  deleteAttorney,
  getAttorneyById,
  setAttorneyOverview,
} from 'services/attorney-profile';
import { createNotification } from 'components/react-notification';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ROLES } from 'permissions/constants';

const AttorneyProfileContext = createContext();
export const useAttoreyProfile = () => useContext(AttorneyProfileContext);

export default function AttorneyProfileProvider({ children }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [attorney, setAttorney] = useState(null);
  const [meta, setMeta] = useState(null);
  const [clientsName, setClientsName] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [deletingPhoto, setDeletingPhoto] = useState(false);
  const [dletingLoader, setDletingLoader] = useState(false);
  const [add, setAdd] = useState(false);
  const [active, setActive] = useState(0);
  const [cancelModal, setCancelModal] = useState(false);
  const userData = useSelector((state) => state.user.userData);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(
      userData.userType === ROLES.ATTORNEY
        ? schemaAttorney
        : id === 'new'
        ? addSchema
        : updateSchema
    ),
  });

  useEffect(() => {
    setEdit(false);
  }, [active]);

  useEffect(() => {
    (async () => {
      if (id == 'new') {
        setEdit(true);
        setAttorney({
          attorney: {
            attorneyPracticeGroup: [],
            attorneyLocation: [],
            attorneyClientsHistory: [],
            attorneyBars: [],
            attorneyComments: [],
          },
          meta: {},
        });
        setMeta({});
        return;
      } else {
        Promise.all([await getData(), await getLength()]);
      }
    })();
  }, [id]);

  const Comp = useMemo(
    () =>
      edit ? (props) => <form {...props} /> : (props) => <div {...props} />,
    [edit]
  );

  const getData = async () => {
    const res = await getAttorneyById(id);
    const { attorneyStartDate, attorneyEndDate, attorneyBirthdate } = {
      attorneyStartDate: res.data.attorney.attorneyStartDate
        ? moment(res.data.attorney.attorneyStartDate?.split('T')?.[0])
        : null,
      attorneyEndDate: res.data.attorney.attorneyEndDate
        ? moment(res.data.attorney.attorneyEndDate?.split('T')?.[0])
        : null,
      attorneyBirthdate: res.data.attorney.attorneyBirthdate
        ? moment(res.data.attorney.attorneyBirthdate?.split('T')?.[0])
        : null,
    };

    setAttorney({
      ...res.data,
      attorney: {
        ...res.data.attorney,
        attorneyStartDate,
        attorneyEndDate,
        attorneyBirthdate,
        attorneyLocation: res?.data?.attorney?.attorneyLocation?.map(
          (e) => e?.location
        ),
        attorneyPracticeGroup: res?.data?.attorney?.attorneyPracticeGroup?.map(
          (e) => e?.practiceGroup
        ),
      },
    });
    reset({
      ...res.data.attorney,
      attorneyStartDate,
      attorneyLocation: res?.data?.attorney?.attorneyLocation?.map(
        (e) => e?._id
      ),
      attorneyPracticeGroup: res?.data?.attorney?.attorneyPracticeGroup?.map(
        (e) => e?._id
      ),
      attorneyEndDate,
      attorneyBirthdate,
      attorneyAmLaw50:
        res.data.attorney.attorneyAmLaw50 === null
          ? null
          : res.data.attorney.attorneyAmLaw50 === true
          ? 'true'
          : 'false',
      attorneyGradYear:
        res.data.attorney.attorneyGradYear &&
        moment().year(res.data.attorney.attorneyGradYear).toDate(),
    });
    setMeta(res.data.meta);
  };

  const getLength = async () => {
    const res = await getAttorneyById(id);
    setMeta(res.data.meta);
  };

  const onSubmit = async (data) => {
    const {
      firstName,
      profileImage,
      lastName,
      email,
      liStatus,
      attorneyStartDate,
      attorneyEndDate,
      attorneyBirthdate,
      attorneyLawSchool,
      attorneyGPA,
      attorneyClassRank,
      attorneyAmLaw50,
      attorneyDemo,
      attorneyGender,
      attorneyParkerScore,
      attorneyWritingScore,
      attorneyPracticeGroup,
      attorneyLocation,
      attorneyGradYear,
    } = data;

    try {
      const res = await setAttorneyOverview(
        {
          attorneyId: id == 'new' ? undefined : id,
          profileImage: !profileImage
            ? undefined
            : profileImage.startsWith('http')
            ? undefined
            : profileImage,
          firstName,
          lastName,
          email,
          liStatus,
          attorneyStartDate: attorneyStartDate
            ? moment(attorneyStartDate).format('YYYY-MM-DD')
            : '',
          attorneyEndDate: attorneyEndDate
            ? moment(attorneyEndDate).format('YYYY-MM-DD')
            : '',
          attorneyBirthdate: attorneyBirthdate
            ? moment(attorneyBirthdate).format('YYYY-MM-DD')
            : '',
          attorneyLawSchool,
          attorneyGPA,
          attorneyClassRank,
          attorneyAmLaw50,
          attorneyDemo,
          attorneyGender,
          attorneyParkerScore,
          attorneyWritingScore,
          attorneyPracticeGroup: attorneyPracticeGroup.filter((e) => e),
          attorneyLocation: attorneyLocation.filter((e) => e),
          attorneyGradYear: attorneyGradYear
            ? new Date(attorneyGradYear).getFullYear()
            : undefined,
        },
        id === 'new' ? undefined : id
      );
      if (id === 'new') {
        navigate('/attorneys/' + res.data.attorney._id, {
          state: {
            from: 'new',
          },
        });
        setAttorney(false);
        setTimeout(() => {
          setEdit(false);
        }, 300);
        createNotification(
          'success',
          'Success',
          'Attorney added successfully',
          3500
        );

        return;
      } else {
        await getData();
        setDisable(false);
        setEdit(false);
        createNotification(
          'success',
          'Success',
          'Attorney updated successfully',
          3500
        );
      }
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Server Error'
      );
      console.error('err', err);
    }
  };
  const avatarDelete = async () => {
    const {
      firstName,
      profileImage,
      lastName,
      email,
      liStatus,
      attorneyStartDate,
      attorneyEndDate,
      attorneyBirthdate,
      attorneyLawSchool,
      attorneyGPA,
      attorneyClassRank,
      attorneyAmLaw50,
      attorneyDemo,
      attorneyGender,
      attorneyParkerScore,
      attorneyWritingScore,
      attorneyGradYear,
    } = attorney?.attorney;

    try {
      setDletingLoader(true);
      const res = await setAttorneyOverview(
        {
          attorneyId: id == 'new' ? undefined : id,
          profileImage: null,
          firstName,
          lastName,
          email,
          liStatus,
          attorneyStartDate: attorneyStartDate
            ? moment(attorneyStartDate).format('YYYY-MM-DD')
            : '',
          attorneyEndDate: attorneyEndDate
            ? moment(attorneyEndDate).format('YYYY-MM-DD')
            : '',
          attorneyBirthdate: attorneyBirthdate
            ? moment(attorneyBirthdate).format('YYYY-MM-DD')
            : '',
          attorneyLawSchool,
          attorneyGPA,
          attorneyClassRank,
          attorneyAmLaw50,
          attorneyDemo,
          attorneyGender,
          attorneyParkerScore,
          attorneyWritingScore,
          attorneyPracticeGroup: watch('attorneyPracticeGroup').filter(
            (e) => e
          ),
          attorneyLocation: watch('attorneyLocation').filter((e) => e),
          attorneyGradYear: attorneyGradYear
            ? new Date(attorneyGradYear).getFullYear()
            : undefined,
        },
        id === 'new' ? undefined : id
      );
      if (res?.status === 201) {
        getData();
        setDisable(false);
        setEdit(false);
        setDeletingPhoto(false);
        setDletingLoader(false);
      }
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Server Error'
      );
      console.error('err', err);
      setDletingLoader(false);
    }
  };

  //delete
  const handleDelete = async () => {
    try {
      await deleteAttorney(id);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AttorneyProfileContext.Provider
      value={{
        register,
        control,
        handleSubmit,
        errors,
        attorney,
        setAttorney,
        Comp,
        setActive,
        onSubmit,
        clientsName,
        setClientsName,
        deletingPhoto,
        setDeletingPhoto,
        isLoading,
        dletingLoader,
        add,
        setAdd,
        active,
        setEdit,
        edit,
        isSubmitting,
        handleDelete,
        getData,
        id,
        avatarDelete,
        disable,
        setDisable,
        reset,
        setValue,
        watch,
        cancelModal,
        setCancelModal,
      }}
    >
      {children}
    </AttorneyProfileContext.Provider>
  );
}
const currentYear = new Date().getFullYear();
const yearMessage = `Grad Year must be between 1900-${currentYear}`;
const basicSchema = {
  profileImage: yup.string().nullable().optional(),
  firstName: yup.string().optional('First name is required'),
  lastName: yup.string().optional('Last name is required'),
  email: yup.string().email().required('Email is required'),
  liStatus: yup.string().nullable().optional('LI-Status is required'),
  attorneyStartDate: yup.string().nullable().optional('Start date is required'),
  attorneyBirthdate: yup.string().nullable().optional('Birth date is required'),
  attorneyLawSchool: yup.string().nullable().optional('Law school is required'),
  attorneyGPA: yup.string().nullable().optional('Gpa is required'),
  attorneyClassRank: yup.string().nullable().optional('Class rank is required'),
  attorneyAmLaw50: yup.boolean().nullable().optional('AmLaw50 is required'),
  attorneyGender: yup.string().nullable().optional('Gender is required'),
  attorneyParkerScore: yup
    .string()
    .nullable()
    .optional('Parker score is required'),
  attorneyGradYear: yup.date().nullable().typeError(yearMessage).optional(),
  attorneyWritingScore: yup.string().optional('Writing score is required'),
  attorneyPracticeGroup: yup
    .array()
    .test('isValid', 'Please select at least one practice group', (value) => {
      return value.filter((e) => e !== null && e !== undefined).length > 0
        ? value[0] !== null &&
            value[0] !== undefined &&
            value.filter((e) => e !== null && e !== undefined).length > 0
        : true;
    })
    .nullable()
    .optional(),
  attorneyLocation: yup
    .array()
    .test('isValid', 'Please select at least one location', (value) => {
      return value.filter((e) => e !== null && e !== undefined).length > 0
        ? value[0] !== null &&
            value[0] !== undefined &&
            value.filter((e) => e !== null && e !== undefined).length > 0
        : true;
    })
    .nullable()
    .optional(),
};
const addSchema = yup.object({
  ...basicSchema,
  attorneyDemo: yup.array().of(yup.string()).optional(),
});
const updateSchema = yup.object({
  ...basicSchema,
  attorneyDemo: yup.array().of(yup.string()).optional(),
});

const schemaAttorney = yup
  .object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    attorneyDemo: yup
      .array()
      .of(yup.string())
      .min(1, 'Demo field must have at least 1 item')
      .required('Demo is required'),
    attorneyGender: yup.string().required('Gender is required'),
    attorneyPracticeGroup: yup
      .array()
      .test('isValid', 'Please select at least one practice group', (value) => {
        return (
          value[0] !== null &&
          value[0] !== undefined &&
          value.filter((e) => e).length > 0
        );
      })
      .required('Please at least one practice group'),
    attorneyLocation: yup
      .array()
      .test('isValid', 'Please select at least one location', (value) => {
        return (
          value[0] !== null &&
          value[0] !== undefined &&
          value.filter((e) => e).length > 0
        );
      })
      .required('Please select at least one location'),
  })
  .required();
