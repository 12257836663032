import React from 'react';
import { useForm } from 'react-hook-form';

import Button from 'components/button';
import Modal from 'components/modal';
import TextField from 'components/textfield';

import { updateVersionName } from 'services/positions';

import style from './edit-modal.module.scss';

const EditModal = ({
  editModal,
  setEditModal,
  setVersionModal,
  handleRestoreVersion,
}) => {
  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const handleClose = () => {
    reset({ name: '' });
    setEditModal(false);
  };

  const handleUpdateVersionName = async (body) => {
    await updateVersionName({
      id: editModal?._id,
      body,
      setVersionModal,
      handleRestoreVersion,
    });
    handleClose();
  };

  return (
    <div>
      <Modal
        open={editModal}
        handleClose={handleClose}
        className={style.mainDiv}
      >
        <form onSubmit={handleSubmit(handleUpdateVersionName)}>
          <p className={style.modalTitle}>Change version name</p>
          <p className={style.para}>Enter new version name below:</p>
          <p className={style.para}>Current Name</p>
          <h4>{editModal?.name}</h4>
          <TextField
            name="name"
            label="New Name"
            register={register}
            placeholder={'Enter new version name'}
          />
          <div className={style.mainBtnDiv}>
            <Button
              text="Save Name"
              isLoading={isSubmitting}
              btnClass={style.btnClassModal}
              disabled={!watch('name')?.trim?.()}
            />
            <Button
              text="Cancel"
              type="button"
              btnClass={style.btnClassUncheckModal}
              handleClick={() => {
                handleClose();
                setVersionModal(true);
              }}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EditModal;
