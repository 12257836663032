/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from 'components/button';
import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextField from 'components/textfield';

import closeIcon from 'assets/icons/blue-cross.svg';
import saveIcon from 'assets/images/save.svg';

import { createNotification } from 'components/react-notification';
import { BAR_STATUS_OPTIONS } from 'constants';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { setBarHistory } from 'services/attorney-profile';
import style from './edit.module.scss';

const EditAddTable = ({
  setEditPopUp,
  className,
  data,
  onFormSubmit: submitted,
  alreadyUsedStatus,
}) => {
  const [options, setOptions] = useState();

  const { states } = useSelector((state) => state.systemValues);
  const attorneyData = useSelector((state) => state.attorney.attorneyData);
  const id = attorneyData?.attorney?._id;
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: !data
      ? {}
      : {
          ...data,
          barTestedDate: data.barTestedDate && new Date(data.barTestedDate),
        },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const stateOptionsList =
      states?.map((e) => ({
        label: e.barState,
        value: e.barState,
      })) || [];

    let found = stateOptionsList?.filter(
      (x) => !alreadyUsedStatus.includes(x?.label)
    );

    setOptions(found);
  }, [alreadyUsedStatus]);

  const onSubmit = async (values) => {
    const { barStatus, barTestedDate, barState, barId } = values;

    try {
      const res = await setBarHistory(
        {
          attorneyId: id,
          barId,
          barState: states.find((state) => state.barState == barState)._id,
          barStatus,
          barTestedDate,
        },
        data?._id
      );
      submitted(res.data.bar);
      setEditPopUp(false);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          err?.message ||
          'Server Error'
      );
    }
  };

  return (
    <div>
      <div className={style.mainEdit}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${style.innerDiv} ${className}`}>
            <DatePicker
              label="Date *"
              name={'barTestedDate'}
              id="1"
              monthYear={true}
              placeholder="mm/yyyy"
              control={control}
              errorMessage={errors?.barTestedDate?.message}
              isblue
            />
            <div className={style.barEditFields}>
              <SelectBox
                options={options ?? []}
                label={`State ${_.isEmpty(data?._id) ? '*' : ''}`}
                placeholder="Select"
                disabled={data?._id}
                name={'barState'}
                control={control}
                isDemo
                showValueOnlyOnDisabled
                errorMessage={errors?.barState?.message}
              />
            </div>
            <div className={style.barEditFields}>
              <SelectBox
                options={BAR_STATUS_OPTIONS}
                label={'Status *'}
                placeholder="Select"
                control={control}
                name={'barStatus'}
                errorMessage={errors?.barStatus?.message}
                isDemo
              />
            </div>

            <TextField
              label={'ID'}
              wraperClass={style.inputWraper}
              name="barId"
              placeholder={'e.g., 122151251'}
              register={register}
            />
          </div>
          <div className={style.btnSection}>
            <img
              src={closeIcon}
              className={style.img}
              onClick={() => setEditPopUp(false)}
            />
            <Button
              type={'submit'}
              text={'Save'}
              iconStart={saveIcon}
              btnClass={style.btnClass}
              isLoading={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAddTable;

const schema = yup
  .object({
    barStatus: yup.string().nullable().required('Status is required'),
    barTestedDate: yup
      .string()
      .required('Date is required')
      .typeError('Date is required'),
    barState: yup.string().nullable().required('Bar state is required'),
  })
  .required();
