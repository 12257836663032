import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import Modal from 'components/modal';
import Button from 'components/button';
import SelectBox from 'components/select-box';

import { ATTORNEY_CLIENT_STATUS_COLORS } from 'constants';
import { updateAttorneyLiStatus } from 'services/attorny-directory-table';

import style from './add-status.module.scss';

const PlacedStatusPopup = ({
  changeValue,
  getUpdatedData,
  cancelBtnEvent = () => {},
  previousAttorneyClientHistory,
}) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const schema = yup.object().shape({
    status: yup.string().required(),
  });

  const {
    control,
    getValues,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm(
    {
      resolver: yupResolver(schema),
      defaultValues: {
        status: '',
      },
    },
    {}
  );

  useEffect(() => {
    setOpenModal(true);
  }, [changeValue]);

  const onSubmit = async (data) => {
    const { attorneyId, clientId, positionId, statusDate, _id } =
      previousAttorneyClientHistory;
    const { status } = getValues();
    setLoading(true);
    await updateAttorneyLiStatus({
      updateAttorneyClientId: _id,
      attorneyId,
      clientId,
      positionId: positionId?._id,
      statusDate,
      liStatus: changeValue,
      attorneyClientStatus: status,
    });
    await getUpdatedData();
    resetAllValues();
    setLoading(false);
    setOpenModal(false);
  };

  const resetAllValues = () => {
    reset({
      status: null,
    });
  };

  return (
    <Modal
      open={openModal}
      handleClose={() => setOpenModal(false)}
      className={`  ${style.placedModal} `}
    >
      <h6 className={style.modalTitle}>Change Status</h6>
      <p className={style.modalTitleInner}>
        If this attorney is no longer at this client, then please update their
        client status to one of the following:
      </p>
      <form id="updateACStatus" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <SelectBox
            label={'Client Status'}
            placeholder={'Select'}
            options={[
              {
                value: 'Returned',
                label: 'Returned',
                color: ATTORNEY_CLIENT_STATUS_COLORS.Returned,
              },
              {
                value: 'Midway Return',
                label: 'Midway Return',
                color: ATTORNEY_CLIENT_STATUS_COLORS['Midway Return'],
              },
            ]}
            control={control}
            name={'status'}
            errorMessage={
              errors?.status?.message && 'status is a required field'
            }
          />
        </div>

        <div className={style.mainBtnDiv}>
          <Button
            isLoading={loading}
            type="submit"
            text={'Confirm'}
            btnClass={style.btnClassModal}
            form="updateACStatus"
          />
          <Button
            type="button"
            text={'Cancel'}
            btnClass={style.btnClassUncheckModal}
            handleClick={() => {
              setOpenModal(false);
              cancelBtnEvent && cancelBtnEvent();
            }}
          />
        </div>
      </form>
    </Modal>
  );
};

export default PlacedStatusPopup;
