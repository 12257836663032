/* eslint-disable no-dupe-else-if */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import pinkCrossIcon from 'assets/images/pinkCrossIcon.svg';
import saveIcon from 'assets/images/save.svg';
import TextField from 'components/textfield';

import style from './editContact.module.scss';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/button';
import * as yup from 'yup';

import { createNotification } from 'components/react-notification';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useParams } from 'react-router-dom';
import { setContact } from 'services/client-contact';

const EditContact = ({ setEdit, setAdd, data, onSubmit: submitted }) => {
  const [isValid, setIsValid] = useState(true);
  const { id } = useParams();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: !data
      ? {}
      : {
          ...data,
        },

    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    const { contactName, contactRole, contactEmail, contactPhone } = values;
    try {
      const res = await setContact(
        {
          clientId: id,
          contactName,
          contactRole,
          contactEmail,
          contactPhone,
        },
        data ? data?._id : id,
        data ? true : false
      );
      submitted(res.data.contact);
      setEdit(null);
      setAdd(false);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          err?.message ||
          'Server Error'
      );
      console.error('err', err.response.data);
    }
  };

  return (
    <>
      <form
        id="contact_form"
        onSubmit={handleSubmit(onSubmit)}
        className={style.wrapper}
      >
        <div className={style.mainDiv}>
          <div>
            <TextField
              label="Name"
              name="contactName"
              register={register}
              errorMessage={errors?.contactName?.message}
            />
          </div>
          <div>
            <TextField
              label="Role"
              name="contactRole"
              register={register}
              errorMessage={errors?.contactRole?.message}
            />
          </div>
          <div>
            <TextField
              label="Email"
              name="contactEmail"
              register={register}
              errorMessage={errors?.contactEmail?.message}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={style.phoneLabel}>Phone Number</span>
            <Controller
              name="contactPhone"
              control={control}
              render={({ field }) => {
                return (
                  <PhoneInput
                    inputStyle={{
                      height: '40px',
                      border: ' 1px solid #E6E7E8',
                      borderRadius: '5px',
                      width: ' 100%',
                      fontSize: '14px',
                      backgroundColor: '#fff',
                    }}
                    country={'us'}
                    placeholder="e.g., 555 555 5555"
                    {...field}
                    isValid={(value) => {
                      if (value.length < 2) {
                        setIsValid(true);
                      } else if (value.length < 11) {
                        setIsValid(false);
                      } else {
                        setIsValid(true);
                      }
                    }}
                  />
                );
              }}
            />
          </div>
        </div>
        <div className={style.btnDiv}>
          <img
            src={pinkCrossIcon}
            onClick={() => {
              setEdit(false);
              setAdd(false);
            }}
          />
          <Button
            text={'Save'}
            type={'submit'}
            disabled={!isValid}
            iconStart={saveIcon}
            btnClass={style.btnClass}
            isLoading={isSubmitting}
            width="100px"
          />
        </div>
      </form>
    </>
  );
};
export default EditContact;

const schema = yup
  .object({
    contactName: yup.string().required('Contact Name is required'),
    contactRole: yup.string().required('Role is required'),

    contactEmail: yup
      .string()
      .email('Contact email must be a valid email address')
      .optional(),
  })
  .required();
