/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Button from 'components/button';
import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextArea from 'components/textarea';
import TextField from 'components/textfield';

import { createNotification } from 'components/react-notification';
import { CLE_SUBJECTS, CLE_SUBJECTS_MATTER, STATES } from 'constants';
import { setAttorneyTrainingHistory } from 'services/attorney-profile';

import closeIcon from 'assets/icons/blue-cross.svg';
import saveIcon from 'assets/images/save.svg';

import moment from 'moment';
import { useSelector } from 'react-redux';
import style from './add-training-course.module.scss';

const AddTrainingHistory = ({
  setToggle,
  setEdit,
  data,
  onSubmit: submitted,
}) => {
  const attorneyData = useSelector((state) => state.attorney.attorneyData);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: !data
      ? {}
      : {
          ...data,
          courseCompletedDate:
            data.courseCompletedDate && new Date(data.courseCompletedDate),
        },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const {
      courseName,
      courseCompletedDate,
      courseHours,
      liveHours,
      credits,
      state,
      subject,
      courseSubjMatter,
      myFeedback,
      cloFeedback,
    } = data;
    try {
      const res = await setAttorneyTrainingHistory(
        {
          attorneyId: attorneyData.attorney._id,
          courseName,
          courseCompletedDate: moment(courseCompletedDate).format('YYYY-MM-DD'),
          courseHours,
          liveHours,
          credits,
          state,
          subject,
          courseSubjMatter,
          cloFeedback,
          myFeedback,
        },
        data?._id
      );
      submitted(res.data.training);
      setToggle(false);

      setEdit(false);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error('err', err);
    }
  };

  return (
    <form
      id="trainingHistory-form"
      onSubmit={handleSubmit(onSubmit)}
      className={style.wraper}
    >
      <div className={style.sectionOne}>
        <div>
          <TextField
            label={'Course Name *'}
            placeholder="Course name"
            name="courseName"
            register={register}
            errorMessage={errors?.courseName?.message}
          />
        </div>
        <div>
          <DatePicker
            label="Date Completed"
            name={'courseCompletedDate'}
            id="1"
            placeholder="mm/dd/yyyy"
            control={control}
            errorMessage={errors?.courseCompletedDate?.message}
            isblue
          />
        </div>
        <div>
          {' '}
          <div>
            <TextField
              label={'Hours *'}
              placeholder={'2'}
              name="courseHours"
              register={register}
              type="number"
              errorMessage={errors?.courseHours?.message}
            />
          </div>
        </div>
        <div>
          {' '}
          <div>
            <TextField
              label={'Live *'}
              type="number"
              placeholder={'2'}
              name="liveHours"
              register={register}
              errorMessage={errors?.liveHours?.message}
            />
          </div>
        </div>
        <div>
          {' '}
          <div>
            <TextField
              label={'CLE Credits *'}
              placeholder={'2'}
              type="number"
              name="credits"
              register={register}
              errorMessage={errors?.credits?.message}
            />
          </div>
        </div>
        <div>
          <SelectBox
            options={STATES}
            label={'CLE State *'}
            name={'state'}
            placeholder="Select"
            control={control}
            errorMessage={errors?.state?.message}
          />
        </div>
      </div>
      <div className={style.subjectSection}>
        <div>
          <SelectBox
            options={CLE_SUBJECTS}
            label={'CLE Subject *'}
            placeholder="Select"
            name={'subject'}
            control={control}
            errorMessage={errors?.subject?.message}
          />
        </div>
        <div>
          <SelectBox
            options={CLE_SUBJECTS_MATTER}
            label={'Subject Matter *'}
            name={'courseSubjMatter'}
            placeholder="Select"
            control={control}
            errorMessage={errors?.courseSubjMatter?.message}
          />
        </div>
      </div>
      <div className={style.sectionTwo}>
        <div>
          <TextArea
            label={'My Feedback'}
            name="myFeedback"
            placeholder={'Type your feedback'}
            register={register}
            errorMessage={errors?.myFeedback?.message}
          />
        </div>
      </div>
      <div className={style.btnSection}>
        <img
          src={closeIcon}
          onClick={() => {
            setToggle(false);
            setEdit(false);
          }}
        />
        <Button
          text={'Save'}
          type={'submit'}
          width="100px"
          form="trainingHistory-form"
          iconStart={saveIcon}
          btnClass={style.btnClass}
          isLoading={isSubmitting}
        />
      </div>
    </form>
  );
};

export default AddTrainingHistory;

const schema = yup
  .object({
    courseName: yup.string().required('Course name is required'),
    courseCompletedDate: yup.date().nullable(),
    courseHours: yup.string().required('Course hours are required'),
    liveHours: yup.string().required('Live hours are required'),
    credits: yup.string().required('Credits are required'),
    state: yup.string().nullable().required('State is required'),
    subject: yup.string().nullable().required('Subject is required'),
    courseSubjMatter: yup
      .string()
      .nullable()
      .required('Subject matter is required'),
  })
  .required();
