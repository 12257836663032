import Zip from 'jszip';
import axios from 'utils/axios';

import { imageRegex } from 'pages/attorney-profile/files/helper';
import { apiRequest } from 'utils/helpers';
import { API_URLS } from './url-paths';

//overview
//Attorney By ID

const { ADD_UPDATE_CLIENT_HISTORY, GET_ALL_BAR_HISTORIES, GET_CLIENT_HISTORY } =
  API_URLS;
export const getAttorneyById = async (id) => {
  let url = `${API_URLS.GET_ATTORNEY}/${id}`;
  return axios.get(url);
};

export const getAttorneyByEmail = async (email) => {
  let url = `${API_URLS.GET_ATTORNEY_BY_EMAIL}/${email}`;
  return axios.get(url);
};

export const setAttorneyUpdated = async (id) => {
  let url = `${API_URLS.SET_ATTORNEY_UPDATED}/${id}`;
  return axios.put(url);
};

export const setAttorneyOverview = (obj, id) => {
  let url = API_URLS.SET_ATTORNEY;
  if (id) url = `${API_URLS.SET_ATTORNEY}/${id}`;
  return axios[id ? 'put' : 'post'](url, obj);
};

//delete attorey
export const deleteAttorney = (id) => {
  let url = `${API_URLS.SET_ATTORNEY}/${id}`;
  return axios.delete(url);
};

//bar
export const getAttorneyBarHistory = async (id) => {
  let url = `${API_URLS.GET_BAR_HISTORY}/${id}`;
  return axios.get(url);
};

export const getAllBarHistories = async ({
  params,
  setBarHistory,
  currentBarState,
}) => {
  const res = await apiRequest({
    path: GET_ALL_BAR_HISTORIES,
    type: 'get',
    params,
  });
  if (res.status === 200) {
    setBarHistory((prev) => {
      const index = [...prev]?.findIndex?.(
        (x) => x.barState === currentBarState?.barState
      );
      if (prev?.[index]) {
        prev[index].histories = res?.data?.histories;
        return prev;
      } else {
        return prev;
      }
    });
  }
};

export const setBarHistory = (obj, id) => {
  let url = API_URLS.SET_BAR_HISTORY;
  if (id) url = `${API_URLS.SET_BAR_HISTORY}/${id}`;
  return axios[id ? 'put' : 'post'](url, obj);
};

export const deleteBarHistory = ({ single, history }) => {
  let url = `${API_URLS.SET_BAR_HISTORY}/${history.id ? history.id : history}`;
  return single === true
    ? axios.delete(url, {
        ...(history.id && { params: { single: 'true' } }),
      })
    : axios.delete(url, {
        ...(history.id && { params: { single: 'false' } }),
      });
};

//client History
export const getClientName = async () => {
  let url = API_URLS.GET_CLIENT_NAME;
  return axios.get(url);
};

export const getClientPosition = async () => {
  let url = API_URLS.GET_CLIENT_POSITION;
  return axios.get(url);
};

export const getAttorneyClientHistory = async (id) => {
  let url = `${API_URLS.GET_CLIENT_HISTORY}/${id}`;
  return axios.get(url);
};
export const getNestedAttorneyClientHistory = async ({
  params,
  historyId,
  setClientHistory,
}) => {
  const res = await apiRequest({
    type: 'get',
    path: GET_CLIENT_HISTORY,
    params,
  });
  if (res.status === 200) {
    const histories = res.data.histories.filter((x, index) => index > 0);
    setClientHistory((prev) => {
      const currentIndex = prev.findIndex((x) => x._id === historyId);
      prev[currentIndex] = {
        ...prev[currentIndex],
        histories,
      };
      return prev;
    });
  }
};
export const setAttorneyClientHistory = (obj, id) => {
  let url = API_URLS.SET_CLIENT_HISTORY;
  if (id) url = `${API_URLS.SET_CLIENT_HISTORY}/${id}`;
  return axios[id ? 'put' : 'post'](url, obj);
};

export const deleteClientHistory = ({
  id,
  attorneyId,
  clientId,
  positionId,
}) => {
  let url = `${API_URLS.SET_CLIENT_HISTORY}/${id ? id : attorneyId}`;
  return id
    ? axios.delete(url)
    : axios.delete(url, {
        params: {
          deleteType: 'all',
          clientId: clientId,
          positionId: positionId,
        },
      });
};

//comments
export const getAttorneyComments = async (id) => {
  let url = `${API_URLS.GET_COMMENTS}`;
  return axios.get(url, {
    params: {
      attorneyId: id,
    },
  });
};
export const setComments = (obj, id) => {
  let url = API_URLS.SET_COMMENTS;
  if (id) url = `${API_URLS.SET_COMMENTS}/${id}`;
  return axios[id ? 'put' : 'post'](url, {
    ...obj,
  });
};

export const deleteComment = (id) => {
  let url = `${API_URLS.SET_COMMENTS}/${id}`;
  return axios.delete(url);
};

//training
export const getAttorneyTrainingHistory = async (id) => {
  let url = `${API_URLS.GET_TRAINIG_HISTORY}/${id}`;
  return axios.get(url);
};

export const setAttorneyTrainingHistory = (obj, id) => {
  let url = API_URLS.SET_TRAINIG_HISTORY;
  if (id) url = `${API_URLS.SET_TRAINIG_HISTORY}/${id}`;
  return axios[id ? 'put' : 'post'](url, {
    ...obj,
  });
};

export const deleteTrainingHistory = (id) => {
  let url = `${API_URLS.SET_TRAINIG_HISTORY}/${id}`;
  return axios.delete(url);
};

//files
// all
export const getFiles = (id, obj) => {
  return axios.get(`${API_URLS.GET_FILES}/${id}`, obj);
};

//downloads
export const downloadFileData = async (id, name) => {
  let response = await axios({
    method: 'GET',
    url: `${API_URLS.DOWNLOAD_FILES}/${id}`,
    responseType: 'blob',
  });

  if (!imageRegex.test(name)) {
    const zip = Zip();

    const data = await zip.loadAsync(response.data);
    response.data = await data.files[Object.keys(data.files)[0]].async('blob');
  }

  const href = URL.createObjectURL(response.data);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const setFiles = (obj, controller) => {
  let url = API_URLS.SET_FILES;
  return axios['post'](url, obj, {
    signal: controller.signal,
  });
};

export const cancelAdd = (obj) => {
  let url = API_URLS.CANCEL_SET_FILES;
  return axios['post'](url, obj);
};

//edit name
export const setFileName = (obj, id) => {
  let url = API_URLS.SET_FILE_NAME;
  if (id) url = `${API_URLS.SET_FILE_NAME}/${id}`;
  return axios[id ? 'put' : 'put'](url, {
    ...obj,
  });
};

//delete
export const deleteFile = (id) => {
  let url = `${API_URLS.SET_FILES}/${id}`;
  return axios.delete(url);
};

export const addAttorneyClientStatus = async ({
  body,
  setSelectStatus,
  handleRestoreVersion,
  setRemoveStatusConfirm,
}) => {
  const res = await apiRequest({
    type: 'post',
    path: ADD_UPDATE_CLIENT_HISTORY,
    body,
  });
  if (res.status === 200) {
    setSelectStatus(false);
    setRemoveStatusConfirm && setRemoveStatusConfirm(body);
    handleRestoreVersion && handleRestoreVersion();
  }
};
