/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import EditAddTable from './edit-add';
import Button from 'components/button';
import Loader from 'components/loader';
import TextField from 'components/textfield';
import DeleteModal from 'components/delete-modal';
import CardComment from 'components/card-comment';

import { createNotification } from 'components/react-notification';
import { systemValuesService } from 'services/system-values';

import del from 'assets/icons/del-blue.svg';
import edit from 'assets/icons/blue-edit.svg';
import addIcon from 'assets/images/whiteAddIcon.svg';
import searchIcon from 'assets/icons/search-blue.svg';

import style from './system-values-tab.module.scss';

const SystemValuesTab = ({
  setEditPopUp,
  setAddPopUp,
  editPopUp,
  getData,
  addPopUp,
  openDelModal,
  setOpenDelModal,
  setLoading,
  values,
  fetchData,
  fetchKey,
  deleteHandler,
  addHandler,
  editHandler,
  title,
  valueKey,
  placeholder,
  label,
  buttonText,
  active,
  deleteLabel,
  addLabel,
}) => {
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [deleteMeta, setDeleteMeta] = useState({});
  const [valuesLocal, setValuesLocal] = useState(values);
  const [search, setSearch] = useState('');

  const handleFetchData = async () => {
    setLoader(true);
    await getData();
    setLoader(false);
  };

  const onSearch = async (name) => {
    setLoading(values.length !== 0);

    try {
      const res = await fetchData({
        params: {
          name,
        },
      });
      setValuesLocal(res?.data[fetchKey]);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const getDeleteSystemValueMeta = async () => {
    const res = await systemValuesService.getDeleteSystemValueMeta(
      ['practiceGroup', 'location', 'barState'][active],
      openDelModal
    );
    if (res.status === 200) setDeleteMeta(res.data);
  };
  const deleteItem = async (exportCSV) => {
    setLoading(true);
    try {
      dispatch(deleteHandler(openDelModal, exportCSV));
      createNotification('success', 'Success', 'Deleted Successfully!', 3500);
      setValuesLocal((prev) => {
        const newArr = [...prev];
        const index = newArr.findIndex(
          (element) => element._id === openDelModal
        );
        newArr.splice(index, 1);
        return newArr;
      });
      setOpenDelModal(false);
    } catch (err) {
      console.error('err', err.response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    let timeout;
    if (search) {
      timeout = setTimeout(() => {
        onSearch(search);
      }, 500);
    } else {
      setValuesLocal(values);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [search, values]);

  useEffect(() => {
    setSearch('');
  }, [active]);

  useEffect(() => {
    openDelModal && getDeleteSystemValueMeta();
  }, [openDelModal]);

  useEffect(() => {
    handleFetchData();
  }, [fetchKey]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className={style.practiceMain} style={{}}>
          <div className={style.textField}>
            <TextField
              icon={searchIcon}
              placeholder="Search"
              className={style.field}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              searchClearToggle
              onClear={() => setSearch('')}
            />
          </div>
          <div>
            {!addPopUp && (
              <Button
                text={buttonText}
                iconStart={addIcon}
                btnClass={style.btn}
                handleClick={() => {
                  setAddPopUp(true);
                  setEditPopUp(false);
                }}
              />
            )}
          </div>
          <div className={style.mainMain}>
            {addPopUp && (
              <EditAddTable
                container={style.editContainerTop}
                setLoading={setLoading}
                setEditPopUp={setAddPopUp}
                submitFunction={addHandler}
                dataKey={valueKey}
                label={addLabel || label}
                placeholder={placeholder}
                editPopUp={editPopUp}
              />
            )}
          </div>
          <p className={style.practiceText}>{title}</p>
          <div className={style.mainMain}>
            {valuesLocal.length == 0 ? (
              <div className={style.noRecords}>No Records Found</div>
            ) : (
              valuesLocal?.map((ele, index) => (
                <div className={style.cardsNew}>
                  {index === editPopUp ? (
                    <EditAddTable
                      container={style.editContainer}
                      setLoading={setLoading}
                      setEditPopUp={setEditPopUp}
                      data={ele}
                      submitFunction={editHandler}
                      dataKey={valueKey}
                      editPopUp={editPopUp >= 0}
                      label={label}
                      placeholder={placeholder}
                    />
                  ) : (
                    <div className={style.card} key={index}>
                      <CardComment
                        elipsisMode
                        salesforce={ele?.salesforce}
                        className={style.cardComp}
                        para={ele[valueKey]}
                        editIcon={ele?.salesforce ? false : edit}
                        deleteIcon={ele?.salesforce ? false : del}
                        handleEdit={() => {
                          setEditPopUp(index);
                          setAddPopUp(false);
                        }}
                        handleDelete={() => {
                          setOpenDelModal(ele._id);
                        }}
                      />
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
          <DeleteModal
            openDelModal={openDelModal}
            setOpenDelModal={setOpenDelModal}
            del={del}
            iconSize={48}
            className={style.modal}
            handleClick={(exportCSV) => deleteItem(exportCSV)}
            btn1Text="No, don’t delete it"
            btnText="Delete the record"
            btn2Text={deleteMeta?.deleteable ? '' : 'Save as CSV and Delete'}
            paraText={
              <>
                <p>{`This ${deleteLabel} is used for`}</p>
                <p>
                  <span style={{ color: '#39695b' }}>
                    {deleteMeta?.attorneyCount || 0}{' '}
                    {(deleteMeta?.attorneyCount || 0) == 1
                      ? 'Attorney'
                      : 'Attorneys'}
                  </span>{' '}
                  and{' '}
                  <span style={{ color: '#673e9b' }}>
                    {deleteMeta?.positionCount || 0}{' '}
                    {(deleteMeta?.positionCount || 0) == 1
                      ? 'Position'
                      : 'Positions'}
                  </span>
                  ,
                </p>
                <p style={{ width: '240px' }}>
                  are you sure you want to delete it?
                </p>
              </>
            }
          />
        </div>
      )}
    </>
  );
};

export default SystemValuesTab;
