import { FilterState } from './filter-state';
import * as Actions from './constants';

const INITIAL_STATE = new FilterState();

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case Actions.SET_DASHBOARD_FILTER: {
      return {
        ...state,
        dashBoardFilter: action.payload,
      };
    }
    case Actions.SET_ATTORNEY_TABLE_FILTER: {
      return {
        ...state,
        attorneyTableFilter: action.payload,
      };
    }
    case Actions.SET_ATTORNEY_TABLE_COLUMNS: {
      return {
        ...state,
        attorneyTableColumns: action.payload,
      };
    }
    case Actions.SET_CLIENTS_TABLE_FILTER: {
      return {
        ...state,
        clientTableFilter: action.payload,
      };
    }
    case Actions.SET_POSITIONS_TABLE_FILTER: {
      return {
        ...state,
        positionTableFilter: action.payload,
      };
    }
    case Actions.SET_ATTORNEY_DIRECTORY_CHECKED: {
      return {
        ...state,
        attorneyDirectoryChecked: action.payload,
      };
    }
    case Actions.SET_CLIENT_DIRECTORY_CHECKED: {
      return {
        ...state,
        clientDirectoryChecked: action.payload,
      };
    }
    case Actions.SET_POSITION_DIRECTORY_CHECKED: {
      return {
        ...state,
        positionDirectoryChecked: action.payload,
      };
    }
    default:
      return state;
  }
}
