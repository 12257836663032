/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from 'components/button';
import ButtonDropdown from 'components/button-dropdown';
import CustomDatePicker from 'components/custom-date-picker';
import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextField from 'components/textfield';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { CLIENT_STATUS_OPTIONS, CLIENT_TYPE_OPTIONS } from 'constants';
import { ROLES } from 'permissions/constants';
import {
  setCLientTableFilter,
  setCheckedRowsData,
} from 'redux/filters/filters-actions';
import { getSalesForceClientsAndPositions } from 'services/data-sync';
import { moveSelctedDropdownStatusToTop } from '../helper';

import searchIcon from 'assets/icons/search-blue.svg';
import cross from 'assets/images/greenCrossIcon.svg';
import refreshIcon from 'assets/images/refreshIcon.svg';
import plusIcon from 'assets/images/whiteAddIcon.svg';
import arrowDown from 'assets/pink-arrow-down.svg';

import style from './header.module.scss';

const ClientHeader = (
  {
    search,
    filters,
    dateRange,
    unCheckAll,
    setPageSize,
    setDirectoryFilters,
    getDataFromClientAfterApplyingFilter,
  },
  ref
) => {
  const dispatch = useDispatch();
  const { control, reset, watch, handleSubmit } = useForm();
  const { dashboardData } = useSelector((state) => state?.systemValues);
  const { clientTableFilter } = useSelector((state) => state?.pageFilters);

  const currentValue = clientTableFilter?.currentValue || 'Now';
  const [syncLoading, setSyncLoading] = useState(false);
  const [clientTypeOptions, setCLientTypeOptions] = useState(
    CLIENT_TYPE_OPTIONS?.map((x) => {
      return { ...x, checkbox: true };
    })
  );

  const [clientStatusOptions, setCLientStatusOptions] = useState(
    CLIENT_STATUS_OPTIONS?.map((x) => {
      return { ...x, checkbox: true };
    })
  );

  const debouncedSearch = React.useRef(
    _.debounce(async ({ currentValue, newCalculation, filters }) => {
      await getDataFromClientAfterApplyingFilter({
        filterData: filters,
        currentValue,
        newCalculation,
      });
    }, 300)
  ).current;

  const clickOnCrossIcon = async () => {
    setPageSize(1);
    await getDataFromClientAfterApplyingFilter({
      filterData: {
        mainFilters: {
          ...(filters?.mainFilters || {}),
          clientName: '',
        },
        statsFilters: filters.statsFilters || {},
      },
      currentValue,
      newCalculation: clientTableFilter?.calculationSelected,
    });
  };

  const onSubmit = async (data) => {
    await getDataFromClientAfterApplyingFilter({
      filterData: {
        mainFilters: {
          ...(filters?.mainFilters || {}),
          ...data,
        },
        statsFilters: {},
      },
      currentValue,
      newCalculation:
        clientTableFilter?.calculationSelected === 'All' ? 'All' : '',
    });
  };

  useImperativeHandle(ref, () => ({
    async clearAllFiltersWhenNoRowIsMatched(currentValue) {
      await clearAllFilter(currentValue);
    },
  }));

  const clearAllFilter = async (newCurrentValue = '') => {
    unCheckAll();
    dispatch(setCheckedRowsData([], 'CLIENT'));
    await getDataFromClientAfterApplyingFilter({
      filterData: {
        mainFilters: {},
        statsFilters: {},
      },
      currentValue: newCurrentValue || currentValue,
      newCalculation: 'All',
    });
    resetAllValues();
    setPageSize(1);
  };

  const handleSync = async () => {
    setSyncLoading(true);
    await getSalesForceClientsAndPositions(dispatch);
    setSyncLoading(false);
    await getDataFromClientAfterApplyingFilter({
      filterData: { mainFilters: {}, statsFilters: {} },
      currentValue,
      newCalculation: clientTableFilter?.calculationSelected,
    });
  };

  const resetAllValues = () => {
    reset({
      clientName: null,
      startDate: null,
      endDate: null,
      clientType: null,
      clientStatus: null,
    });
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#A93E71');
  }, []);

  useEffect(() => {
    _.isEmpty(filters?.mainFilters)
      ? resetAllValues()
      : reset(filters?.mainFilters);
  }, [filters?.mainFilters]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    setCLientTypeOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('clientType') ?? [],
        CLIENT_TYPE_OPTIONS?.map((x) => {
          return { ...x, checkbox: true };
        })
      ),
    ]);
    setCLientStatusOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('clientStatus') ?? [],
        CLIENT_STATUS_OPTIONS?.map((x) => {
          return { ...x, checkbox: true };
        })
      ),
    ]);
  }, [watch('clientType'), watch('clientStatus')]);

  const [salDate, salTime, salAmPm] = (
    dashboardData?.salesForceLastSync
      ? moment(dashboardData?.salesForceLastSync).format('L h:mm A')
      : ''
  ).split(' ');

  return (
    <div>
      <div className={style.mainHeader}>
        <div className={style.innerFlex}>
          <p className={style.attorney}>Clients</p>
          <div className={style.btns}>
            <div className={style.hoverBtn}>
              <Button
                type="button"
                isLoading={syncLoading}
                iconStart={refreshIcon}
                handleClick={handleSync}
                btnClass={style.btnClassRefresh}
              />
              <div className={style.tooltip}>
                <p>
                  Salesforce last synced on {salDate} at {salTime} {salAmPm}
                </p>
              </div>
            </div>
            <ButtonDropdown
              btnBorder={style.btnDropdown}
              currentValue={currentValue}
              onChange={(value) => {
                setPageSize(1);
                setDirectoryFilters((prev) => ({
                  ...prev,
                  page: 1,
                  currentValue: value,
                  dateRange: [null, null],
                  extraParams: {
                    showLoaderOnFullScreen: false,
                  },
                }));
                value !== 'Custom' &&
                  dispatch(
                    setCLientTableFilter({
                      ...clientTableFilter,
                      currentValue: value,
                      dateRange: [null, null],
                    })
                  );
              }}
              options={[
                'Now',
                'Cumulative',
                {
                  Component: CustomDatePicker,
                  props: {
                    key: 'c-custom',
                    isPink: true,
                    value: dateRange,
                    onChange: (newDateRange) => {
                      // this was causing the date range one day before selection issue can be removed if everything is working fine

                      // newDateRange = [
                      //   newDateRange?.[0]
                      //     ? moment(newDateRange?.[0])?.format('YYYY-MM-DD')
                      //     : null,
                      //   newDateRange?.[1]
                      //     ? moment(newDateRange[1])?.format('YYYY-MM-DD')
                      //     : null,
                      // ];

                      dispatch(
                        setCLientTableFilter({
                          ...clientTableFilter,
                          currentValue: 'Custom',
                          dateRange: newDateRange,
                        })
                      );
                      if (newDateRange[1]) {
                        setPageSize(1);
                        setDirectoryFilters((prev) => ({
                          ...prev,
                          page: 1,
                          currentValue: 'Custom',
                          dateRange: newDateRange,
                          extraParams: {
                            showLoaderOnFullScreen: false,
                          },
                        }));
                      }
                    },
                  },
                },
              ]}
              dateRange={dateRange}
              arrowDown={arrowDown}
            />
            <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
              <Link to="/clients/add" style={{ textDecoration: 'none' }}>
                <Button
                  text={'Add Client'}
                  iconStart={plusIcon}
                  btnClass={style.btnClass}
                  type="button"
                />
              </Link>
            </PermissionRestrict>
          </div>
        </div>

        <div className={style.grid}>
          <div className={style.textField}>
            <TextField
              value={search}
              onChange={(e) => {
                const { value: clientName } = e.target;
                dispatch(
                  setCLientTableFilter({
                    ...clientTableFilter,
                    filters: {
                      mainFilters: {
                        ...(filters?.mainFilters || {}),
                        clientName,
                      },
                      statsFilters: filters.statsFilters || {},
                    },
                  })
                );
                debouncedSearch({
                  currentValue,
                  filters: {
                    mainFilters: {
                      ...(filters?.mainFilters || {}),
                      clientName,
                    },
                    statsFilters: filters.statsFilters || {},
                  },
                  newCalculation: clientTableFilter?.calculationSelected,
                });
              }}
              icon={!_.isEmpty(watch('clientName')) ? cross : searchIcon}
              placeholder="Search"
              className={style.field}
              label="Search"
              onClick={clickOnCrossIcon}
            />
          </div>
          <div className={style.datePicker}>
            <DatePicker
              label="Start Date"
              name="startDate"
              id="1"
              placeholder="Now"
              control={control}
              inputClass={style.date}
              isPink
            />
          </div>
          <div className={style.datePicker}>
            <DatePicker
              label="End Date"
              name="endDate"
              id="2"
              placeholder="Now"
              minDate={watch('startDate')}
              control={control}
              inputClass={style.date}
              isPink
            />
          </div>
          <div className={style.statusBar}>
            <SelectBox
              name="clientType"
              control={control}
              options={clientTypeOptions}
              label={'Type'}
              placeholder={'Select'}
              numberBadgeColor={'#A93E71'}
              dynamicClass={style.zDynamicState4}
              isMulti
              showNumber
              currentValue={watch('clientType') || []}
              showSelected
            />
          </div>
          <div className={style.statusBar}>
            <SelectBox
              name="clientStatus"
              numberBadgeColor={'#A93E71'}
              control={control}
              options={clientStatusOptions}
              label={'Client Status'}
              dynamicClass={style.zDynamicState4}
              isMulti
              showNumber
              currentValue={watch('clientStatus') || []}
              showSelected
              placeholder={'Select'}
            />
          </div>

          <div className={style.resetDiv}>
            <Button
              text={'Reset'}
              type="button"
              handleClick={() => {
                clearAllFilter();
              }}
              btnClass={style.check}
              style={{ marginRight: '10px', marginLeft: '10px' }}
            />
            <Button
              text={'Apply'}
              type="button"
              btnClass={style.apply}
              handleClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(React.forwardRef(ClientHeader));
