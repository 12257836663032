/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import moment from 'moment';

import Modal from 'components/modal';
import Button from 'components/button';
import NoRecords from 'components/no-records';
import AddTrainingHistory from './add-training';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { Message } from 'languages';

import useHelper from './helper';
import { ROLES } from 'permissions/constants';

import Trash from 'assets/icons/Trash.svg';
import addIcon from 'assets/images/whiteAddIcon.svg';
import editIcon from 'assets/images/greenEditIcon.svg';
import deleteIcon from 'assets/images/GreenDelIcon.svg';
import downIcon from 'assets/images/greenDownArrow.svg';
import downIcon1 from 'assets/icons/down.svg';
import editIconBlue from 'assets/images/EditBlue.svg';
import deleteIconBlue from 'assets/images/blueDelIcon.svg';
import rightIcon from 'assets/images/greenRightArrow.svg';
import rightIcon1 from 'assets/icons/right-blue.svg';

import style from './training-history.module.scss';
import { A02_SCREEN_MESSAGES } from 'data/userFacingMessages';

const TrainingHistory = ({ barStates, setDisable }) => {
  const {
    toggle,
    trainingHistory,
    openEdit,
    open,
    edit,
    setToggle,
    handleDelete,
    setOpenEdit,
    setOpen,
    setEdit,
    traingingCalculations,
    getTrainingData,
  } = useHelper({ barStates });
  return (
    <>
      <div className={style.wraper}>
        {trainingHistory.length < 1 ? (
          !openEdit && (
            <NoRecords
              icon={true}
              btnText={'Add Training History'}
              onClick={() => {
                setOpenEdit(true);
                setDisable(true);
              }}
              extraPermissions={[ROLES.ATTORNEY]}
              noRecord={
                <Message id="A02_SCREEN_MESSAGES.emptyPlaceholder.trainingHistory" />
              }
            />
          )
        ) : (
          <>
            <div className={style.scrollDiv}>
              <div className={style.innerScrollDiv}>
                <div className={style.headerBtn}>
                  <div className={style.headerBtnContent}>
                    <span className={style.btnText}>
                      Completed Courses:{' '}
                      {traingingCalculations.completedCourses}
                    </span>
                  </div>
                  <div className={style.headerBtnContent}>
                    <span className={style.btnText}>
                      Completed Courses Hours:{' '}
                      {traingingCalculations.completedCoursesHours}
                    </span>
                  </div>
                  <div className={style.headerBtnContent}>
                    <span className={style.btnText}>
                      Total CLE Credits/Birthdate:{' '}
                      {traingingCalculations.totalCleCreditsBirthDate}
                    </span>
                  </div>
                  <div className={style.headerBtnContent}>
                    <span className={style.btnText}>
                      Total Live Hours/Birthdate:{' '}
                      {traingingCalculations.totalLiveHoursBirthDate}
                    </span>
                  </div>
                </div>

                <div className={style.tblHeader}>
                  <span
                    className={style.tblElements}
                    style={{ marginLeft: '10px' }}
                  >
                    Course Name
                  </span>
                  <span className={style.tblElements}>Date Completed</span>
                  <span className={style.tblElements}>Hours</span>
                  <span className={style.tblElements}>Live</span>
                  <span className={style.tblElements}>CLE Credits</span>
                  <span className={style.tblElements}>CLE State</span>
                  <span className={style.tblElements}>CLE Subject</span>
                  <span className={style.tblElements}>Subject Matter</span>
                  <span></span>
                </div>

                {trainingHistory?.map((item, index) => {
                  return (
                    <Fragment key={item._id}>
                      {item._id === edit ? (
                        <AddTrainingHistory
                          setDisable={setDisable}
                          data={item}
                          setToggle={setToggle}
                          setEdit={setEdit}
                          onSubmit={getTrainingData}
                        />
                      ) : (
                        <div
                          className={style.tblBody}
                          onClick={() =>
                            setToggle((prev) => (prev == index ? -1 : index))
                          }
                        >
                          <div
                            style={{
                              marginLeft: '10px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <img
                              src={toggle === index ? downIcon : rightIcon}
                              style={{ marginRight: '10px' }}
                            />

                            <span
                              className={style.tblValue}
                              style={{
                                overflowWrap: 'anywhere',
                                marginRight: '5px',
                              }}
                            >
                              {' '}
                              {item.courseName}
                            </span>
                          </div>
                          <span className={style.tblValue}>
                            {item.courseCompletedDate
                              ? moment(item.courseCompletedDate).format(
                                  'MM/DD/YYYY'
                                )
                              : '-'}
                          </span>
                          <span className={style.tblValue}>
                            {item.courseHours}
                          </span>
                          <span className={style.tblValue}>
                            {item.liveHours}
                          </span>
                          <span className={style.tblValue}>{item.credits}</span>
                          <span className={style.tblValue}>{item.state}</span>
                          <span className={style.tblValue}>{item.subject}</span>
                          <span className={style.tblValue}>
                            {item.courseSubjMatter}
                          </span>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'end',
                              paddingRight: 20,
                            }}
                          >
                            <PermissionRestrict
                              roles={[ROLES.ADMIN, ROLES.EDITOR]}
                            >
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEdit(item._id);
                                  setToggle(false);
                                  setDisable(true);
                                }}
                                src={editIcon}
                                width={20}
                                style={{ marginRight: '10px' }}
                              />
                              <img
                                src={deleteIcon}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpen(item._id);
                                }}
                              />
                            </PermissionRestrict>
                            <PermissionRestrict roles={[ROLES.ATTORNEY]}>
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEdit(item._id);
                                  setToggle(false);
                                  setDisable(true);
                                }}
                                src={editIcon}
                                width={20}
                                style={{ marginRight: '10px' }}
                              />
                              <img
                                src={deleteIcon}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setOpen(item._id);
                                }}
                              />
                            </PermissionRestrict>
                          </div>
                        </div>
                      )}
                      {index === toggle && (
                        <>
                          <div className={style.editSection}>
                            <PermissionRestrict roles={[ROLES.ATTORNEY]}>
                              <div className={style.sectionOneAttorney}>
                                <h5>My Feedback</h5>
                                <p
                                  style={{
                                    overflowWrap: 'anywhere',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  {item.myFeedback}
                                </p>
                              </div>

                              <div className={style.sectionTwoAttorney}>
                                <h5>CLO Feedback for Attorney</h5>
                                <p
                                  style={{
                                    overflowWrap: 'anywhere',
                                    marginTop: '8px',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  {item.cloFeedback}
                                </p>
                              </div>
                            </PermissionRestrict>
                            <PermissionRestrict
                              roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}
                            >
                              <div className={style.sectionOne}>
                                <h5>My Feedback</h5>
                                <p
                                  style={{
                                    overflowWrap: 'anywhere',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  {item.myFeedback}
                                </p>
                              </div>

                              <div className={style.sectionTwo}>
                                <h5>CLO Feedback for Attorney</h5>
                                <p
                                  style={{
                                    overflowWrap: 'anywhere',
                                    marginTop: '8px',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  {item.cloFeedback}
                                </p>
                              </div>

                              {/* <div className={style.editSectionInternal}> */}
                              <div className={style.sectionThree}>
                                <h5>CLO Feedback for Internal Use</h5>
                                <p
                                  style={{
                                    overflowWrap: 'anywhere',
                                    marginTop: '8px',
                                    whiteSpace: 'pre-wrap',
                                  }}
                                >
                                  {item.cloFeedbackInternal}
                                </p>
                                {/* </div> */}
                              </div>
                            </PermissionRestrict>
                          </div>
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </>
        )}
        {openEdit && (
          <AddTrainingHistory
            setToggle={setOpenEdit}
            setEdit={setEdit}
            setDisable={setDisable}
            onSubmit={getTrainingData}
          />
        )}
        {trainingHistory?.length >= 1 && (
          <div>
            {!openEdit && (
              <PermissionRestrict
                roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.ATTORNEY]}
              >
                <Button
                  text={'Add Training Course'}
                  btnClass={style.btnWraper}
                  iconStart={addIcon}
                  handleClick={() => {
                    setOpenEdit(true);
                    setDisable(true);
                    setEdit(false);
                  }}
                />
              </PermissionRestrict>
            )}
          </div>
        )}

        <Modal open={open} handleClose={() => setOpen(false)}>
          <div className={style.modalHeader}>
            <img src={Trash} height={48} width={48} />
            <h3 className={style.deleteText}>Delete Training History</h3>
            <p>Are you sure you want to delete this record?</p>
            <Button
              btnClass={style.buttonStyle}
              text={
                A02_SCREEN_MESSAGES.popups.deleteConfirm.trainingHistory.yes
              }
              handleClick={handleDelete}
            />
            <h3 className={style.cancelText} onClick={() => setOpen(false)}>
              {A02_SCREEN_MESSAGES.popups.deleteConfirm.trainingHistory.no}
            </h3>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default TrainingHistory;
