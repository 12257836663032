import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import DatePicker from 'components/date-picker';
import TextField from 'components/textfield';
import PermissionRestrict from 'hoc/PermissionRestrict';

import delIcon from 'assets/icons/pinkTrash.svg';
import { ROLES } from 'permissions/constants';

import style from './directory.module.scss';

import SelectBox from 'components/select-box';
import Tags from 'components/tags';
import {
  CLIENT_STATUS_OPTIONS,
  CLIENT_TYPE_OPTIONS,
  clientTypeOptionsKeys,
} from 'constants';
import { exportClientCSV } from 'services/client-page';

export const getDefaultColumnOfClientTable = (setDelModal, setDeleteRowId) => {
  const defaultColumns = [
    {
      name: 'Client Name',
      key: 'clientName',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '246px', height: '58px' },
      widthInEditMode: { width: '250px' },
      showPointer: true,
      displayIcon: true,

      render: (
        { value, inputName, register, errorMessage, row },
        column,
        isEditMode
      ) => (
        <>
          {isEditMode ? (
            <TextField
              name={inputName}
              register={register}
              errorMessage={errorMessage}
            />
          ) : (
            <div className={style.rounded}>
              <span className={style?.attorneyColumnEllipsis}>
                <div>{value}</div>
              </span>
              {value && value.length > 10 && (
                <div className={style.tooltip}>
                  <div className={style.tooltipChild}>
                    <p className={style.tooltipText}>{value}</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      name: 'Status',
      key: 'clientStatus',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '123px', height: '58px' },
      widthInEditMode: { width: '220px' },
      showPointer: true,
      displayIcon: true,

      render: ({ value, inputName, watch, control }, column, isEditMode) => (
        <>
          {isEditMode ? (
            <SelectBox
              name={inputName}
              control={control}
              badge
              options={CLIENT_STATUS_OPTIONS}
              placeholder={'Select'}
              numberBadgeColor={'#A93E71'}
              dynamicClass={style.zDynamicState4}
              currentValue={watch(inputName)}
              isEditMode={isEditMode}
            />
          ) : (
            <Tags
              key="tags"
              text={value
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
              color={
                CLIENT_STATUS_OPTIONS?.find(
                  (findColor) => findColor?.value === value
                )?.color
              }
            />
          )}
        </>
      ),
    },
    {
      name: 'Contract Start Date',
      key: 'contractDateStart',
      type: 'date',
      editable: true,
      hidden: false,
      showPointer: true,
      widthAndHeight: { width: '123px', height: '58px' },
      widthInEditMode: { width: '180px' },
      displayIcon: true,
      render: (
        { value, errorMessage, control, row, inputName },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode ? (
              <DatePicker
                id={row?._id}
                name={inputName}
                control={control}
                errorMessage={errorMessage}
                isPink
              />
            ) : (
              <span>
                {_.isEmpty(value) ? '-' : moment(value).format('MM/DD/YYYY')}
              </span>
            )}
          </>
        );
      },
    },
    {
      name: 'Contract End Date',
      key: 'contractDateEnd',
      type: 'date',
      editable: true,
      hidden: false,
      sortKey: 'contractDateEnd',
      widthAndHeight: { width: '123px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,

      render: (
        { value, errorMessage, control, row, inputName },
        column,
        isEditMode
      ) => (
        <>
          {isEditMode ? (
            <DatePicker
              control={control}
              name={inputName}
              errorMessage={errorMessage}
              isPink
            />
          ) : (
            <span>
              {_.isEmpty(value) ? '-' : moment(value).format('MM/DD/YYYY')}
            </span>
          )}
        </>
      ),
    },

    {
      name: 'Placements',
      key: 'placement',
      sortKey: 'placements',
      type: 'text',
      editable: false,
      hidden: false,
      widthInEditMode: { width: '123px' },
      widthAndHeight: { width: '100px', height: '58px' },
      showPointer: true,
      displayIcon: true,

      render: (
        { value, inputName, register, errorMessage, row },
        column,
        isEditMode
      ) => (
        <>
          {isEditMode ? (
            <TextField
              value={value?.total}
              name={inputName}
              register={register}
              errorMessage={errorMessage}
            />
          ) : (
            <span>{row?.placements}</span>
          )}
        </>
      ),
    },
    {
      name: 'Open Positions',
      key: 'openPositions',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '123px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,

      render: (
        { value, inputName, register, errorMessage, row },
        column,
        isEditMode
      ) => (
        <div style={{ paddingLeft: '15px' }}>
          {isEditMode ? (
            <TextField
              name={inputName}
              register={register}
              errorMessage={errorMessage}
            />
          ) : parseInt(row?.openPositions || 0) >
            row?.postionInterviewingNumber ? (
            <div className={style.openPositions}>
              {parseInt(row?.openPositions || 0)}
            </div>
          ) : (
            <div className={style.openPositionsZero}>
              {parseInt(row?.openPositions || 0)}
            </div>
          )}
        </div>
      ),
    },
    {
      name: 'Positions Interviewing',
      key: 'postionInterviewingNumber',
      type: 'text',
      hidden: false,
      editable: false,
      widthAndHeight: { width: '123px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,

      render: (
        { value, inputName, register, errorMessage, row },
        column,
        isEditMode
      ) => (
        <>
          {isEditMode ? (
            <TextField
              name={inputName}
              register={register}
              errorMessage={errorMessage}
            />
          ) : (
            <span>{value}</span>
          )}
        </>
      ),
    },
    {
      name: 'Type',
      key: 'clientType',
      type: 'date',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '123px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,

      render: ({ value, inputName, control, watch }, column, isEditMode) => (
        <>
          {isEditMode ? (
            <SelectBox
              name={inputName}
              control={control}
              options={CLIENT_TYPE_OPTIONS}
              numberBadgeColor={'#A93E71'}
              dynamicClass={style.zDynamicState4}
              isEditMode={isEditMode}
            />
          ) : (
            <span>
              {clientTypeOptionsKeys?.find((x) => x?.label === value)?.value}
            </span>
          )}
        </>
      ),
    },

    {
      name: '',
      key: 'actions',
      hidden: false,
      type: 'text',
      editable: false,
      widthAndHeight: { width: '56px', height: '58px' },
      widthInEditMode: { width: '56px' },
      render: ({ row, navigate }, column, isEditMode) => (
        <>
          <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
            <div className={style.rounded}>
              <img
                src={delIcon}
                alt=""
                className={style.imgDel}
                onClick={() => {
                  if (!row?.deleted) {
                    setDeleteRowId(row?._id);
                    setDelModal(true);
                  }
                }}
              />
            </div>
          </PermissionRestrict>
        </>
      ),
    },
  ];
  return defaultColumns;
};

const showTooltipOnAlreadyDeletedField = (deleted) => {
  return (
    deleted && (
      <>
        <div className={style.tooltip} style={{ width: '200px', left: '0' }}>
          <div className={style.tooltipChild}>
            <p className={style.tooltipText}>
              This field is accessible when the selected date is “Now”.
            </p>
          </div>
        </div>
      </>
    )
  );
};

export const getSideBarLinksWithCalculation = (calculations) => {
  return [
    {
      name: 'All',
      number: calculations?.allClients || 0,
      class: style.mainElement,
      array: [
        {
          name: 'Clients',
          number: calculations?.activeClients || 0,
          showDotIcon: true,
          multiDots: true,
        },
      ],
      array1: [
        {
          name: 'Law Firms',
          number: calculations?.lawFirms || 0,
          showDash: true,
          class: style.ar1,
          labelContainerClass: style.labelContainerClass,
        },
        {
          name: 'Corporate',
          number: calculations?.corporate || 0,
          showDash: true,
          class: style.ar1,
          labelContainerClass: style.labelContainerClass,
        },
        {
          name: 'Hybrid',
          number: calculations?.hybrid || 0,
          showDash: true,
          class: style.ar1,
          labelContainerClass: style.labelContainerClass,
        },
      ],
    },
    {
      array: [
        {
          name: 'Opportunities',
          number: calculations?.opportunityPositions,
          showDotIcon: true,
          multiDots: true,
        },
      ],
    },
    {
      name: 'Headhunter (cumulative)',
      number: calculations?.headHunter,
      class: `${style.innerSecondDiv} ${style.mainElement}`,
    },
    {
      name: 'Clients with Open Positions',
      number: calculations?.clientWithOpenPosition,
      class: `${style.innerSecondDiv} ${style.mainElement}`,
    },
  ];
};

export const checkAllTheValuesInFilterIsEmpty = (filter) => {
  if (
    _.size(_.flatten(Object.values(filter)?.filter((x) => x))?.filter((y) => y))
  )
    return false;
  else return true;
};

export const moveSelctedDropdownStatusToTop = (selectedValue, options) => {
  const deleteShowLineFromDefualtOptionsArray = options?.filter(
    (option) => option?.label !== 'showLine' && option?.value !== 'showLine'
  );
  const checkSelectedValue = _.isEmpty(selectedValue)
    ? []
    : !_.isArray(selectedValue)
    ? [selectedValue]
    : selectedValue;
  return !_.isEmpty(selectedValue)
    ? [
        ...deleteShowLineFromDefualtOptionsArray.filter((item) =>
          checkSelectedValue?.includes(item.value)
        ),
        { label: 'showLine', value: 'showLine', isdisabled: true },
        ...deleteShowLineFromDefualtOptionsArray.filter(
          (item) => !checkSelectedValue?.includes(item.value)
        ),
      ]
    : [
        ...deleteShowLineFromDefualtOptionsArray.filter((item) =>
          checkSelectedValue?.includes(item.value)
        ),
        ...deleteShowLineFromDefualtOptionsArray.filter(
          (item) => !checkSelectedValue?.includes(item.value)
        ),
      ];
};

export const downloadClientsCSV = async (
  rows,
  sortBy,
  orderBy,
  filters,
  currentValue,
  dateRange,
  columns
) => {
  const arrToExport = [
    ...(rows?.filter((x) => x?.checked) ?? []),
    ...(rows?.filter((y) => !y?.checked) ?? []),
  ];
  const checkedRowIds =
    arrToExport?.filter((x) => x?.checked)?.map((y) => y?._id) ?? [];

  const response = await exportClientCSV(
    checkedRowIds,
    sortBy,
    orderBy,
    filters,
    currentValue,
    dateRange,
    columns.filter((x) => x?.hidden === false && x?.name).map((x) => x?.name)
  );

  const fileURL = window.URL.createObjectURL(new Blob([response?.data]));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  const currentDate = moment().format('MMM DD, YYYY HH:mm:ss a');
  fileLink.setAttribute('download', `Client Directory [${currentDate}].csv`);
  fileLink.setAttribute('target', '_blank');
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
};
