import React from 'react';
import style from './loading.module.scss';
const Loading = ({ loaderClass, styleClass }) => {
  return (
    <>
      <div
        className={`${style.loader} ${loaderClass}`}
        style={styleClass}
      ></div>
    </>
  );
};
export default Loading;
