import axios from 'utils/axios';
import { API_URLS } from './url-paths';

const login = (obj) => {
  return axios.post(API_URLS.LOGIN, obj);
};

//add Users
const setUser = (obj, id) => {
  let url = API_URLS.USER;
  if (id) url = `${API_URLS.USER}/${id}`;
  return axios[id ? 'put' : 'post'](url, obj);
};
//get users
const getUser = (obj) => {
  return axios.get(API_URLS.USER, obj);
};

const checkAuth = () => {
  return axios.get(API_URLS.GET_ROLE);
};

const forgotPassword = (obj) => {
  return axios.post(API_URLS.FORGOT_PASSWORD, obj);
};

const changePassword = (obj) => {
  return axios.post(API_URLS.CHANGE_PASSWORD, obj);
};

const resetPassword = (obj) => {
  return axios.post(API_URLS.RESET_PASSWORD, obj);
};

const deleteUser = (id) => {
  const url = `${API_URLS.USER}/${id}`;
  return axios.delete(url);
};

const getSystemPracticeGroups = (obj) => {
  return axios.get(API_URLS.GET_SYSTEM_PRACTICE_GROUPS, obj);
};
const deleteSystemPracticeGroups = (id) => {
  return axios.delete(`${API_URLS.DELETE_SYSTEM_PRACTICE_GROUPS}/${id}`);
};

const addSystemPracticeGroups = (obj, id) => {
  let url = API_URLS.ADD_SYSTEM_PRACTICE_GROUPS;
  if (id) url = `${API_URLS.ADD_SYSTEM_PRACTICE_GROUPS}/${id}`;
  return axios[id ? 'put' : 'post'](url, obj);
};

const getSystemLocations = (obj) => {
  return axios.get(API_URLS.GET_SYSTEM_LOCATIONS, obj);
};
const addSystemLocations = (obj, id) => {
  let url = API_URLS.ADD_SYSTEM_LOCATIONS;
  if (id) url = `${API_URLS.ADD_SYSTEM_LOCATIONS}/${id}`;
  return axios[id ? 'put' : 'post'](url, obj);
};

const deleteSystemLocation = (id) => {
  return axios.delete(`${API_URLS.DELETE_SYSTEM_LOCATIONS}/${id}`);
};

const getSystemBarStates = (obj) => {
  return axios.get(API_URLS.GET_SYSTEM_BAR_STATES, obj);
};
const addSystemBarStates = (obj, id) => {
  let url = API_URLS.ADD_SYSTEM_BAR_STATES;
  if (id) url = `${API_URLS.ADD_SYSTEM_BAR_STATES}/${id}`;
  return axios[id ? 'put' : 'post'](url, obj);
};
const deleteSystemBarStates = (id) => {
  return axios.delete(`${API_URLS.DELETE_SYSTEM_BAR_STATES}/${id}`);
};

const getSystemWeights = (obj) => {
  return axios.get(API_URLS.GET_SYSTEM_WEIGHTS, obj);
};
const editAlgoWeights = (id, obj) => {
  let url = API_URLS.EDIT_ALGO_WEIGHT;
  if (id) url = `${API_URLS.EDIT_ALGO_WEIGHT}/${id}`;
  return axios[id ? 'put' : 'post'](url, obj);
};

//dashboard
const getDashBoard = (obj) => {
  return axios.get(API_URLS.GET_DASHBOARD, {
    params: obj,
  });
};

export const userService = {
  login,
  getUser,
  setUser,
  getSystemPracticeGroups,
  addSystemPracticeGroups,
  getSystemLocations,
  addSystemLocations,
  getSystemBarStates,
  addSystemBarStates,
  getSystemWeights,
  forgotPassword,
  resetPassword,
  changePassword,
  deleteSystemLocation,
  deleteSystemBarStates,
  editAlgoWeights,
  deleteSystemPracticeGroups,
  deleteUser,
  checkAuth,
  getDashBoard,
};
