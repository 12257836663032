/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateWeight } from 'redux/system-values/system-values-actions';

import { createNotification } from 'components/react-notification';
import Button from 'components/button';
import TextField from 'components/textfield';
import Modal from 'components/modal';

import saveIcon from 'assets/images/save.svg';
import editIcon from 'assets/images/Edit.svg';
import crossIcon from 'assets/icons/cross.svg';

import style from './default-weight.module.scss';

const DefaultAlgoWeight = ({ setEditPopUp, editPopUp, values }) => {
  const [weights, setWeights] = useState(values);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setWeights(values);
  }, [values]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      let isBelow0 = false;
      const sum = Object.values(keys).reduce((acc, { key }) => {
        if (Number(weights[key]) < 0) isBelow0 = key;
        return acc + Number(weights[key]);
      }, 0);

      if (isBelow0) {
        const value = keys.find((e) => e.key == isBelow0).label;
        createNotification(
          'error',
          'Error',
          value + " can't be negative!",
          3500
        );
      } else if (sum !== 100) {
        createNotification('error', 'Error', 'Sum is not equal to 100', 3500);
      } else {
        await dispatch(updateWeight(weights, weights._id));
        setEditMode(false);
      }
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error',
        3500
      );
    }
    setLoading(false);
  };

  const closeWarningModal = () => {
    setEditMode(true);
    setOpenConfirmationModal(false);
  };

  return (
    <>
      <div className={style.maxWidthClass}>
        <div className={style.main}>
          <div className={style.tableHeader}>
            <p className={style.practiceText}>Item</p>
            <p className={style.practiceText}>System Weights</p>
          </div>
          {keys?.map((ele, index) => (
            <>
              <div className={style.card} key={index}>
                <div className={style.innerChild}>
                  {/* <p>
                    {ele?.label}
                  </p> */}
                  <div className={style.rounded}>
                    <span className={style?.divElipsis}>
                      <p>{ele?.label}</p>
                    </span>
                    {ele?.label && ele?.label.length > 20 && (
                      <div className={style.tooltip}>
                        <div className={style.tooltipChild}>
                          <p className={style.tooltipText}>{ele?.label}</p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={style.inner}>
                    {editMode ? (
                      <div className={`${style.field} ${style.col}`}>
                        <TextField
                          placeholder="10"
                          value={
                            weights[ele?.key] || weights[ele?.key] === 0
                              ? weights[ele?.key]
                              : ''
                          }
                          type="number"
                          onChange={(e) =>
                            setWeights({
                              ...weights,
                              [ele.key]:
                                e.target.value !== ''
                                  ? Number(e.target.value)
                                  : '',
                            })
                          }
                          name={ele.key}
                          className={style.inputClass}
                        />
                        %
                      </div>
                    ) : (
                      <p className={`${style.weight} ${style.col}`}>
                        {weights?.[ele?.key]}%
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
      <div className={style.innerFlex1}>
        {editMode ? (
          <Button
            text="Save"
            iconStart={saveIcon}
            btnClass={style.btn}
            isLoading={loading}
            handleClick={onSubmit}
          />
        ) : (
          <Button
            text="Edit"
            btnClass={style.btn}
            iconStart={editIcon}
            handleClick={() => setOpenConfirmationModal(true)}
            type="button"
          />
        )}
        <p>
          Changing the default algorithm weights will affect all <u>future</u>{' '}
          matches and scoring calculations.
        </p>
      </div>
      <Modal handleClose={closeWarningModal} open={openConfirmationModal}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '14px',
          }}
        >
          <img
            src={crossIcon}
            onClick={closeWarningModal}
            style={{
              height: '12px',
              cursor: 'pointer',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <p className={style.modalTitle}>
            Changing the default algorithm weights will affect all <u>future</u>{' '}
            matches and scoring calculations.
          </p>
          <Button
            handleClick={closeWarningModal}
            text="Ok"
            btnClass={style.okButton}
          />
        </div>
      </Modal>
    </>
  );
};

export default DefaultAlgoWeight;

const keys = [
  {
    key: 'location',
    label: 'Attorney Location',
  },
  {
    key: 'status',
    label: 'Attorney Status',
  },
  {
    key: 'bar',
    label: 'Attorney Bar',
  },
  {
    key: 'practiceGroup',
    label: 'Attorney Practice Group',
  },
];
