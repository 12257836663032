import React, { useEffect, useState } from 'react';

import Container from 'components/container';
import Sidebar from './sidebar';

import { useDispatch, useSelector } from 'react-redux';
import { setStatsTableOpen } from 'redux/stats-table/stats-table-actions';
import style from './layout.module.scss';

const Layout = ({
  bgClass,
  current,
  children,
  openArrow,
  textNumber,
  setCurrent,
  activeClass,
  sidebarLinks,
  setOpenArrow,
  inactiveClass,
  leftIconArrow,
}) => {
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state?.statsTable);

  const [openSidebar, setOpenSidebar] = useState();
  const [openSidebarMobile, setOpenSidebarMobile] = useState();

  useEffect(() => {
    setOpenSidebar(open);
    setOpenSidebarMobile(open);
  }, [open]);

  return (
    <div className={`${style.layoutWrapper} ${bgClass}`}>
      <aside
        className={
          openSidebar ? `${style.sideBarOpen} ${style.aside}` : style.side
        }
        style={{ paddingLeft: '0px' }}
      >
        <div className={style.hiddenSmall}>
          <Sidebar
            openSidebar={openSidebar}
            setOpenSidebar={() => {
              dispatch(setStatsTableOpen(!openSidebar));
              setOpenSidebar((prev) => !prev);
            }}
            textNumber={textNumber}
            leftIconArrow={leftIconArrow}
            activeClass={activeClass}
            inactiveClass={inactiveClass}
            sidebarLinks={sidebarLinks}
            active={current}
            setActive={setCurrent}
            openArrow={openArrow}
            setOpenArrow={setOpenArrow}
          />
        </div>
        <div className={style.showSmall}>
          {openSidebarMobile && (
            <div
              className={style.backdropDiv}
              onClick={() => {
                dispatch(setStatsTableOpen(false));
                setOpenSidebarMobile(false);
              }}
            ></div>
          )}
          <div className={style.innerSide}>
            <Sidebar
              openSidebar={openSidebarMobile}
              setOpenSidebar={() => {
                dispatch(setStatsTableOpen(!setOpenSidebarMobile));
                setOpenSidebarMobile((prev) => !prev);
              }}
              textNumber={textNumber}
              leftIconArrow={leftIconArrow}
              activeClass={activeClass}
              inactiveClass={inactiveClass}
              sidebarLinks={sidebarLinks}
              openArrow={openArrow}
              setOpenArrow={setOpenArrow}
              setActive={setCurrent}
              active={current}
            />
          </div>
        </div>
      </aside>
      <main style={{ marginLeft: openSidebar ? '268px' : '20px' }}>
        <Container className={style.containerStyle}>{children}</Container>
      </main>
    </div>
  );
};

export default Layout;
