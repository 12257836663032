/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from 'components/button';
import Container from 'components/container';
import AvatarUpload from 'components/edit-avatar';
import SelectBox from 'components/select-box';
import TextField from 'components/textfield';

import { DEMO_OPTIONS, GENDER_OPTIONS } from 'constants';

import { yupResolver } from '@hookform/resolvers/yup';
import uploadIcon from 'assets/images/upload-blue.svg';
import delIcon from 'assets/icons/del-blue.svg';
import { createNotification } from 'components/react-notification';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAttorneyReduxData } from 'redux/attorney/attorney-actions';
import { setAttorneyOverview } from 'services/attorney-profile';
import * as yup from 'yup';
import style from './form.module.scss';

const AttorneyForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const attorneyData = useSelector((state) => state.attorney.attorneyData);
  const userData = useSelector((state) => state.user.userData);
  const {
    control,
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { practiceGroups, locations } = useSelector(
    (state) => state.systemValues
  );

  const praticeGroup = watch('attorneyPracticeGroup');
  const [choice1, choice2] = praticeGroup || [];

  const attorneyLocations = watch('attorneyLocation');
  const [optionA, optionB, optionC] = attorneyLocations || [];

  useEffect(() => {
    if (!attorneyData) return;
    reset({
      ...attorneyData.attorney,
    });
  }, [attorneyData]);

  const onSubmit = async (values) => {
    const {
      profileImage,
      attorneyClassRank,
      attorneyDemo,
      attorneyGender,
      attorneyPracticeGroup,
      attorneyLocation,
    } = values;

    try {
      await setAttorneyOverview(
        {
          attorneyId: attorneyData?.attorney?._id,
          profileImage: !profileImage
            ? undefined
            : profileImage.startsWith('http')
            ? undefined
            : profileImage,
          attorneyClassRank,
          attorneyDemo,
          attorneyGender,
          attorneyPracticeGroup: attorneyPracticeGroup.filter((e) => e),
          attorneyLocation: attorneyLocation.filter((e) => e),
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
        },
        attorneyData?.attorney?._id
      );
      dispatch(getAttorneyReduxData(userData.email));
      navigate('/barhistoryForm');
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          err?.message ||
          'Server Error'
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <div className={style.mainDiv}>
          <div className={style.mainDiv2}>
            <p className={style.p3}>
              Your Picture{' '}
              <span className={style.p4}>(Please upload your photo)</span>
            </p>
            <div
              style={{
                width: 'fit-content',
                borderRadius: '50%',
                cursor: 'pointer',
              }}
            >
              <AvatarUpload
                control={control}
                hideIcon
                name={'profileImage'}
                uploadIcon={uploadIcon}
                delIcon={delIcon}
                color={'#223149'}
              />
            </div>
            <p className={style.p5}>Tell us a little more about yourself</p>
            <div className={style.childDiv}>
              <div className={style.attorneyEditFields}>
                <SelectBox
                  name="attorneyDemo"
                  control={control}
                  isMulti
                  options={DEMO_OPTIONS}
                  placeholder="Select"
                  label={'Demo (Select all that apply) *'}
                  errorMessage={errors?.attorneyDemo?.message}
                />
              </div>
              <div className={style.attorneyEditFields}>
                <SelectBox
                  name="attorneyGender"
                  control={control}
                  options={GENDER_OPTIONS}
                  label={'Gender *'}
                  placeholder="Select"
                  errorMessage={errors?.attorneyGender?.message}
                />
              </div>
              <div className={style.attorneyEditFields}>
                <TextField
                  placeholder="Enter Class Rank"
                  className={style.field}
                  register={register}
                  name={'attorneyClassRank'}
                  type="number"
                  label="Class Rank"
                  errorMessage={errors?.attorneyClassRank?.message}
                />
              </div>
            </div>

            <p className={style.p6}>
              What are your position placement preferences?
            </p>
            <p className={style.p7}>Practice group</p>
            <p className={style.p8}>
              Rank your practice group preferences. You must choose at least
              one.
            </p>
            <div className={style.childDiv}>
              <div className={style.attorneyEditFields}>
                <SelectBox
                  hideIndicator={true}
                  placeholder="Enter 1st Choice"
                  className={style.field}
                  label="1st Choice*"
                  control={control}
                  name={'attorneyPracticeGroup.0'}
                  options={practiceGroups
                    ?.filter((el) => el?._id !== choice2)
                    ?.map((e) => ({
                      label: e.practiceGroup,
                      value: e._id,
                    }))}
                  errorMessage={errors?.attorneyPracticeGroup?.message}
                />
              </div>
              <div className={style.attorneyEditFields}>
                <SelectBox
                  hideIndicator={true}
                  placeholder="Enter 2nd Choice"
                  className={style.field}
                  label="2nd Choice"
                  control={control}
                  name={'attorneyPracticeGroup.1'}
                  options={practiceGroups
                    ?.filter((el) => el?._id !== choice1)
                    ?.map((e) => ({
                      label: e.practiceGroup,
                      value: e._id,
                    }))}
                />
              </div>
            </div>

            <p className={style.p7}>Location</p>
            <p className={style.p8}>
              Rank your location preferences. You may be required to pass the
              bar in the location in which you are placed.
            </p>
            <div className={style.childDiv}>
              <div className={style.attorneyEditFields}>
                <SelectBox
                  hideIndicator={true}
                  placeholder="Enter 1st Choice"
                  className={style.field}
                  label="1st Choice (Current)*"
                  control={control}
                  name={'attorneyLocation.0'}
                  options={locations
                    ?.filter((el) => el?._id !== optionC && el?._id !== optionB)
                    ?.map((e) => ({
                      label: e.location,
                      value: e._id,
                    }))}
                  errorMessage={errors?.attorneyLocation?.message}
                />
              </div>
              <div className={style.attorneyEditFields}>
                <SelectBox
                  hideIndicator={true}
                  placeholder="Enter 2nd Choice"
                  className={style.field}
                  label="2nd Choice"
                  control={control}
                  name={'attorneyLocation.1'}
                  options={locations
                    ?.filter((el) => el?._id !== optionA && el?._id !== optionC)
                    ?.map((e) => ({
                      label: e.location,
                      value: e._id,
                    }))}
                />
              </div>
              <div className={style.attorneyEditFields}>
                <SelectBox
                  hideIndicator={true}
                  placeholder="Enter 3rd Choice"
                  className={style.field}
                  label="3rd Choice"
                  control={control}
                  name={'attorneyLocation.2'}
                  options={locations
                    ?.filter((el) => el?._id !== optionA && el?._id !== optionB)
                    ?.map((e) => ({
                      label: e.location,
                      value: e._id,
                    }))}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={style.buttonDiv}>
          <Button
            text={'Next'}
            btnClass={style.btnClass}
            isLoading={isSubmitting}
          />
        </div>
      </Container>
    </form>
  );
};
export default AttorneyForm;

const schema = yup.object({
  attorneyDemo: yup
    .array()
    .of(yup.string())
    .min(1, 'Demo field must have at least 1 item')
    .required('Demo is required'),
  attorneyGender: yup.string().required('Please select your gender'),
  attorneyClassRank: yup
    .mixed()
    .test('checkIfIsCorrect', 'Class rank should be a number', (value) =>
      value ? !isNaN(value) : true
    )
    .optional(),
  attorneyPracticeGroup: yup
    .array()
    .test(
      'isValid',
      'Please select (at least) the 1st practice group',
      (value) => {
        return (
          value[0] !== null &&
          value[0] !== undefined &&
          value.filter((e) => e).length > 0
        );
      }
    )
    .required('Please at least one practice group'),
  attorneyLocation: yup
    .array()
    .test('isValid', 'Please select (at least) the 1st location', (value) => {
      return (
        value[0] !== null &&
        value[0] !== undefined &&
        value.filter((e) => e).length > 0
      );
    })
    .required('Please select at least one location'),
});
