/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import Button from 'components/button';
import Container from 'components/container';
import Modal from 'components/modal';
import AddTrainingHistory from './add-training-course';

import editIcon from 'assets/icons/blue-edit.svg';
import Trash from 'assets/icons/blueTrash.svg';
import deleteIcon from 'assets/icons/del-blue.svg';
import downIcon from 'assets/images/blueDownArrow.svg';
import rightIcon from 'assets/images/blueRightArrow.svg';
import addIcon from 'assets/images/whiteAddIcon.svg';

import Loader from 'components/loader';
import NoRecords from 'components/no-records';
import { A02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import moment from 'moment';
import { ROLES } from 'permissions/constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAttorneyUpdatedRedux } from 'redux/attorney/attorney-actions';
import useHelper from './helper';
import style from './training-history.module.scss';

const TrainingHistoryForm = () => {
  const navigate = useNavigate();
  const [redirectLoading, setRedirectLoading] = useState();
  const dispatch = useDispatch();
  const {
    toggle,
    trainingHistory,
    openEdit,
    open,
    edit,
    setToggle,
    handleDelete,
    setOpenEdit,
    setOpen,
    setEdit,
    onAdd,
    onEdit,
    traingingCalculations,
    attorney,
    isLoading,
  } = useHelper();

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#304059');
  }, []);

  return (
    <div>
      <Container>
        <p className={style.p3}>Training History</p>
        <p className={style.p4}>
          Keep track of the courses you have taken and your feed back on them.
        </p>

        {isLoading ? (
          <div className={style.loading}>
            <Loader />
          </div>
        ) : (
          <>
            {trainingHistory.length ? (
              <div className={style.wraper}>
                <>
                  <div className={style.scrollDiv}>
                    <div className={style.innerScrollDiv}>
                      <div className={style.headerBtn}>
                        <div className={style.headerBtnContent}>
                          <span className={style.btnText}>
                            Completed Courses:{' '}
                            {traingingCalculations.completedCourses}
                          </span>
                        </div>
                        <div className={style.headerBtnContent}>
                          <span className={style.btnText}>
                            Completed Courses Hours:{' '}
                            {traingingCalculations.completedCoursesHours}
                          </span>
                        </div>

                        <div className={style.headerBtnContent}>
                          <span className={style.btnText}>
                            Total CLE Credits/Birthdate:{' '}
                            {!traingingCalculations.totalCleCreditsBirthDate
                              ? '0'
                              : traingingCalculations.totalCleCreditsBirthDate}
                          </span>
                        </div>
                        <div className={style.headerBtnContent}>
                          <span className={style.btnText}>
                            Total Live CLE Credits/Birthdate:{' '}
                            {!traingingCalculations.totalLiveCleCreditsBirthDate
                              ? '0'
                              : traingingCalculations.totalLiveCleCreditsBirthDate}
                          </span>
                        </div>
                      </div>

                      <div className={style.tblHeader}>
                        <span
                          className={style.tblElements}
                          style={{ marginLeft: '10px' }}
                        >
                          Course Name
                        </span>
                        <span className={style.tblElements}>
                          Date Completed
                        </span>
                        <span className={style.tblElements}>Hours</span>
                        <span className={style.tblElements}>Live</span>
                        <span className={style.tblElements}>CLE Credits</span>
                        <span className={style.tblElements}>CLE State</span>
                        <span className={style.tblElements}>CLE Subject</span>
                        <span className={style.tblElements}>
                          Subject Matter
                        </span>
                        <span></span>
                      </div>

                      {trainingHistory?.map((item, index) => {
                        return item._id === edit ? (
                          <AddTrainingHistory
                            data={item}
                            setToggle={setToggle}
                            setEdit={setEdit}
                            onSubmit={onEdit}
                          />
                        ) : (
                          <>
                            <div
                              className={style.tblBody}
                              key={index}
                              onClick={() =>
                                setToggle((prev) =>
                                  prev == index ? -1 : index
                                )
                              }
                            >
                              <div
                                style={{
                                  marginLeft: '20px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <img
                                  src={toggle === index ? downIcon : rightIcon}
                                  style={{ marginRight: '10px' }}
                                />
                                <span
                                  className={style.tblValue}
                                  style={{
                                    overflowWrap: 'anywhere',
                                    paddingRight: '10px',
                                  }}
                                >
                                  {' '}
                                  {item.courseName}
                                </span>
                              </div>
                              <span className={style.tblValue}>
                                {item.courseCompletedDate
                                  ? moment(item.courseCompletedDate).format(
                                      'MM/DD/YYYY'
                                    )
                                  : '-'}
                              </span>
                              <span className={style.tblValue}>
                                {item.courseHours}
                              </span>
                              <span className={style.tblValue}>
                                {item.liveHours}
                              </span>
                              <span className={style.tblValue}>
                                {item.credits}
                              </span>
                              <span className={style.tblValue}>
                                {item.state}
                              </span>
                              <span className={style.tblValue}>
                                {item.subject}
                              </span>
                              <span className={style.tblValue}>
                                {item.courseSubjMatter}
                              </span>
                              <div
                                style={{
                                  margin: '0 20px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'end',
                                }}
                              >
                                <img
                                  onClick={() => {
                                    setEdit(item._id);
                                    setToggle(false);
                                  }}
                                  src={editIcon}
                                  width={20}
                                  style={{ marginRight: '10px' }}
                                />
                                <img
                                  src={deleteIcon}
                                  onClick={() => setOpen(item._id)}
                                />
                              </div>
                            </div>

                            {index === toggle && (
                              <div className={style.editSection}>
                                <div className={style.sectionOne}>
                                  <h5>My Feedback</h5>
                                  <p
                                    style={{
                                      overflowWrap: 'anywhere',
                                      whiteSpace: 'pre-wrap',
                                    }}
                                  >
                                    {' '}
                                    {item.myFeedback}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                </>

                {!openEdit && (
                  <Button
                    text={'Add Training Course'}
                    btnClass={style.btnWraper}
                    iconStart={addIcon}
                    handleClick={() => {
                      setOpenEdit(true), setEdit(false);
                    }}
                  />
                )}

                <Modal open={open} handleClose={() => setOpen(false)}>
                  <div className={style.modalHeader}>
                    <img src={Trash} height={48} width={48} />
                    <h3>Delete Training History</h3>
                    <p className={style.warningText}>
                      {
                        A02_SCREEN_MESSAGES.popups.deleteConfirm.trainingHistory
                          .body
                      }
                    </p>
                    <Button
                      btnClass={style.buttonStyle}
                      text={
                        A02_SCREEN_MESSAGES.popups.deleteConfirm.trainingHistory
                          .yes
                      }
                      handleClick={handleDelete}
                    />
                    <h3
                      className={style.cancelText}
                      onClick={() => setOpen(false)}
                    >
                      {
                        A02_SCREEN_MESSAGES.popups.deleteConfirm.trainingHistory
                          .no
                      }
                    </h3>
                  </div>
                </Modal>
              </div>
            ) : (
              !openEdit && (
                <div className={style.noRecords}>
                  <NoRecords
                    icon={true}
                    btnText={'Add Training History'}
                    onClick={() => {
                      setOpenEdit(true);
                    }}
                    extraPermissions={[ROLES.ATTORNEY]}
                    btnClass={style.noRecordsButton}
                  />
                </div>
              )
            )}
          </>
        )}

        {openEdit && (
          <AddTrainingHistory
            setToggle={setOpenEdit}
            setEdit={setEdit}
            onSubmit={onAdd}
          />
        )}
        <div className={style.buttonDiv}>
          <Button
            text={'Back'}
            handleClick={() => {
              navigate('/barhistoryForm');
            }}
            btnClass={style.btnClass2}
          />
          <Button
            text={'Send'}
            type="button"
            isLoading={redirectLoading}
            handleClick={() => {
              try {
                setRedirectLoading(true);
                dispatch(
                  setAttorneyUpdatedRedux(attorney?._id, attorney?.email)
                );
              } catch (error) {
                setRedirectLoading(false);
              }
            }}
            btnClass={style.btnClass}
          />
        </div>
      </Container>
    </div>
  );
};
export default TrainingHistoryForm;
