import moment from 'moment';
import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';

import PermissionRestrict from 'hoc/PermissionRestrict';
import { Message } from 'languages';
import Tags from 'components/tags';
import Button from 'components/button';
import Modal from 'components/modal';
import NoRecords from 'components/no-records';
import AddClient from './add-client';

import { ROLES } from 'permissions/constants';
import { ATTORNEY_CLIENT_STATUS_COLORS } from 'constants';
import useHelper from './helper';

import editIcon from 'assets/images/greenEditIcon.svg';
import deleteIcon from 'assets/images/GreenDelIcon.svg';
import addIcon from 'assets/images/whiteAddIcon.svg';
import Trash from 'assets/icons/Trash.svg';
import up from 'assets/images/upIcon.svg';
import down from 'assets/images/downIcon.svg';

import style from './client-history.module.scss';
import { useEffect } from 'react';
import { A02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import ViewTable from './view-table';

const ClientHistory = ({ setDisable }) => {
  const {
    editTable,
    setEditTable,
    columns,
    setColumns,
    viewTable,
    handleEdit,
    setViewTable,
    clientPosition,
    clientHistory,
    handleDelete,
    clientNames,
    innerColumns,
    isLoading,
    setToggle,
    setOpen,
    setEdit,
    onEdit,
    toggle,
    isLoadingAll,
    onAdd,
    all,
    setAll,
    edit,
    open,
  } = useHelper();

  useEffect(() => {
    setColumns([...innerColumns]);
  }, []);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#39695b');
  }, []);

  return (
    <div className={style.wraper}>
      {clientHistory.length < 1 ? (
        !toggle && (
          <NoRecords
            noRecord={
              <Message id="A02_SCREEN_MESSAGES.emptyPlaceholder.clientHistory" />
            }
            icon={true}
            btnText={'Add Client History'}
            onClick={() => {
              setToggle(true);
              setDisable(true);
            }}
          />
        )
      ) : (
        <>
          <div className={style.scrollDiv}>
            <div className={style.innerScrollDiv}>
              <div className={style.tblHeader}>
                <span className={style.spanClass} style={{ width: '254px' }}>
                  <b>Client Name</b>
                </span>
                <span
                  className={style.spanClass}
                  style={{
                    width: '254px',
                  }}
                >
                  <b>Position</b>
                </span>
                <span
                  className={style.spanClass1}
                  style={{
                    width: '254px',
                  }}
                >
                  <b>Client Status</b>
                </span>
                <span className={style.spanClass1} style={{ width: '250px' }}>
                  <b>Status Date</b>
                </span>
                <span className={style.spanClass2} style={{ width: '254px' }}>
                  <b>Feedback</b>
                </span>
                <span className={style.spanClass}></span>
              </div>

              {clientHistory
                .sort(
                  (a, b) => new Date(b?.statusDate) - new Date(a?.statusDate)
                )
                ?.map((item, index) => {
                  return (
                    <Fragment key={item._id}>
                      {index === edit ? (
                        <AddClient
                          data={item}
                          onEdit={onEdit}
                          setEdit={setEdit}
                          setToggle={setToggle}
                          setDisable={setDisable}
                          clientNames={clientNames}
                          clientPosition={clientPosition}
                        />
                      ) : (
                        <div className={style.tblBody}>
                          <span
                            className={style.spanClass}
                            style={{
                              overflowWrap: 'anywhere',
                              display: 'flex',
                              width: '250px',
                              color: '#363636',
                            }}
                          >
                            {item?.histories?.length > 0 ? (
                              <div className={style.imgDiv}>
                                {index === viewTable ? (
                                  <img
                                    src={up}
                                    alt=""
                                    className={style.upIcon}
                                    onClick={() => setViewTable(false)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                ) : (
                                  <img
                                    src={down}
                                    alt=""
                                    className={style.upIcon}
                                    onClick={() => {
                                      setViewTable(index);
                                      setEditTable(false);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                )}
                              </div>
                            ) : (
                              <div style={{ marginLeft: '24px' }}></div>
                            )}
                            <Link
                              style={{ color: '#363636' }}
                              to={`/clients/${item?.clientId}`}
                            >
                              {item?.clientName}
                            </Link>
                          </span>
                          <span
                            className={style.spanClass}
                            style={{
                              overflowWrap: 'anywhere',
                              width: '250px',
                              color: '#363636',
                            }}
                          >
                            <Link
                              style={{ color: '#363636' }}
                              to={`/positions/${item?.positionId?._id}`}
                            >
                              {` ${
                                item?.positionId?.practiceGroup[0]
                                  ?.practiceGroup
                                  ? item?.positionId?.practiceGroup[0]
                                      ?.practiceGroup
                                  : '-'
                              } | ${
                                item?.positionId?.locations[0]?.location
                                  ? item?.positionId?.locations[0]?.location
                                  : '-'
                              } | ${
                                item?.positionId?.sowStartDate
                                  ? moment(
                                      item?.positionId?.sowStartDate
                                    ).format('MM/DD/YYYY')
                                  : ''
                              }-${
                                item?.positionId?.sowEndDate
                                  ? moment(item?.positionId?.sowEndDate).format(
                                      'MM/DD/YYYY'
                                    )
                                  : ''
                              }`}
                            </Link>
                          </span>
                          <span
                            className={style.spanClass1}
                            style={{ width: '254px' }}
                          >
                            <Tags
                              circular={true}
                              text={item.status}
                              color={ATTORNEY_CLIENT_STATUS_COLORS[item.status]}
                            />
                          </span>
                          <span
                            className={style.spanClass1}
                            style={{
                              paddingLeft: '7px',
                              width: '246px',
                              color: '#363636',
                            }}
                          >
                            {moment(item?.statusDate?.split('T')?.[0]).format(
                              'L'
                            )}
                          </span>
                          <div
                            className={`${style.spanClass2} ${style.toolTool}`}
                            style={{
                              color: '#363636',
                              minWidth: '400px',
                              paddingLeft: '10px',
                              whiteSpace: 'pre-wrap',
                              overflowWrap: 'anywhere',
                            }}
                          >
                            <p
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '410px',
                              }}
                            >
                              {item.feedback || '-'}
                            </p>
                            {item.feedback?.length > 20 && (
                              <span>{item.feedback}</span>
                            )}
                          </div>
                          <span className={style.spanClass}>
                            <span></span>
                            <div className={style.rowIcons}>
                              <PermissionRestrict
                                roles={[ROLES.ADMIN, ROLES.EDITOR]}
                              >
                                <img
                                  onClick={() => {
                                    setEdit(index);
                                    setToggle(false);
                                    setDisable(true);
                                  }}
                                  src={editIcon}
                                  style={{
                                    marginRight: '10px',
                                  }}
                                  width={20}
                                />
                                <img
                                  src={deleteIcon}
                                  onClick={() => {
                                    setOpen({ id: item._id });
                                    setAll(
                                      item?.histories?.length > 0
                                        ? { value: true, index }
                                        : { value: false, index }
                                    );
                                  }}
                                />
                              </PermissionRestrict>
                            </div>
                          </span>
                        </div>
                      )}
                      {index === viewTable && (
                        <ViewTable
                          columns={columns}
                          singleEditMode={true}
                          editTable={editTable}
                          setEditTable={setEditTable}
                          attorneys={
                            item?.histories.map((history) => ({
                              ...history,
                              statusDate: moment(
                                history?.statusDate?.split('T')?.[0]
                              ).format('L'),
                            })) || []
                          }
                          onSaveSubmit={handleEdit}
                          handleSingleDelete={(row) => {
                            setOpen({
                              id: row?._id,
                              outerId: item._id,
                            });
                          }}
                          formId="hook-formClient"
                        />
                      )}
                    </Fragment>
                  );
                })}
            </div>
          </div>
        </>
      )}
      {toggle && (
        <AddClient
          clientPosition={clientPosition}
          setEdit={setEdit}
          setToggle={setToggle}
          setDisable={setDisable}
          clientNames={clientNames}
          onSubmit={onAdd}
        />
      )}
      {clientHistory.length >= 1 && (
        <div>
          {!toggle && (
            <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
              <Button
                text={'Add Client History'}
                btnClass={style.btnWraper}
                iconStart={addIcon}
                handleClick={() => {
                  setToggle(true), setEdit(false), setDisable(true);
                }}
                onSubmit={onAdd}
              />
            </PermissionRestrict>
          )}
        </div>
      )}
      <Modal
        open={open}
        handleClose={() => {
          setOpen(false);
          setAll(false);
        }}
      >
        <div className={style.modalHeader}>
          <img src={Trash} height={48} width={48} />
          <h3 className={style.deleteText}>Delete Client</h3>
          {all?.value === true ? (
            <p>
              Do you want to delete the entire history for this position or just
              the current status?
            </p>
          ) : (
            <p>Are you sure you want to delete this record?</p>
          )}
          {all?.value === true ? (
            <>
              <Button
                btnClass={style.buttonStyle}
                text={'Current Status'}
                handleClick={() => {
                  handleDelete();
                }}
                isLoading={isLoading}
                width="100%"
              />
              <Button
                text={' Entire History'}
                handleClick={() => {
                  handleDelete('all');
                }}
                isLoading={isLoadingAll}
                width="100%"
              />
            </>
          ) : (
            <Button
              btnClass={style.buttonStyle}
              text={A02_SCREEN_MESSAGES.popups.deleteConfirm.clientHistory.yes}
              handleClick={() => {
                handleDelete();
              }}
              isLoading={isLoading}
              width="100%"
            />
          )}
          <h3
            className={style.cancelText}
            onClick={() => {
              setOpen(false);
              setAll(false);
            }}
          >
            {A02_SCREEN_MESSAGES.popups.deleteConfirm.clientHistory.no}
          </h3>
        </div>
      </Modal>
    </div>
  );
};

export default ClientHistory;
