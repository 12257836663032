import moment from 'moment';

import { createNotification } from 'components/react-notification';
import { ROLES } from 'permissions/constants';
import axios from 'utils/axios';
import { apiRequest, removeKeys } from 'utils/helpers';
import { API_URLS } from './url-paths';

const {
  ADD_POSITION,
  GET_COMMENTS,
  UPDATE_POSITION,
  GET_CLIENT_NAME,
  DELETE_POSITION,
  GET_CLIENT_BY_ID,
  GENERATE_MATCHES,
  GET_ALL_POSITIONS,
  GET_POSITION_BY_ID,
  CREATE_MATCH_VERSION,
  UPDATE_MATCH_VERSION,
  GET_ADD_ATTORNEY_DATA,
  GET_COMPARISON_REPORT,
  GET_ALL_MATCH_VERSIONS,
  GET_SINGLE_MATCH_VERSION,
  GET_ALL_POSITIONS_MATCHES,
  GET_USER_POSITION_WEIGHTS,
  ADD_USER_POSITION_WEIGHTS,
  ADD_UPDATE_CLIENT_HISTORY,
  GET_UPDATE_DELETE_MATCH_BY_ID,
} = API_URLS;

export const addPosition = async ({
  body,
  reset,
  setEdit,
  navigate,
  setDuplicatePositionModal,
}) => {
  const res = await apiRequest({
    path: ADD_POSITION,
    type: 'post',
    body,
  });
  if (res.status === 200) {
    if (res?.data?.duplicate) {
      setDuplicatePositionModal(body);
    } else {
      reset({ placementQuantity: 0 });
      setEdit(false);
      setDuplicatePositionModal(false);
      navigate(`/positions/${res?.data?.newPosition?._id}`, {
        state: {
          from: 'new',
        },
      });
    }
  }
};

export const updatedPosition = async ({
  id,
  body,
  reset,
  setEdit,
  fetchData,
  setDuplicatePositionModal,
}) => {
  const res = await apiRequest({
    path: UPDATE_POSITION(id),
    type: 'put',
    body,
  });
  if (res.status === 200) {
    if (res?.data?.duplicate) {
      setDuplicatePositionModal(body);
    } else {
      reset({ placementQuantity: 0 });
      setEdit(false);
      setDuplicatePositionModal(false);
      fetchData();
    }
  }
};

export const getClientById = async ({ id, setClient }) => {
  const res = await apiRequest({
    path: GET_CLIENT_BY_ID(id),
    type: 'get',
  });
  if (res.status === 200) {
    setClient(res.data.client);
  }
};

export const getAllPositions = async ({ setLoading, setPositions, params }) => {
  setLoading(true);
  const res = await apiRequest({
    path: GET_ALL_POSITIONS,
    type: 'get',
    ...(params && { params }),
  });
  if (res.status === 200) {
    setPositions(res.data.positions);
  }
  setLoading(false);
};

export const getAllPositionsWithClients = async (params) => {
  let url = `${API_URLS.GET_ALL_POSITIONS}`;
  return axios.get(url, {
    params: params || {},
  });
};
export const getAllPositionsMatches = async ({
  setLoading,
  setPositions,
  setStats,
  setAttorneys,
  params,
}) => {
  setLoading(true);
  const res = await apiRequest({
    path: GET_ALL_POSITIONS_MATCHES,
    type: 'get',
    ...(params && { params }),
  });
  if (res.status === 200) {
    setStats(res.data.stats);
    setPositions(res.data.positions);
    setAttorneys(res?.data?.attorneys);
  }
  setLoading(false);
};

export const getPositionById = async ({ id, setPosition }) => {
  const res = await apiRequest({
    path: GET_POSITION_BY_ID(id),
    type: 'get',
  });
  if (res.status === 200) {
    setPosition(res.data.position);
  }
};

export const getAllClients = async ({
  setClients,
  setClientsNameWithStatus,
}) => {
  const res = await apiRequest({
    path: GET_CLIENT_NAME,
    type: 'get',
  });
  if (res.status === 200) {
    setClients(
      res?.data?.clientData?.map(({ clientName, clientId }) => {
        return { label: clientName, value: clientId };
      })
    );
    setClientsNameWithStatus(res?.data?.clientData);
  }
};

export const deletePosition = async ({ id, navigate }) => {
  const res = await apiRequest({
    path: DELETE_POSITION(id),
    type: 'delete',
  });
  if (res.status === 200) {
    navigate && navigate('/positions');
  }
};

export const getUserPositionWeights = async ({
  userId,
  positionId,
  setUserWeights,
  handleResetGenerate,
}) => {
  const res = await apiRequest({
    type: 'get',
    path: GET_USER_POSITION_WEIGHTS,
    params: { userId, positionId },
  });
  if (res.status === 200) {
    setUserWeights && setUserWeights(res?.data?.systemWeights);
    handleResetGenerate &&
      (await handleResetGenerate({ id: res?.data?.systemWeights._id }));

    return res?.data?.systemWeights;
  }
};

export const addUserPositionWeights = async ({
  data,
  userId,
  positionId,
  setAlgoWeight,
  setUserWeights,
  handleResetGenerate,
}) => {
  const res = await apiRequest({
    path: ADD_USER_POSITION_WEIGHTS,
    type: 'post',
    body: { userId, positionId, ...data },
  });
  if (res.status === 200) {
    await getUserPositionWeights({
      userId,
      positionId,
      handleResetGenerate,
      setUserWeights,
    });
    setAlgoWeight(false);
  } else {
    const message = res?.response?.data?.message;
    createNotification(
      'error',
      'Error',
      message?.includes?.('practice')
        ? 'Please enter algorithm weights'
        : res?.response?.data?.msg,
      3500
    );
  }
};

export const getPlacementComments = async ({ id }) => {
  const res = await apiRequest({
    type: 'get',
    path: GET_COMMENTS,
    params: { placementId: id },
  });
  return res;
};

export const generateMatches = async ({
  body,
  setMatches,
  setArranged,
  setIsMatchVersion,
}) => {
  const res = await apiRequest({
    type: 'post',
    path: GENERATE_MATCHES,
    body,
    config: { timeout: 50000 },
  });
  if (res.status === 200) {
    setMatches(res.data.newMatches);
    setArranged(false);
    setIsMatchVersion(false);
  } else {
    const message = res?.response?.data?.message || res?.response?.data?.msg;
    createNotification(
      'error',
      'Error',
      message?.includes?.('userWeightsId')
        ? 'Please enter user weights first'
        : '',
      3500
    );
  }
};

export const getMatchById = async ({ id, setMatches }) => {
  const res = await apiRequest({
    type: 'get',
    path: GET_UPDATE_DELETE_MATCH_BY_ID(id),
  });
  if (res.status === 200) {
    setMatches(res?.data?.match);
  }
};

export const createMatchVersion = async ({ body, setCreateVersionLoader }) => {
  setCreateVersionLoader(true);
  const res = await apiRequest({
    type: 'post',
    path: CREATE_MATCH_VERSION,
    body,
  });
  if (res.status === 200) {
    createNotification('success', 'Success', res?.data?.msg);
  } else {
    createNotification('error', 'Error', res.response.data.msg, 3500);
  }
  setCreateVersionLoader(false);
};

export const getAllMatchVersions = async ({
  params,
  setLoading,
  setVersions,
}) => {
  setLoading(true);
  const res = await apiRequest({
    type: 'get',
    path: GET_ALL_MATCH_VERSIONS,
    params,
  });
  if (res.status === 200) {
    setVersions(res?.data?.matchVersions);
  }
  setLoading(false);
};

export const getMatchVersion = async ({
  id,
  clientId,
  userData,
  positionId,
  setMatches,
  setArranged,
  setUserWeights,
  setIsMatchVersion,
  handleUpdateMatches,
  setIneligibleAttorneys,
}) => {
  const res = await apiRequest({
    type: 'get',
    path: GET_SINGLE_MATCH_VERSION,
    params: { ...(id && { id }), positionId },
    config: { timeout: 100000 },
  });
  if (res.status === 200 && res?.data?.matchVersion) {
    handleUpdateMatches && handleUpdateMatches(res?.data?.matchVersion);
    const userWeights = { ...res?.data?.matchVersion.userWeightsId };
    removeKeys(userWeights, [
      'createdAt',
      'discarded',
      'isSystem',
      'positionId',
      'updatedAt',
      'userId',
      '__v',
    ]);
    setUserWeights && setUserWeights(userWeights);
    setMatches && setMatches(res?.data?.matchVersion);
    if (res?.data?.matchVersion?.matches?.length) {
      setIsMatchVersion && setIsMatchVersion(true);
      setArranged && setArranged(false);
    }
  } else if (res?.status === 200 && res?.data?.matches) {
    setMatches && setMatches(res.data.matches);
    setIsMatchVersion && setIsMatchVersion(false);
    setArranged && setArranged(false);
  }
  setIneligibleAttorneys &&
    [ROLES.ADMIN, ROLES.EDITOR].includes(userData?.userType) &&
    (await getAddAttorneyData({
      body: {
        attorneysToExclude: res?.data?.matchVersion?.matches?.map(
          ({ _id }) => _id
        ),
        clientId,
        positionId,
      },
      setIneligibleAttorneys,
    }));
};

export const updateVersionName = async ({
  id,
  body,
  setVersionModal,
  handleRestoreVersion,
}) => {
  const res = await apiRequest({
    type: 'put',
    path: UPDATE_MATCH_VERSION(id),
    body,
  });
  if (res.status === 200) {
    await handleRestoreVersion();
    setVersionModal(true);
  }
};

export const addUpdateAttorneyClientHistory = async ({ body }) => {
  const res = await apiRequest({
    type: 'post',
    path: ADD_UPDATE_CLIENT_HISTORY,
    body,
  });
  if (res.status === 200) {
    createNotification('success', 'Success', res?.data?.msg);
  } else {
    createNotification('error', 'Error', res.response.data.msg, 3500);
  }
};

export const getAddAttorneyData = async ({ body, setIneligibleAttorneys }) => {
  const res = await apiRequest({
    type: 'post',
    path: GET_ADD_ATTORNEY_DATA,
    body,
  });
  if (res.status === 200) {
    setIneligibleAttorneys(res.data.attorneys);
  }
};

export const getComparisonReport = async ({
  params,
  setHistories,
  setTotalStatuses,
}) => {
  const res = await apiRequest({
    type: 'get',
    path: GET_COMPARISON_REPORT,
    params,
  });
  if (res.status === 200) {
    setHistories(res.data.histories);
    setTotalStatuses({
      placements:
        res.data.totalStatuses.filter((x) => x === 'Placed')?.length || 0,
      openPositions:
        res.data.totalStatuses.filter((x) => x === 'Interviewing').length || 0,
    });
  }
};

export const exportPositionsCSV = async (
  checkedRowIds,
  sortBy,
  orderBy,
  filters,
  currentValue,
  dateRange,
  columns
) => {
  let url = `${API_URLS.GET_POSITIONS_CSV}`;
  const _filters = {
    ...filters,
    sowStartDate: filters.sowStartDate
      ? moment(filters.sowStartDate).format('YYYY-MM-DD')
      : '',
    sowEndDate: filters.sowEndDate
      ? moment(filters.sowEndDate).format('YYYY-MM-DD')
      : '',
  };
  return axios.get(url, {
    params: {
      _ids: checkedRowIds.join(',') ?? '',
      sortBy,
      orderBy,
      currentValue,
      filters: _filters,
      columns,
      dateRange: {
        startDate: moment(dateRange[0] ? dateRange[0] : new Date()).format(
          'YYYY-MM-DD'
        ),
        endDate: moment(dateRange[1] ? dateRange[1] : new Date()).format(
          'YYYY-MM-DD'
        ),
      },
    },
  });
};

export const getPositionCalculation = async (params) => {
  let url = `${API_URLS.GET_POSITIONS_CALCULATIONS}`;
  return axios.get(url, {
    params: params || {},
  });
};

export const updateAllPositionsDirectory = async (data) => {
  let url = `${API_URLS.UPDATE_ALL_POSITIONS}`;
  return axios.put(url, {
    positions: data,
  });
};
