import closeIcon from 'assets/icons/close.png';

import style from './input.module.scss';

const TextField = ({
  id,
  ref,
  type,
  icon,
  name,
  label,
  value,
  onClick,
  onClear,
  register,
  readOnly,
  onChange,
  className,
  isDisable,
  iconClass,
  wraperClass,
  placeholder,
  errorMessage,
  onClickhandle,
  searchClearToggle,
  ...restOfProps
}) => {
  return (
    <>
      <div className={`${style.inputContainer} ${wraperClass} `}>
        {label && <label>{label}</label>}
        <div
          style={{ position: 'relative' }}
          className={className}
          onClick={onClickhandle}
        >
          <input
            ref={ref}
            style={{
              border: errorMessage ? '1px solid #ff5050' : ' 1px solid #E6E7E8',
              backgroundColor: readOnly || isDisable ? '#ddd' : '#fff',
              ...(icon && { paddingRight: '34px' }),
            }}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            type={type}
            placeholder={placeholder}
            {...(register && register(name))}
            readOnly={readOnly || false}
            disabled={isDisable || false}
            {...restOfProps}
            autoComplete="off"
            step={'any' || restOfProps.step}
          />
          {searchClearToggle && value && onClear ? (
            <img
              className={`${style.clearIcon}`}
              style={{
                cursor: 'pointer',
                right: '20px',
                top: '13px',
              }}
              src={closeIcon}
              alt=""
              onClick={onClear}
            />
          ) : (
            icon && (
              <img
                className={`${style.icon} ${iconClass}`}
                style={{ cursor: 'pointer' }}
                src={icon}
                alt=""
                onClick={onClick}
              />
            )
          )}
        </div>
        {errorMessage && (
          <span className={style.errorMessage}>{errorMessage}</span>
        )}
      </div>
    </>
  );
};

export default TextField;
