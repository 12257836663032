/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useState } from 'react';

import { deleteComment } from 'services/client-page';
import { createNotification } from 'components/react-notification';

const useHelper = ({ comments, setComments, setDisable }) => {
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState();

  const handleDelete = () => {
    setIsLoading(true);

    try {
      deleteComment(open);
      setComments([...comments.filter((ele) => ele._id !== open)]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);

    setOpen(false);
  };

  const onAdd = (value) => {
    let _comments = [...comments];
    _comments.unshift(value);

    setComments(_comments);
  };

  const onEdit = (value) => {
    setComments((prev) => {
      const newArr = [...prev];
      const indexFound = newArr.findIndex((e) => e._id === value._id);
      newArr[indexFound] = value;

      return newArr;
    });
    setDisable(false);
  };

  return {
    toggle,
    isLoading,
    handleDelete,
    comments,
    setToggle,
    open,
    setOpen,
    edit,
    setEdit,
    onAdd,
    onEdit,
  };
};

export default useHelper;
