import { createNotification } from 'components/react-notification';
import { SET_DASHBOARD } from 'redux/system-values/constants';
import { apiRequest } from 'utils/helpers';
import { API_URLS } from './url-paths';

const { UPLOAD_FLAT_FILE, GET_PAYLOCITY, GET_SALES_FORCE_SYNC } = API_URLS;

export const uploadFlatFile = async ({ body }, dispatch) => {
  const res = await apiRequest({
    path: UPLOAD_FLAT_FILE,
    type: 'post',
    body,
    formData: true,
  });

  if (res.status === 200) {
    dispatch({
      type: SET_DASHBOARD,
      payload: {
        flatFileLastSync: new Date(),
      },
    });
    createNotification(
      'success',
      'Synced',
      res?.data?.msg || 'Attorneys updated Successfully.',
      5000
    );
  }
};

export const getPaylocityEmployees = async (dispatch) => {
  const res = await apiRequest({
    path: GET_PAYLOCITY,
    type: 'get',
  });
  if (res.status === 200) {
    dispatch({
      type: SET_DASHBOARD,
      payload: {
        paylocityLastSync: new Date(),
      },
    });
    createNotification(
      'success',
      'Synced',
      res.data.msg || 'Attorneys updated successfully.',
      5000
    );
  }
};

export const getSalesForceClientsAndPositions = async (dispatch) => {
  const res = await apiRequest({
    path: GET_SALES_FORCE_SYNC,
    type: 'get',
  });
  if (res.status === 200) {
    dispatch({
      type: SET_DASHBOARD,
      payload: {
        salesForceLastSync: new Date(),
      },
    });
    createNotification(
      'success',
      'Synced',
      res.data.msg || 'Clients and their positions updated successfully.',
      3500
    );
  }
};
