/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';

import Button from 'components/button';
import CardComment from 'components/card-comment';
import CardCommentEdit from 'components/edit-card-comment';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { C02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import { ROLES } from 'permissions/constants';

import cross from 'assets/icons/cross-pink.svg';
import del from 'assets/icons/del-pink.svg';
import editIcon from 'assets/icons/edit-pink.svg';
import addIcon from 'assets/images/whiteAddIcon.svg';

import pinkTrash from 'assets/icons/pinkTrash.svg';
import Loader from 'components/loader';
import Modal from 'components/modal';
import NoRecords from 'components/no-records';
import moment from 'moment';
import style from './comment.module.scss';
import useHelper from './helper';

const Comments = ({ comments, setComments, setDisable }) => {
  const {
    toggle,
    edit,
    open,
    isLoading,
    setToggle,
    setOpen,
    setEdit,
    handleDelete,
    onAdd,
    onEdit,
  } = useHelper({ comments, setComments, setDisable });

  return (
    <form>
      <div className={style.wraper}>
        {isLoading && <Loader />}
        {!isLoading && comments?.length < 1 ? (
          !toggle && (
            <div style={{ marginTop: '48px' }}>
              <NoRecords
                noRecord={C02_SCREEN_MESSAGES.emptyPlaceholder.comments}
                icon={true}
                onClick={() => {
                  setToggle(true);
                  setDisable(true);
                }}
                btnText={'Add Comment'}
              />
            </div>
          )
        ) : (
          <>
            <div className={style.scrollDiv}>
              <div className={style.innerScrollDiv}>
                <h4 className={style.topText}>Comments</h4>
                {comments?.map((ele, index) => (
                  <div key={index} style={{ marginBottom: '15px' }}>
                    <Fragment key={ele._id}>
                      {index === edit ? (
                        <CardCommentEdit
                          setDisable={setDisable}
                          data={ele}
                          btnClass={style.btnWraper}
                          closeIcon={cross}
                          setToggle={setToggle}
                          setEdit={setEdit}
                          index={index}
                          onSubmit={onEdit}
                        />
                      ) : (
                        <CardComment
                          date={moment(ele?.commentDate).format('MM/DD/YY')}
                          name={ele?.userName}
                          para={ele?.comment}
                          editIcon={editIcon}
                          deleteIcon={del}
                          handleEdit={() => {
                            setDisable(true);
                            setEdit(index);
                          }}
                          handleDelete={() => setOpen(ele._id)}
                        />
                      )}
                    </Fragment>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {toggle ? (
          <div className={style.scrollDiv}>
            <div className={style.innerScrollDiv}>
              <CardCommentEdit
                setDisable={setDisable}
                btnClass={style.btnWraper}
                closeIcon={cross}
                setToggle={setToggle}
                setEdit={setEdit}
                onSubmit={onAdd}
              />
            </div>
          </div>
        ) : (
          !isLoading &&
          comments?.length > 0 && (
            <div>
              <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                <Button
                  text={'Add Comment'}
                  btnClass={style.btnWraper}
                  iconStart={addIcon}
                  handleClick={() => {
                    setToggle(true);
                    setDisable(true);
                    setEdit(false);
                  }}
                />
              </PermissionRestrict>
            </div>
          )
        )}
      </div>
      <Modal open={open} handleClose={() => setOpen(false)}>
        <div className={style.modalHeader}>
          <img src={pinkTrash} height={48} width={48} />
          <h3 className={style.deleteText}>Delete Comment</h3>
          <p>{C02_SCREEN_MESSAGES.popups.deleteConfirm.comment.body}</p>
          <Button
            btnClass={style.buttonStyle}
            text={C02_SCREEN_MESSAGES.popups.deleteConfirm.comment.yes}
            handleClick={handleDelete}
            width="100%"
            isLoading={isLoading}
          />
          <h3 className={style.cancelText} onClick={() => setOpen(false)}>
            {C02_SCREEN_MESSAGES.popups.deleteConfirm.comment.no}
          </h3>
        </div>
      </Modal>
    </form>
  );
};

export default Comments;
