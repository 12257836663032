import moment from 'moment';

import cross from 'assets/icons/close-purple.svg';

import style from './card.module.scss';

const ComparisonCard = ({
  index,
  history,
  cardClass,
  verticalScroll,
  setDeleteRecord,
  removeAble,
  ...props
}) => {
  const {
    location,
    attorneys,
    clientName,
    sowEndDate,
    sowStartDate,
    practiceGroup,
    placementQuantity,
  } = history;

  const list = Array.from(
    Array(Math.max(placementQuantity, attorneys?.length || 0)).keys()
  );

  return (
    <div {...props}>
      <div className={`${style.mainCard} ${cardClass}`}>
        <div className={style.title}>
          <h6 className={style.heading}>{`${clientName} ${
            sowStartDate ? moment(sowStartDate).format('L') : ''
          } - ${sowEndDate ? moment(sowEndDate).format('L') : ''}`}</h6>
          {removeAble && (
            <img
              style={{ paddingLeft: '20px' }}
              src={cross}
              alt="cross-icon"
              onClick={() => {
                setDeleteRecord(index);
              }}
            />
          )}
        </div>
        <div className={style.headerDiv}>
          <p className={style.para}>{`${practiceGroup || ''}${
            practiceGroup && location ? ', ' : ''
          } ${location || ''}`}</p>
        </div>
        <div className={style.tableHead}>
          <p className={style.header} style={{ paddingLeft: '36px' }}>
            Status
          </p>
          <p className={style.header}>Name</p>
        </div>
        <div className={`${style.verticalScroll} ${verticalScroll}`}>
          <div style={{ minHeight: '295px' }}>
            {list?.map((ele, index) => {
              return (
                <div className={style.tableBody} key={index}>
                  <ul>
                    <li>
                      <p style={{ width: '15px' }}>{index + 1}.</p>
                      <div style={{ display: 'flex' }}>
                        {attorneys?.[index]?.status ? (
                          <p>{attorneys?.[index]?.status}</p>
                        ) : (
                          <p>
                            <span
                              className={style.emptySpan}
                              style={{ marginLeft: '6px' }}
                            >
                              {'__'}
                            </span>
                          </p>
                        )}
                        {attorneys?.[index]?.status === 'Interviewing' &&
                          attorneys?.[index]?.otherInterviews?.count > 0 && (
                            <div className={style.rounded}>
                              {`(${attorneys?.[index]?.otherInterviews?.count})`}
                              {attorneys?.[index]?.otherInterviews?.count >
                                0 && (
                                <div
                                  className={
                                    index < 4 || index !== list?.length - 1
                                      ? style.tooltip
                                      : style.tooltipLast
                                  }
                                >
                                  {attorneys?.[
                                    index
                                  ]?.otherInterviews?.interviews.map(
                                    ({ statuses, _id, clientName }, index) => (
                                      <div
                                        className={style.tooltipChild}
                                        key={index}
                                      >
                                        <div className={style.tooltipText}>
                                          <span style={{ fontWeight: 400 }}>
                                            <strong style={{ fontWeight: 700 }}>
                                              {clientName}{' '}
                                            </strong>
                                            {statuses[0].statusDate &&
                                              `(${moment(statuses[0].statusDate)
                                                .utc()
                                                .format('MM/DD/YYYY')})`}
                                          </span>{' '}
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                      {attorneys?.[index]?.statusDate && (
                        <span
                          style={{
                            color: '#9DA1AA',
                          }}
                        >
                          {attorneys?.[index]?.statusDate
                            ? `(${moment(attorneys?.[index]?.statusDate).format(
                                'L'
                              )})`
                            : '-'}
                        </span>
                      )}
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div style={{ display: 'flex' }}>
                        {attorneys?.[index]?.name ? (
                          <p
                            style={{
                              fontWeight: '500',
                            }}
                          >
                            {attorneys?.[index]?.name}
                          </p>
                        ) : (
                          <p>
                            <span className={style.emptySpan}>{'__'}</span>
                          </p>
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparisonCard;
