import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useSelector } from 'react-redux';

import { setBarHistory } from 'services/attorney-profile';
import { BAR_STATUS_OPTIONS } from 'constants';
import { useAttoreyProfile } from 'contexts/attorney-profile';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { ROLES } from 'permissions/constants';

import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextField from 'components/textfield';
import Button from 'components/button';

import saveIcon from 'assets/images/save.svg';
import greenCrossIcon from 'assets/images/greenCrossIcon.svg';
import crossIcon1 from 'assets/icons/blue-cross.svg';

import style from './edit-bar.module.scss';
import { createNotification } from 'components/react-notification';
import moment from 'moment';

const EditBarHistory = ({
  setToggle,
  data,
  setEdit,
  setDisable,
  alreadyUsedStatus,
  onSubmit: submitted,
  setViewTable,
}) => {
  const [options, setOptions] = useState();
  const { id } = useParams();
  const { states } = useSelector((state) => state.systemValues);

  const { getData } = useAttoreyProfile();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: !data
      ? {}
      : {
          ...data,
          barTestedDate:
            data.barTestedDate && new Date(data.barTestedDate.replace('Z', '')),
        },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const stateOptionsList =
      states?.map((e) => ({
        label: e.barState,
        value: e.barState,
      })) || [];

    let found = stateOptionsList?.filter(
      (x) => !alreadyUsedStatus.includes(x?.label)
    );

    setOptions(found);
  }, [alreadyUsedStatus]);

  const onSubmit = async (values) => {
    const { barStatus, barTestedDate, barState, barId } = values;

    try {
      const res = await setBarHistory(
        {
          attorneyId: id,
          barId,
          barState: states.find((state) => state.barState == barState)._id,
          barStatus,
          barTestedDate: moment(barTestedDate).format('YYYY-MM-DD'),
        },
        data?._id
      );
      setViewTable(false);
      await getData();
      await submitted(res.data.bar);
      setEdit(null);
      setToggle(false);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          err?.message ||
          'Server Error'
      );
      console.error('err', err.response.data);
    }
  };

  return (
    <form
      id="barHistory-form"
      form="barHistory-form"
      onSubmit={handleSubmit(onSubmit)}
      className={style.wraper}
    >
      <div className={style.editbarSection}>
        <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}>
          <div className={style.barEditFields}>
            <DatePicker
              label="Date"
              name={'barTestedDate'}
              id="1"
              monthYear={true}
              placeholder="mm/yyyy"
              control={control}
              className={style.zDynamic2}
              errorMessage={errors?.barTestedDate?.message}
            />
          </div>
        </PermissionRestrict>
        <PermissionRestrict roles={[ROLES.ATTORNEY]}>
          <div className={style.barEditFields}>
            <DatePicker
              label="Date"
              name={'barTestedDate'}
              id="1"
              monthYear={true}
              placeholder="mm/yyyy"
              control={control}
              errorMessage={errors?.barTestedDate?.message}
            />
          </div>
        </PermissionRestrict>
        <div className={style.barEditFields}>
          <SelectBox
            options={options ?? []}
            label={'State'}
            disabled={data?._id}
            dynamicClass={style.zDynamic}
            name={'barState'}
            placeholder="Select One"
            control={control}
            showValueOnlyOnDisabled
            errorMessage={errors?.barState?.message}
          />
        </div>
        <div className={style.barEditFields}>
          <SelectBox
            options={BAR_STATUS_OPTIONS}
            label={'Status'}
            placeholder="Select One"
            control={control}
            name={'barStatus'}
            errorMessage={errors?.barStatus?.message}
          />
        </div>
        <div className={style.barEditFields}>
          <TextField
            label={'ID'}
            wraperClass={style.inputWraper}
            name="barId"
            register={register}
            errorMessage={errors?.barInstituteId?.message}
          />
        </div>
      </div>{' '}
      <div className={style.btnSection}>
        <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}>
          <img
            src={greenCrossIcon}
            onClick={() => {
              setToggle(false);
              setEdit(null);
              setDisable(false);
            }}
          />
        </PermissionRestrict>
        <PermissionRestrict roles={[ROLES.ATTORNEY]}>
          <img
            src={crossIcon1}
            onClick={() => {
              setToggle(false);
              setEdit(null);
              setDisable(false);
            }}
          />
        </PermissionRestrict>
        <Button
          text={'Save'}
          type={'submit'}
          iconStart={saveIcon}
          btnClass={style.btnClass}
          isLoading={isSubmitting}
          width="100px"
          onClick={() => {
            setDisable(false);
          }}
        />
      </div>
    </form>
  );
};

export default EditBarHistory;

const schema = yup
  .object({
    barStatus: yup
      .string()
      .required('Status is required')
      .typeError('Status is required'),
    barId: yup.string().typeError('Bar ID is required'),
    barTestedDate: yup
      .string()
      .required('Date is required')
      .typeError('Date is required'),
    barState: yup
      .string()
      .required('Bar state is required')
      .typeError('Bar state is required'),
  })
  .required();
