import { NotificationManager } from 'react-notifications';

export const createNotification = (type, title, message, timeOut) => {
  switch (type) {
    case 'info':
      NotificationManager.info(message, title, timeOut > 3000 ? timeOut : 3000);
      break;
    case 'success':
      NotificationManager.success(
        message,
        title,
        timeOut > 1500 ? timeOut : 1500
      );
      break;
    case 'warning':
      NotificationManager.warning(
        message,
        title,
        timeOut > 3000 ? timeOut : 3000
      );
      break;
    case 'error':
      NotificationManager.error(
        message,
        title,
        timeOut > 5000 ? timeOut : 5000
      );
      break;
    default:
      return false;
  }
};
