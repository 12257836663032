import React, { forwardRef, useEffect, useState } from 'react';

import GenericTable from 'components/generic-table';

import pinIconFilled from 'assets/icons/pin-filled.svg';
import pinIcon from 'assets/icons/pin.svg';

import style from './directory-table.module.scss';

const PositionTable = (
  {
    rows,
    columns,
    editTable,
    setMatches,
    setArranged,
    setEditTable,
    placementQuantity,
  },
  ref
) => {
  const [dataSource, setDataSource] = useState([...rows]);

  useEffect(() => {
    setDataSource([...rows]);
  }, [rows]);

  return (
    <div>
      <div className={style.tableWidth}>
        <GenericTable
          ref={ref}
          columns={columns}
          dragColWidth="85"
          isEditMode={editTable}
          dataSource={dataSource}
          onSaveSubmit={(updatedRows) => {
            setDataSource([...updatedRows]);
            setEditTable(false);
          }}
          height={'500px'}
          formId="hook-form"
          renderNoDataFound={() => (
            <tr>
              <td colSpan={columns.length}>No Data Found</td>
            </tr>
          )}
          selectable={false}
          renderSelector={() => {}}
          classes={{
            table: style.table,
            th: style.th,
            thead: style.thead,
            tableBody: style.tableRow,
            tableOnLoading: style.genericTableOnLoading,
            tableOffLoading: style.genericTableOffLoading,
          }}
          checkbox={false}
          isDragable={true}
          renderPins={(row) => {
            const handlePin = (e) => {
              e.stopPropagation();
              const tempData = [...dataSource];
              const currentIndex = tempData.findIndex((x) => x._id === row._id);
              tempData[currentIndex].slot =
                tempData?.[currentIndex]?.slot > -1 ? -1 : currentIndex;
              setMatches((prev) => ({ ...prev, matches: tempData }));
            };
            return (
              <div
                style={{
                  marginLeft: '20px',
                  padding: '5px 10px 5px 0',
                  cursor: 'pointer',
                }}
              >
                {row?.slot < 0 ? (
                  <img
                    width="24px"
                    src={pinIcon}
                    onClick={handlePin}
                    className={style.userName}
                  />
                ) : (
                  <img
                    width="24px"
                    src={pinIconFilled}
                    onClick={handlePin}
                    className={style.userName}
                  />
                )}
              </div>
            );
          }}
          onDragEnd={(data) => {
            setMatches((prev) => ({ ...prev, matches: data.data }));
            setArranged(data.data);
          }}
          rowColor={(index) => (placementQuantity > index ? '#EAFCFF' : '')}
        />
      </div>
    </div>
  );
};

export default forwardRef(PositionTable);
