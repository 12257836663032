/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { useController } from 'react-hook-form';

import Avatar from 'components/avatar';
import Button from 'components/button';
import Modal from 'components/modal';

import placeholder from 'assets/icons/image-placeholder.svg';
import upload from 'assets/icons/upload.svg';
import editIcon from 'assets/images/Edit.svg';
import trashIcon from 'assets/images/delete.svg';
import Trash from 'assets/icons/Trash.svg';
import TrashBlue from 'assets/icons/TrashBlue.svg';

import { useDimensions } from 'hooks/useDimensions';
import style from './editAvatar.module.scss';

function AvatarUpload({
  control,
  name,
  initials,
  delIcon,
  deletingPhoto,
  userMode,
  setDeletingPhoto,
  hideIcon,
  showCancel,
  uploadIcon,
  color,
  loading,
  textColor,
}) {
  const {
    field: { onChange, value },
  } = useController({
    control,
    name,
  });
  const ref = useRef();
  const [imageCrop, setimageCrop] = useState(false);
  const [zoom, setZoom] = useState(1);
  const [file, setFile] = useState(false);
  const [imageDel, setimageDel] = useState(false);

  const size = useDimensions();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (e) => {
      setFile(e[0]);
    },
    accept: {
      'image/*': [],
    },
  });

  const saveCropImage = () => {
    const canvas = ref.current.getImage().toDataURL();
    onChange(canvas);
    setimageCrop(false);
    setFile(false);
  };

  return (
    <div>
      <div
        onClick={() => {
          setimageCrop(true);
        }}
        className={style.avatarWrapper}
      >
        <Avatar icon={(value && value) || placeholder} initials={initials} />

        {!hideIcon && (
          <div className={style.editImgBox}>
            <img
              src={editIcon}
              alt={'Edit icon'}
              style={{ objectFit: 'cover' }}
            />
          </div>
        )}
      </div>
      {file || value ? (
        <div
          onClick={() => {
            setimageDel(true);
          }}
          className={style.avatarWrapper}
        >
          <div
            className={style.deleteImgBox}
            style={{ background: 'lightGrey' }}
          >
            <img
              src={trashIcon}
              alt={'delete icon'}
              style={{ objectFit: 'cover' }}
            />
          </div>
        </div>
      ) : (
        ''
      )}
      <Modal
        modalWrapper={style.modalWrapper}
        className={style.modal}
        open={imageCrop}
        showCross={true}
        uploadMode={true}
        bodyPadding={'5px 30px 30px'}
        handleClose={() => {
          setimageCrop(false);
          setFile(false);
        }}
      >
        {!file ? (
          <>
            <h3 className={`${style.topText} ${textColor}`}></h3>
            <div className={style.uploadDiv} {...(!file && getRootProps())}>
              <img
                src={uploadIcon ? uploadIcon : upload}
                height={65}
                width={65}
                style={{ marginBottom: 17 }}
              />
              <input {...getInputProps()} />
              <h4 className={style.uploadText}>
                Drag and drop your file here or{' '}
              </h4>

              <h4
                className={style.uploadText}
                style={{ color: '#2475D0', cursor: 'pointer' }}
              >
                click here to select from directory
              </h4>
            </div>
          </>
        ) : (
          <AvatarEditor
            ref={ref}
            image={file}
            width={['sm', 'xs'].includes(size) ? 200 : 230}
            height={['sm', 'xs'].includes(size) ? 200 : 230}
            border={50}
            borderRadius={200}
            color={[0, 0, 0, 0.6]} // RGBA
            scale={zoom}
            crossOrigin={'anonymous'}
            rotate={0}
            style={{ marginTop: 20 }}
          />
        )}
        {file && (
          <div>
            <input
              type="range"
              max={10}
              className={style.slider}
              min={0.5}
              step={0.1}
              value={zoom}
              onChange={(e) => {
                setZoom(Number(e.target.value));
              }}
            />
          </div>
        )}
        <div className={style.uploadBtnDiv}>
          <Button
            handleClick={saveCropImage}
            text="Upload"
            disabled={!file}
            className={style.uploadBtn}
          />
          {showCancel && (
            <p
              style={{
                marginTop: 20,
                fontSize: 14,
                fontWeight: 600,
                color: '#39695b',
                cursor: 'pointer',
              }}
              onClick={() => setimageCrop(false)}
            >
              Cancel
            </p>
          )}
        </div>
      </Modal>
      <Modal
        open={imageDel}
        handleClose={() => {
          setimageDel(false);
        }}
      >
        <div className={style.modalHeader}>
          <img src={delIcon ? TrashBlue : Trash} />
          {userMode ? (
            <h3
              style={{ color: color ? '#223149' : '#39695b' }}
              className={style.deleteText}
            >
              Delete User Photo
            </h3>
          ) : (
            <h3
              style={{ color: color ? '#223149' : '#39695b' }}
              className={style.deleteText}
            >
              Delete Profile Photo
            </h3>
          )}
          {userMode ? (
            <p style={{ color: color ? '#223149' : '#39695b' }}>
              Are you sure you would like to delete this user’s photo?
            </p>
          ) : (
            <p style={{ color: color ? '#223149' : '#39695b' }}>
              Are you sure you would like to delete this profile photo?
            </p>
          )}

          <>
            {delIcon ? (
              <Button
                btnClass={delIcon ? style.buttonStyleBlue : style.buttonStyle}
                text={'Delete'}
                handleClick={() => {
                  setFile(null);
                  onChange(null);
                  setimageDel(false);
                  setDeletingPhoto(true);
                }}
                isLoading={loading}
                width="100%"
                type={'button'}
              />
            ) : (
              <Button
                btnClass={delIcon ? style.buttonStyleBlue : style.buttonStyle}
                text={'Delete'}
                handleClick={() => {
                  setFile(null);
                  onChange(null);
                  setimageDel(false);
                  setDeletingPhoto(true);
                }}
                isLoading={loading}
                width="100%"
                type={'button'}
              />
            )}

            <h3
              className={style.cancelText}
              onClick={() => {
                setimageDel(false);
              }}
              style={{ color: color ? color : '#39695b' }}
            >
              Cancel
            </h3>
          </>
        </div>
      </Modal>
    </div>
  );
}

export default AvatarUpload;
