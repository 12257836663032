import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import Button from 'components/button';
import NowDropdown from 'components/now-dropdown';

import arrow from 'assets/images/greenDownArrow.svg';

import style from 'components/button-dropdown/button-dropdown.module.scss';

export default function ButtonDropdown({
  options,
  onChange,
  btnBorder,
  arrowDown,
  dateRange,
  currentValue,
  isDisabled = false,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <Button
        disabled={isDisabled}
        btnClass={`${style.btn} ${btnBorder}`}
        text={
          currentValue === 'Custom' && !_.isEmpty(dateRange?.filter((x) => x))
            ? `
            ${
              dateRange[0] ? moment(dateRange[0]).format('MM/DD/YYYY') : ''
            } - ${dateRange[1] ? moment(dateRange[1]).format('MM/DD/YYYY') : ''}

            `
            : currentValue
        }
        iconEnd={arrowDown ? arrowDown : arrow}
        handleClick={() => setOpen(true)}
      />
      {open && (
        <NowDropdown
          open={open}
          setOpen={setOpen}
          nameArr={options}
          onClick={onChange}
        />
      )}
    </div>
  );
}
