/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import Button from 'components/button';
import { createNotification } from 'components/react-notification';
import TextField from 'components/textfield';

import { setUserReduxLogin } from 'redux/auth/auth-actions';
import { userService } from 'services/users';

import eyeOpen from 'assets/icons/Hide.svg';
import mainLogo from 'assets/icons/Legal-logo.svg';
import eyeClose from 'assets/icons/Show.svg';
import loginLogo from 'assets/icons/auth.png';
import crossIcon from 'assets/icons/cross-gray.svg';
import redCross from 'assets/icons/red-cross.svg';

import Modal from 'components/modal';
import style from './login.module.scss';

function Login() {
  const disptach = useDispatch();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showError, setShowError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const { email, password } = data;
    try {
      const result = await userService.login({
        email: email,
        password: password,
      });
      if (result?.data?.token) {
        disptach(setUserReduxLogin(result.data.token));
      }
    } catch (err) {
      setIsSubmitting(false);

      if (err.response.status == 500) {
        createNotification('error', 'Login Error', 'Server Error!', 3500);
      } else {
        setShowError(err?.response?.data?.error);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.mainDiv}>
          <div className={style.leftInnerDiv}>
            <img src={mainLogo} alt="" className={style.logo} />
            <h2>Login</h2>
            <TextField
              label="Email"
              className={style.field}
              name="email"
              register={register}
              placeholder="Enter Your Email"
              errorMessage={errors?.email?.message}
              icon={crossIcon}
              onClick={() => reset({ email: '' })}
              iconClass={style.crossIcon}
            />
            <TextField
              label="Password"
              className={style.field}
              name="password"
              placeholder="Enter Your Password"
              register={register}
              errorMessage={errors?.password?.message}
              wraperClass={style.textFieldWraper}
              onClick={() => setPasswordVisible(!passwordVisible)}
              icon={passwordVisible ? eyeClose : eyeOpen}
              type={passwordVisible ? 'text' : 'password'}
              iconClass={style.eyeIcon}
            />
            <div className={style.forgotPassword}>
              <p>
                <Link
                  style={{
                    textDecoration: 'none',
                    color: '#696F7A',
                  }}
                  to="/forgot-password"
                >
                  Forgot password?
                </Link>
              </p>
            </div>
            <div className={style.buttonDiv}>
              <Button
                type={'submit'}
                text="Login"
                btnClass={style.loginButton}
                isLoading={isSubmitting}
              />
            </div>
          </div>
          <div className={style.rightInnerDiv}>
            <img src={loginLogo} alt="" />
          </div>
        </div>
      </form>
      <Modal
        className={style.modalWrapper}
        open={showError}
        handleClose={() => setShowError(false)}
      >
        <div className={style.modalBody}>
          <img src={redCross} />
          <h3>Access Denied</h3>
          <p>{showError}</p>
          <Button
            handleClick={() => setShowError(false)}
            text="Ok"
            btnClass={style.loginButton}
          />
        </div>
      </Modal>
    </>
  );
}

export default Login;

const schema = yup
  .object({
    email: yup
      .string()
      .required('Email is required ')
      .email('Please enter valid email'),
    password: yup
      .string()
      .required('Password is required ')
      .min(4, 'Must be at least 4 characters'),
  })
  .required();
