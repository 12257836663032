/* eslint-disable no-unused-vars */
import CrossTag from 'assets/icons/crossTag.svg';
import CrossTagBlack from 'assets/icons/crossTagBlack.svg';
import React from 'react';
import style from './tags.module.scss';

const Tags = ({
  circular,
  color,
  text,
  cross,
  number,
  isBlack,
  dateMode,
  trainingWidget,
  isCircularNumber,
  borderColor,
  numberCircular,
  isCircular = false,
}) => {
  return (
    <>
      {isCircularNumber ? (
        <>
          <div
            className={style.main}
            style={{ backgroundColor: color, borderRadius: 32 }}
          >
            {numberCircular && (
              <div
                className={style.text}
                style={{
                  padding: '3px',
                }}
              >
                <span> {numberCircular}</span> Selected
              </div>
            )}
          </div>
        </>
      ) : (
        <div></div>
      )}
      {circular ? (
        <div
          className={style.main}
          style={{
            backgroundColor: color,
            borderRadius: 32,
            border: borderColor ? '1px solid' : null,

            borderColor: borderColor && borderColor,
            display: isCircularNumber ? 'none' : null,
          }}
        >
          {dateMode ? (
            <div
              className={style.text}
              style={{
                color: borderColor ? borderColor : '#696F7A',
                fontSize: trainingWidget ? '14px' : '12px',
                fontWeight: trainingWidget ? '400' : '600',
              }}
            >
              {text}
            </div>
          ) : (
            <div
              className={style.text}
              style={{
                color: borderColor ? borderColor : '#363636',
                fontSize: trainingWidget ? '14px' : '12px',
                fontWeight: trainingWidget ? '400' : '600',
              }}
            >
              {text}
            </div>
          )}

          {cross ? (
            <img
              src={CrossTagBlack}
              height={6}
              width={6}
              onClick={() => alert('closed')}
            />
          ) : (
            <div></div>
          )}
        </div>
      ) : (
        <div
          className={style.main}
          style={{
            backgroundColor: color,
            borderRadius: isCircular && 32,
            border: borderColor ? '1px solid' : null,

            borderColor: borderColor && borderColor,
            display: isCircularNumber ? 'none' : null,
          }}
        >
          {number ? (
            <>
              <div
                className={style.text}
                style={{
                  marginRight: '5px',
                }}
              >
                {number}
              </div>
              <div
                className={style.text}
                style={{
                  padding: '3px',
                }}
              >
                Selected
              </div>
            </>
          ) : (
            <div
              className={style.text}
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: borderColor ? borderColor : undefined,
              }}
            >
              {text?.length > 15 ? (
                <p className={style.selectBoxEllipses}>{text}</p>
              ) : (
                text
              )}
            </div>
          )}
          {cross ? (
            <img
              src={CrossTag}
              height={6}
              width={6}
              onClick={() => alert('closed')}
            />
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
  );
};
export default Tags;
