import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import * as yup from 'yup';

import Button from 'components/button';
import Modal from 'components/modal';
import TextField from 'components/textfield';

import { userService } from 'services/users';

import eyeOpen from 'assets/icons/Hide.svg';
import eyeClose from 'assets/icons/Show.svg';

import { createNotification } from 'components/react-notification';
import { useForm } from 'react-hook-form';
import style from './reset.module.scss';

const ResetPassword = ({ updatePasswordModal, setUpdatePasswordModal }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisibleNew, setPasswordVisibleNew] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { password } = data;
    try {
      await userService.changePassword({
        password,
        userId: updatePasswordModal,
      });
      createNotification(
        'success',
        'Success',
        'Password set successfully!',
        3500
      );
      setUpdatePasswordModal(false);
    } catch (err) {
      createNotification(
        'error',
        'Login Error',
        err?.response?.data?.error || err?.message || 'Server Error',
        3500
      );
    }
  };

  return (
    <>
      <Modal
        open={updatePasswordModal}
        handleClose={() => setUpdatePasswordModal(false)}
        className={style.mainDiv}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <h6>Reset Password</h6>
          <TextField
            label="New Password"
            onClick={() => setPasswordVisible(!passwordVisible)}
            icon={!passwordVisible ? eyeOpen : eyeClose}
            type={passwordVisible ? 'text' : 'password'}
            wraperClass={style.input}
            register={register}
            name="password"
            errorMessage={errors?.password?.message}
            autocomplete="new-password"
          />

          <TextField
            label="Confirm New Password"
            wraperClass={style.input}
            onClick={() => setPasswordVisibleNew(!passwordVisibleNew)}
            icon={!passwordVisibleNew ? eyeOpen : eyeClose}
            type={passwordVisibleNew ? 'text' : 'password'}
            register={register}
            name="confirmPassword"
            errorMessage={errors?.confirmPassword?.message}
          />
          <div className={style.mainBtnDiv}>
            <Button
              text={'Save Password'}
              btnClass={style.btnClassModal}
              isLoading={isSubmitting}
              type="submit"
            />
            <Button
              text={'Cancel'}
              btnClass={style.btnClassUncheckModal}
              handleClick={() => setUpdatePasswordModal(false)}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ResetPassword;

const schema = yup
  .object({
    password: yup
      .string()
      .required('Password is required ')
      .min(4, 'Must be at least 4 characters'),
    confirmPassword: yup
      .string()
      .required('Both passwords are required ')
      .min(4, 'Must be at least 4 characters')
      .oneOf([yup.ref('password')], 'Passwords should match'),
  })
  .required();
