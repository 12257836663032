/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/button';
import Container from 'components/container';
import NoRecords from 'components/no-records';
import Spinner from 'components/spinner';
import PermissionRestrict from 'hoc/PermissionRestrict';
import AddAttorneyModal from './add-attorney-modal';
import ColumnModal from './choose-columns-modal';
import DeleteAttorneyModal from './delete-attorney-modal';
import DeleteRecordModal from './delete-record-modal';
import Header from './header';
import MyAlgoWeights from './my-alogo-weights';
import OverviewTable from './overview-table';
import RemoveStatusHistory from './remove-status-history';
import ResetModal from './reset-modal';
import SelectStatus from './select-status';
import VersionModal from './version-modal';
import EditModal from './version-modal/edit-modal';
import RestoreModal from './version-modal/restore-modal';

import { P02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import { useWindowDimensions } from 'hooks/useDimensions';
import { ROLES } from 'permissions/constants';
import {
  addUserPositionWeights,
  createMatchVersion,
  generateMatches,
  getAddAttorneyData,
  getMatchVersion,
  getUserPositionWeights,
} from 'services/positions';
import { getTableColumns, updateTableColumns } from 'services/utils';
import { removeKeys } from 'utils/helpers';
import { defaultColumns } from './helper';

import delIcon from 'assets/del-red.svg';
import crossIcon from 'assets/icons/close-purple.svg';
import refresh from 'assets/icons/refresh-purple.svg';
import save from 'assets/icons/save-purple.svg';
import editIcon from 'assets/images/Edit.svg';
import columnIcon from 'assets/images/columnIcon.svg';
import dotIcon from 'assets/images/dotsIcon.svg';
import generateMatchesIcon from 'assets/images/generateMatches.svg';
import plusIcon from 'assets/images/plusIcon.svg';
import refreshIcon from 'assets/images/refreshIcon.svg';
import saveIcon from 'assets/images/save.svg';

import style from './overview.module.scss';

const PositionOverview = ({
  id,
  edit,
  watch,
  reset,
  errors,
  active,
  setEdit,
  control,
  register,
  position,
  setDisable,
  handleReset,
  isSubmitting,
}) => {
  const tableName = 'matchesTable';
  const ref = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const {
    systemValues,
    user: { userData },
  } = useSelector((state) => state);

  const [matches, setMatches] = useState([]);
  const [columns, setColumns] = useState([]);
  const [arrData, setArrData] = useState(arr);
  const [loading, setLoading] = useState(true);
  const [arranged, setArranged] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [userWeights, setUserWeights] = useState({});
  const [algoWeight, setAlgoWeight] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [addAttorney, setAddAttorney] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [versionModal, setVersionModal] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(false);
  const [selectStatus, setSelectStatus] = useState(false);
  const [removeStatus, setRemoveStatus] = useState(false);
  const [choseColModal, setChoseColModal] = useState(false);
  const [isMatchVersion, setIsMatchVersion] = useState(false);
  const [deleteAttorney, setDeleteAttorney] = useState(false);
  const [ineligibleAttorneys, setIneligibleAttorneys] = useState([]);
  const [matchesTableColumns, setMatchesTableColumns] = useState([]);
  const [createVersionLoader, setCreateVersionLoader] = useState(false);

  const handleRestoreVersion = async (id) => {
    setLoading(true);
    await getMatchVersion({
      id,
      userData,
      setMatches,
      setArranged,
      setUserWeights,
      setIsMatchVersion,
      setIneligibleAttorneys,
      positionId: params?.id,
      clientId: position?.client?._id,
    });
    userWeights &&
      !Object.keys(userWeights)?.length &&
      params?.id &&
      (await getUserPositionWeights({
        setUserWeights,
        userId: userData?._id,
        positionId: params?.id,
      }));
    setLoading(false);
  };

  const handleCreateVersion = async () => {
    await createMatchVersion({
      body: {
        userId: userData?._id,
        positionId: params?.id,
        clientId: position?.client?._id,
        userWeightsId: userWeights?._id,
        matches: matches?.matches?.map(({ _id, score, slot }) => {
          return {
            attorneyId: _id,
            score,
            slot,
          };
        }),
      },
      setCreateVersionLoader,
    });
    await handleRestoreVersion();
  };

  const setColumnsHandle = async (modifiedColumns) => {
    const checkedAllColumnsNotDisplayed = modifiedColumns?.every(
      (column) => column?.hidden === true
    );
    if (checkedAllColumnsNotDisplayed) {
      setColumns((prev) => [...prev]);
    } else {
      const newColumns = [...modifiedColumns];
      const hiddenColumns = modifiedColumns.map((x) => x.hidden);
      const hiddenColumnsLength = hiddenColumns.filter((x) => x).length;
      hiddenColumns.pop();
      const newHiddenColumns = [
        ...newColumns.map((x, index) => {
          return {
            ...x,
            hidden: hiddenColumns[index] || false,
            ...(x.key === 'actions' &&
              hiddenColumnsLength > 0 && {
                widthAndHeight: { width: '100%', height: '20px' },
              }),
          };
        }),
      ];
      await updateTableColumns({
        tableName,
        userId: userData?._id,
        prevColumns: columns.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
        columns: newHiddenColumns.map(({ key, hidden, widthAndHeight }) => ({
          key,
          hidden,
        })),
      });
      setColumns(newHiddenColumns);
    }
  };

  const onColumnsReset = async () => {
    const defaultMatchesTableColumns = [
      ...defaultColumns({
        userData,
        navigate,
        setMatches,
        setSelectStatus,
        setDeleteAttorney,
        positionId: position?._id,
        clientId: position?.client?._id,
        matches: matches || matches?.matches,
      }),
    ];
    await updateTableColumns({
      tableName,
      userId: userData?._id,
      columns: defaultMatchesTableColumns.map(({ key, hidden }) => ({
        key,
        hidden,
      })),
      prevColumns: columns.map(({ key, hidden }) => ({
        key,
        hidden,
      })),
    });
    setColumns([...defaultMatchesTableColumns]);
    setChoseColModal(false);
  };

  const handleGenerateMatches = async ({ id, loadTimeOut }) => {
    setLoading(true);
    const pinned = matches?.matches
      ?.filter((x) => x.slot > -1)
      .map(({ _id, slot }) => {
        return { _id, slot };
      });
    const userWeightsId = id?.length ? id : userWeights?._id;
    const weights = { ...systemValues?.weights };
    removeKeys(weights, [
      'createdAt',
      'discarded',
      'isSystem',
      'positionId',
      'updatedAt',
      'userId',
      '__v',
      '_id',
    ]);
    if (
      !userWeightsId &&
      [ROLES.ADMIN, ROLES.EDITOR].includes(userData?.userType)
    )
      await addUserPositionWeights({
        data: weights,
        setAlgoWeight,
        positionId: position._id,
        userId: userData?._id,
        setUserWeights,
      });
    await generateMatches({
      body: {
        positionId: params?.id,
        clientId: position?.client?._id,
        userWeightsId,
        ...(pinned?.length && {
          pinned,
        }),
      },
      setMatches,
      setArranged,
      setIsMatchVersion,
    });
    await getAddAttorneyData({
      body: {
        positionId: position._id,
        clientId: position?.client?._id,
        attorneysToExclude: matches?.matches?.map(({ _id }) => _id) || [],
      },
      setIneligibleAttorneys,
    });
    clearTimeout(loadTimeOut);
    setLoading(false);
  };

  const handleResetGenerate = async ({ id }) => {
    !arranged && setLoading(true);
    const loadTimeOut = setTimeout(() => {
      !arranged &&
        setLoading(
          P02_SCREEN_MESSAGES?.loader?.[Math.floor(Math.random() * 2)]
        );
    }, 3000);
    await getUserPositionWeights({
      setUserWeights,
      userId: userData?._id,
      positionId: params?.id,
    });
    arranged
      ? setResetModal(true)
      : await handleGenerateMatches({ id, loadTimeOut });
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const _matchesTableColumns = await getTableColumns({
        tableName,
        userId: userData?._id,
        setColumns: setMatchesTableColumns,
      });
      const defaultMatchesTableColumns = [
        ...defaultColumns({
          userData,
          navigate,
          setMatches,
          setSelectStatus,
          setDeleteAttorney,
          positionId: position?._id,
          clientId: position?.client?._id,
          matches: matches || matches?.matches,
        }),
      ];
      if (_.size(_matchesTableColumns) > 0) {
        let _defaultColumns = defaultMatchesTableColumns?.reduce(
          (acc, curr) => {
            acc[curr.key] = curr;
            return acc;
          },
          {}
        );
        let defaultColumnStoreSorting = [];
        const hiddenColumns = _matchesTableColumns.map((x) => x.hidden);
        const hiddenColumnsLength = hiddenColumns.filter((x) => x).length;
        hiddenColumns.pop();
        _matchesTableColumns?.map((v) => {
          if (_defaultColumns[v.key])
            defaultColumnStoreSorting.push({
              ..._defaultColumns[v.key],
              hidden: v.hidden,
              ...(v.key === 'actions' &&
                hiddenColumnsLength > 0 && {
                  widthAndHeight: { width: '100%', height: '20px' },
                }),
            });
        });
        setColumns(defaultColumnStoreSorting);
      } else {
        await updateTableColumns({
          tableName,
          userId: userData?._id,
          columns: defaultMatchesTableColumns.map(({ key, hidden }) => ({
            key,
            hidden,
          })),
          prevColumns: _matchesTableColumns.map(({ key, hidden }) => ({
            key,
            hidden,
          })),
        });
        setColumns([...defaultMatchesTableColumns]);
      }
    })();
  }, [matches, userData?.userType]);

  useEffect(() => {
    handleRestoreVersion();
    const algoWeight = ({ setAlgoWeight }) => ({
      content: (
        <>
          <img src={dotIcon} alt="" />
          <img
            src={refreshIcon}
            onClick={() => setAlgoWeight(true)}
            style={{ cursor: 'pointer', marginLeft: '25px' }}
            alt=""
          />
        </>
      ),
    });
    setArrData((prev) => {
      prev[height > 660 ? 6 : 3] = algoWeight;
      return prev;
    });

    if (params?.id && userData) getAndUpdateWeights();
  }, []);

  const getAndUpdateWeights = async () => {
    const weights = await getUserPositionWeights({
      userId: userData?._id,
      positionId: position._id,
      setUserWeights,
    });

    if (!weights && [ROLES.ADMIN, ROLES.EDITOR].includes(userData?.userType)) {
      const sysWeights = { ...systemValues?.weights };
      removeKeys(sysWeights, [
        'createdAt',
        'discarded',
        'isSystem',
        'positionId',
        'updatedAt',
        'userId',
        '__v',
        '_id',
      ]);

      await addUserPositionWeights({
        data: sysWeights,
        setAlgoWeight,
        positionId: position._id,
        userId: userData?._id,
        setUserWeights,
      });
    }
  };

  return (
    <>
      <DragDropContext
        onDragEnd={(e) => {
          if (!e.destination) return;
          let tempData = Array.from(arrData);
          let [source_data] = tempData.splice(e.source.index, 1);
          tempData.splice(e.destination.index, 0, source_data);
          setArrData(tempData);
        }}
      >
        <PermissionRestrict roles={[ROLES.ADMIN]}>
          <Droppable droppableId="regenerate">
            {(provided) => (
              <div
                style={{
                  zIndex: 5,
                  top: '70px',
                  width: '5px',
                  position: 'fixed',
                  height: 'calc(100vh - 71px)',
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {arrData.map((item, index) => {
                  const element = item({ setAlgoWeight });
                  return (
                    <Draggable
                      key={`${item} ${index}`}
                      draggableId={`${item} ${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          key={`${item} ${index}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            height: '72px',
                            width: '4px',
                            ...provided.draggableProps.style,
                            ...(element?.content && {
                              width: '100px',
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: '#673e9b',
                              padding: '10px 10px 10px 0',
                              justifyContent: 'space-between',
                              borderRadius: '0px 12px 12px 0px',
                            }),
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '-webkit-fill-available',
                              ...(!element?.content && {
                                visibility: 'hidden',
                              }),
                            }}
                            {...provided.dragHandleProps}
                          >
                            {element?.content || element}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </PermissionRestrict>
        <Container>
          <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
            <Header
              edit={edit}
              watch={watch}
              errors={errors}
              control={control}
              register={register}
              position={position}
            />
          </PermissionRestrict>
          <div className={style.rightDiv}>
            {active === 0 && (
              <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                {edit ? (
                  <div style={{ display: 'flex' }}>
                    <Button
                      text={'Cancel'}
                      iconStart={crossIcon}
                      className={style.btnText}
                      btnClass={style.cancelBtn}
                      handleClick={() => {
                        reset({ placementQuantity: 0 });
                        setEdit(false);
                        setDisable(false);
                        !id && navigate(-1);
                      }}
                    />
                    <Button
                      text={'Save'}
                      type="submit"
                      iconStart={saveIcon}
                      form="add-placement"
                      btnClass={style.btnClass}
                      isLoading={isSubmitting}
                    />
                  </div>
                ) : (
                  <Button
                    text={'Edit'}
                    type="button"
                    iconStart={editIcon}
                    btnClass={style.btnClass}
                    handleClick={() => {
                      position && reset(handleReset(position));
                      setEdit('edit');
                      setDisable(true);
                    }}
                  />
                )}
              </PermissionRestrict>
            )}
          </div>

          <div className={style.retrybtnOtherButtons} style={{ width: '100%' }}>
            {params?.id && (
              <PermissionRestrict roles={[ROLES.ADMIN]}>
                <Button
                  text={'Generate Matches'}
                  btnClass={style.btnClass}
                  iconStart={generateMatchesIcon}
                  handleClick={handleResetGenerate}
                />
              </PermissionRestrict>
            )}
            <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
              <Button
                iconStart={plusIcon}
                text={'Add Attorney'}
                btnClass={style.btnClassUncheck}
                handleClick={() => {
                  setAddAttorney(true);
                }}
              />
            </PermissionRestrict>
            <Button
              iconStart={columnIcon}
              text={'Change Columns'}
              btnClass={style.btnClassUncheck}
              handleClick={() => setChoseColModal(true)}
            />
          </div>
        </Container>
        {algoWeight && (
          <MyAlgoWeights
            userWeights={userWeights}
            setAlgoWeight={setAlgoWeight}
            handleResetGenerate={handleResetGenerate}
          />
        )}
        <Container>
          {loading ? (
            <div className={style.loaderDiv}>
              <div style={{ marginBottom: '50px' }}>
                <Spinner />
              </div>
              <p>{loading}</p>
            </div>
          ) : (
            <>
              {matches?.matches?.length > 0 ? (
                <OverviewTable
                  ref={ref}
                  columns={columns}
                  editTable={editTable}
                  setArranged={setArranged}
                  setMatches={setMatches}
                  setEditTable={setEditTable}
                  rows={matches?.attorneys || matches?.matches}
                  placementQuantity={matches?.placementQuantity}
                />
              ) : (
                <NoRecords
                  noRecord={P02_SCREEN_MESSAGES.emptyPlaceholder.overview}
                  icon={true}
                  hideButton={true}
                />
              )}
            </>
          )}

          <div className={style.flexInner}>
            <div>
              {edit === 'edit' && (
                <Button
                  iconStart={delIcon}
                  text={'Delete Placement'}
                  btnClass={style.delBtn}
                  handleClick={() => {
                    setDeleteRecord(true);
                  }}
                />
              )}
            </div>
            {(matches?.attorneys?.length > 0 ||
              matches?.matches?.length > 0) && (
              <div className={style.retrybtnOtherButtons}>
                {isMatchVersion && (
                  <p className={style.p}>
                    {matches?.name ? `${matches?.name} - ` : ''} Last Updated:{' '}
                    {moment(matches?.updatedAt).format('L h:mm a')} by{' '}
                    {`${matches?.userId?.firstName} ${matches?.userId?.lastName}`}
                  </p>
                )}
                <Button
                  text={'Version History'}
                  iconStart={refresh}
                  btnClass={style.btnClassUncheck}
                  handleClick={() => {
                    setVersionModal(true);
                  }}
                />
                <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                  <Button
                    iconStart={save}
                    text={'Save as a Version'}
                    isLoading={createVersionLoader}
                    btnClass={style.btnClassUncheck}
                    handleClick={handleCreateVersion}
                    btnLoaderClass={style.btnLoaderClass}
                  />
                </PermissionRestrict>
              </div>
            )}
          </div>
        </Container>
        <ResetModal
          resetModal={resetModal}
          setLoading={setLoading}
          setResetModal={setResetModal}
          handleCreateVersion={handleCreateVersion}
          handleGenerateMatches={handleGenerateMatches}
        />
        <AddAttorneyModal
          setMatches={setMatches}
          addAttorney={addAttorney}
          setAddAttorney={setAddAttorney}
          ineligibleAttorneys={ineligibleAttorneys}
        />
        <ColumnModal
          columns={[...columns]}
          setColumns={setColumnsHandle}
          choseColModal={choseColModal}
          onColumnsReset={onColumnsReset}
          setChoseColModal={setChoseColModal}
        />
        {versionModal && (
          <VersionModal
            versionModal={versionModal}
            setEditModal={setEditModal}
            setVersionModal={setVersionModal}
            setRestoreModal={setRestoreModal}
            matchVersion={matches?._id}
          />
        )}
        <EditModal
          editModal={editModal}
          setEditModal={setEditModal}
          setVersionModal={setVersionModal}
          handleRestoreVersion={handleRestoreVersion}
        />
        <RestoreModal
          restoreModal={restoreModal}
          setRestoreModal={setRestoreModal}
          setVersionModal={setVersionModal}
          handleRestoreVersion={handleRestoreVersion}
        />
        <DeleteRecordModal
          deleteRecord={deleteRecord}
          setDeleteRecord={setDeleteRecord}
        />
        <SelectStatus
          matches={matches}
          setMatches={setMatches}
          selectStatus={selectStatus}
          setSelectStatus={setSelectStatus}
          setRemoveStatus={setRemoveStatus}
        />
        <RemoveStatusHistory
          removeStatus={removeStatus}
          setRemoveStatus={setRemoveStatus}
          setSelectStatus={setSelectStatus}
        />
        <DeleteAttorneyModal
          setMatches={setMatches}
          deleteAttorney={deleteAttorney}
          setDeleteAttorney={setDeleteAttorney}
        />
      </DragDropContext>
    </>
  );
};

export default PositionOverview;

const arr = [
  () => '',
  () => '',
  () => '',
  () => '',
  () => '',
  () => '',
  () => '',
  () => '',
  () => '',
  () => '',
  () => '',
  () => '',
  () => '',
];
