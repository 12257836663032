/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import del from 'assets/icons/del-pink.svg';
import editIcon from 'assets/icons/edit-pink.svg';
import addIcon from 'assets/images/whiteAddIcon.svg';
import Button from 'components/button';
import Loader from 'components/loader';
import Modal from 'components/modal';
import NoRecords from 'components/no-records';
import { useClientProfile } from 'contexts/client-profile';
import { C02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { ROLES } from 'permissions/constants';
import React from 'react';
import style from './contact.module.scss';
import EditContact from './editContact';
import useHelper from './helper';

const Contact = () => {
  const { getContactData } = useClientProfile();

  const {
    contact,
    edit,
    setEdit,
    add,
    setAdd,
    open,
    setOpen,
    handleDelete,
    isLoading,
  } = useHelper();

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && contact?.length < 1 ? (
        !add && (
          <div style={{ marginTop: '48px' }}>
            <NoRecords
              noRecord={C02_SCREEN_MESSAGES.emptyPlaceholder.contact}
              icon={true}
              onClick={() => {
                setAdd(true);
              }}
              btnText={'Add Contact'}
            />
          </div>
        )
      ) : (
        <>
          <p className={style.topText}>Contacts</p>
          <div className={style.scrollDiv}>
            <div className={style.innerScrollDiv}>
              {contact &&
                contact?.map((item, index) => (
                  <div>
                    {index === edit ? (
                      <EditContact
                        onSubmit={getContactData}
                        setAdd={setAdd}
                        setEdit={setEdit}
                        data={contact[index]}
                      />
                    ) : (
                      <div key={index} className={style.mainDiv}>
                        <div>
                          <p className={style.p1}>Name</p>
                          <p className={style.p2}>
                            {!item?.contactName ? '-' : item?.contactName}
                          </p>
                        </div>

                        <div>
                          <p className={style.p1}>Role</p>
                          <p className={style.p2}>
                            {!item?.contactRole ? '-' : item?.contactRole}
                          </p>
                        </div>

                        <div>
                          <p className={style.p1}>Email</p>
                          <p
                            className={style.p2}
                            style={{
                              overflowWrap: 'anywhere',
                              paddingRight: '10px',
                            }}
                          >
                            {!item?.contactEmail ? '-' : item?.contactEmail}
                          </p>
                        </div>

                        <div>
                          <p className={style.p1}>Phone Number</p>
                          <p className={style.p2}>
                            {!item?.contactPhone
                              ? '-'
                              : item?.contactPhone
                                  .replace(/\D+/g, '')
                                  .replace(
                                    /(\d{1})(\d{3})(\d{3})/,
                                    '+$1 ($2) $3-'
                                  )}
                          </p>
                        </div>
                        <div className={style.iconsDiv}>
                          <PermissionRestrict
                            roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.ATTORNEY]}
                          >
                            <img
                              src={editIcon}
                              width={25}
                              style={{ marginRight: '10px' }}
                              alt=""
                              onClick={() => {
                                setEdit(index);
                                setAdd(false);
                              }}
                            />
                            <img
                              src={del}
                              width={25}
                              alt=""
                              onClick={() => {
                                setOpen(item._id);
                              }}
                            />
                          </PermissionRestrict>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <Button
            text={'Add Contacts'}
            btnClass={style.btnWraper}
            iconStart={addIcon}
            handleClick={() => {
              setAdd(true);
            }}
          />
        </>
      )}
      {add && (
        <EditContact
          onSubmit={getContactData}
          setAdd={setAdd}
          setEdit={setEdit}
        />
      )}
      <Modal open={open} handleClose={() => setOpen(false)}>
        <div className={style.modalHeader}>
          <img src={del} height={48} width={48} />
          <h3 className={style.deleteText}>Delete Contact</h3>
          <p>Are you sure you want to delete this contact?</p>
          <Button
            btnClass={style.buttonStyle}
            text={C02_SCREEN_MESSAGES.popups.deleteConfirm.contact.yes}
            handleClick={handleDelete}
            isLoading={isLoading}
            width="100%"
          />
          <h3 className={style.cancelText} onClick={() => setOpen(false)}>
            {C02_SCREEN_MESSAGES.popups.deleteConfirm.contact.no}
          </h3>
        </div>
      </Modal>
    </>
  );
};
export default Contact;
