import React from 'react';
import { useSelector } from 'react-redux';

import SelectBox from 'components/select-box';

import style from './header.module.scss';

const Header = ({ edit, register, control, position, errors }) => {
  const { states, locations } = useSelector((state) => state?.systemValues);

  return (
    <>
      <h1 className={style.heading}>Placement Preferences</h1>
      <div className={style.overviewWraper}>
        {edit ? (
          <>
            <div>
              <SelectBox
                label="City"
                name="locations"
                control={control}
                register={register}
                options={locations?.map((x) => {
                  return { label: x?.location, value: x?._id };
                })}
                errorMessage={errors?.locations?.message}
              />
            </div>
            <div>
              <SelectBox
                name="barStates"
                label="Accepted Bars"
                control={control}
                register={register}
                options={states?.map((x) => {
                  return { label: x?.barState, value: x?._id };
                })}
                errorMessage={errors?.barStates?.message}
              />
            </div>
          </>
        ) : (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>City</p>
              <span>{position?.locations?.location || '-'}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p>Accepted Bars</p>
              <span>{position?.barStates?.barState || '-'}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Header;
