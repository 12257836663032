import React, { useState } from 'react';

import Button from 'components/button';
import Modal from 'components/modal';

import style from './remove-confirm-modal.module.scss';

const DeleteModal = ({
  openDelModal,
  setOpenDelModal,
  del,
  className,
  btnYes,
  btnNo,
  delText,
  paraText,
  btnText,
  btn2Text,
  btn1Text,
  iconSize,
  handleClick,
  delPink,
  color,
}) => {
  const [submitting, setSubmitting] = useState(false);

  return (
    <Modal
      open={openDelModal}
      handleClose={() => setOpenDelModal(false)}
      className={`${style.mainDiv} ${className}`}
    >
      <div className={style.iconRefresh}>
        <img src={del} alt="" height={iconSize} width={iconSize} />
      </div>
      {delText && (
        <h6
          className={`${style.modalTitle} ${color}`}
          style={{ color: delPink ? `${delPink}` : '' }}
        >
          {delText}
        </h6>
      )}
      <p
        style={{
          fontWeight: 400,
          fontSize: '14px',
          color: '#363636',
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {paraText ? paraText : ' Are you sure you want to delete this record?'}
      </p>
      <div className={style.mainBtnDiv}>
        {btn2Text && (
          <Button
            text={btn2Text ? btn2Text : 'Save as CSV and Delete'}
            btnClass={`${style.btnClassUncheckModal} ${btnYes} `}
            style={{ marginBottom: '10px' }}
            handleClick={async () => {
              setSubmitting(true);
              await handleClick('yes');
              setSubmitting(false);
            }}
            isLoading={submitting}
          />
        )}
        <Button
          text={btnText ? btnText : 'Yes, delete this record'}
          btnClass={`${style.btnClassModal} ${btnYes} `}
          handleClick={async () => {
            setSubmitting(true);
            await handleClick();
            setSubmitting(false);
          }}
          isLoading={submitting}
        />
        <Button
          text={btn1Text ? btn1Text : 'No, keep this record'}
          btnClass={`${style.btnClassUncheckModal} ${btnNo} `}
          handleClick={() => setOpenDelModal(false)}
        />
      </div>
    </Modal>
  );
};

export default DeleteModal;
