/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Avatar from 'components/avatar';
import Button from 'components/button';
import Container from 'components/container';
import AvatarUpload from 'components/edit-avatar';
import Modal from 'components/modal';
import SelectBox from 'components/select-box';
import Tab from 'components/tabs';
import Tags from 'components/tags';
import PermissionRestrict from 'hoc/PermissionRestrict';
import TextField from './../../components/textfield/index';

import { ATTRONEY_STATUS_COLORS, ATTRONEY_STATUS_OPTIONS } from 'constants';
import { useAttoreyProfile } from 'contexts/attorney-profile';
import { ROLES } from 'permissions/constants';
import useHelper from './helper';

import Warning from 'assets/icons/warning.svg';
import editIcon from 'assets/images/Edit.svg';
import backIcon from 'assets/images/backIcon.svg';
import backIconGrey from 'assets/images/backIconGrey.svg';
import crossIcon from 'assets/images/crossIcon.svg';
import saveIcon from 'assets/images/save.svg';

import style from './profile-top.module.scss';

const ProfileTop = ({ disable, setDisable, color }) => {
  const {
    register,
    control,
    errors,
    attorney,
    active,
    setActive,
    deletingPhoto,
    setDeletingPhoto,
    edit,
    setEdit,
    avatarDelete,
    dletingLoader,
    isLoading,
    setAdd,
    isSubmitting,
    reset,
    cancelModal,
    setCancelModal,
    watch,
  } = useAttoreyProfile();
  const { firstName, lastName, email, liStatus, profileImage, updatedAt } =
    attorney.attorney;
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { tabs } = useHelper({
    length: attorney?.meta,
    clientLength: attorney?.attorneyClientsHistory?.length,
  });

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#39695b');
  }, []);

  return (
    <div className={style.profileContainer}>
      <Container>
        <div className={style.overViewWraper}>
          <h3
            style={{
              color: '#39695B',
              margin: '0px',
              padding: '35px 0px',
            }}
          >
            Attorney Profile
          </h3>
        </div>
        <div className={style.wraper}>
          <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}>
            {!edit && (
              <img
                className={style.imgLogoBack}
                src={!disable ? backIcon : backIconGrey}
                alt={'back icon'}
                onClick={() => {
                  if (!disable) {
                    if (location.state?.from === 'new') navigate(-2);
                    else navigate(-1);
                  }
                }}
              />
            )}
          </PermissionRestrict>

          <div className={style.container}>
            <div className={style.profileImgContainer}>
              <PermissionRestrict
                roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}
              >
                {!edit && (
                  <img
                    className={style.imgLogoBack1}
                    src={!disable ? backIcon : backIconGrey}
                    alt={'back icon'}
                    onClick={() => {
                      if (!disable) {
                        if (location.state?.from === 'new') navigate(-2);
                        else navigate(-1);
                      }
                    }}
                  />
                )}
              </PermissionRestrict>

              {!edit ? (
                <div className={style.marginClass}>
                  <Avatar
                    icon={profileImage}
                    initials={`${firstName || ''} ${lastName || ''}`}
                  />
                </div>
              ) : (
                <>
                  <div className={style.marginClass}>
                    <AvatarUpload
                      deletingPhoto={deletingPhoto}
                      setDeletingPhoto={setDeletingPhoto}
                      errors={errors}
                      control={control}
                      initials={`${firstName} ${lastName}`}
                      name="profileImage"
                      loading={isLoading}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={style.userInfoWraper}>
              {edit && (
                <>
                  <div className={style.editTextSec}>
                    <TextField
                      label={'First Name'}
                      name="firstName"
                      register={register}
                      errorMessage={errors?.firstName?.message}
                    />
                  </div>
                  <div className={style.editTextSec}>
                    <TextField
                      label={'Last Name'}
                      name="lastName"
                      register={register}
                      errorMessage={errors?.lastName?.message}
                    />
                  </div>
                  <PermissionRestrict
                    roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}
                  >
                    <div className={style.editTextSec}>
                      <TextField
                        label={'Email'}
                        name="email"
                        register={register}
                        errorMessage={errors?.email?.message}
                      />
                    </div>
                    <div className={style.liEditTextSec}>
                      <SelectBox
                        name="liStatus"
                        control={control}
                        badge
                        placeholder={'Select One'}
                        options={ATTRONEY_STATUS_OPTIONS}
                        label={'LI Status'}
                        dynamicClass={style.zDynamic}
                        errorMessage={errors?.liStatus?.message}
                      />
                    </div>
                  </PermissionRestrict>
                </>
              )}
              {!edit && (
                <>
                  <div className={style.editClassInfo}>
                    <span className={style.labelspan}>First Name</span>
                    <h1 className={style.valueSpan}>
                      {firstName ? firstName : '-'}
                    </h1>
                  </div>
                  <div className={style.editClassInfo}>
                    <span className={style.labelspan}>Last Name</span>
                    <span className={style.valueSpan}>
                      <span>{lastName ? lastName : '-'}</span>
                    </span>
                  </div>
                  <PermissionRestrict
                    roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}
                  >
                    <div className={style.editClassInfo}>
                      <span className={style.labelspan}>Email</span>
                      <span className={style.valueSpan}>
                        <a href={`mailto:${email} `}>{email}</a>
                      </span>
                    </div>
                    <div className={style.editClassInfo}>
                      <span className={style.labelspan}>LI Status</span>
                      {liStatus ? (
                        <Tags
                          text={liStatus}
                          color={ATTRONEY_STATUS_COLORS[liStatus]}
                        />
                      ) : (
                        '-'
                      )}
                    </div>
                  </PermissionRestrict>
                </>
              )}
            </div>
            <div className={style.buttonSection}>
              {!edit && active == 0 && (
                <PermissionRestrict
                  roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.ATTORNEY]}
                >
                  <Button
                    text={'Edit'}
                    iconStart={editIcon}
                    btnClass={style.btnClass}
                    handleClick={() => {
                      reset();
                      setEdit(true);
                      setDisable(true);
                    }}
                  />
                </PermissionRestrict>
              )}
              {edit && (
                <>
                  <Button
                    text={'Cancel'}
                    iconStart={crossIcon}
                    btnClass={style.cancelBtn}
                    className={style.btnText}
                    type="button"
                    handleClick={() => {
                      setDeletingPhoto(false);
                      id === 'new' ? setCancelModal(true) : setEdit(false);
                      id !== 'new' && setDisable(false);
                    }}
                  />
                  {deletingPhoto ? (
                    <Button
                      text={'Save'}
                      iconStart={saveIcon}
                      type="button"
                      handleClick={() => avatarDelete()}
                      form="overview-form"
                      isLoading={dletingLoader}
                      width="100px"
                      btnClass={style.btnClass}
                    />
                  ) : (
                    <Button
                      text={'Save'}
                      iconStart={saveIcon}
                      type="submit"
                      form="overview-form"
                      isLoading={isSubmitting}
                      width="100px"
                      btnClass={style.btnClass}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Container>

      <Tab
        disable={disable}
        active={active}
        onChange={(value) => {
          setActive(value);
          setAdd(false);
        }}
        tabs={id == 'new' ? tabs.slice(0, 1) : tabs}
        color={color}
      />
      <Modal open={cancelModal} handleClose={() => setCancelModal(false)}>
        <div className={style.header}>
          <img src={Warning} height={48} width={48} />
          <p> You will lose your progress</p>
          <h3 className={style.warningText}>
            All information will be lost if you exit without saving
          </h3>
          <div className={style.buttonsWrapper}>
            <Button
              btnClass={style.buttonStyle}
              text={'Yes, delete it'}
              handleClick={() => {
                navigate(-1);
                setDisable(false);
              }}
            />
            <h3
              className={style.bottomText}
              onClick={() => setCancelModal(false)}
            >
              Whoops, no, cancel this.
            </h3>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(ProfileTop);
