/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/button';
import DeleteModal from 'components/delete-modal';
import Layout from 'components/layout';
import Loader from 'components/loader';
import Spinner from 'components/spinner';
import PermissionRestrict from 'hoc/PermissionRestrict';
import AddClient from './add-client';
import ColumnModal from './choose-columns-modal';
import ClientTable from './client-table';
import ClientHeader from './header';

import { C01_SCREEN_MESSAGES } from 'data/userFacingMessages';
import { ROLES } from 'permissions/constants';
import {
  setCLientTableFilter,
  setCheckedRowsData,
} from 'redux/filters/filters-actions';
import {
  deleteClient,
  getClients,
  getClientsCalculations,
  updateAllClientDirectory,
} from 'services/client-page';
import { getTableColumns, updateTableColumns } from 'services/utils';
import {
  downloadClientsCSV,
  getDefaultColumnOfClientTable,
  getSideBarLinksWithCalculation,
} from './helper.js';

import columnsImg from 'assets/columns-pink.png';
import exportIcon from 'assets/download-pink.svg';
import crossIcon from 'assets/icons/cross-pink.svg';
import edit from 'assets/icons/edit-pink.svg';
import delIcon from 'assets/icons/pinkTrash.svg';
import saveIcon from 'assets/images/save.svg';
import leftIcon from 'assets/pink-arrow-right.svg';

import style from './directory.module.scss';

const ClientDirectory = () => {
  const tableName = 'clientDirectory';

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userData._id);
  const { clientTableFilter, clientDirectoryChecked } = useSelector(
    (state) => state?.pageFilters
  );

  const ref = useRef();
  const headerRef = useRef();
  const isFirstRender = useRef(true);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [copyData, setCopyData] = useState([]);
  const [openArrow, setOpenArrow] = useState(0);
  const [loading, setLoading] = useState(false);
  const [noMatch, setNoMatch] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [editTable, setEditTable] = useState(false);
  const [sideBarLinks, setSideBarLinks] = useState([]);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [loaderOnTable, setLoaderOnTable] = useState(false);
  const [choseColModal, setChoseColModal] = useState(false);
  const [sort, setSort] = useState({ sortBy: '', order: '' });
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 30,
    totalPages: 1,
    totalDocuments: 0,
  });

  const checkRowIds = clientDirectoryChecked || [];
  const filters = clientTableFilter?.filters || {};
  const search = clientTableFilter?.filters?.mainFilters?.clientName ?? '';
  const currentValue = clientTableFilter?.currentValue || 'Now';
  const calculationSelected = clientTableFilter?.calculationSelected
    ? clientTableFilter?.calculationSelected
    : clientTableFilter?.calculationSelected === ''
    ? ''
    : 'All';
  const dateRange = clientTableFilter?.dateRange || [null, null];

  const [directoryFilters, setDirectoryFilters] = useState({
    page: 1,
    filters: {
      mainFilters: filters?.mainFilters || {},
      statsFilters: getStatsFilters({
        calculationSelected,
        currentValue,
      }),
    },
    dateRange,
    checkRowIds,
    pageSize: 30,
    currentValue,
    order: sort?.order,
    sortBy: sort?.sortBy,
    extraParams: { showLoaderOnFullScreen: true, clientCalculation: true },
  });

  const fetchClients = async ({
    page,
    order,
    sortBy,
    filters,
    pageSize = 30,
    currentValue = 'Now',
    clientCalculation = true,
    dateRange = [null, null],
    clientDirectoryChecked = [],
    showLoaderOnFullScreen = true,
  }) => {
    filters = {
      mainFilters: {
        ...(filters?.mainFilters || {}),
        ...(filters?.mainFilters?.startDate && {
          startDate: moment(filters.mainFilters.startDate).format('YYYY-MM-DD'),
        }),
        ...(filters?.mainFilters?.endDate && {
          endDate: moment(filters?.mainFilters?.endDate).format('YYYY-MM-DD'),
        }),
      },
      statsFilters: filters?.statsFilters || {},
    };

    showLoaderOnFullScreen ? setLoading(true) : setLoaderOnTable(true);

    if (clientCalculation && page === 1)
      await fetchCalculations({
        filters,
        dateRange,
        currentValue,
        checkedRowIds: clientDirectoryChecked,
      });

    const getAllClientsData = await getClients({
      pageSize,
      page,
      sortBy,
      order,
      filters,
      currentValue,
      dateRange: {
        startDate: dateRange[0] && moment(dateRange[0]).format('YYYY-MM-DD'),
        endDate: dateRange[1] && moment(dateRange[1]).format('YYYY-MM-DD'),
      },
      checkRowIds: checkRowIds.join(',') ?? '',
    });

    const result = getAllClientsData?.data?.clients ?? [];
    const clientsArrayWithCustomCheckField = result?.map((x) => {
      return {
        ...x,
        contractDateStart:
          !_.isEmpty(x.contractDateStart) &&
          moment(x.contractDateStart?.split('T')?.[0]),
        contractDateEnd:
          !_.isEmpty(x.contractDateEnd) &&
          moment(x.contractDateEnd?.split('T')?.[0]),
        checked:
          x?._id && clientDirectoryChecked.includes(x?._id) ? true : false,
      };
    });

    const newClientsMap = clientsArrayWithCustomCheckField?.reduce(
      (acc, curr) => {
        acc[curr._id] = true;
        return acc;
      },
      {}
    );

    if (
      !checkRowIds.length ||
      checkRowIds.filter((x) => newClientsMap[x]).length
    ) {
      setNoMatch(false);
    } else {
      setNoMatch(true);
    }

    if (page === 1) ref.current && (ref.current.scrollTop = 0);
    const _data = [...data];
    const clientsList = makeClientsListBasedOnConditions(
      _data,
      sortBy,
      order,
      clientsArrayWithCustomCheckField,
      page,
      copyData,
      checkRowIds
    );

    setData([...clientsList]);

    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        page: getAllClientsData?.data?.currentPage,
        totalPages: getAllClientsData?.data?.totalPages,
        totalDocuments: getAllClientsData?.data?.totalDocuments ?? 0,
      };
    });
    showLoaderOnFullScreen ? setLoading(false) : setLoaderOnTable(false);
  };

  const fetchCalculations = async ({
    filters,
    dateRange,
    currentValue,
    checkedRowIds = [],
  }) => {
    const { data } = await getClientsCalculations({
      filters,
      currentValue,
      dateRange: {
        startDate: dateRange[0] && moment(dateRange[0]).format('YYYY-MM-DD'),
        endDate: dateRange[1] && moment(dateRange[1]).format('YYYY-MM-DD'),
      },
      checkedRowIds,
    });
    const sideBarLinksWithCalculation = getSideBarLinksWithCalculation(data);
    setSideBarLinks(sideBarLinksWithCalculation);
  };

  const downloadClientsDirectoryIntoCsvHandleEvent = async () => {
    await downloadClientsCSV(
      copyData,
      sort?.sortBy,
      sort?.order,
      filters,
      currentValue,
      dateRange,
      columns
    );
  };

  const getNextClientData = async () => {
    const currentPage = (pagination.page += 1);
    if (currentPage <= pagination?.totalPages) {
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          page: currentPage,
        };
      });

      await fetchClients({
        filters,
        page: currentPage,
        order: sort?.order,
        sortBy: sort?.sortBy,
        currentValue: currentValue,
        showLoaderOnFullScreen: false,
      });
    }
  };

  const setCheckedRowData = async (checkedRows, allData) => {
    setLoaderOnTable(true);
    const checkedRowIds = [...(allData || checkedRows)]
      ?.filter((x) => x?.checked)
      ?.map((y) => y?._id);

    setCopyData([]);
    let _rowData = [...checkedRows];
    setCopyData(_rowData);

    dispatch(setCheckedRowsData(checkedRowIds, 'CLIENT'));
    dispatch(
      setCLientTableFilter({
        ...clientTableFilter,
        lastQuery: {
          ...clientTableFilter?.lastQuery,
          clientDirectoryChecked: checkedRowIds,
        },
      })
    );

    await fetchCalculations({ currentValue, dateRange, checkedRowIds });
    setLoaderOnTable(false);
  };

  const getSelectedSortingHandleEvenet = async (sortObject) => {
    const _columns = [...columns];
    let findColumnIndex = _columns.findIndex(
      (column) =>
        column.sortKey === sortObject.sortBy || column.key === sortObject.sortBy
    );
    _columns[findColumnIndex] = {
      ..._columns[findColumnIndex],
      icon:
        sortObject?.order === 'asc'
          ? 'up'
          : sortObject.order === 'desc'
          ? 'down'
          : 'up',
    };
    setColumns([..._columns]);
    setSort(sortObject);
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        page: 1,
      };
    });

    await fetchClients({
      page: 1,
      dateRange,
      currentValue,
      clientCalculation: false,
      order: sortObject?.order,
      sortBy: sortObject?.sortBy,
      showLoaderOnFullScreen: false,
      filters: clientTableFilter?.lastQuery?.filters,
    });
  };

  const setColumnsHanlde = async (modifiedColumns) => {
    const checkedAllColumnsNotDisplayed = modifiedColumns?.every(
      (column) => column?.hidden === true
    );
    const columnsWithActionRowNotHidden = modifiedColumns.map((column) => {
      if (column?.key === 'actions') return { ...column, hidden: false };
      else return { ...column };
    });

    if (!checkedAllColumnsNotDisplayed) {
      await updateTableColumns({
        userId,
        tableName,
        prevColumns: columns.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
        columns: columnsWithActionRowNotHidden.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
      });
      setColumns(columnsWithActionRowNotHidden);
    } else setColumns((prev) => [...prev]);
  };

  const onColumnsReset = async () => {
    const defaultColumns = getDefaultColumnOfClientTable(
      setDelModal,
      setDeleteRowId
    );
    await updateTableColumns({
      userId,
      tableName,
      columns: defaultColumns.map(({ key, hidden }) => ({
        key,
        hidden,
      })),
      prevColumns: columns.map(({ key, hidden }) => ({
        key,
        hidden,
      })),
    });
    setColumns([...defaultColumns]);
    setChoseColModal(false);
  };

  const getDataFromClientAfterApplyingFilter = async ({
    filterData,
    newCalculation,
    currentValue = 'Now',
  }) => {
    setDirectoryFilters((prev) => ({
      ...prev,
      page: 1,
      dateRange,
      currentValue,
      filters: filterData,
      extraParams: {
        clientCalculation: true,
        showLoaderOnFullScreen: false,
      },
    }));
    dispatch(
      setCLientTableFilter({
        ...clientTableFilter,
        currentValue,
        filters: filterData,
        calculationSelected: newCalculation,
      })
    );
  };

  const convertSelectedStatsTableCalculationToFilter = async (value) => {
    let _currentValue = currentValue;
    let _dateRange = dateRange;
    let getCalculations = false;

    const _filters = getStatsFilters({
      calculationSelected: value,
      currentValue,
    });

    if (value === 'Headhunter (cumulative)') {
      getCalculations = currentValue === 'Cumulative' ? false : true;
      _currentValue = 'Cumulative';
      _dateRange = [null, null];
    }

    dispatch(
      setCLientTableFilter({
        ...clientTableFilter,
        calculationSelected: value,
        filters: {
          mainFilters: filters?.mainFilters || {},
          statsFilters: _filters,
        },
        currentValue: _currentValue,
        dateRange: _dateRange,
      })
    );
    setDirectoryFilters((prev) => ({
      ...prev,
      page: 1,
      filters: {
        mainFilters: filters?.mainFilters || {},
        statsFilters: _filters,
      },
      currentValue: _currentValue,
      extraParams: {
        clientCalculation: getCalculations,
        showLoaderOnFullScreen: false,
      },
    }));
  };

  const updateClientDataHanleEvent = async (updatedRows) => {
    setLoaderOnTable(true);
    const clientsDirectoryMap = data.reduce((acc, curr) => {
      acc[curr._id] = curr;
      return acc;
    }, {});
    const dataForUpdate = updatedRows?.map((x) => {
      return {
        _id: x?._id,
        clientName: x?.clientName || '',
        clientStatus: x?.clientStatus || '',
        clientType: x?.clientType || '',
        clientHistoryId: clientsDirectoryMap[x?._id]?.clientHistory?._id || '',
        contractDateStart: x?.contractDateStart || '',
        contractDateEnd: x?.contractDateEnd || '',
      };
    });
    await updateAllClientDirectory(dataForUpdate);
    await fetchCalculations({ currentValue, dateRange });
    setLoaderOnTable(false);
    setEditTable(false);
  };

  const unCheckAll = () => {
    setNoMatch(false);
    dispatch(setCheckedRowsData([], 'CLIENT'));
    setCopyData((prev) => {
      let prevCopy = [...prev];
      for (const el of prevCopy) {
        el.checked = false;
      }
      return prevCopy;
    });
    ref.current?.clearAllCheckRows();
    setDirectoryFilters((prev) => ({
      ...prev,
      extraParams: {
        showLoaderOnFullScreen: false,
        clientCalculation: true,
      },
    }));
  };

  const clearFilters = () => {
    setNoMatch(false);
    headerRef.current?.clearAllFiltersWhenNoRowIsMatched(currentValue);
  };

  const deleteClientById = async () => {
    await deleteClient(deleteRowId);
    unCheckAll();
    setDelModal(false);
    setDeleteRowId(null);
    let newDataRowAfterDeleteClient = [...data];
    newDataRowAfterDeleteClient = newDataRowAfterDeleteClient?.filter(
      (x) => x?._id !== deleteRowId
    );
    setData([...newDataRowAfterDeleteClient]);
    setPagination((prev) => {
      return {
        ...prev,
        totalDocuments: prev?.totalDocuments - 1,
      };
    });
    await fetchCalculations({ currentValue, dateRange });
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#A93E71');
    document.documentElement.style.setProperty(
      '--primary-color-of-column-change',
      '#A93E71'
    );
    document.documentElement.style.setProperty(
      '--primary-color-of-stats',
      '#A93E71'
    );
  }, []);

  useEffect(() => {
    (async () => {
      const _clientTableColumns = await getTableColumns({
        userId,
        tableName,
      });
      const defaultColumns = getDefaultColumnOfClientTable(
        setDelModal,
        setDeleteRowId
      );
      if (_.size(_clientTableColumns) > 0) {
        let _defaultColumns = defaultColumns?.reduce((acc, curr) => {
          acc[curr.key] = curr;
          return acc;
        }, {});
        let defaultColumnStoreSorting = [];
        _clientTableColumns?.map((v) => {
          if (_defaultColumns[v.key])
            defaultColumnStoreSorting.push({
              ..._defaultColumns[v.key],
              hidden: v.hidden,
            });
        });
        setColumns(defaultColumnStoreSorting);
      } else {
        await updateTableColumns({
          userId,
          tableName,
          columns: defaultColumns.map(({ key, hidden }) => ({ key, hidden })),
          prevColumns: _clientTableColumns.map(({ key, hidden }) => ({
            key,
            hidden,
          })),
        });
        setColumns([...defaultColumns]);
      }
    })();
  }, []);

  useEffect(() => {
    const isFirst = isFirstRender.current;

    const lastQuery =
      isFirstRender.current && !_.isEmpty(clientTableFilter?.lastQuery)
        ? clientTableFilter?.lastQuery
        : directoryFilters;

    const newQuery = {
      clientDirectoryChecked,
      page: directoryFilters?.page,
      order: directoryFilters?.order,
      sortBy: directoryFilters?.sortBy,
      filters: directoryFilters?.filters,
      dateRange: directoryFilters?.dateRange,
      currentValue: directoryFilters?.currentValue,
      clientCalculation: directoryFilters?.extraParams?.clientCalculation,
      showLoaderOnFullScreen: false,
    };

    const currentQuery = {
      ...(isFirst ? lastQuery : newQuery),
      ...(isFirst && {
        clientCalculation: true,
        showLoaderOnFullScreen: true,
      }),
    };

    dispatch(
      setCLientTableFilter({
        ...clientTableFilter,
        lastQuery: isFirst ? lastQuery : newQuery,
      })
    );

    fetchClients(currentQuery);

    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [directoryFilters]);

  return (
    <>
      {loading && (
        <div className={style.mainLoader}>
          <Loader cStyles={{ backgroundColor: '#F8FFFD' }} />
        </div>
      )}
      <Layout
        sidebarLinks={sideBarLinks}
        current={calculationSelected}
        setCurrent={convertSelectedStatsTableCalculationToFilter}
        openArrow={openArrow}
        setOpenArrow={setOpenArrow}
        leftIconArrow={leftIcon}
      >
        <>
          <ClientHeader
            {...{
              search,
              filters,
              dateRange,
              unCheckAll,
              ref: headerRef,
              setDirectoryFilters,
              getDataFromClientAfterApplyingFilter,
              setPageSize: (page) => {
                setPagination((prev) => {
                  return {
                    ...prev,
                    page,
                  };
                });
              },
            }}
          />
          <div className={style.btnClass}>
            <p className={style.all}>
              Results (
              {(!noMatch && checkRowIds?.length < data?.length
                ? checkRowIds?.length
                : data?.length) || data?.length}
              )
            </p>
            <div className={style.btn}>
              {editTable ? (
                <>
                  <Button
                    text={'Cancel'}
                    iconStart={crossIcon}
                    btnClass={style.cancelBtn}
                    handleClick={() => setEditTable(false)}
                  />
                  <Button
                    text={'Save'}
                    iconStart={saveIcon}
                    type="submit"
                    form="overview-form1"
                    btnClass={style.defaultClass}
                  />
                </>
              ) : (
                <>
                  <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                    <Button
                      iconStart={edit}
                      text="Edit"
                      handleClick={() => setEditTable(true)}
                      btnClass={style.btnDropdown}
                    />
                  </PermissionRestrict>
                  <Button
                    type="button"
                    iconStart={exportIcon}
                    btnClass={style.btnDropdown}
                    text="Export"
                    handleClick={async () =>
                      await downloadClientsDirectoryIntoCsvHandleEvent()
                    }
                  />
                  <Button
                    iconStart={columnsImg}
                    text="Change Columns"
                    handleClick={() => setChoseColModal(true)}
                    btnClass={style.chooseColumnBtn}
                  />
                </>
              )}
            </div>
          </div>
          {data.length == 0 ? (
            !loaderOnTable ? (
              <>
                {noMatch &&
                  data.map((x) => x._id).filter((x) => checkRowIds.includes(x))
                    .length === 0 && (
                    <div className={style.noMatch}>
                      <span>
                        Oops! Looks like no checked rows match your filters.
                      </span>
                      <Button
                        text="Uncheck All"
                        handleClick={unCheckAll}
                        style={{
                          marginRight: '10px',
                        }}
                      />
                      <Button text="Clear Filters" handleClick={clearFilters} />
                    </div>
                  )}
                <AddClient modalHeight="400px" showLabel={false} />
              </>
            ) : (
              <Spinner pageLoader={true} diffHeight="501" />
            )
          ) : (
            <>
              {noMatch &&
                data.map((x) => x._id).filter((x) => checkRowIds.includes(x))
                  .length === 0 && (
                  <div className={style.noMatch}>
                    <span>
                      Oops! Looks like no checked rows match your filters.
                    </span>
                    <Button
                      text="Uncheck All"
                      handleClick={unCheckAll}
                      style={{
                        marginRight: '10px',
                      }}
                    />
                    <Button text="Clear Filters" handleClick={clearFilters} />
                  </div>
                )}

              <ClientTable
                {...{
                  ref,
                  columns,
                  noMatch,
                  editTable,
                  rows: data,
                  setEditTable,
                  openArrow,
                  setCheckedRowData,
                  order: sort?.order,
                  sortBy: sort?.sortBy,
                  loading: loaderOnTable,
                  page: pagination?.page,
                  formId: 'overview-form1',
                  getNextData: getNextClientData,
                  updateClientData: updateClientDataHanleEvent,
                  getSelectedSorting: getSelectedSortingHandleEvenet,
                }}
              />
            </>
          )}
        </>
      </Layout>
      <ColumnModal
        columns={[...columns]}
        setColumns={setColumnsHanlde}
        choseColModal={choseColModal}
        onColumnsReset={onColumnsReset}
        setChoseColModal={setChoseColModal}
      />
      <DeleteModal
        openDelModal={delModal}
        setOpenDelModal={setDelModal}
        del={delIcon}
        color={style.colorText}
        btnYes={style.btnYes}
        btnNo={style.btnNo}
        handleClick={deleteClientById}
        iconSize="48px"
        delText={C01_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.body}
        btnText={C01_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.yes}
        btn1Text={C01_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.no}
      />
    </>
  );
};
export default ClientDirectory;

const getStatsFilters = ({ calculationSelected, currentValue }) => {
  let _filters = {};

  const clientStatus =
    currentValue === 'Now'
      ? ['Active client']
      : ['Active client', 'Former client'];
  if (calculationSelected === 'Clients') _filters = { clientStatus };
  if (calculationSelected === 'Opportunities')
    _filters = {
      clientStatus:
        currentValue === 'Now'
          ? ['Active opportunity']
          : ['Active opportunity', 'Inactive opportunity'],
    };
  if (calculationSelected === 'Law Firms')
    _filters = { clientStatus, clientType: ['Law Firm'] };
  if (calculationSelected === 'Corporate')
    _filters = { clientStatus, clientType: ['Corporate'] };
  if (calculationSelected === 'Hybrid')
    _filters = { clientStatus, clientType: ['Hybrid'] };
  if (calculationSelected === 'Headhunter (cumulative)') {
    _filters = { clientType: ['Head Hunter'] };
  }
  if (calculationSelected === 'Clients with Open Positions') {
    _filters = { clientStatus, haveOpenPosition: true };
  }
  return _filters;
};

const makeClientsListBasedOnConditions = (
  _data,
  sortBy,
  order,
  clientsArrayWithCustomCheckField,
  page,
  copyData,
  checkRowIds
) => {
  if (page === 1)
    clientsArrayWithCustomCheckField = clientsArrayWithCustomCheckField?.map(
      (x) => {
        const find = copyData?.find((y) => y?._id === x?._id && y?.checked);
        if (_.isEmpty(find)) return x;
        else return { ...x, checked: true };
      }
    );

  let clientsList = [];
  if (_.isEmpty(sortBy) && _.isEmpty(order)) {
    if (page === 1) clientsList = [...clientsArrayWithCustomCheckField];
    else {
      if (_.isEmpty(copyData))
        clientsList = [..._data, ...clientsArrayWithCustomCheckField];
      else {
        const prevUncheckValue = _data?.filter(
          (x) => !checkRowIds.includes(x?._id)
        );
        clientsList = [
          ...copyData,
          ...prevUncheckValue,
          ...clientsArrayWithCustomCheckField,
        ];
      }
    }
  } else {
    if (!_.isEmpty(sortBy) && !_.isEmpty(order))
      clientsList = [...clientsArrayWithCustomCheckField];
    else {
      if (_.isEmpty(copyData)) {
        clientsList =
          page === 1
            ? [...clientsArrayWithCustomCheckField]
            : [..._data, ...clientsArrayWithCustomCheckField];
      } else if (!_.isEmpty(copyData)) {
        const setSortingDataChecked = clientsArrayWithCustomCheckField?.map(
          (x) => {
            const find = copyData?.find((y) => y?._id === x?._id && y?.checked);
            if (_.isEmpty(find)) return x;
            else return { ...x, checked: true };
          }
        );
        clientsList = setSortingDataChecked;
      }
    }
  }
  return clientsList;
};
