import { useEffect, useState } from 'react';

import {
  getAttorneyBarHistory,
  deleteBarHistory,
} from 'services/attorney-profile';
import { createNotification } from 'components/react-notification';
import { useSelector } from 'react-redux';

const useHelper = () => {
  const attorneyData = useSelector((state) => state.attorney.attorneyData);

  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [barHistory, setBarHistory] = useState([]);

  useEffect(() => {
    if (!attorneyData?.attorney?._id) return;
    getBarsData();
  }, [attorneyData?.attorney?._id]);

  const getBarsData = async () => {
    setIsLoading(true);
    try {
      const res = await getAttorneyBarHistory(attorneyData.attorney._id);

      setBarHistory([...res.data.bars]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  //delete
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteBarHistory(open);
      getBarsData();
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
    setOpen(false);
  };

  const onAdd = () => {
    getBarsData();
  };

  const onEdit = () => {
    getBarsData();
  };

  return {
    onAdd,
    onEdit,
    setEdit,
    setOpen,
    setToggle,
    handleDelete,
    edit,
    barHistory,
    open,
    toggle,
    isLoading,
    getBarsData,
  };
};

export default useHelper;
