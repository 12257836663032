import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/button';
import DeleteModal from 'components/delete-modal';
import Layout from 'components/layout';
import Loader from 'components/loader';
import Spinner from 'components/spinner';
import PermissionRestrict from 'hoc/PermissionRestrict';
import AddPosition from './add-position';
import ColumnModal from './choose-columns-modal';
import PositionHeader from './header';
import PositionTable from './position-table';

import { P01_SCREEN_MESSAGES } from 'data/userFacingMessages';
import { ROLES } from 'permissions/constants';
import {
  setCheckedRowsData,
  setPositionTableFilter,
} from 'redux/filters/filters-actions';
import {
  deletePosition,
  getAllPositionsWithClients,
  getPositionCalculation,
  updateAllPositionsDirectory,
} from 'services/positions';
import { getTableColumns, updateTableColumns } from 'services/utils';
import {
  downloadPositionsCSV,
  getDefaultColumnOfPositionsTable,
  getSideBarLinksWithCalculation,
} from './helper';

import crossIcon from 'assets/icons/close-purple.svg';
import columnsImg from 'assets/icons/columns-purple.svg';
import edit from 'assets/icons/edit-purple.svg';
import leftIcon from 'assets/icons/purple-arrow-left.svg';
import exportIcon from 'assets/icons/purple-export.svg';
import delIcon from 'assets/images/blueDelIcon.svg';
import saveIcon from 'assets/images/save.svg';

import style from './positions.module.scss';

const PositionsDirectory = () => {
  const tableName = 'positionDirectory';

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.userData._id);
  const { practiceGroups } = useSelector((state) => state?.systemValues);
  const { locations } = useSelector((state) => state?.systemValues);
  const { positionTableFilter, positionDirectoryChecked } = useSelector(
    (state) => state?.pageFilters
  );

  const ref = useRef();
  const headerRef = useRef();
  const isFirstRender = useRef(true);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [copyData, setCopyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openArrow, setOpenArrow] = useState(0);
  const [noMatch, setNoMatch] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [sideBarLinks, setSideBarLinks] = useState([]);
  const [choseColModal, setChoseColModal] = useState(false);
  const [loaderOnTable, setLoaderOnTable] = useState(false);
  const [sort, setSort] = useState({ sortBy: '', order: '' });

  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 30,
    totalPages: 1,
    totalDocuments: 0,
  });
  const checkRowIds = positionDirectoryChecked || [];
  const filters = positionTableFilter?.filters || {};
  const search = positionTableFilter?.filters?.clientName ?? '';
  const currentValue = positionTableFilter?.currentValue || 'Now';
  const calculationSelected = positionTableFilter?.calculationSelected
    ? positionTableFilter?.calculationSelected
    : positionTableFilter?.calculationSelected === ''
    ? ''
    : 'All';
  const dateRange = positionTableFilter?.dateRange || [null, null];
  const [directoryFilters, setDirectoryFilters] = useState({
    page: 1,
    filters: {
      ...filters,
      ...getStatsFilters({
        calculationSelected,
      }),
    },
    dateRange,
    checkRowIds,
    pageSize: 30,
    currentValue,
    order: sort?.order,
    sortBy: sort?.sortBy,
    extraParams: { showLoaderOnFullScreen: true, clientCalculation: true },
  });

  const fetchPositions = async ({
    page,
    order,
    sortBy,
    filters,
    pageSize = 30,
    currentValue = 'Now',
    positionCalculation = true,
    dateRange = [null, null],
    positionDirectoryChecked = [],
    showLoaderOnFullScreen = true,
  }) => {
    showLoaderOnFullScreen ? setLoading(true) : setLoaderOnTable(true);
    if (currentValue === 'Now') dateRange = [new Date(), new Date()];

    if (positionCalculation && page === 1)
      await fetchCalculations({
        filters,
        dateRange,
        currentValue,
        checkedRowIds: positionDirectoryChecked,
      });

    const getAllPositionsData = await getAllPositionsWithClients({
      pageSize,
      page,
      sortBy,
      order,
      filters: {
        ...filters,
        ...(filters.sowStartDate && {
          sowStartDate: moment(filters.sowStartDate).format('YYYY-MM-DD'),
        }),
        ...(filters.sowEndDate && {
          sowEndDate: moment(filters.sowEndDate).format('YYYY-MM-DD'),
        }),
      },
      currentValue,
      dateRange: {
        startDate: dateRange[0] && moment(dateRange[0]).format('YYYY-MM-DD'),
        endDate: dateRange[1] && moment(dateRange[1]).format('YYYY-MM-DD'),
      },
    });
    const result = getAllPositionsData?.data?.positions ?? [];
    const positionsArrayWithCustomCheckField = result?.map((x) => {
      return {
        ...x,
        sowEndDate: x?.sowEndDate
          ? moment(x?.sowEndDate?.split?.('T')?.[0])
          : '',
        sowStartDate: x?.sowStartDate
          ? moment(x?.sowStartDate?.split?.('T')?.[0])
          : '',
        checked:
          x?._id && positionDirectoryChecked.includes(x?._id) ? true : false,
      };
    });

    const newPositionsMap = getAllPositionsData?.data?.positions?.reduce(
      (acc, curr) => {
        acc[curr._id] = true;
        return acc;
      },
      {}
    );
    if (
      !checkRowIds.length ||
      checkRowIds.filter((x) => newPositionsMap[x]).length
    ) {
      setNoMatch(false);
    } else {
      setNoMatch(true);
    }

    if (page === 1) ref.current && (ref.current.scrollTop = 0);
    const _data = [...data];
    const positionsList = makePositionsListBasedOnConditions(
      _data,
      sortBy,
      order,
      positionsArrayWithCustomCheckField,
      page,
      copyData,
      checkRowIds
    );

    setData([...positionsList]);

    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        page: getAllPositionsData?.data?.currentPage,
        totalPages: getAllPositionsData?.data?.totalPages,
        totalDocuments: getAllPositionsData?.data?.totalDocuments ?? 0,
      };
    });
    showLoaderOnFullScreen ? setLoading(false) : setLoaderOnTable(false);
  };

  const fetchCalculations = async ({
    filters,
    dateRange,
    currentValue,
    checkedRowIds = [],
  }) => {
    const { data } = await getPositionCalculation({
      filters,
      currentValue,
      dateRange: {
        startDate: dateRange[0]
          ? moment(dateRange[0]).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        endDate: dateRange[1]
          ? moment(dateRange[1]).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
      },
      checkedRowIds,
    });
    const sideBarLinksWithCalculation = getSideBarLinksWithCalculation(data);
    setSideBarLinks(sideBarLinksWithCalculation);
  };

  const downloadPositionsDirectoryIntoCsvHandleEvent = async () => {
    await downloadPositionsCSV(
      copyData,
      sort?.sortBy,
      sort?.order,
      filters,
      currentValue,
      dateRange,
      columns
    );
  };

  const getNextPositionData = async () => {
    const currentPage = (pagination.page += 1);
    if (currentPage <= pagination?.totalPages) {
      setPagination((prevPagination) => {
        return {
          ...prevPagination,
          page: currentPage,
        };
      });

      await fetchPositions({
        filters,
        page: currentPage,
        order: sort?.order,
        sortBy: sort?.sortBy,
        currentValue: currentValue,
        showLoaderOnFullScreen: false,
      });
    }
  };

  const setCheckedRowData = async (checkedRows, allData) => {
    setLoaderOnTable(true);
    const checkedRowIds = [...(allData || checkedRows)]
      ?.filter((x) => x?.checked)
      ?.map((y) => y?._id);

    setCopyData([]);
    let _rowData = [...checkedRows];
    setCopyData(_rowData);
    dispatch(setCheckedRowsData(checkedRowIds, 'POSITION'));
    dispatch(
      setPositionTableFilter({
        ...positionTableFilter,
        lastQuery: {
          ...positionTableFilter?.lastQuery,
          positionDirectoryChecked: checkedRowIds,
        },
      })
    );
    await fetchCalculations({ currentValue, dateRange, checkedRowIds });
    setLoaderOnTable(false);
  };

  const getSelectedSortingHandleEvenet = async (sortObject) => {
    const _columns = [...columns];
    let findColumnIndex = _columns.findIndex(
      (column) =>
        column.sortKey === sortObject.sortBy || column.key === sortObject.sortBy
    );
    _columns[findColumnIndex] = {
      ..._columns[findColumnIndex],
      icon:
        sortObject?.order === 'asc'
          ? 'up'
          : sortObject.order === 'desc'
          ? 'down'
          : 'up',
    };
    setColumns([..._columns]);
    setSort(sortObject);
    setPagination((prevPagination) => {
      return {
        ...prevPagination,
        page: 1,
      };
    });
    await fetchPositions({
      page: 1,
      dateRange,
      order: sortObject?.order,
      sortBy: sortObject?.sortBy,
      positionCalculation: false,
      currentValue: currentValue,
      showLoaderOnFullScreen: false,
      filters: positionTableFilter?.lastQuery?.filters,
    });
  };

  const setColumnsHanlde = async (modifiedColumns) => {
    const checkedAllColumnsNotDisplayed = modifiedColumns?.every(
      (column) => column?.hidden === true
    );
    const columnsWithActionRowNotHidden = modifiedColumns.map((column) => {
      if (column?.key === 'actions') return { ...column, hidden: false };
      else return { ...column };
    });

    if (!checkedAllColumnsNotDisplayed) {
      await updateTableColumns({
        userId,
        tableName,
        prevColumns: columns.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
        columns: columnsWithActionRowNotHidden.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
      });
      setColumns(columnsWithActionRowNotHidden);
    } else setColumns((prev) => [...prev]);
  };

  const onColumnsReset = async () => {
    const defaultColumns = getDefaultColumnOfPositionsTable(
      setDelModal,
      setDeleteRowId,
      practiceGroups,
      locations ?? []
    );
    await updateTableColumns({
      userId,
      tableName,
      prevColumns: columns.map(({ key, hidden }) => ({ key, hidden })),
      columns: defaultColumns.map(({ key, hidden }) => ({ key, hidden })),
    });
    setColumns([...defaultColumns]);
    setChoseColModal(false);
  };

  const getDataFromPositionAfterApplyingFilter = async ({
    filterData,
    newCalculation,
    currentValue = 'Now',
  }) => {
    setDirectoryFilters((prev) => ({
      ...prev,
      page: 1,
      dateRange,
      currentValue,
      filters: filterData,
      extraParams: {
        showLoaderOnFullScreen: false,
      },
    }));
    dispatch(
      setPositionTableFilter({
        ...positionTableFilter,
        currentValue,
        filters: filterData,
        calculationSelected: newCalculation,
      })
    );
  };

  // yet to be worked on apply the stats filter and update same as in client directory
  const convertSelectedStatsTableCalculationToFilter = async (value) => {
    setDirectoryFilters((prev) => ({
      ...prev,
      page: 1,
      dateRange,
      currentValue,
      filters: {
        ...filters,
        statsFilters: getStatsFilters({ calculationSelected: value }),
      },
      extraParams: {
        positionCalculation: false,
        showLoaderOnFullScreen: false,
      },
    }));
    dispatch(
      setPositionTableFilter({
        ...positionTableFilter,
        calculationSelected: value,
      })
    );
  };
  // yet to be worked on apply the stats filter and update same as in client directory

  const updatePositionsDataHanleEvent = async (updatedRows) => {
    setLoaderOnTable(true);
    const clientsDirectoryMap = data.reduce((acc, curr) => {
      acc[curr._id] = curr;
      return acc;
    }, {});

    const dataForUpdate = updatedRows?.map((x) => {
      return {
        _id: x?._id,
        placementQuantity: x?.placementQuantity || '',
        practiceGroup: x?.practiceGroup || '',
        sowStartDate: x?.sowStartDate || '',
        sowEndDate: x?.sowEndDate || '',
        clientType: x?.clientType || '',
        clientId: clientsDirectoryMap[x?._id]?.clientId || '',
        // locationName: clientsDirectoryMap[x?._id]?.locationName || '',
        practiceGroupId: clientsDirectoryMap[x?._id]?.practiceGroupId || '',
        newPracticeGroupId: practiceGroups?.find(
          (y) => y?.practiceGroup === x?.practiceGroup
        )?._id,
      };
    });
    await updateAllPositionsDirectory(dataForUpdate);
    setLoaderOnTable(false);
    setEditTable(false);
  };

  const unCheckAll = () => {
    setNoMatch(false);
    dispatch(setCheckedRowsData([], 'POSITION'));
    setCopyData((prev) => {
      let prevCopy = [...prev];
      for (const el of prevCopy) {
        el.checked = false;
      }
      return prevCopy;
    });
    ref.current?.clearAllCheckRows();
    setDirectoryFilters((prev) => ({
      ...prev,
      extraParams: {
        showLoaderOnFullScreen: false,
        clientCalculation: true,
      },
    }));
  };

  const clearFilters = () => {
    setNoMatch(false);
    headerRef.current?.clearAllFiltersWhenNoRowIsMatched(currentValue);
  };

  const deletePositionById = async () => {
    await deletePosition({ id: deleteRowId });
    unCheckAll();
    setDelModal(false);
    setDeleteRowId(null);

    let newDataRowAfterDeletePosition = [...data];
    newDataRowAfterDeletePosition = newDataRowAfterDeletePosition?.filter(
      (x) => x?._id !== deleteRowId
    );
    setData([...newDataRowAfterDeletePosition]);
    setPagination((prev) => {
      return {
        ...prev,
        totalDocuments: prev?.totalDocuments - 1,
      };
    });
    await fetchCalculations({ currentValue, dateRange });
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#673e9b');
    document.documentElement.style.setProperty(
      '--primary-color-of-column-change',
      '#673e9b'
    );
    document.documentElement.style.setProperty(
      '--primary-color-of-stats',
      '#673e9b'
    );
  }, []);

  useEffect(() => {
    (async () => {
      const _positionTableColumns = await getTableColumns({
        userId,
        tableName,
      });
      const defaultColumns = getDefaultColumnOfPositionsTable(
        setDelModal,
        setDeleteRowId,
        practiceGroups,
        locations ?? []
      );
      if (_.size(_positionTableColumns) > 0) {
        let _defaultColumns = defaultColumns?.reduce((acc, curr) => {
          acc[curr.key] = curr;
          return acc;
        }, {});
        let defaultColumnStoreSorting = [];
        _positionTableColumns?.map((v) => {
          if (_defaultColumns[v.key])
            defaultColumnStoreSorting.push({
              ..._defaultColumns[v.key],
              hidden: v.hidden,
            });
        });
        setColumns(defaultColumnStoreSorting);
      } else {
        await updateTableColumns({
          userId,
          tableName,
          columns: defaultColumns.map(({ key, hidden }) => ({ key, hidden })),
          prevColumns: _positionTableColumns.map(({ key, hidden }) => ({
            key,
            hidden,
          })),
        });
        setColumns([...defaultColumns]);
      }
    })();
  }, []);

  useEffect(() => {
    const isFirst = isFirstRender.current;

    const lastQuery =
      isFirstRender.current && !_.isEmpty(positionTableFilter?.lastQuery)
        ? positionTableFilter?.lastQuery
        : directoryFilters;

    const newQuery = {
      positionDirectoryChecked,
      page: directoryFilters?.page,
      order: directoryFilters?.order,
      sortBy: directoryFilters?.sortBy,
      filters: directoryFilters?.filters,
      dateRange: directoryFilters?.dateRange,
      currentValue: directoryFilters?.currentValue,
      positionCalculation: directoryFilters?.extraParams?.positionCalculation,
      showLoaderOnFullScreen: false,
    };

    const currentQuery = {
      ...(isFirst ? lastQuery : newQuery),
      ...(isFirst && {
        positionCalculation: true,
        showLoaderOnFullScreen: true,
      }),
    };

    dispatch(
      setPositionTableFilter({
        ...positionTableFilter,
        lastQuery: isFirst ? lastQuery : newQuery,
      })
    );

    fetchPositions(currentQuery);

    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  }, [directoryFilters]);

  return (
    <>
      {loading && (
        <div className={style.mainLoader}>
          <Loader cStyles={{ backgroundColor: '#F8FFFD' }} />
        </div>
      )}
      <Layout
        sidebarLinks={sideBarLinks}
        current={calculationSelected}
        setCurrent={convertSelectedStatsTableCalculationToFilter}
        openArrow={openArrow}
        setOpenArrow={setOpenArrow}
        leftIconArrow={leftIcon}
      >
        <>
          <PositionHeader
            {...{
              search,
              filters,
              dateRange,
              unCheckAll,
              checkRowIds,
              ref: headerRef,
              setDirectoryFilters,
              getDataFromPositionAfterApplyingFilter,
              setPageSize: (page) => {
                setPagination((prev) => {
                  return {
                    ...prev,
                    page,
                  };
                });
              },
            }}
          />
          <div className={style.btnClass}>
            <p className={style.all}>
              Results (
              {(!noMatch && checkRowIds?.length < data?.length
                ? checkRowIds?.length
                : data?.length) || data?.length}
              )
            </p>
            <div className={style.btn}>
              {editTable ? (
                <>
                  <Button
                    text={'Cancel'}
                    iconStart={crossIcon}
                    btnClass={style.btnDropdown}
                    handleClick={() => setEditTable(false)}
                  />
                  <Button
                    text={'Save'}
                    type="submit"
                    iconStart={saveIcon}
                    form="overview-form2"
                    btnClass={style.defaultClass}
                  />
                </>
              ) : (
                <>
                  <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                    <Button
                      iconStart={edit}
                      text="Edit"
                      handleClick={() => setEditTable(true)}
                      btnClass={style.btnDropdown}
                    />
                  </PermissionRestrict>
                  <Button
                    type="button"
                    iconStart={exportIcon}
                    text="Export"
                    btnClass={style.btnDropdown}
                    handleClick={async () =>
                      await downloadPositionsDirectoryIntoCsvHandleEvent()
                    }
                  />
                  <Button
                    iconStart={columnsImg}
                    text="Change Columns"
                    btnClass={style.chooseColumnBtn}
                    handleClick={() => setChoseColModal(true)}
                  />
                </>
              )}
            </div>
          </div>

          {data.length == 0 ? (
            !loaderOnTable ? (
              <>
                {noMatch &&
                  data.map((x) => x._id).filter((x) => checkRowIds.includes(x))
                    .length === 0 && (
                    <div className={style.noMatch}>
                      <span
                        style={{
                          marginBottom: '5px',
                        }}
                      >
                        Oops! Looks like no checked rows match your filters.
                      </span>
                      <Button
                        text="Uncheck All"
                        handleClick={unCheckAll}
                        style={{
                          marginRight: '10px',
                        }}
                      />
                      <Button text="Clear Filters" handleClick={clearFilters} />
                    </div>
                  )}
                <AddPosition modalHeight="400px" showLabel={false} />
              </>
            ) : (
              <Spinner pageLoader={true} diffHeight="501" />
            )
          ) : (
            <>
              {noMatch &&
                data.map((x) => x._id).filter((x) => checkRowIds.includes(x))
                  .length === 0 && (
                  <div className={style.noMatch}>
                    <span
                      style={{
                        marginBottom: '5px',
                      }}
                    >
                      Oops! Looks like no checked rows match your filters.
                    </span>
                    <Button
                      text="Uncheck All"
                      handleClick={unCheckAll}
                      style={{
                        marginRight: '10px',
                      }}
                    />
                    <Button text="Clear Filters" handleClick={clearFilters} />
                  </div>
                )}
              <PositionTable
                {...{
                  ref,
                  columns,
                  noMatch,
                  editTable,
                  rows: data,
                  dataSource,
                  setEditTable,
                  setDataSource,
                  order: sort?.order,
                  sortBy: sort?.sortBy,
                  page: pagination?.page,
                  loading: loaderOnTable,
                  getNextData: getNextPositionData,
                  setCheckedRowData: setCheckedRowData,
                  getSelectedSorting: getSelectedSortingHandleEvenet,
                  updateClientData: updatePositionsDataHanleEvent,
                  formId: 'overview-form2',
                }}
              />
            </>
          )}
        </>
      </Layout>
      <ColumnModal
        columns={[...columns]}
        choseColModal={choseColModal}
        setColumns={setColumnsHanlde}
        onColumnsReset={onColumnsReset}
        setChoseColModal={setChoseColModal}
      />
      <DeleteModal
        openDelModal={delModal}
        setOpenDelModal={setDelModal}
        del={delIcon}
        color={style.colorText}
        btnYes={style.btnYes}
        btnNo={style.btnNo}
        handleClick={deletePositionById}
        iconSize="48px"
        delText={P01_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.body}
        btnText={P01_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.yes}
        btn1Text={P01_SCREEN_MESSAGES.popups.deleteConfirm.attorneyRecord.no}
      />
    </>
  );
};
export default PositionsDirectory;

const getStatsFilters = ({ calculationSelected }) => {
  const statsFilter = {
    'Total Positions': { placementQuantity: { $gt: 0 } },
    Placements: { totalPlacedAttorneys: { $gt: 0 } },
    'Open Positions': { openPositions: { $gt: 0 } },
    'Positions Interviewing': { totalInterViewing: { $gt: 0 } },
    'Positions Not Addressed': { totalNotAddressed: { $gt: 0 } },
  };
  return statsFilter[calculationSelected] || '';
};

const makePositionsListBasedOnConditions = (
  _data,
  sortBy,
  order,
  positionsArrayWithCustomCheckField,
  page,
  copyData,
  checkRowIds
) => {
  if (page === 1)
    positionsArrayWithCustomCheckField =
      positionsArrayWithCustomCheckField?.map((x) => {
        const find = copyData?.find((y) => y?._id === x?._id && y?.checked);
        if (_.isEmpty(find)) return x;
        else return { ...x, checked: true };
      });

  let positionsList = [];
  if (_.isEmpty(sortBy) && _.isEmpty(order)) {
    if (page === 1) positionsList = [...positionsArrayWithCustomCheckField];
    else {
      if (_.isEmpty(copyData))
        positionsList = [..._data, ...positionsArrayWithCustomCheckField];
      else {
        const prevUncheckValue = _data?.filter(
          (x) => !checkRowIds.includes(x?._id)
        );
        positionsList = [...copyData, ...positionsArrayWithCustomCheckField];
      }
    }
  } else {
    if (!_.isEmpty(sortBy) && !_.isEmpty(order))
      positionsList = [...positionsArrayWithCustomCheckField];
    else {
      if (_.isEmpty(copyData)) {
        positionsList =
          page === 1
            ? [...positionsArrayWithCustomCheckField]
            : [..._data, ...positionsArrayWithCustomCheckField];
      } else if (!_.isEmpty(copyData)) {
        const setSortingDataChecked = positionsArrayWithCustomCheckField?.map(
          (x) => {
            const find = copyData?.find((y) => y?._id === x?._id && y?.checked);
            if (_.isEmpty(find)) return x;
            else return { ...x, checked: true };
          }
        );
        positionsList = setSortingDataChecked;
      }
    }
  }

  return positionsList;
};
