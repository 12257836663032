import axios from 'axios';

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function (error) {
      reject('Error: ', error);
    };
  });
};

export const sortAlphabetically = (arr = [], key = '') =>
  arr.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()));

export const apiRequest = async ({
  type,
  path,
  body,
  params,
  config,
  formData,
}) => {
  try {
    const res = await axios[type](
      path,
      formData
        ? body
        : {
            ...(body && body),
            ...(['get', 'delete'].includes(type) && params && { params }),
            ...(['get', 'delete'].includes(type) && config && { ...config }),
          },
      {
        ...config,
        ...(['post', 'put'].includes(type) && params && { params }),
      }
    );
    return res;
  } catch (err) {
    console.error(err);
    return err;
  }
};

export const removeKeys = (data, keysToDelete, emptyString = false) => {
  for (const key in data) {
    keysToDelete.includes(key) && delete data[key];
    emptyString && data[key] === '' && delete data[key];
  }
  return data;
};

export function placeObjects(arr) {
  // First, sort the array by the "slot" property
  arr.sort((a, b) => a.slot - b.slot);

  // Initialize an empty array to hold the final placement of the objects
  const finalArr = [];

  // Iterate through the sorted array
  for (const obj of arr) {
    // If the object has a "slot" property of -1, add it to the final array
    if (obj.slot === -1) {
      finalArr.push(obj);
    } else {
      // Otherwise, insert the object at its specified "slot" index
      finalArr.splice(obj.slot, 0, obj);
    }
  }

  return finalArr;
}
