import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';

import Modal from 'components/modal';
import Button from 'components/button';
import SelectBox from 'components/select-box';
import DatePicker from 'components/date-picker';

import { ATTORNEY_CLIENT_STATUS } from 'constants';
import { updateACHAndLIForPlaced } from 'services/attorny-directory-table';

import style from './add-status.module.scss';

const ChangeStatusToPlacedModal = ({
  clientNames,
  openModal,
  attorneyId,
  changeValue,
  setOpenModal,
  handleCancel,
  getUpdatedData,
  showStatus = false,
  clientNamesWithPositions = [],
  previousAttorneyClientHistory = [],
}) => {
  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const singleHistory = previousAttorneyClientHistory?.length === 1;

  const [clientNameWithPositionList, setClientNameWithPositionList] = useState(
    []
  );

  const { clientId, positionId, status, statusDate } = watch(
    'placedHistory'
  ) || {
    status: '',
    clientId: '',
    positionId: '',
    statusDate: '',
  };

  useEffect(() => {
    const currClientId = previousAttorneyClientHistory[0].clientId;
    const currPositionId = previousAttorneyClientHistory[0].positionId._id;
    reset({
      attorneyId,
      liStatus: changeValue,
      placedHistory: {
        attorneyId,
        statusDate: '',
        positionId: currPositionId,
        status: ATTORNEY_CLIENT_STATUS[5].value,
        clientId: singleHistory ? currClientId : '',
      },
    });
  }, []);

  const onSubmit = async (data) => {
    await updateACHAndLIForPlaced({
      ...data,
      placedHistory: {
        ...data.placedHistory,
        statusDate:
          data.placedHistory.statusDate &&
          moment(data.placedHistory.statusDate).format('YYYY-MM-DD'),
      },
      currentClientName: clientNames.find(
        (x) => x.value === data.placedHistory.clientId
      )?.label,
      restOfHistoryIds: previousAttorneyClientHistory
        .filter((x) => x.positionId._id !== data.placedHistory.positionId)
        .map((x) => x?._id),
    });
    await getUpdatedData();
    reset({});
    setOpenModal(false);
  };

  useEffect(() => {
    if (!singleHistory) {
      setValue('placedHistory.positionId', null);
      const list =
        clientNamesWithPositions?.find(
          (x) => x?._id === watch('placedHistory.clientId')
        )?.positions || [];

      setClientNameWithPositionList([...list]);
    }
  }, [watch('placedHistory.clientId')]);

  return (
    <Modal
      open={openModal}
      handleClose={handleCancel}
      className={`${style.mainDiv} ${showStatus && style.mainDivWidth}`}
    >
      <h6 className={style.modalTitle}>Change Status</h6>
      <p className={style.modalTitleInner}>
        {`Please fill in the client name, position and date where the attorney was placed.`}
      </p>

      <form id="hook-form90" onSubmit={handleSubmit(onSubmit)}>
        <div className={style.statusModalWidth}>
          <div className={style.innerDiv1}>
            <h6>Client Name</h6>
            <h6>Position</h6>
            {showStatus && <h6>Status</h6>}
            <h6>Status Date</h6>
          </div>

          {previousAttorneyClientHistory.length === 1 ? (
            <div className={style.innerDiv2}>
              <p className={style.ele}>
                {previousAttorneyClientHistory[0].clientName}
              </p>
              <p className={style.ele}>
                {previousAttorneyClientHistory[0].positionName}
              </p>
              <p className={style.ele}>{ATTORNEY_CLIENT_STATUS[5].value}</p>
              <DatePicker
                id="10"
                control={control}
                placeholder="mm/dd/yyyy"
                name={'placedHistory.statusDate'}
                popparBack={!showStatus ? 'popparClass' : 'popparClassLeft'}
                errorMessage={
                  errors?.placedHistory?.statusDate?.message &&
                  'Status Date is a required field'
                }
              />
            </div>
          ) : (
            <div className={style.innerDiv2}>
              <SelectBox
                options={clientNames}
                name={'placedHistory.clientId'}
                dynamicClass={style.zDynamicState2}
                control={control}
                errorMessage={
                  errors?.placedHistory?.clientId?.message &&
                  'Client Name is a required field'
                }
              />
              <SelectBox
                options={clientNameWithPositionList}
                name={'placedHistory.positionId'}
                dynamicClass={style.zDynamicState2}
                control={control}
                errorMessage={
                  errors?.history?.positionId?.message &&
                  'Client Position is a required field'
                }
              />
              <p className={style.ele}>{ATTORNEY_CLIENT_STATUS[5].value}</p>
              <DatePicker
                id="10"
                control={control}
                placeholder="mm/dd/yyyy"
                name={'placedHistory.statusDate'}
                popparBack={!showStatus ? 'popparClass' : 'popparClassLeft'}
                errorMessage={
                  errors?.placedHistory?.statusDate?.message &&
                  'Status Date is a required field'
                }
              />
            </div>
          )}
        </div>
        <div className={style.mainBtnDiv}>
          <Button
            type="submit"
            text={'Confirm'}
            form="hook-form90"
            disabled={!clientId || !positionId || !status || !statusDate}
            isLoading={isSubmitting}
            btnClass={style.btnClassModal}
          />
          <Button
            type="button"
            text={'Cancel'}
            handleClick={handleCancel}
            btnClass={style.btnClassUncheckModal}
          />
        </div>
      </form>
    </Modal>
  );
};

export default ChangeStatusToPlacedModal;
