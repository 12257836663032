import _ from 'lodash';
import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';

import ChangeStatusToPlacedModal from 'components/change-status-modals/change-status-to-placed';
import PlacedStatusPopup from 'components/change-status-modals/add-status-modal/PlacedStatusPopup';
import ChangeStatusInterviewingToNotInterviewingModal from 'components/change-status-modals/change-status-interviewing-to-not-interviewing';

import { getAttorneyClientHistory } from 'services/attorney-profile';

const OpenLiStatusModalOnEditMode = ({
  attorneyId,
  clientNames,
  currentLiStatus,
  setLiStausValue,
  previousLiStatus,
  clientNamesWithPositions,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentClientNames, setCurrentClientNames] = useState([]);
  const [currentClientPositions, setCurrentClientPositions] = useState([]);
  const [
    openModalWhenLIStatusChangedToPlaced,
    setOpenModalWhenLIStatusChangedToPlaced,
  ] = useState(false);

  const [
    openModalWhenLIStatusChangeToNotInterviewing,
    setOpenModalWhenLIStatusChangeToNotInterviewing,
  ] = useState(false);

  const [
    openModalWhenLIStatusChangePlacedToNotInterviewing,
    setOpenModalWhenLIStatusChangePlacedToNotInterviewing,
  ] = useState(false);
  const [previousAttorneyClientHistory, setPreviousAttorneyClientHistory] =
    useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (previousLiStatus !== currentLiStatus) {
        if (
          (previousLiStatus === 'Interviewing_LI' ||
            previousLiStatus === 'Interviewing_P') &&
          currentLiStatus === 'Not Interviewing'
        ) {
          const { data } = await getAttorneyClientHistory(attorneyId);
          const filterInterviewing = data?.history?.filter(
            (x) => x?.status === 'Interviewing'
          );
          if (_.size(filterInterviewing) > 0) {
            setOpenModalWhenLIStatusChangeToNotInterviewing(true);
            setPreviousAttorneyClientHistory(filterInterviewing);
          }
        } else if (
          previousLiStatus === 'Placed' &&
          currentLiStatus === 'Not Interviewing'
        ) {
          const { data } = await getAttorneyClientHistory(attorneyId);
          if (data?.history?.[0]?.status === 'Placed') {
            setPreviousAttorneyClientHistory(data?.history[0]);
            setOpenModalWhenLIStatusChangePlacedToNotInterviewing(true);
          }
        } else if (currentLiStatus === 'Placed') {
          const { data } = await getAttorneyClientHistory(attorneyId);
          const filterInterviewing = data?.history?.filter(
            (x) => x?.status === 'Interviewing'
          );
          if (_.size(filterInterviewing) > 0) {
            setOpenModalWhenLIStatusChangedToPlaced(true);
            const uniqueClientIds = [
              ...new Set(filterInterviewing.map((x) => x.clientId)),
            ];
            const uniqueClientPositions = uniqueClientIds.map((clientId) => ({
              _id: clientId,
              clientName: filterInterviewing.find(
                (x) => x.clientId === clientId
              ).clientName,
              positions: filterInterviewing
                .filter((x) => x.clientId === clientId)
                .map((x) => ({
                  value: x.positionId._id,
                  label: x.positionName,
                })),
            }));
            setCurrentClientPositions(uniqueClientPositions);
            setCurrentClientNames(
              uniqueClientPositions.map((x) => ({
                label: x?.clientName,
                value: x?._id,
              }))
            );
            setPreviousAttorneyClientHistory(filterInterviewing);
          }
        }
      }
      setLoading(false);
    })();
  }, [currentLiStatus]);

  return (
    <div>
      {openModalWhenLIStatusChangedToPlaced &&
        !loading &&
        ReactDOM.createPortal(
          <ChangeStatusToPlacedModal
            {...{
              attorneyId,
              showStatus: true,
              previousAttorneyClientHistory,
              changeValue: currentLiStatus,
              clientNames: currentClientNames,
              openModal: openModalWhenLIStatusChangedToPlaced,
              setOpenModal: setOpenModalWhenLIStatusChangedToPlaced,
              clientNamesWithPositions: currentClientPositions,
              handleCancel: () => {
                setOpenModalWhenLIStatusChangedToPlaced(false);
                setLiStausValue(previousLiStatus);
              },
              getUpdatedData: async () => {},
            }}
          />,
          document.getElementById('portal')
        )}
      {openModalWhenLIStatusChangeToNotInterviewing &&
        !loading &&
        ReactDOM.createPortal(
          <ChangeStatusInterviewingToNotInterviewingModal
            {...{
              attorneyId,
              showStatus: true,
              previousAttorneyClientHistory,
              changeValue: currentLiStatus,
              openModal: openModalWhenLIStatusChangeToNotInterviewing,
              setOpenModal: setOpenModalWhenLIStatusChangeToNotInterviewing,
              getUpdatedData: async () => {},
              handleCancel: () => {
                setOpenModalWhenLIStatusChangeToNotInterviewing(false);
                setLiStausValue(previousLiStatus);
              },
            }}
          />,
          document.getElementById('portal')
        )}
      {openModalWhenLIStatusChangePlacedToNotInterviewing &&
        !loading &&
        ReactDOM.createPortal(
          <PlacedStatusPopup
            clientNames={clientNames}
            clientNamesWithPositions={clientNamesWithPositions}
            changeValue={currentLiStatus}
            getUpdatedData={async () => {}}
            defaultLiStatus={previousLiStatus}
            attorneyId={attorneyId}
            cancelBtnEvent={() => {
              setOpenModalWhenLIStatusChangePlacedToNotInterviewing(false);
              setLiStausValue(previousLiStatus);
            }}
            previousAttorneyClientHistory={previousAttorneyClientHistory}
            updateOnlyACLatest
          />,
          document.getElementById('portal')
        )}
    </div>
  );
};

export default OpenLiStatusModalOnEditMode;
