import _ from 'lodash';
import moment from 'moment';
import React, { Fragment } from 'react';

import Avatar from 'components/avatar';
import Tags from 'components/tags';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { ATTORNEY_CLIENT_STATUS_COLORS, BAR_STATUS_OPTIONS } from 'constants';
import { ROLES } from 'permissions/constants';

import delIcon from 'assets/del-purple.svg';
import edit from 'assets/icons/edit-purple.svg';

import style from './overview.module.scss';

export const defaultColumns = ({
  userData,

  navigate,
  clientId,
  positionId,

  setSelectStatus,
  setDeleteAttorney,
}) => {
  const isAdmin = ROLES.ADMIN === userData?.userType;
  return [
    {
      name: 'Rank',
      key: 'slot',
      type: 'text',
      hidden: false,
      editable: false,
      widthAndHeight: { width: '75px', height: '58px' },
      render: ({ index }) => {
        return (
          <>
            <div className={style.slotContainer}>
              <p className={style.index}>{index + 1}</p>
            </div>
          </>
        );
      },
    },
    {
      name: 'Client Status',
      key: 'clientStatus',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '200px', height: '58px' },
      render: ({ row, index }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {row?.interviews?.clientStatus?.status ? (
            <Tags
              text={row?.interviews?.clientStatus?.status}
              circular={true}
              color={
                ATTORNEY_CLIENT_STATUS_COLORS[
                  row?.interviews?.clientStatus?.status
                ]
              }
            />
          ) : (
            <>
              {isAdmin ? (
                <p
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    return setSelectStatus({
                      _id: {
                        attorneyId: row?._id,
                        clientId,
                        positionId,
                      },
                      index,
                      status: row?.interviews?.clientStatus?.status,
                      statusDate: row?.interviews?.clientStatus?.statusDate,
                      statusHistory:
                        row?.interviews?.clientStatus?.statusHistory,
                    });
                  }}
                >
                  Add Status
                </p>
              ) : (
                <p>-</p>
              )}
            </>
          )}
          <PermissionRestrict roles={[ROLES.ADMIN]}>
            <img
              src={edit}
              alt=""
              style={{ marginLeft: '6px', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectStatus({
                  _id: {
                    attorneyId: row?._id,
                    clientId,
                    positionId,
                  },
                  index,
                  status: row?.interviews?.clientStatus?.status,
                  statusDate: row?.interviews?.clientStatus?.statusDate,
                  statusHistory: row?.interviews?.clientStatus?.statusHistory,
                });
              }}
            />
          </PermissionRestrict>
        </div>
      ),
    },
    {
      name: 'Score',
      key: 'score',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '75px', height: '58px' },
      render: ({ row }) => {
        return <span>{row?.score || 0}</span>;
      },
    },
    {
      name: 'Name',
      key: 'name',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '250px', height: '58px' },
      render: ({ row }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            circleSize={40}
            className={style.avatar}
            icon={row?.profileImage}
            initials={`${row?.firstName} ${row?.lastName}`}
          />
          {/* <div className={style.rounded}>
            <span className={style?.attorneyColumnEllipsis}>
              <p
                style={{
                  color: '#000',
                  marginLeft: '5px',
                  textDecoration: 'underline',
                  textTransform: 'capitalize',
                }}
                className={style.width}
                onClick={() => {
                  navigate(`/attorneys/${row?._id}`);
                }}
              >
                {`${row?.firstName} ${row?.lastName}`}
              </p>
            </span>
          </div> */}
          <div className={style.rounded}>
            <span className={style?.attorneyColumnEllipsis}>
              <p
                style={{
                  color: '#000',
                  marginLeft: '5px',
                  textDecoration: 'underline',
                  textTransform: 'capitalize',
                }}
                className={style.width}
                onClick={() => {
                  navigate(`/attorneys/${row?._id}`);
                }}
              >
                {`${row?.firstName} ${row?.lastName}`}
              </p>
            </span>
            {`${row?.firstName} ${row?.lastName}` &&
              `${row?.firstName} ${row?.lastName}`.length > 20 && (
                <div className={style.tooltip}>
                  <div className={style.tooltipChild}>
                    <p
                      className={style.tooltipText}
                    >{`${row?.firstName} ${row?.lastName}`}</p>
                  </div>
                </div>
              )}
          </div>
        </div>
      ),
    },
    {
      name: 'Other Interviews',
      key: 'otherInterview',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '105px', height: '58px' },
      render: ({ row }) => {
        return (
          <>
            <div className={style.rounded}>
              <span>{row?.interviews?.otherInterviews?.count}</span>
              {row?.interviews?.otherInterviews?.count > 0 && (
                <div className={style.tooltip}>
                  {row?.interviews?.otherInterviews?.interviews.map(
                    ({ statusDate, client }, index) => (
                      <div className={style.tooltipChild} key={index}>
                        <p className={style.tooltipText}>
                          <span style={{ fontWeight: 400 }}>
                            <strong style={{ fontWeight: 700 }}>
                              {client?.clientName}{' '}
                            </strong>
                            {statusDate?.[0] &&
                              `(${moment(
                                statusDate?.[0]?.split('T')?.[0]
                              ).format('L')})`}
                          </span>{' '}
                        </p>
                      </div>
                    )
                  )}
                </div>
              )}
            </div>
          </>
        );
      },
    },
    {
      name: 'LI Start Date',
      key: 'startDate',
      type: 'date',
      editable: false,
      hidden: false,

      widthAndHeight: { width: '115px', height: '58px' },
      render: ({ row }) => {
        const attorneyStartDate = row?.attorneyStartDate?.split?.('T')?.[0];
        return (
          <>
            <span>
              {_.isNull(attorneyStartDate) || _.isUndefined(attorneyStartDate)
                ? '-'
                : moment(attorneyStartDate).format('MM/DD/YYYY')}
            </span>
          </>
        );
      },
    },
    {
      name: 'Demo',
      key: 'demo',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '115px', height: '58px' },
      render: ({ row }) => (
        <>
          <div className={style.rounded}>
            <span className={style?.attorneyColumnEllipsis}>
              <p>
                {row?.attorneyDemo?.map(
                  (x, index) =>
                    `${index === row?.attorneyDemo.length - 1 ? x : `${x}, `}`
                ) || '-'}
              </p>
            </span>
            <div className={style.tooltip}>
              {row?.attorneyDemo.map((x, index) => (
                <div className={style.tooltipChild} key={index}>
                  <p className={style.tooltipText}>{x}</p>
                </div>
              ))}
            </div>
          </div>
        </>
      ),
    },
    {
      name: 'Gender',
      key: 'gender',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      render: ({ row }) => (
        <div className={style.rounded}>
          <span className={style?.attorneyColumnEllipsis}>
            <p style={{ textTransform: 'capitalize' }}>
              {row?.attorneyGender || '-'}
            </p>
          </span>
          {row?.attorneyGender && row?.attorneyGender.length > 12 && (
            <div className={style.tooltip}>
              <div className={style.tooltipChild}>
                <p className={style.tooltipText}>{row?.attorneyGender}</p>
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      name: 'City',
      key: 'city',
      type: 'text',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '130px', height: '58px' },
      customHeaderClass: style.cityColumn,
      render: ({ row }) => {
        const noCities = row?.attorneyLocation?.[0] === '-';
        const tooltipText = row?.attorneyLocation?.map(
          (x, index) =>
            `${index === row?.attorneyLocation.length - 1 ? x : `${x}, `}`
        );

        return (
          <div className={style.rounded}>
            <span className={style?.attorneyColumnEllipsis}>
              <p>
                {row?.attorneyLocation?.map(
                  (x, index) =>
                    `${
                      index === row?.attorneyLocation.length - 1 ? x : `${x}, `
                    }`
                ) || '-'}
              </p>
            </span>

            {!noCities && (
              <div className={style.tooltip}>
                <div className={style.tooltipChild}>
                  <p className={style.tooltipText}>{tooltipText}</p>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: 'School',
      key: 'school',
      type: 'text',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '110px', height: '58px' },
      render: ({ row }) => (
        // <span className={style?.attorneyColumnEllipsis}>
        //   <p style={{ textTransform: 'capitalize' }}>
        //     {row?.attorneyLawSchool || '-'}
        //   </p>
        // </span>
        <div className={style.rounded}>
          <span className={style?.attorneyColumnEllipsis}>
            <p style={{ textTransform: 'capitalize' }}>
              {row?.attorneyLawSchool || '-'}
            </p>
          </span>
          {row?.attorneyLawSchool && row?.attorneyLawSchool.length > 12 && (
            <div className={style.tooltip}>
              <div className={style.tooltipChild}>
                <p className={style.tooltipText}>{row?.attorneyLawSchool}</p>
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      name: 'Grad Year',
      key: 'gradYear',
      type: 'text',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '80px', height: '58px' },
      render: ({ row }) => (
        <span className={style?.attorneyColumnEllipsis}>
          <p>{row?.attorneyGradYear || '-'}</p>
        </span>
      ),
    },
    {
      name: 'Parker Score',
      key: 'parkerScore',
      type: 'text',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '80px', height: '58px' },
      render: ({ row }) => <span>{row?.attorneyParkerScore || '-'}</span>,
    },
    {
      name: 'Writing Score',
      key: 'writingScore',
      type: 'text',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '80px', height: '58px' },
      render: ({ row }) => <span>{row?.attorneyWritingScore || '-'}</span>,
    },
    {
      name: 'Bar Locations',
      key: 'barLocations',
      type: 'text',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '140px', height: '58px' },
      render: ({ value }) => {
        return (
          <div className={style.rounded}>
            <span className={style?.attorneyColumnEllipsis}>
              <div>{value.length > 0 ? value.join(', ') : '-'}</div>
            </span>
            {_.size(value) > 1 && (
              <div className={style.tooltip}>
                <div className={style.tooltipChild}>
                  <p className={style.tooltipText}>
                    {value?.map((barLocation, index) => (
                      <p key={index}>{barLocation}</p>
                    ))}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: 'Last Bar Status',
      key: 'lastBarStatus',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '140px', height: '58px' },
      render: ({ value }) => (
        <div>
          {value?.barStatus ? (
            <Tags
              text={value.barStatus}
              circular={true}
              color={
                BAR_STATUS_OPTIONS?.find(
                  (findColor) => findColor?.label === value?.barStatus
                )?.color
              }
            />
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      name: '',
      key: 'actions',
      hidden: false,
      type: 'text',
      editable: true,
      widthAndHeight: { width: '20px', height: '20px' },
      render: ({ row }) => {
        return (
          <PermissionRestrict roles={[ROLES.ADMIN]}>
            <div className={style.editIconDiv}>
              <img
                style={{
                  position: 'absolute',
                  right: '3px',
                  top: '-11px',
                  cursor: 'pointer',
                }}
                src={delIcon}
                alt=""
                className={style.imgDel}
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteAttorney(row?._id);
                }}
              />
            </div>
          </PermissionRestrict>
        );
      },
    },
  ];
};
