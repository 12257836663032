import React from 'react';

import style from './dropdown.module.scss';

const NowDropdown = ({ open, setOpen, nameArr, onClick }) => {
  return (
    <div>
      {open && (
        <div className={style.backdropDiv} onClick={() => setOpen(false)}></div>
      )}
      <div className={style.positionDiv}>
        {nameArr.map((ele, index) =>
          ele.Component ? (
            <ele.Component
              {...ele.props}
              key={index}
              setValue={onClick}
              setOpen={setOpen}
            />
          ) : (
            <p
              onClick={() => {
                onClick(ele);
                setOpen(false);
              }}
              key={index}
            >
              {ele}
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default NowDropdown;
