import React, { useEffect, useRef, useState } from 'react';
import * as yup from 'yup';

import Button from 'components/button';
import Modal from 'components/modal';
import Dropzone from 'components/dropzone';

import cross from 'assets/images/greenCrossIcon.svg';

import style from './files.module.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import { cancelAdd, setFiles } from 'services/attorney-profile';
import { convertFileToBase64 } from 'utils/helpers';
import { createNotification } from 'components/react-notification';
import { fileRegEx, imageRegex } from './helper';
import Zip from 'jszip';

let controller = new AbortController();

const AddEditModal = ({ onAdd, editFile, setEditFile }) => {
  const { id } = useParams();
  const progressRef = useRef();
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    setShowProgress(true);

    try {
      const fileName = values.file.name;
      if (!imageRegex.test(fileName)) {
        const zip = Zip();
        zip.file(fileName, values.file);
        values.file = await zip.generateAsync({ type: 'blob' });
      }
      const base64 = await convertFileToBase64(values.file);
      const response = await setFiles(
        {
          attorneyId: id,
          fileName: fileName,
          base64,
        },
        controller
      );

      onAdd(response.data.file);
      setProgress(100);

      setTimeout(() => {
        setShowProgress(false);
        setEditFile(false);
        reset({});
      }, 400);
    } catch (err) {
      if (err.name === 'CanceledError') {
        cancelAdd({
          attorneyId: id,
        });
        setShowProgress(false);
        setEditFile(false);
        reset({});
        return;
      }
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
    }
  };
  const startProgress = (speed) => {
    progressRef.current = setTimeout(() => {
      setProgress((prev) => prev + 5);
      startProgress(speed * 2);
    }, speed);
  };

  useEffect(() => {
    if (showProgress) {
      startProgress(50);
    } else if (progressRef.current && progress) {
      clearTimeout(progressRef.current);
    }
  }, [showProgress]);
  const fileState = watch('file');

  useEffect(() => {
    if (!fileState) {
      controller.abort();
      controller = new AbortController();
    }
  }, [fileState]);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#39695b');
    reset({});
    if (!editFile) {
      controller.abort();
      controller = new AbortController();
    }
  }, [editFile]);

  return (
    <Modal
      open={editFile}
      handleClose={() => setEditFile(false)}
      className={style.modal}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.img}>
          <img src={cross} onClick={() => setEditFile(false)} alt="" />
        </div>

        <p style={{ fontSize: ' 18px', fontWeight: '600' }}>Upload File</p>
        <Dropzone
          control={control}
          name={'file'}
          error={errors.file?.message}
          showProgress={showProgress}
          progress={progress}
        />
        <div className={style.mainBtnDiv}>
          <Button
            text={'Upload'}
            btnClass={
              errors.file || !fileState ? style.disableClass : style.btnYes
            }
            disabled={errors.file || !fileState}
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </Modal>
  );
};

const schema = yup.object().shape({
  file: yup
    .mixed()
    .required('Please select a file.')
    .test('required', 'Please select a file.', (value) => {
      return value;
    })
    .test('fileSize', 'The file size can’t be larger than 50MB', (file) => {
      return file && file?.size < 50000000;
    })
    .test(
      'fileType',
      'Only the following file types can be uploaded: .PDF, .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX, .TXT, ,.BMP, .JPG, .JPEG, .PNG, .GIF, .TIFF, .EPS, .XPS, .RTF, .CSV.',
      (file) => {
        return fileRegEx.test(file?.name);
      }
    ),
});

export default AddEditModal;
