import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from 'components/button';
import ButtonDropdown from 'components/button-dropdown';
import CustomDatePicker from 'components/custom-date-picker';
import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextField from 'components/textfield';
import PermissionRestrict from 'hoc/PermissionRestrict';
import ResetPopup from './reset-popup';

import { ATTRONEY_STATUS_OPTIONS } from 'constants';
import { ROLES } from 'permissions/constants';
import {
  setAttorneyTableFilter,
  setCheckedRowsData,
} from 'redux/filters/filters-actions';
import { getPaylocityEmployees } from 'services/data-sync';
import { moveSelctedDropdownStatusToTop } from '../helper';

import searchIcon from 'assets/icons/search-blue.svg';
import down from 'assets/images/downIcon.svg';
import cross from 'assets/images/greenCrossIcon.svg';
import refreshIcon from 'assets/images/refreshIcon.svg';
import plusIcon from 'assets/images/whiteAddIcon.svg';

import style from './header.module.scss';

const AttorneyHeader = (
  {
    search,
    setResetChip,
    filters,
    dateRange,
    unCheckAll,
    openFilter,
    setPageSize,
    setOpenFilter,
    setDirectoryFilters,
    getDataFromAttorneyAfterApplyingFilter,
  },
  ref
) => {
  const dispatch = useDispatch();
  const { control, register, reset, watch, handleSubmit } = useForm();
  const { open } = useSelector((state) => state?.statsTable);
  const { dashboardData } = useSelector((state) => state?.systemValues);
  const { attorneyTableFilter } = useSelector((state) => state?.pageFilters);

  const currentValue = attorneyTableFilter?.currentValue || 'Now';

  const [syncLoading, setSyncLoading] = useState(false);
  const [attornyStatusOptions, setAttorneyStatusOptions] = useState(
    ATTRONEY_STATUS_OPTIONS?.map((x) => {
      return { ...x, checkbox: true };
    })
  );

  const debouncedSearch = React.useRef(
    _.debounce(async ({ currentValue, newCalculation, filters }) => {
      await getDataFromAttorneyAfterApplyingFilter({
        filterData: filters,
        currentValue,
        newCalculation,
      });
    }, 300)
  ).current;

  const clickOnCrossIcon = async () => {
    setPageSize(1);
    await getDataFromAttorneyAfterApplyingFilter({
      filterData: {
        mainFilters: {
          ...(filters?.mainFilters || {}),
          search: '',
        },
        statsFilters: filters.statsFilters || {},
      },
      currentValue,
      newCalculation: attorneyTableFilter?.calculationSelected,
    });
  };

  const onSubmit = async (data) => {
    setResetChip(false);
    await getDataFromAttorneyAfterApplyingFilter({
      filterData: {
        mainFilters: {
          ...(filters?.mainFilters || {}),
          ...data,
        },
        statsFilters: {},
      },
      currentValue,
      newCalculation:
        attorneyTableFilter?.calculationSelected === 'All' ? 'All' : '',
    });
  };
  useImperativeHandle(ref, () => ({
    async clearAllFiltersWhenNoRowIsMatched(currentValue) {
      await clearAllFilter(currentValue);
    },
  }));

  const clearAllFilter = async (newCurrentValue = '') => {
    unCheckAll();
    dispatch(setCheckedRowsData([], 'ATTORNEY'));
    await getDataFromAttorneyAfterApplyingFilter({
      filterData: {
        mainFilters: {},
        statsFilters: {},
      },
      currentValue: newCurrentValue || currentValue,
      newCalculation: 'All',
    });
    resetAllValues();
    setPageSize(1);
  };

  const handleSync = async () => {
    setSyncLoading(true);
    await getPaylocityEmployees(dispatch);
    setSyncLoading(false);
    await getDataFromAttorneyAfterApplyingFilter({
      filterData: { mainFilters: {}, statsFilters: {} },
      currentValue,
      newCalculation: attorneyTableFilter?.calculationSelected,
    });
  };

  const resetAllValues = () => {
    reset({
      barFails: null,
      A_Latest_LIStatus: null,
      Li_Start_Date: null,
      Placement_Date: null,
      attorneyDemo: null,
      gender: null,
      city: null,
      attorneyLawSchool: null,
      attorneyGradYear: null,
      attorneyParkerScore: null,
      clientName: null,
      barLocation: null,
      lastBarStatus: null,
      search: null,
      currentValue: null,
      dateRange: [null, null],
    });
  };
  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#39695b');
  }, []);

  useEffect(() => {
    _.isEmpty(filters?.mainFilters)
      ? resetAllValues()
      : reset(filters?.mainFilters);
  }, [filters?.mainFilters]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    const optionsSelctedValueOnTop = moveSelctedDropdownStatusToTop(
      watch('A_Latest_LIStatus') ?? [],
      ATTRONEY_STATUS_OPTIONS?.map((x) => {
        return { ...x, checkbox: true };
      })
    );
    setAttorneyStatusOptions([...optionsSelctedValueOnTop]);
  }, [watch('A_Latest_LIStatus')]);

  const [palDate, palTime, palAmPm] = (
    dashboardData?.paylocityLastSync
      ? moment(dashboardData?.paylocityLastSync).format('L h:mm A')
      : ''
  ).split(' ');

  return (
    <div>
      <div className={style.mainHeader}>
        <div className={style.innerFlex}>
          <p className={style.attorney}>Attorneys</p>
          <div className={style.btns}>
            <div className={style.hoverBtn}>
              <Button
                type="button"
                isLoading={syncLoading}
                iconStart={refreshIcon}
                handleClick={handleSync}
                btnClass={style.btnClassRefresh}
              />
              <div className={style.tooltip}>
                <p>
                  Paylocity last synced on {palDate} at {palTime} {palAmPm}
                </p>
              </div>
            </div>
            <ButtonDropdown
              currentValue={currentValue}
              onChange={(value) => {
                setPageSize(1);
                setDirectoryFilters((prev) => ({
                  ...prev,
                  page: 1,
                  currentValue: value,
                  dateRange: [null, null],
                  extraParams: {
                    showLoaderOnFullScreen: false,
                  },
                }));
                value !== 'Custom' &&
                  dispatch(
                    setAttorneyTableFilter({
                      ...attorneyTableFilter,
                      currentValue: value,
                      dateRange: [null, null],
                    })
                  );
              }}
              options={[
                'Now',
                'Cumulative',
                {
                  Component: CustomDatePicker,

                  props: {
                    key: 'a-custom',
                    value: dateRange,
                    onChange: (newDateRange) => {
                      // this was causing the date range one day before selection issue can be removed if everything is working fine

                      // newDateRange = [
                      //   newDateRange?.[0]
                      //     ? moment(newDateRange?.[0])?.format('YYYY-MM-DD')
                      //     : null,
                      //   newDateRange?.[1]
                      //     ? moment(newDateRange[1])?.format('YYYY-MM-DD')
                      //     : null,
                      // ];
                      dispatch(
                        setAttorneyTableFilter({
                          ...attorneyTableFilter,
                          currentValue: 'Custom',
                          dateRange: newDateRange,
                        })
                      );
                      if (newDateRange[1]) {
                        setPageSize(1);
                        setDirectoryFilters((prev) => ({
                          ...prev,
                          page: 1,
                          currentValue: 'Custom',
                          dateRange: newDateRange,
                          extraParams: {
                            showLoaderOnFullScreen: false,
                          },
                        }));
                      }
                    },
                  },
                },
              ]}
              dateRange={dateRange}
            />
            <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
              <Link to="/attorneys/new" style={{ textDecoration: 'none' }}>
                <Button
                  text={'Add Attorney'}
                  iconStart={plusIcon}
                  btnClass={style.btnClass}
                  type="button"
                />
              </Link>
            </PermissionRestrict>
          </div>
        </div>

        <div className={style.grid}>
          <div className={style.textField}>
            <TextField
              value={search}
              onChange={(e) => {
                const { value: search } = e.target;
                dispatch(
                  setAttorneyTableFilter({
                    ...attorneyTableFilter,
                    filters: {
                      mainFilters: {
                        ...(filters?.mainFilters || {}),
                        search,
                      },
                      statsFilters: filters.statsFilters || {},
                    },
                  })
                );
                debouncedSearch({
                  currentValue,
                  filters: {
                    mainFilters: {
                      ...(filters?.mainFilters || {}),
                      search,
                    },
                    statsFilters: filters.statsFilters || {},
                  },
                  newCalculation: attorneyTableFilter?.calculationSelected,
                });
              }}
              icon={!_.isEmpty(watch('search')) ? cross : searchIcon}
              placeholder="Search"
              className={style.field}
              label="Search"
              onClick={clickOnCrossIcon}
            />
          </div>
          <div className={style.statusBar}>
            <SelectBox
              name="A_Latest_LIStatus"
              control={control}
              badge
              options={attornyStatusOptions}
              label={'LI Status'}
              isMulti
              placeholder={'Select'}
              numberBadgeColor={'#39695b'}
              dynamicClass={style.zDynamicState4}
              showNumber
              showSelected
              currentValue={watch('A_Latest_LIStatus') || []}
            />
          </div>

          <div className={style.datePicker}>
            <DatePicker
              popparBack={
                !open
                  ? style.poperClassForLiStartDateOnMenuClosed
                  : style.poperClassForLiStartDateOnMenuOpen
              }
              label="LI Start Date"
              name="Li_Start_Date"
              id="1"
              placeholder="Now"
              control={control}
              inputClass={style.date}
              selectsRange={true}
              defaultVal={watch('Li_Start_Date')}
            />
          </div>
          <div className={style.datePicker}>
            <DatePicker
              id="2"
              name="Placement_Date"
              placeholder="Now"
              control={control}
              inputClass={style.date}
              selectsRange={true}
              label="Placement Date"
              defaultVal={watch('Placement_Date')}
              popparBack={
                !open
                  ? style.poperClassForPlacementOnMenuClosed
                  : style.poperClassForPlacementOnMenuOpen
              }
            />
          </div>
          <div className={style.resetDiv}>
            <img
              src={down}
              alt=""
              style={{
                padding: '5px',
                width: '30px',
                height: '30px',
                transform: openFilter ? 'rotate(180deg)' : '',
              }}
              onClick={() => setOpenFilter((prev) => !prev)}
            />

            <Button
              text={'Reset'}
              type="button"
              handleClick={() => {
                clearAllFilter();
              }}
              btnClass={style.check}
              style={{ marginRight: '10px', marginLeft: '10px' }}
            />
            <Button
              text={'Apply'}
              type="button"
              btnClass={style.apply}
              handleClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>

      {openFilter && (
        <ResetPopup
          control={control}
          register={register}
          reset={reset}
          watch={watch}
        />
      )}
    </div>
  );
};

export default React.memo(React.forwardRef(AttorneyHeader));
