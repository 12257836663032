import React from 'react';
import * as yup from 'yup';

import GenericTable from 'components/generic-table';
import Loading from 'components/loading';
import NoRecords from 'components/no-records';

import { C02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import style from './view.module.scss';

const ViewTable = ({
  attorneys,
  singleEditMode,
  setEditTable,
  setOpen,
  editTable,
  columns,
  isLoading,
  onSaveSubmit,
  handleSingleDelete,
  formId,
}) => {
  return (
    <>
      {!isLoading && attorneys?.length > 0 ? (
        <div className={style.tableWidth}>
          {isLoading && <Loading />}
          <GenericTable
            formId={formId || 'hook-form'}
            setOpen={setOpen}
            columns={columns}
            isEditMode={editTable}
            dataSource={attorneys}
            setEditTable={setEditTable}
            singleEditMode={singleEditMode}
            handleSingleDelete={handleSingleDelete}
            onSaveSubmit={onSaveSubmit}
            schema={schema}
            classes={{
              test: style.test,
              table: `${style.table} ${
                editTable !== false ? style.paddingBottom : ''
              }`,
              thead: style.thead,
              th: style.th,
              tableBody: style.tableRow,
              tableOnLoading: style.genericTableOnLoading,
              tableOffLoading: style.genericTableOffLoading,
            }}
            renderNoDataFound={() => (
              <tr>
                <div className={style.noDataDiv}></div>
              </tr>
            )}
          />
        </div>
      ) : (
        !isLoading && (
          <div className={style.noDataDiv}>
            <NoRecords
              noRecord={C02_SCREEN_MESSAGES.emptyPlaceholder.overview}
              icon={true}
              hideButton
              singleRowMode
            />
          </div>
        )
      )}
    </>
  );
};

export default ViewTable;

const schema = yup
  .object({
    data: yup.array().of(
      yup.object({
        clientName: yup.string().nullable().required('Client name is required'),
        status: yup.string().nullable().required('Status is required'),
        statusDate: yup
          .string()
          .typeError('Please enter a valid date')
          .required('Status date is required'),
      })
    ),
  })
  .required();
