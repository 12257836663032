import moment from 'moment';
import axios from 'utils/axios';
import { API_URLS } from './url-paths';

const { GET_CLIENT_BY_ID } = API_URLS;
//All clients
export const getClients = async (params) => {
  try {
    let url = `${API_URLS.GET_CLIENTS}`;
    const res = await axios.get(url, {
      params: params || {},
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getClientsCalculations = async (params) => {
  let url = `${API_URLS.GET_CLIENTS_CALCULATIONS}`;
  return axios.get(url, {
    params: params || {},
  });
};

export const updateAllClientDirectory = async (data) => {
  let url = `${API_URLS.UPDATE_CLIENT_DIRECTORY}`;
  return axios.put(url, {
    clients: data,
  });
};

//Client By ID
export const getClientById = async (id) => {
  let url = GET_CLIENT_BY_ID(id);
  return axios.get(url);
};

export const setClient = (obj, id) => {
  let url = API_URLS.SET_CLIENT;
  if (id !== 'add') url = `${API_URLS.SET_CLIENT}/${id}`;
  return axios[id !== 'add' ? 'put' : 'post'](url, obj);
};
//delete client
export const deleteClient = (id) => {
  let url = `${API_URLS.SET_CLIENT}/${id}`;
  return axios.delete(url);
};

//comments
export const getClientComments = async (id) => {
  let url = `${API_URLS.GET_COMMENTS}`;
  return axios.get(url, {
    params: {
      clientId: id,
    },
  });
};
export const setComments = (obj, id) => {
  let url = API_URLS.SET_COMMENTS;
  if (id) url = `${API_URLS.SET_COMMENTS}/${id}`;
  return axios[id ? 'put' : 'post'](url, {
    ...obj,
  });
};

export const deleteComment = (id) => {
  let url = `${API_URLS.SET_COMMENTS}/${id}`;
  return axios.delete(url);
};

export const exportClientCSV = async (
  checkedRowIds,
  sortBy,
  orderBy,
  filters,
  currentValue,
  dateRange,
  columns
) => {
  let url = `${API_URLS.GET_CLIENTS_CSV}`;
  return axios.get(url, {
    params: {
      _ids: checkedRowIds.join(',') ?? '',
      sortBy,
      orderBy,
      currentValue,
      filters,
      columns,
      dateRange: {
        startDate:
          dateRange[0] && moment(dateRange[0]).format('YYYY-MM-DDTHH:mm:ss'),
        endDate:
          dateRange[1] && moment(dateRange[1]).format('YYYY-MM-DDTHH:mm:ss'),
      },
    },
  });
};
