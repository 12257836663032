import moment from 'moment';
import style from './chipsFilter.module.scss';

const ChipsFilter = ({ chipsData, resetChip }) => {
  return (
    <>
      {chipsData?.search?.length > 0 && (
        <div className={style.chipWrap}>Search: {chipsData?.search}</div>
      )}
      {chipsData?.A_Latest_LIStatus?.length > 0 && (
        <div className={style.chipWrap}>
          {chipsData?.A_Latest_LIStatus.length < 2
            ? `LI Status: ${chipsData?.A_Latest_LIStatus}`
            : `LI Status: ${chipsData?.A_Latest_LIStatus?.length} Selected`}
        </div>
      )}
      {chipsData?.Li_Start_Date &&
        chipsData?.Li_Start_Date[0] &&
        resetChip !== true && (
          <div className={style.chipWrap}>
            {`LI Start Date: ${
              moment(chipsData?.Li_Start_Date[0]).isValid()
                ? moment(chipsData?.Li_Start_Date[0]).format('MM/DD/YYYY')
                : ''
            }${
              moment(chipsData?.Li_Start_Date[0]).isValid() &&
              moment(chipsData?.Li_Start_Date[1]).isValid()
                ? ' - '
                : ''
            }${
              moment(chipsData?.Li_Start_Date[1]).isValid()
                ? moment(chipsData?.Li_Start_Date[1]).format('MM/DD/YYYY')
                : ''
            }`}
          </div>
        )}
      {chipsData?.Placement_Date &&
        chipsData?.Placement_Date[0] &&
        !resetChip && (
          <div className={style.chipWrap}>
            {`Placement Date: ${
              moment(chipsData?.Placement_Date[0]).isValid()
                ? moment(chipsData?.Placement_Date[0]).format('MM/DD/YYYY')
                : ''
            }${
              moment(chipsData?.Placement_Date[0]).isValid() &&
              moment(chipsData?.Placement_Date[1]).isValid()
                ? ' - '
                : ''
            }${
              moment(chipsData?.Placement_Date[1]).isValid()
                ? moment(chipsData?.Placement_Date[1]).format('MM/DD/YYYY')
                : ''
            }`}
          </div>
        )}
      {chipsData?.attorneyDemo?.length > 0 && (
        <div className={style.chipWrap}>
          {chipsData?.attorneyDemo?.length < 2
            ? `Demo: ${chipsData?.attorneyDemo}`
            : `Demo: ${chipsData?.attorneyDemo.length} Selected`}
        </div>
      )}
      {chipsData?.gender?.length > 0 && (
        <div className={style.chipWrap}>
          {chipsData?.gender?.length < 2
            ? `Gender: ${chipsData?.gender}`
            : `Gender: ${chipsData?.gender.length} Selected`}
        </div>
      )}
      {chipsData?.city?.length > 0 && (
        <div className={style.chipWrap}>
          {chipsData?.city?.length < 2
            ? `City: ${chipsData?.city}`
            : `City: ${chipsData?.city?.length} Selected`}
        </div>
      )}
      {chipsData?.attorneyLawSchool?.length > 0 && (
        <div className={style.chipWrap}>
          {chipsData?.attorneyLawSchool?.length < 2
            ? `School: ${chipsData?.attorneyLawSchool}`
            : `School: ${chipsData?.attorneyLawSchool?.length} Selected`}
        </div>
      )}
      {chipsData?.attorneyGradYear &&
        chipsData?.attorneyGradYear !== null &&
        resetChip !== true && (
          <div className={style.chipWrap}>
            Grad Year: {moment(chipsData?.attorneyGradYear).format('YYYY')}
          </div>
        )}
      {chipsData?.attorneyParkerScore?.length > 0 && (
        <div className={style.chipWrap}>
          Parker Score: {chipsData?.attorneyParkerScore}
        </div>
      )}
      {chipsData?.clientName?.length > 0 && (
        <div className={style.chipWrap}>
          {chipsData?.clientName?.length < 2
            ? `Client Names: ${chipsData?.clientName}`
            : `Client Names: ${chipsData?.clientName?.length} Selected`}
        </div>
      )}
      {chipsData?.barLocation?.length > 0 && (
        <div className={style.chipWrap}>
          {chipsData?.barLocation?.length < 2
            ? `Bar Location: ${chipsData?.barLocation}`
            : `Bar Location: ${chipsData?.barLocation?.length} Selected`}
        </div>
      )}
      {chipsData?.lastBarStatus?.length > 0 && (
        <div className={style.chipWrap}>
          {chipsData?.lastBarStatus?.length < 2
            ? `Last Bar Status: ${chipsData?.lastBarStatus}`
            : `Last Bar Status: ${chipsData?.lastBarStatus?.length} Selected`}
        </div>
      )}
      {chipsData?.barFails?.length > 0 && (
        <div className={style.chipWrap}>
          {chipsData?.barFails?.length < 2
            ? `Bar Fails: ${chipsData?.barFails}`
            : `Bar Fails: ${chipsData?.barFails?.length} Selected`}
        </div>
      )}
    </>
  );
};

export default ChipsFilter;
