/* eslint-disable no-unused-vars */
import { useContext, useState, useEffect, createContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getPlacementComments } from 'services/positions';
import { createNotification } from 'components/react-notification';

const PositionContext = createContext();
export const usePositionContext = () => useContext(PositionContext);

export default function PositionProvider({ children }) {
  const { id } = useParams();

  const [comments, setComments] = useState([]);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getPositionCommentData = async () => {
    setIsLoading(true);
    try {
      const res = await getPlacementComments({ id });
      setComments([...res.data.comments]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    id && getPositionCommentData();
  }, []);

  return (
    <PositionContext.Provider
      value={{
        disable,
        comments,
        isLoading,
        setDisable,
        setComments,
        getPositionCommentData,
        commentLength: comments?.length,
      }}
    >
      {children}
    </PositionContext.Provider>
  );
}
