import { ROLES } from 'permissions/constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function useHelper({ length }) {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.userData);
  const tabs = useMemo(
    () =>
      userData.userType === ROLES.ATTORNEY
        ? [
            { name: 'Overview ' },
            {
              name: `Bar History ${
                length?.totalBars ? `(${length?.totalBars})` : '(0)'
              }`,
            },
            {
              name: `Training History ${
                length?.totalTrainings ? `(${length?.totalTrainings})` : '(0)'
              }`,
            },
          ]
        : [
            { name: 'Overview ' },
            {
              name: `Bar History ${
                length?.totalBars ? `(${length?.totalBars})` : '(0)'
              }`,
            },
            {
              name: `Client History ${
                length?.totalClientHistory
                  ? `(${length?.totalClientHistory})`
                  : '(0)'
              }`,
            },
            {
              name: `Comments ${
                length?.totalComments ? `(${length?.totalComments})` : '(0)'
              }`,
            },
            {
              name: `Training History ${
                length?.totalTrainings ? `(${length?.totalTrainings})` : '(0)'
              }`,
            },
            {
              name: `Files ${
                length?.totalFiles ? `(${length?.totalFiles})` : '(0)'
              }`,
            },
          ],
    [length, userData.userType]
  );

  return {
    tabs,
    navigate,
  };
}
