import _ from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Modal from 'components/modal';
import Button from 'components/button';
import SelectBox from 'components/select-box';
import DatePicker from 'components/date-picker';

import { ATTORNEY_CLIENT_STATUS } from 'constants';
import { updateACHAndLIForNotInterviewing } from 'services/attorny-directory-table';

import style from './add-status.module.scss';

const ChangeStatusInterviewingToNotInterviewingModal = ({
  openModal,
  attorneyId,
  changeValue,
  setOpenModal,
  handleCancel,
  getUpdatedData,
  showStatus = false,
  previousAttorneyClientHistory = [],
}) => {
  const {
    watch,
    reset,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm();

  useEffect(() => {
    const histories = previousAttorneyClientHistory.map(
      ({
        status,
        clientId,
        positionId,
        clientName,
        statusDate,
        positionName,
      }) => ({
        clientId,
        attorneyId,
        positionId: positionId._id,
        status,
        statusDate: moment(statusDate?.split('T')[0]).format('MM/DD/YYYY'),
        clientName,
        positionName,
      })
    );
    reset({
      attorneyId,
      liStatus: changeValue,
      histories,
    });
  }, []);

  const onSubmit = async (data) => {
    await updateACHAndLIForNotInterviewing({
      ...data,
      histories: data?.histories?.map(
        ({ clientId, attorneyId, positionId, status, statusDate }) => ({
          clientId,
          attorneyId,
          positionId,
          status,
          statusDate: moment(statusDate).format('YYYY-MM-DD'),
        })
      ),
    });
    await getUpdatedData();
    reset({});
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      handleClose={handleCancel}
      className={`${style.mainDiv} ${showStatus && style.mainDivWidth}`}
    >
      <h6 className={style.modalTitle}>Change Status</h6>
      <p className={style.modalTitleInner}>
        {`Do you want to change the client status at  ${
          watch('histories')?.length > 1 ? 'these placements' : 'this placement'
        } from
        "Interviewing" to something else?`}
      </p>

      <form id="hook-form90" onSubmit={handleSubmit(onSubmit)}>
        <div className={style.statusModalWidth}>
          <div className={style.innerDiv1}>
            <h6>Client Name</h6>
            <h6>Position</h6>
            {showStatus && <h6>Status</h6>}
            <h6>Status Date</h6>
          </div>
          {watch('histories')?.map((history, index) => (
            <div className={style.innerDiv2} key={index}>
              <p className={style.ele}>{history?.clientName}</p>
              <p className={style.ele}>{history?.positionName}</p>
              <SelectBox
                control={control}
                options={ATTORNEY_CLIENT_STATUS}
                dynamicClass={style.zDynamicState2}
                name={`histories.[${index}].status`}
                errorMessage={
                  errors?.[`histories.[${index}].status`]?.message &&
                  'Status is a required field'
                }
              />
              <DatePicker
                id="10"
                control={control}
                placeholder="mm/dd/yyyy"
                name={`histories.[${index}].statusDate`}
                popparBack={!showStatus ? 'popparClass' : 'popparClassLeft'}
                errorMessage={
                  errors?.[`histories.[${index}].statusDate`]?.message &&
                  'Status Date is a required field'
                }
              />
            </div>
          ))}
        </div>
        <div className={style.mainBtnDiv}>
          <Button
            isLoading={isSubmitting}
            text={'Confirm'}
            btnClass={style.btnClassModal}
            type="submit"
            form="hook-form90"
          />
          <Button
            type="button"
            text={'Cancel'}
            btnClass={style.btnClassUncheckModal}
            handleClick={handleCancel}
          />
        </div>
      </form>
    </Modal>
  );
};

export default ChangeStatusInterviewingToNotInterviewingModal;
