import { useState } from 'react';

import { createNotification } from 'components/react-notification';
import { deleteComment } from 'services/attorney-profile';

const useHelper = ({ getPositionCommentData, setDisable }) => {
  const [edit, setEdit] = useState();
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [comments, setComments] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);

  //delete
  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteComment(open);
      setComments([...comments.filter((ele) => ele._id !== open)]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    getPositionCommentData();
    setIsDeleting(false);
    setOpen(false);
  };

  const onAdd = (value) => {
    let _comments = [...comments];
    _comments.unshift(value);

    setComments(_comments);
  };

  const onEdit = (value) => {
    setComments((prev) => {
      const newArr = [...prev];
      const indexFound = newArr.findIndex((e) => e._id === value._id);
      newArr[indexFound] = value;
      return newArr;
    });
    setDisable(false);
  };

  return {
    edit,
    open,
    onAdd,
    toggle,
    onEdit,
    setOpen,
    setEdit,
    setToggle,
    isDeleting,
    handleDelete,
  };
};

export default useHelper;
