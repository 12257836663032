import { createNotification } from 'components/react-notification';
import _ from 'lodash';
import { apiRequest } from 'utils/helpers';
import { API_URLS } from './url-paths';

const { GET_TABLE_COLUMNS, UPDATE_TABLE_COLUMNS } = API_URLS;

export const getTableColumns = async ({ userId, tableName, setColumns }) => {
  const res = await apiRequest({
    path: GET_TABLE_COLUMNS,
    type: 'get',
    params: { userId, tableName },
  });
  if (res.status === 200) {
    setColumns && setColumns(res?.data?.columns);
    return res?.data?.columns || [];
  }
};

export const updateTableColumns = async ({
  userId,
  columns,
  tableName,
  prevColumns,
}) => {
  const isTableColumnsUpdated =
    _.isEmpty(prevColumns) ||
    JSON.stringify(columns) !== JSON.stringify(prevColumns);
  if (!isTableColumnsUpdated) {
    return;
  }
  const res = await apiRequest({
    path: UPDATE_TABLE_COLUMNS,
    type: 'put',
    body: { columns, tableName, userId },
  });
  if (res.status === 200) {
    createNotification('success', 'Success', res?.data?.msg);
  } else {
    createNotification('error', 'Error', res.response.data.msg, 3500);
  }
};
