import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import saveIcon from 'assets/images/save.svg';

import { useAttoreyProfile } from 'contexts/attorney-profile';
import {
  getAttorneyBarHistory,
  deleteBarHistory,
  setBarHistory,
} from 'services/attorney-profile';
import { createNotification } from 'components/react-notification';
import Tags from 'components/tags';
import TextField from 'components/textfield';
import SelectBox from 'components/select-box';
import moment from 'moment/moment';
import { BAR_STATUS_COLORS } from 'constants';
import DatePicker from 'components/date-picker';
import { BAR_STATUS_OPTIONS } from 'constants';
import greenCrossIcon from 'assets/images/greenCrossIcon.svg';
import Button from 'components/button';
import { useSelector } from 'react-redux';
import style from './bar-history.module.scss';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { ROLES } from 'permissions/constants';

const useHelper = ({ setEditTable }) => {
  const { id } = useParams();
  const { getData, add, active } = useAttoreyProfile();
  const { states } = useSelector((state) => state.systemValues);

  const [toggle, setToggle] = useState(false);
  const [all, setAll] = useState(false);

  const [viewTable, setViewTable] = useState(false);

  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(true);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [edit, setEdit] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [barHistoryArr, setBarHistoryArr] = useState([]);

  const innerColumns = [
    {
      name: 'Date',
      key: 'barTestedDate',
      type: 'text',
      editable: true,
      hidden: false,
      customHeaderClass: style.dateHeader,
      widthAndHeight: {
        width: '330px',
        height: '58px',
      },
      widthInEditMode: { width: '315px' },
      render: (
        { value, inputName, control, register, errorMessage, row, index },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode === index ? (
              <div style={{ width: '320px' }}>
                <DatePicker
                  name={inputName}
                  id={row?._id}
                  monthYear={true}
                  placeholder="mm/yyyy"
                  control={control}
                />
              </div>
            ) : (
              <p
                style={{
                  fontSize: '14px',
                  color: '#363636',
                  paddingLeft: '13px',
                }}
              >
                {moment(row?.barTestedDate).format('MM/YYYY')}
              </p>
            )}
          </>
        );
      },
    },
    {
      name: 'State',
      key: 'barState',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '320px', height: '58px' },
      widthInEditMode: { width: '246px' },
      render: (
        { value, inputName, control, register, errorMessage, row },
        column,
        isEditMode
      ) => {
        return (
          <>
            <p
              style={{
                fontSize: '14px',
                width: '318px',
                color: '#363636',
                paddingLeft: '10px ',
              }}
            >
              {row?.barState}
            </p>
          </>
        );
      },
    },
    {
      name: 'Status',
      key: 'barStatus',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '330px', height: '58px' },
      widthInEditMode: { width: '340px' },
      render: (
        { value, inputName, control, register, errorMessage, row, index },
        column,
        isEditMode
      ) => {
        return isEditMode === index ? (
          <div style={{ width: '318px', paddingLeft: '10px' }}>
            <SelectBox
              name={inputName}
              placeholder="Select One"
              control={control}
              options={BAR_STATUS_OPTIONS}
              errorMessage={errorMessage}
            />
          </div>
        ) : (
          <div style={{ width: '318px', paddingLeft: '10px' }}>
            <Tags
              text={row?.barStatus}
              color={BAR_STATUS_COLORS[row?.barStatus]}
              circular={true}
            />
          </div>
        );
      },
    },
    {
      name: 'ID',
      key: 'barId',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '318px', height: '58px' },
      widthInEditMode: { width: '182px' },
      render: (
        { value, inputName, register, errorMessage, row, index },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode === index ? (
              <div style={{ width: '318px', paddingLeft: '10px' }}>
                <TextField name={inputName} register={register} />
              </div>
            ) : (
              <div style={{ width: '318px', paddingLeft: '7px' }}>
                <p style={{ fontSize: '14px', color: '#363636' }}>
                  {row?.barId}
                </p>
              </div>
            )}
          </>
        );
      },
    },
    {
      name: '',
      key: 'action',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '540px' },
      render: (
        {
          value,
          inputName,
          editRowLoading,
          register,
          errorMessage,
          row,
          index,
        },
        column,
        isEditMode
      ) => {
        return (
          <PermissionRestrict
            roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.ATTORNEY]}
          >
            {isEditMode === index ? (
              <div
                style={{
                  overflowWrap: 'normal',
                  display: 'flex',
                  position: 'relative',
                  justifyContent: 'flex-end',
                }}
              >
                <img
                  style={{
                    cursor: 'pointer',
                    marginRight: '10px',
                  }}
                  width={25}
                  onClick={() => {
                    setEditTable(false);
                  }}
                  src={greenCrossIcon}
                />
                <Button
                  text={'Save'}
                  type="submit"
                  form="hook-form"
                  isLoading={editRowLoading}
                  iconStart={saveIcon}
                />
              </div>
            ) : (
              <p> </p>
            )}
          </PermissionRestrict>
        );
      },
    },
  ];

  useEffect(() => {
    getBarsData();
  }, []);

  useEffect(() => {
    setToggle(add);
  }, [add]);

  useEffect(() => {
    if (active === 1) {
      if (!add) {
        setToggle(false);
        setEdit(false);
      }
    }
  }, [active]);

  const handleEdit = async (values) => {
    try {
      const { attorneyId, barStatus, barTestedDate, barState, barId, _id } =
        values;
      const res = await setBarHistory(
        {
          barId,
          barStatus,
          attorneyId,
          barTestedDate,
          barState: states.find((state) => state.barState == barState)._id,
          edit: true,
        },
        _id
      );
      await getBarsData();
      setViewTable(false);
      await getData();
      setToggle(false);
      setEdit(null);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.msg ||
          err?.response?.data?.message ||
          err?.message ||
          'Server Error'
      );
      console.error('err', err.response.data);
    }
  };

  const getBarsData = async () => {
    setIsLoading(true);
    try {
      const res = await getAttorneyBarHistory(id);
      setBarHistoryArr([...res.data.bars]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  //delete

  const handleDelete = async (type = null) => {
    try {
      if (type === 'all') {
        setIsLoadingAll(true);
        await deleteBarHistory({
          history: open?.id ? open : { id: open },
          single: false,
        });
        setIsLoadingAll(false);
        getBarsData();
        getData();
        setAll(false);
        setOpen(false);
      } else {
        setIsLoading(true);
        await deleteBarHistory({
          history: open?.id ? open : { id: open },
          single: true,
        });
        getBarsData();
        setIsLoading(false);
      }
      setViewTable(false);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setAll(false);
    getBarsData();
    getData();
    setIsLoading(false);
    setOpen(false);
  };
  const onAdd = (value) => {
    setBarHistoryArr((prev) => [...prev, value]);
  };

  const onEdit = (value) => {
    setBarHistoryArr((prev) => {
      const newArr = [...prev];
      const indexFound = newArr.findIndex((e) => e._id === value._id);
      newArr[indexFound] = value;

      return newArr;
    });
  };

  return {
    id,
    onAdd,
    onEdit,
    setEdit,
    setOpen,
    setToggle,
    handleDelete,
    edit,
    open,
    toggle,
    isLoading,
    isLoadingAll,
    innerColumns,
    close,
    setClose,
    handleEdit,
    getBarsData,
    barHistory: barHistoryArr,
    setBarHistory: setBarHistoryArr,
    viewTable,
    all,
    setAll,
    setViewTable,
  };
};

export default useHelper;
