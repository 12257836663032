import axios from 'axios';
import { BASE_URL } from 'constants/api';
import { setAttorneyReduxData } from 'redux/attorney/attorney-actions';
import { setUserReduxLogout } from 'redux/auth/auth-actions';
import store from 'redux/store';
import { setUserReduxData } from 'redux/user/user-actions';

import { getAppDispatch } from './dispatch';

axios.defaults.baseURL = `${BASE_URL}/api`;

axios.interceptors.request.use(
  (successfulReq) => {
    const token = store.store.getState().auth.userLogin;
    if (token) {
      successfulReq.headers['x-auth-token'] = token;
      successfulReq.headers['Access-Control-Allow-Credentials'] = true;
    }
    return successfulReq;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (successRes) => {
    return successRes;
  },
  (error) => {
    const { code, response } = error;
    if (
      (code === 'ERR_BAD_REQUEST' &&
        response?.data?.message === 'Invalid Token.') ||
      response?.data?.msg ===
        'Access denied. Please contact the system administrator'
    ) {
      const disptach = getAppDispatch();
      disptach(setUserReduxLogout(null));
      disptach(setUserReduxData(null));
      disptach(setAttorneyReduxData(null));
    }

    return Promise.reject(error);
  }
);

export default axios;
