import GenericTable from 'components/generic-table';
import Loading from 'components/loading';
import NoRecords from 'components/no-records';
import { C02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import React, { useEffect, useState } from 'react';
import style from './view.module.scss';

const ViewTable = ({ attorneys, columns, isLoading }) => {
  const [data, setData] = useState([...attorneys]);

  useEffect(() => {
    setData([...attorneys]);
  }, [columns, attorneys]);

  return (
    <>
      {!isLoading && attorneys?.length > 0 ? (
        <div className={style.tableWidth}>
          {isLoading && <Loading />}
          <GenericTable
            columns={columns}
            dataSource={data}
            classes={{
              test: style.test,
              table: style.table,
              thead: style.thead,
              th: style.th,
              tableBody: style.tableRow,
              tableOnLoading: style.genericTableOnLoading,
              tableOffLoading: style.genericTableOffLoading,
            }}
            renderNoDataFound={() => (
              <tr>
                <td colSpan={columns.length}>No Data Found</td>
              </tr>
            )}
          />
        </div>
      ) : (
        !isLoading && (
          <div className={style.noDataDiv}>
            <NoRecords
              mainClass={style.noRecord}
              noRecord={C02_SCREEN_MESSAGES.emptyPlaceholder.overview}
              icon={true}
              hideButton
              singleRowMode
            />
          </div>
        )
      )}
    </>
  );
};

export default ViewTable;
