import Login from 'pages/auth/login';
import ForgotPassword from 'pages/auth/forgot-password';
import Home from 'pages/home';
import SystemValues from 'pages/settings/system-values';
import ComponentsPage from 'pages/components-page';
import AttorrneyProfile from 'pages/attorney-profile';
import Settings from 'pages/settings/users';
import ConfirmationCode from 'pages/auth/confirmation-code';
import ResetPassword from 'pages/auth/reset-password';
import AttorneyDirectory from 'pages/attorneys-directory';
import ClientProfile from 'pages/client-profile';
import { ROLES } from 'permissions/constants';
import AttorneyForm from 'pages/attorney-form';
import BarHistoryForm from 'pages/attorney-form/bar-history';
import TrainingHistoryForm from 'pages/attorney-form/training-history';
import ClientDirectory from 'pages/client-directory';
import PositionsDirectory from 'pages/positions-directory';
import PlacementPositionProfile from 'pages/placement-position-profile';
import PositionComparisonReport from 'pages/position-comparison-report';

export const authRoutes = [
  {
    path: '/login',
    component: <Login />,
  },
  {
    path: '/forgot-password',
    component: <ForgotPassword />,
  },
  {
    path: '/confirmation-code',
    component: <ConfirmationCode />,
  },
  {
    path: '/reset-password',
    component: <ResetPassword />,
  },
];
export const PrivateRoutes = [
  {
    path: '/system-values',
    component: <SystemValues />,
    roles: [ROLES.ADMIN],
  },
  {
    path: '/',
    component: <Home />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    path: '/attorneys',
    component: <AttorneyDirectory />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    path: '/clients',
    component: <ClientDirectory />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    path: '/',
    component: <AttorneyForm />,
    roles: [ROLES.ATTORNEY],
  },
  {
    path: '/barhistoryForm',
    component: <BarHistoryForm />,
    roles: [ROLES.ATTORNEY],
  },
  {
    path: '/trainingHistoryForm',
    component: <TrainingHistoryForm />,
    roles: [ROLES.ATTORNEY],
  },
  {
    path: '/component-page',
    component: <ComponentsPage />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    path: '/attorneys/:id',
    component: <AttorrneyProfile />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    path: '/positions/comparison',
    component: <PositionComparisonReport />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    path: '/positions',
    component: <PositionsDirectory />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    path: '/positions/add',
    component: <PlacementPositionProfile />,
    roles: [ROLES.ADMIN, ROLES.EDITOR],
  },
  {
    path: '/positions/:id',
    component: <PlacementPositionProfile />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  ///////////////////////// Dumy Routes ///////////////////////////
  {
    path: '/overview',
    component: <Home />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    path: '/clients/:id',
    component: <ClientProfile />,
    roles: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER],
  },
  {
    path: '/users-management',
    component: <Settings />,
    roles: [ROLES.ADMIN],
  },
];
