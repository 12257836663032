/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Button from 'components/button';
import PermissionRestrict from 'hoc/PermissionRestrict';
import ViewTable from './view-table';

import { ROLES } from 'permissions/constants';

import del from 'assets/icons/del-blue.svg';
import pinkTrash from 'assets/icons/pinkTrash.svg';
import addPlus from 'assets/images/addPlusIcon.svg';
import columnIcon from 'assets/images/changeColumnsIcon.svg';
import clientDel from 'assets/images/clientDelIcon.svg';
import down from 'assets/images/downIcon.svg';
import up from 'assets/images/upIcon.svg';
import { useClientProfile } from 'contexts/client-profile';
import { C02_SCREEN_MESSAGES } from 'data/userFacingMessages';

import Loader from 'components/loader';
import Modal from 'components/modal';
import NoRecords from 'components/no-records';
import Tags from 'components/tags';
import { ATTORNEY_CLIENT_STATUS_COLORS } from 'constants';
import moment from 'moment';
import ColumnModal from 'pages/attorneys-directory/choose-columns-modal';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getTableColumns, updateTableColumns } from 'services/utils';
import style from './overview.module.scss';

const Overview = () => {
  const tableName = 'placementHistory';
  const navigate = useNavigate();
  const { id } = useParams();
  const userId = useSelector((state) => state.user.userData._id);

  const [columns, setColumns] = useState([]);
  const [viewTable, setViewTable] = useState([]);
  const [choseColModal, setChoseColModal] = useState(false);
  const [placementHistoryTableColumns, setPlacementHistoryTableColumns] =
    useState([]);

  const {
    open,
    edit,
    setOpen,
    handleDelete,
    deletePlacement,
    setPlacementPopup,
    placementPopup,
    placements,
    stats,
    isLoading,
    setMatches,
    attorneys,
  } = useClientProfile();

  const defaultColumn = [
    {
      name: 'Rank',
      key: 'slot',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '55px', height: '58px' },
      widthInEditMode: { width: '55px' },
      displayIcon: true,
      render: ({ index }) => {
        return (
          <>
            <p className={style.userName}>{index + 1}</p>
          </>
        );
      },
    },
    {
      name: 'Status',
      key: 'liStatus',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '181px', height: '58px' },
      widthInEditMode: { width: '182px' },
      displayIcon: true,
      render: ({ value, row }) => {
        return (
          <>
            <Tags
              circular={true}
              text={row?.attorneyCLientStatus}
              color={ATTORNEY_CLIENT_STATUS_COLORS[row?.attorneyCLientStatus]}
            />
          </>
        );
      },
    },
    {
      name: 'Score',
      key: 'score',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '182px' },
      displayIcon: true,
      render: ({ value }) => {
        return (
          <>
            <p className={style.userName}>{value > -1 ? value : 0}</p>
          </>
        );
      },
    },
    {
      name: 'Name',
      key: 'firstName',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '182px' },
      displayIcon: true,
      render: ({ value, row }) => {
        return (
          <>
            <p
              className={style.userName}
              style={{ textDecoration: 'underline' }}
              onClick={() => navigate(`/attorneys/${row._id}`)}
            >
              {`${row?.firstName} ${row?.lastName}`}
            </p>
          </>
        );
      },
    },
    {
      name: 'LI Start Date',
      key: 'attorneyStartDate',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '182px' },
      displayIcon: true,
      render: ({ value }) => {
        return (
          <>
            <span>
              {_.isNull(value) || _.isUndefined(value)
                ? '-'
                : moment(value).format('MM/DD/YYYY')}
            </span>
          </>
        );
      },
    },
    {
      name: 'Demo',
      key: 'attorneyDemo',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '182px' },
      displayIcon: true,
      render: ({ value }) => {
        return (
          <div className={style.rounded}>
            <span className={style?.columnEllipsis}>
              <div className={style.divElipsis}>{value?.join(', ')} </div>
            </span>
            {value?.join(', ') && value?.join(', ').length > 15 && (
              <div className={style.tooltipDemo}>
                <div className={style.tooltipChild}>
                  <p className={style.tooltipText}>
                    {value?.map((demoValue, index) => (
                      <React.Fragment key={index}>
                        {demoValue}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: 'Gender',
      key: 'attorneyGender',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '182px' },
      displayIcon: true,
      render: ({ value }) => {
        return (
          <div className={style.rounded}>
            <span className={style?.columnEllipsis}>
              <div className={style.divElipsis}>{value} </div>
            </span>
            {value?.length > 15 && (
              <div className={style.tooltip}>
                <div className={style.tooltipChild}>
                  <p className={style.tooltipText}>{value}</p>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
    {
      name: 'City',
      key: 'attorneyLocation',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '182px' },
      displayIcon: true,
      render: ({ value }) => {
        return (
          <div className={style.rounded}>
            <span>{value.join(', ')}</span>
          </div>
        );
      },
    },
    {
      name: 'Grad Year',
      key: 'attorneyGradYear',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '182px' },
      displayIcon: true,
      render: ({ value }) => {
        return (
          <>
            <p className={style.userName}>{value}</p>
          </>
        );
      },
    },
    {
      name: 'Bar Locations',
      key: 'barLocations',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '182px' },
      displayIcon: true,
      render: ({ value }) => {
        return (
          <div className={style.rounded}>
            <span className={style?.columnEllipsis}>
              <div className={style.divElipsis}>
                {value.length > 0 ? value.join(', ') : '-'}
              </div>
            </span>
            {_.size(value) > 1 && (
              <div className={style.tooltip}>
                <div className={style.tooltipChild}>
                  <p className={style.tooltipText}>
                    {value?.map((barState, index) => (
                      <React.Fragment key={index}>
                        {barState}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      const _placementHistoryTableColumns = await getTableColumns({
        userId,
        tableName,
        setColumns: setPlacementHistoryTableColumns,
      });
      if (_.size(_placementHistoryTableColumns) > 0) {
        let _defaultColumns = defaultColumn?.reduce((acc, curr) => {
          acc[curr.key] = curr;
          return acc;
        }, {});
        let defaultColumnStoreSorting = [];
        const hiddenColumns = _placementHistoryTableColumns.map(
          (x) => x.hidden
        );
        const hiddenColumnsLength = hiddenColumns.filter((x) => x).length;
        const visibileCol = [..._placementHistoryTableColumns].filter(
          (x) => !x.hidden
        );
        _placementHistoryTableColumns?.map((v) => {
          if (_defaultColumns[v.key])
            defaultColumnStoreSorting.push({
              ..._defaultColumns[v.key],
              ...(v.key === visibileCol[visibileCol.length - 1].key &&
                hiddenColumnsLength > 0 && {
                  widthAndHeight: { width: '100%', height: '58px' },
                }),
              hidden: v.hidden,
            });
        });
        setColumns(defaultColumnStoreSorting);
      } else {
        await updateTableColumns({
          userId,
          tableName,
          columns: defaultColumn.map(({ key, hidden }) => ({ key, hidden })),
          prevColumns: _placementHistoryTableColumns.map(({ key, hidden }) => ({
            key,
            hidden,
          })),
        });
        setColumns([...defaultColumn]);
      }
    })();
  }, []);

  const setColumnsHanlde = async (modifiedColumns) => {
    const checkedAllColumnsNotDisplayed = modifiedColumns?.every(
      (column) => column?.hidden === true
    );
    if (checkedAllColumnsNotDisplayed) {
      setColumns((prev) => [...prev]);
    } else {
      const newColumns = [...modifiedColumns];
      const hiddenColumns = modifiedColumns.map((x) => x.hidden);
      const hiddenColumnsLength = hiddenColumns.filter((x) => x).length;
      const visibileCol = newColumns.filter((x) => !x.hidden);

      const newHiddenColumns = [
        ...newColumns.map((x, index) => {
          return {
            ...x,
            hidden: hiddenColumns[index] || false,
            widthAndHeight: defaultColumn.find((y) => y.key === x.key)
              .widthAndHeight,
            ...(x.key === visibileCol[visibileCol.length - 1].key &&
              hiddenColumnsLength > 0 && {
                widthAndHeight: { width: '100%', height: '58px' },
              }),
          };
        }),
      ];
      await updateTableColumns({
        userId,
        tableName,
        prevColumns: columns.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
        columns: newHiddenColumns.map(({ key, hidden }) => ({
          key,
          hidden,
        })),
      });
      setColumns(newHiddenColumns);
    }
  };

  const onColumnsReset = async () => {
    await updateTableColumns({
      userId,
      tableName,
      columns: defaultColumn.map(({ key, hidden }) => ({
        key,
        hidden,
      })),
      prevColumns: columns.map(({ key, hidden }) => ({
        key,
        hidden,
      })),
    });
    setColumns([...defaultColumn]);
    setChoseColModal(false);
  };

  return (
    <>
      <div className={style.wrapper}>
        {isLoading && (
          <div
            style={{
              position: 'fixed',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              background: '#F8FFFD',
              zIndex: 50000,
            }}
          >
            <Loader cStyles={{ backgroundColor: '#F8FFFD' }} />
          </div>
        )}
        {placements?.length === 0 ? (
          <NoRecords
            noRecord={C02_SCREEN_MESSAGES.emptyPlaceholder.overview}
            icon={true}
            onClick={() => {
              navigate(`/positions/add?clientId=${id}`);
            }}
            btnText={'Add Placements'}
          />
        ) : (
          id != 'add' && (
            <>
              <div className={style.topDiv}>
                <p className={style.p}>Placement History</p>
                <div className={style.buttons}>
                  <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                    <Button
                      text={'Add Placement'}
                      btnClass={style.btnClassUncheck}
                      iconStart={addPlus}
                      handleClick={() => {
                        navigate(`/positions/add?clientId=${id}`);
                      }}
                    />
                  </PermissionRestrict>
                  <Button
                    handleClick={() => setChoseColModal(true)}
                    text={'Change Columns'}
                    btnClass={style.btnClassUncheck}
                    iconStart={columnIcon}
                  />
                </div>
              </div>
              <div className={style.dataView2}>
                <>
                  <div className={style.dataView}>
                    <p className={style.text}>
                      {'Placements Cumulative: '}
                      {stats?.placementsCummulative
                        ? stats?.placementsCummulative
                        : 0}
                    </p>
                  </div>
                  <div className={style.dataView}>
                    <p className={style.text}>
                      {'Placements Current: '}
                      {stats?.placementsCurrent ? stats?.placementsCurrent : 0}
                    </p>
                  </div>
                  <div className={style.dataView}>
                    <p className={style.text}>
                      {'Converted: '}
                      {stats?.placementsConverted
                        ? stats?.placementsConverted
                        : 0}
                    </p>
                  </div>
                  <div className={style.dataView}>
                    <p className={style.text}>
                      {'Returned: '}
                      {stats?.placementsReturned
                        ? stats?.placementsReturned
                        : 0}
                    </p>
                  </div>
                  <div className={style.dataView}>
                    <p className={style.text}>
                      {'Open Positions: '}
                      {stats?.openPositions ? stats?.openPositions : 0}
                    </p>
                  </div>
                  <div className={style.dataView}>
                    <p className={style.text}>
                      {'Interviewing: '}
                      {stats?.placementsInterviewing
                        ? stats?.placementsInterviewing
                        : 0}
                    </p>
                  </div>
                </>
              </div>

              <div className={style.maxWidthDiv}>
                <div className={style.innerMaxWidth}>
                  <div className={style.tableHeader}>
                    <p
                      className={style.emptyDiv}
                      style={{ flexBasis: '26px' }}
                    ></p>
                    <p>Practice Group</p>
                    <p style={{ paddingLeft: '3px' }}>Start Date</p>
                    <p style={{ paddingLeft: '5px' }}>End Date</p>
                    <p>Placements</p>
                    <p style={{ paddingLeft: '3px' }}>Open Positions</p>
                    <p style={{ paddingLeft: '3px' }}>Interviewing</p>
                    <p style={{ flexBasis: '400px', paddingLeft: '6px' }}>
                      Comments
                    </p>
                    <p className={style.emptyDiv}></p>
                  </div>
                  <div className={style.rowsBody}>
                    <div className={style.innerHeight}>
                      {placements &&
                        placements?.map((ele, index) => {
                          const positionAttorneys = attorneys?.find(
                            (x) => x?.id === ele?._id
                          )?.attorneys;

                          return (
                            <React.Fragment key={index}>
                              <div className={style.rows} key={index}>
                                <div
                                  className={style.imgDiv}
                                  style={{
                                    marginRight:
                                      positionAttorneys?.length === 0
                                        ? '20px'
                                        : '',
                                  }}
                                >
                                  {positionAttorneys?.length > 0 && (
                                    <>
                                      {viewTable.includes(index) ? (
                                        <img
                                          src={up}
                                          alt=""
                                          className={style.upIcon}
                                          onClick={() =>
                                            setViewTable((prev) => {
                                              const arr = prev.filter(
                                                (x) => x !== index
                                              );
                                              return [...arr];
                                            })
                                          }
                                          style={{ cursor: 'pointer' }}
                                        />
                                      ) : (
                                        <img
                                          src={down}
                                          alt=""
                                          className={style.upIcon}
                                          onClick={() => {
                                            setMatches(ele._id);
                                            setViewTable((prev) => [
                                              ...prev,
                                              index,
                                            ]);
                                          }}
                                          style={{ cursor: 'pointer' }}
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                                <div
                                  className={`${style.groupText}  ${style.toolTool}`}
                                >
                                  <p
                                    style={{
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      width: '120px',
                                    }}
                                    onClick={() => {
                                      navigate(`/positions/${ele._id}`);
                                    }}
                                  >
                                    {ele.practiceGroup || '-'}
                                  </p>
                                  {ele?.practiceGroup?.length > 14 && (
                                    <span>{ele.practiceGroup}</span>
                                  )}
                                </div>

                                <p className={style.groupText}>
                                  {moment(
                                    ele.sowStartDate?.split('T')?.[0]
                                  ).format('MM/DD/YYYY')}
                                </p>
                                <p className={style.groupText}>
                                  {moment(
                                    ele.sowEndDate?.split('T')?.[0]
                                  ).format('MM/DD/YYYY')}
                                </p>
                                <p className={style.groupText}>
                                  {ele.totalPlaced}
                                </p>
                                <p className={style.groupText}>
                                  {parseInt(ele?.openPositions || 0)}
                                </p>
                                <p className={style.groupText}>
                                  {ele?.totalInterViewing}
                                </p>
                                <div
                                  className={style.rounded}
                                  style={{
                                    flexBasis: '400px',
                                  }}
                                >
                                  <span className={style?.columnEllipsis}>
                                    <div className={style.commentEllipsis}>
                                      {ele?.comment}
                                    </div>
                                  </span>
                                  {ele?.comment && (
                                    <div className={style.tooltipComment}>
                                      <div className={style.tooltipChild}>
                                        <p
                                          className={style.tooltipText}
                                          style={{ whiteSpace: 'pre-wrap' }}
                                        >
                                          {ele?.comment}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className={style.delView}>
                                  <PermissionRestrict
                                    roles={[ROLES.ADMIN, ROLES.EDITOR]}
                                  >
                                    {edit && (
                                      <img
                                        src={del}
                                        alt=""
                                        onClick={() => {
                                          setPlacementPopup(ele._id);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                      />
                                    )}
                                  </PermissionRestrict>
                                </div>
                              </div>
                              {viewTable.includes(index) && (
                                <ViewTable
                                  columns={columns}
                                  isLoading={isLoading}
                                  attorneys={positionAttorneys || []}
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                open={placementPopup}
                handleClose={() => setPlacementPopup(false)}
              >
                <div className={style.modalHeader}>
                  <img src={pinkTrash} height={48} width={48} />
                  <h3 className={style.deleteText}>{'Delete Placement'}</h3>
                  <p>
                    {
                      C02_SCREEN_MESSAGES.popups.deleteConfirm.placementRecord
                        .body
                    }
                  </p>
                  <Button
                    btnClass={style.buttonStyle}
                    text={
                      C02_SCREEN_MESSAGES.popups.deleteConfirm.placementRecord
                        .yes
                    }
                    handleClick={() => {
                      deletePlacement(placementPopup);
                    }}
                    width="100%"
                    isLoading={isLoading}
                  />
                  <div onClick={() => setPlacementPopup(false)}>
                    <h3 className={style.cancelText}>
                      {
                        C02_SCREEN_MESSAGES.popups.deleteConfirm.placementRecord
                          .no[0]
                      }
                    </h3>
                    <h3 className={style.cancelText}>
                      {
                        C02_SCREEN_MESSAGES.popups.deleteConfirm.placementRecord
                          .no[1]
                      }
                    </h3>
                  </div>
                </div>
              </Modal>

              <ColumnModal
                isPink
                columns={[...columns]}
                choseColModal={choseColModal}
                setColumns={setColumnsHanlde}
                onColumnsReset={onColumnsReset}
                setChoseColModal={setChoseColModal}
              />
            </>
          )
        )}
      </div>
      <Modal open={open}>
        <div className={style.modalHeader}>
          <img src={pinkTrash} height={48} width={48} />
          <h3 className={style.deleteText}>{'Delete Client'}</h3>
          <p>{C02_SCREEN_MESSAGES.popups.deleteConfirm.client.body}</p>
          <Button
            btnClass={style.buttonStyle}
            text={C02_SCREEN_MESSAGES.popups.deleteConfirm.client.yes}
            handleClick={() => {
              handleDelete();
            }}
            width="100%"
          />
          <h3 className={style.cancelText} onClick={() => setOpen(false)}>
            {C02_SCREEN_MESSAGES.popups.deleteConfirm.client.no}
          </h3>
        </div>
      </Modal>
      <div className={style.deleteClientDiv}>
        {edit && id != 'add' && (
          <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
            <Button
              text={'Delete Client'}
              btnClass={style.deleteClient}
              iconStart={clientDel}
              handleClick={() => {
                setOpen(true);
              }}
            />
          </PermissionRestrict>
        )}
      </div>
    </>
  );
};
export default Overview;

const getLiStatusWithClientnames = (row) => {
  if (
    ((row?.statuses?.status?.status ?? row?.liStatus) === 'Interviewing_P' ||
      (row?.statuses?.status?.status ?? row?.liStatus) === 'Interviewing_LI') &&
    _.size(row?.relativeClients) > 0
  ) {
    return `Interviewing (${_.size(row?.relativeClients)}) `;
  } else if (
    ((row?.statuses?.status?.status ?? row?.liStatus) === 'Not Interviewing' ||
      (row?.statuses?.status?.status ?? row?.liStatus) === 'Interviewing_LI') &&
    _.size(row?.relativeClients) > 0
  ) {
    return `Unplaced ${
      _.size(row?.relativeClients) > 1
        ? `(${_.size(row?.relativeClients)})`
        : ''
    }`;
  } else if (
    (row?.statuses?.status?.status ?? row?.liStatus) === 'Placed' &&
    _.size(row?.relativeClients) > 0
  ) {
    return row?.relativeClients && row?.relativeClients[0]?.clientName;
  } else {
    return row?.statuses?.status?.status ?? row?.liStatus ?? '';
  }
};
