import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';

import TextField from 'components/textfield';
import Button from 'components/button';

import saveIcon from 'assets/images/save.svg';
import closeIcon from 'assets/icons/blue-cross.svg';

import style from './edit.module.scss';

const EditAddTable = ({
  setEditPopUp,
  data,
  setLoading,
  dataKey,
  label,
  placeholder,
  container,
  submitFunction,
  editPopUp,
}) => {
  const schema = yup
    .object({
      [dataKey]: yup
        .string()
        .trim()
        .required(label + ' is required '),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { ...data },
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      dispatch(
        submitFunction(
          {
            [dataKey]: values[dataKey],
          },
          data?._id
        )
      );

      setEditPopUp(false);
    } catch (err) {
      console.error('err', err?.response?.data?.message);
    }
    setLoading(false);
  };

  return (
    <div className={`${style.mainEdit} ${container && container}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label={label}
          name={dataKey}
          register={register}
          placeholder={placeholder}
          errorMessage={errors[dataKey] ? errors[dataKey]?.message : ''}
        />
        <div className={style.btnSection}>
          <img
            src={closeIcon}
            className={style.img}
            onClick={() => setEditPopUp(false)}
          />
          <Button
            text={'Save'}
            type={'submit'}
            iconStart={saveIcon}
            isLoading={isSubmitting}
            btnClass={style.btnClass}
          />
        </div>
      </form>
    </div>
  );
};

export default EditAddTable;
