import { useEffect, useState } from 'react';

import { createNotification } from 'components/react-notification';
import { useSelector } from 'react-redux';
import {
  deleteTrainingHistory,
  getAttorneyTrainingHistory,
} from 'services/attorney-profile';

const useHelper = () => {
  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trainingHistory, setTrainingHistory] = useState([]);
  const [traingingCalculations, setTraingingCalculations] = useState({});
  const attorneyData = useSelector((state) => state.attorney.attorneyData);

  useEffect(() => {
    getTrainingData();
  }, []);

  const getTrainingData = async () => {
    setIsLoading(true);
    try {
      const res = await getAttorneyTrainingHistory(attorneyData.attorney._id); //static for now
      setTrainingHistory(res.data.trainings ? [...res.data.trainings] : []);
      setTraingingCalculations({
        trainings: undefined,
        ...res.data,
      });
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  //delete
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteTrainingHistory(open);
      getTrainingData();
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
      setIsLoading(false);
    }
    setOpen(null);
  };

  const onAdd = () => {
    getTrainingData();
  };

  const onEdit = () => {
    getTrainingData();
  };

  return {
    onAdd,
    onEdit,
    toggle,
    openEdit,
    open,
    isLoading,
    trainingHistory,
    edit,
    deleteTrainingHistory,
    handleDelete,
    setToggle,
    setOpenEdit,
    setOpen,
    setEdit,
    traingingCalculations,
    attorney: attorneyData?.attorney,
  };
};

export default useHelper;
