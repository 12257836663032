/* eslint-disable no-unused-vars */
import moment from 'moment';
import { useContext, useState, useEffect, createContext, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { deleteClient, getClientById, setClient } from 'services/client-page';
import { createNotification } from 'components/react-notification';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { getClientComments } from 'services/client-page';
import { getPlacementById, getPlacements } from 'services/placement-profile';
import {
  deletePosition,
  getAllPositionsMatches,
  getMatchVersion,
  getUserPositionWeights,
} from 'services/positions';
import * as yup from 'yup';
import { getContactById } from 'services/client-contact';
import { getAllPositions } from 'services/positions';
import { useSelector } from 'react-redux';

const ClientProfileContext = createContext();

export const useClientProfile = () => useContext(ClientProfileContext);
export default function ClientProfileProvider({ children }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [clientHistory, setClientHistory] = useState();
  const [stats, setStats] = useState();
  const [attorneys, setAttorneys] = useState([]);
  const [placements, setPlacements] = useState();
  const [edit, setEdit] = useState(false);
  const [editContact, setEditContact] = useState();
  const [addContact, setAddContact] = useState(false);
  const [contact, setContact] = useState([]);
  const [matches, setMatches] = useState();
  const [disable, setDisable] = useState(false);
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [placementPopup, setPlacementPopup] = useState(false);
  const [commentLength, setCommentLength] = useState(0);
  const [contactLength, setContactLength] = useState(0);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    setCommentLength(comments?.length);
  }, [comments]);

  useEffect(() => {
    matches && getMatches();
  }, [matches]);

  useEffect(() => {
    setContactLength(contact?.length);
  }, [contact]);

  const handleUpdateMatches = async (data) => {
    setPlacements((prev) => {
      const index = prev.findIndex((x) => x._id === data.positionId);
      prev[index] = { ...prev[index], matches: data.matches };
      return prev;
    });
  };
  const getMatches = async () => {};

  const getClientCommentData = async () => {
    setIsLoading(true);
    try {
      const res = await getClientComments(id);
      setComments([...res.data.comments]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (id !== 'add') {
      (async () => {
        setIsLoading(true);
        Promise.all([
          await getClientData(),
          await getClientCommentData(),
          await getPlacementData(),
          await getContactData(),
        ]);
        setIsLoading(false);
      })();
    } else {
      setEdit(true);
    }
  }, []);

  useEffect(() => {
    if (clientHistory) {
      reset({
        ...clientHistory,
        contractDateStart:
          clientHistory.contractDateStart &&
          moment(clientHistory?.contractDateStart?.split('T')?.[0]),
        contractDateEnd:
          clientHistory.contractDateEnd &&
          moment(clientHistory.contractDateEnd?.split('T')?.[0]),
        clientAmLaw50:
          clientHistory.clientAmLaw50 === null
            ? null
            : clientHistory.clientAmLaw50 === true
            ? 'true'
            : 'false',
      });
    }
  }, [clientHistory]);

  const {
    watch,
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: !clientHistory
      ? {}
      : {
          ...clientHistory,
          contractDateStart:
            clientHistory.contractDateStart &&
            new Date(
              moment(clientHistory?.contractDateStart).format('YYYY-MM-DD')
            ),
          contractDateEnd:
            clientHistory.contractDateEnd &&
            new Date(
              moment(clientHistory?.contractDateEnd).format('YYYY-MM-DD')
            ),
        },
    resolver: yupResolver(schema),
  });

  const getClientData = async () => {
    try {
      const res = await getClientById(id);
      setClientHistory({ ...res.data.client });
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
  };

  const getPlacementData = async () => {
    await getAllPositionsMatches({
      setLoading: setIsLoading,
      setPositions: setPlacements,
      setStats,
      setAttorneys,
      params: { clientId: id },
    });
  };

  const getContactData = async () => {
    setIsLoading(true);
    try {
      const res = await getContactById(id);
      setContact([...res.data.contacts]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  //delete
  const handleDelete = async () => {
    try {
      await deleteClient(id);
      navigate('/clients');
      createNotification('success', 'Success', 'Deleted');
    } catch (err) {
      console.error(err);
    }
  };

  //delete placement
  const deletePlacement = async () => {
    setIsLoading(true);
    try {
      await deletePosition({ id: placementPopup });
      await getPlacementData();
      setPlacementPopup(false);
      createNotification(
        'success',
        'Success',
        'Placement successfully deleted'
      );
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const onSubmit = async (values) => {
    const {
      clientName,
      clientType,
      clientStatus,
      clientAmLaw50,
      contractDateStart,
      contractDateEnd,
    } = values;

    try {
      const response = await setClient(
        {
          clientName,
          clientType,
          clientStatus,
          clientAmLaw50: clientAmLaw50,
          contractDateStart: contractDateStart
            ? moment(contractDateStart).format('YYYY-MM-DD')
            : null,
          contractDateEnd: contractDateEnd
            ? moment(contractDateEnd).format('YYYY-MM-DD')
            : null,
        },
        id
      );
      if (id === 'add') {
        navigate(`/clients/${response.data.client._id}`, {
          state: {
            from: 'new',
          },
        });
      }
      setClientHistory(response.data.client);
      setDisable(false);
      setEdit(null);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          err?.message ||
          'Server Error'
      );
      console.error('err', err);
    }
  };

  return (
    <ClientProfileContext.Provider
      value={{
        register,
        control,
        getClientData,
        clientHistory,
        handleSubmit,
        reset,
        edit,
        setEdit,
        active,
        setActive,
        open,
        setOpen,
        onSubmit,
        errors,
        setCommentLength,
        setComments,
        commentLength,
        matches,
        setMatches,
        handleDelete,
        comments,
        setPlacementPopup,
        placementPopup,
        deletePlacement,
        watch,
        addContact,
        setAddContact,
        placements,
        stats,
        isSubmitting,
        isLoading,
        contact,
        getContactData,
        setContact,
        disable,
        editContact,
        contactLength,
        setContactLength,
        setEditContact,
        setDisable,
        attorneys,
      }}
    >
      {children}
    </ClientProfileContext.Provider>
  );
}

const usPhonenumberRegex =
  /^$|(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g;
const schema = yup
  .object({
    clientName: yup.string().required('Name is required'),
    primaryContactEmail: yup
      .string()
      .email('Email must be a valid email')
      .optional(),
    primaryContactPhone: yup
      .string()
      .matches(
        usPhonenumberRegex,
        'Please enter a valid US phone number e.g., 202 555 4567 '
      )
      .optional(),
    secondaryContactEmail: yup
      .string()
      .email('Email must be a valid email')
      .optional(),
    secondaryContactPhone: yup
      .string()
      .matches(
        usPhonenumberRegex,
        'Please enter a valid US phone number e.g., 202 555 4567 '
      )
      .optional(),
  })
  .required();
