export class FilterState {
  constructor() {
    this.attorneyTableFilter = {};
    this.dashBoardFilter = {};
    this.attorneyTableColumns = [];
    this.clientTableFilter = {};
    this.positionTableFilter = {};
    this.attorneyDirectoryChecked = [];
    this.clientDirectoryChecked = [];
    this.positionDirectoryChecked = [];
  }
}
