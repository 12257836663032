import cross from 'assets/icons/cross.svg';
import React from 'react';
import style from './modal.module.scss';

const Modal = ({
  open,
  children,
  className,
  bodyClass,
  showCross,
  handleClose,
  bodyPadding,
  modalWrapper,
  uploadMode,
  ...props
}) => {
  const handleClickWrapper = (event) => {
    event.nativeEvent.stopImmediatePropagation();
    handleClose?.();
  };

  return (
    <>
      {open && (
        <div
          className={`${style.modalWrapper} ${modalWrapper}`}
          onClick={(e) => {
            e.nativeEvent.stopImmediatePropagation();
            !uploadMode && handleClose?.();
          }}
          {...props}
        >
          <div
            className={`${style.modalContentWrapper} ${className}`}
            onClick={(e) => e.stopPropagation()}
          >
            {showCross && (
              <div
                style={{
                  alignSelf: 'flex-end',
                  marginRight: '33px',
                  marginTop: 20,
                  paddingTop: '10px',
                  cursor: 'pointer',
                }}
              >
                <img
                  height={12}
                  style={{ cursor: 'pointer' }}
                  src={cross}
                  onClick={handleClose}
                />
              </div>
            )}
            <div
              className={`${style.body} ${bodyClass}`}
              {...(bodyPadding && { style: { padding: bodyPadding } })}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
