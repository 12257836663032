const API_URLS = {
  LOGIN: '/users/login',
  USER: '/users',
  GET_ROLE: '/users/get-role',
  CHANGE_PASSWORD: '/users/update-password',
  FORGOT_PASSWORD: '/users/forget-password',
  RESET_PASSWORD: '/users/change-password',

  // System Values
  GET_SYSTEM_PRACTICE_GROUPS: '/system-values/practice-groups',
  ADD_SYSTEM_PRACTICE_GROUPS: '/system-values/practice-groups',
  DELETE_SYSTEM_PRACTICE_GROUPS: '/system-values/practice-groups',

  GET_SYSTEM_LOCATIONS: '/system-values/locations',
  ADD_SYSTEM_LOCATIONS: '/system-values/locations',
  DELETE_SYSTEM_LOCATIONS: '/system-values/locations',

  GET_SYSTEM_BAR_STATES: '/system-values/barstates',
  ADD_SYSTEM_BAR_STATES: '/system-values/barstates',
  DELETE_SYSTEM_BAR_STATES: '/system-values/barstates',

  EDIT_ALGO_WEIGHT: '/system-values/system-weights',
  GET_SYSTEM_WEIGHTS: '/system-values/system-weights',
  ADD_USER_POSITION_WEIGHTS: '/system-values/system-weights',
  GET_USER_POSITION_WEIGHTS: '/system-values/system-weights/userPosition',

  //Attorneys page
  GET_ATTORNEY: '/attorneys/attorneys',
  GET_ATTORNEY_BY_EMAIL: '/attorneys/attorneys/getByEmail',
  SET_ATTORNEY: '/attorneys/attorneys',
  SET_ATTORNEY_UPDATED: '/attorneys/attorneys/attorneyUpdated',
  GET_ADD_ATTORNEY_DATA: '/attorneys/getAddAttorneyData',

  GET_BAR_HISTORY: '/abars/bars',
  SET_BAR_HISTORY: '/abars/bars',
  GET_ALL_BAR_HISTORIES: '/abars/bar-hidtory',

  GET_CLIENT_NAME: '/clients/clients-names',
  GET_CLIENT_POSITION: '/clients/clients-names-with-positions',
  GET_CLIENT_HISTORY: '/history/history',
  SET_CLIENT_HISTORY: '/history/history',
  ADD_UPDATE_CLIENT_HISTORY: '/history/history/addUpdate',

  GET_COMMENTS: '/comments/comments',
  SET_COMMENTS: '/comments/comments',

  GET_TRAINIG_HISTORY: '/trainings/trainings',
  SET_TRAINIG_HISTORY: '/trainings/trainings',

  GET_FILES: '/files/attorneyFiles',
  DOWNLOAD_FILES: '/files/download',
  SET_FILES: '/files/files',
  CANCEL_SET_FILES: '/files/cancel',

  //Clients C02
  GET_CLIENTS: '/clients',
  GET_CLIENTS_CSV: '/clients/clients-csv',
  GET_CLIENTS_CALCULATIONS: '/clients/calculations',
  UPDATE_CLIENT_DIRECTORY: '/clients/edit-mode',
  GET_CLIENT_BY_ID: (id) => `/clients/${id}`,
  SET_CLIENT: '/clients',
  //Contacts C02
  GET_CONTACTS: '/contacts',
  SET_CONTACTS: '/contacts',
  GET_CONTACTS_BY_ID: '/contacts',

  //Clients C02
  ADD_POSITION: '/positions/add',
  GET_ALL_POSITIONS: '/positions/getAll',
  GET_ALL_POSITIONS_MATCHES: '/positions/getPositionsMatches',
  GET_POSITION_BY_ID: (id) => `/positions/get/${id}`,
  UPDATE_POSITION: (id) => `/positions/update/${id}`,
  DELETE_POSITION: (id) => `/positions/delete/${id}`,
  GET_COMPARISON_REPORT: '/positions/position-comparison',

  //Placement
  GET_PLACEMENTS: '/placements/placements',
  SET_PLACEMENT: '/placements/placements',

  //dashboard
  GET_DASHBOARD: '/dashboard',

  SET_FILE_NAME: '/files/files',

  //Attorney Directory
  GET_ATTORNEYS: '/attorneys/attorneys',
  DELETE_ATTORNEY: '/attorneys/attorneys',
  GET_ATTORNEYS_DIRECTORY: '/attorneys/directory',
  UPDATE_ATTORNEYS_DIRECTORY: '/attorneys/edit-mode',
  UPDATE_ATTORNEYS_LISTATUS: '/attorneys/updateStatus',
  UPDATE_A_LI_AND_A_CH_P: '/attorneys/updateClientHistoriesForPlaced',
  UPDATE_A_LI_AND_A_CH_NI: '/attorneys/updateClientHistoriesForNotInterviewing',
  GET_ATTORNEY_CALCULATIONS: '/attorneys/calculations',
  GET_ATTORNEYS_DIRECTORY_CSV: '/attorneys/attorneys-directory-csv',

  GET_LAW_SCHOOLS: '/attorneys/schools',

  // Match
  GENERATE_MATCHES: `/matches/matches`,
  GET_ALL_MATCHES: `/matches/matches`,
  GET_UPDATE_DELETE_MATCH_BY_ID: (id) => `/matches/matches/${id}`,

  // Match Versions
  CREATE_MATCH_VERSION: `/match-version`,
  GET_ALL_MATCH_VERSIONS: '/match-version',
  GET_SINGLE_MATCH_VERSION: `/match-version/single`,
  UPDATE_MATCH_VERSION: (id) => `/match-version/${id}`,
  DELETE_MATCH_VERSION: (id) => `/match-version/${id}`,

  //Data Sync
  UPLOAD_FLAT_FILE: '/flat-file',
  GET_PAYLOCITY: '/paylocity',
  GET_SALES_FORCE_SYNC: '/sales-force',

  GET_POSITIONS_CSV: '/positions/positions-csv',
  GET_POSITIONS_CALCULATIONS: '/positions/calculations',
  UPDATE_ALL_POSITIONS: '/positions/edit-mode',

  // utils
  GET_TABLE_COLUMNS: '/utils/get-table-columns',
  UPDATE_TABLE_COLUMNS: '/utils/update-table-columns',
};

export { API_URLS };
