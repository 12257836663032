import _ from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Checkbox from 'components/checkbox';
import GenericTable from 'components/generic-table';

import style from './directory-table.module.scss';

const PositionTable = (
  {
    page,
    rows,
    order,
    sortBy,
    loading,
    noMatch,
    columns,
    editTable,
    dataSource,
    getNextData,
    setDataSource,
    updateClientData,
    formId = 'hook-form',
    setCheckedRowData = () => {},
    getSelectedSorting = () => {},
  },
  ref
) => {
  const navigate = useNavigate();
  const [checkedArray, setCheckedArray] = useState([]);

  useImperativeHandle(ref, () => ({
    async clearAllCheckRows() {
      setCheckedArray([]);
    },
  }));

  const checkedArrayMap = checkedArray.reduce((acc, curr) => {
    acc[curr._id] = true;
    return acc;
  }, {});

  const arrToRender = React.useMemo(
    () =>
      dataSource?.filter((x) => x?.checked)?.length === rows.length
        ? [...dataSource]
        : page === 1 && noMatch
        ? dataSource?.map((x) => {
            if (checkedArrayMap[x?._id]) return { ...x, checked: true };
            else return x;
          })
        : !noMatch && _.size(dataSource?.filter((x) => x?.checked)) > 0
        ? [
            ...(dataSource?.filter((y) => y?.checked) ?? []),
            ...(dataSource?.filter((y) => !y?.checked) ?? []),
          ]
        : [...checkedArray, ...(dataSource?.filter((y) => !y?.checked) ?? [])],
    [dataSource, checkedArray]
  );

  const clickOnTableRow = (checked, positionRowId) => {
    const selectedRowIndex = dataSource.findIndex(
      (selectedRow) => selectedRow._id === positionRowId
    );
    const copy = [...dataSource];
    copy[selectedRowIndex] = { ...copy[selectedRowIndex], checked };

    setDataSource([...copy]);
    let _checkedArray = [...checkedArray];
    if (checked) {
      _checkedArray = [..._checkedArray, copy[selectedRowIndex]];
      setCheckedArray(_checkedArray);
    } else {
      _checkedArray = [...checkedArray];
      _checkedArray = _checkedArray.filter((x) => x?._id !== positionRowId);
      setCheckedArray(_checkedArray);
    }
    setCheckedRowData(_checkedArray, copy);
  };

  const checkedAllRows = (checkAllRows) => {
    const dataSourceWithChecked = dataSource?.map((x) => {
      return { ...x, checked: checkAllRows };
    });
    setDataSource([...dataSourceWithChecked]);
    setCheckedRowData(dataSourceWithChecked);
    setCheckedArray([]);
  };

  useEffect(() => {
    setDataSource([...rows]);
  }, [rows]);

  return (
    <div>
      <div className={style.tableWidth}>
        <div
        >
          <GenericTable
            ref={ref}
            schema={schema}
            columns={
              !editTable
                ? columns?.filter((x) => x?.key !== 'actions' && !x?.hidden)
                : columns
            }
            dataSource={arrToRender}
            isEditMode={editTable}
            onSaveSubmit={(updatedRows) => {
              updateClientData(updatedRows);
              setDataSource([...updatedRows]);
            }}
            height={'calc(100vh - 380px)'}
            formId={formId}
            onSort={(sort) => getSelectedSorting(sort)}
            renderNoDataFound={() => (
              <tr>
                <td colSpan={columns.length}>No Data Found</td>
              </tr>
            )}
            onScrollBottom={async () => {
              await getNextData();
            }}
            onRowClick={(row) => {
              navigate(`/positions/${row._id}`);
            }}
            selectable={true}
            renderSelector={(selectedRowId, selectedRowChecked) => (
              <>
                <Checkbox
                  containerClass={style.checkboxContainer}
                  handleChange={(e) =>
                    clickOnTableRow(e?.target?.checked, selectedRowId)
                  }
                  checked={selectedRowChecked}
                />
              </>
            )}
            classes={{
              table: style.table,
              thead: style.thead,
              th: style.th,
              tableBody: style.tableRow,
              tableOnLoading: style.genericTableOnLoading,
              tableOffLoading: style.genericTableOffLoading,
            }}
            checkedAllRows={checkedAllRows}
            loading={loading}
            sortBy={sortBy}
            order={order}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(forwardRef(PositionTable));

const schema = Yup.object({
  data: Yup.array().of(
    Yup.object({
      practiceGroup: Yup.string().required('Practice Group is required'),
      sowStartDate: Yup.date()
        .typeError('Start Date is required')
        .required('Start Date is required'),
      sowEndDate: Yup.date()
        .typeError('End Date is required')
        .required('End Date is required'),
      clientType: Yup.string()
        .typeError('Client Type is required')
        .required('Client Type is required'),
    })
  ),
});
