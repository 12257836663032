import * as Actions from './constants';

export const setDashBoardFilter = (filters) => async (dispatch) => {
  dispatch({
    type: Actions.SET_DASHBOARD_FILTER,
    payload: filters,
  });
};
export const setAttorneyTableFilter = (filters) => async (dispatch) => {
  dispatch({
    type: Actions.SET_ATTORNEY_TABLE_FILTER,
    payload: filters,
  });
};

export const setAttorneyTableColumns = (columns) => async (dispatch) => {
  dispatch({
    type: Actions.SET_ATTORNEY_TABLE_COLUMNS,
    payload: columns,
  });
};

export const setCLientTableFilter = (filters) => async (dispatch) => {
  dispatch({
    type: Actions.SET_CLIENTS_TABLE_FILTER,
    payload: filters,
  });
};

export const setPositionTableFilter = (filters) => async (dispatch) => {
  dispatch({
    type: Actions.SET_POSITIONS_TABLE_FILTER,
    payload: filters,
  });
};

export const setCheckedRowsData = (checkedRowIds, type) => async (dispatch) => {
  dispatch({
    type: Actions[`SET_${type}_DIRECTORY_CHECKED`],
    payload: checkedRowIds,
  });
};
