import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import * as yup from 'yup';

import Container from 'components/container';
import DatePicker from 'components/date-picker';
import Loader from 'components/loader';
import SelectBox from 'components/select-box';
import Tags from 'components/tags';
import TextField from 'components/textfield/index';
import PermissionRestrict from 'hoc/PermissionRestrict';
import Comments from '../comments';
import PositionOverview from '../overview';
import DuplicatePositionModal from './duplicate-position-modal';

import { CLIENT_STATUS_COLORS } from 'constants';

import PositionProvider, { usePositionContext } from 'contexts/position';
import { ROLES } from 'permissions/constants';
import {
  addPosition,
  getAllClients,
  getPositionById,
  updatedPosition,
} from 'services/positions';

import backIcon from 'assets/arrow-left-purple.svg';
import style from './profile-top.module.scss';

const ProfileTop = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;
  let [searchParams] = useSearchParams();
  const { practiceGroups } = useSelector((state) => state.systemValues);

  const {
    reset,
    watch,
    control,
    register,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { placementQuantity: 0 },
    resolver: yupResolver(schema),
  });

  const {
    disable,
    comments,
    isLoading,
    setDisable,
    setComments,
    commentLength,
    getPositionCommentData,
  } = usePositionContext();

  const [active, setActive] = useState(0);
  const [clients, setClients] = useState([]);
  const [clientsNameWithStatatus, setClientsNameWithStatus] = useState([]);
  const [position, setPosition] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [practiceGroupsOptions, setPracticeGroupsOptions] = useState([]);
  const [duplicatePositionModal, setDuplicatePositionModal] = useState(false);
  const [edit, setEdit] = useState(
    pathname.includes('/add')
      ? 'add'
      : pathname.includes('edit')
      ? 'edit'
      : false
  );

  const fetchData = async () => {
    setIsDataLoading(true);
    await getAllClients({ setClients, setClientsNameWithStatus });
    id && (await getPositionById({ id, setPosition }));
    setIsDataLoading(false);
  };

  const handleReset = (position) => {
    return {
      clientId: position?.client?._id,
      sowEndDate: position?.sowEndDate
        ? moment(position?.sowEndDate.split('T')[0])
        : '',
      sowStartDate: position?.sowStartDate
        ? moment(position?.sowStartDate?.split('T')[0])
        : '',
      locations: position?.locations?._id,
      barStates: position?.barStates?._id,
      practiceGroup: position?.practiceGroup?._id,
      placementQuantity: position?.placementQuantity || 0,
    };
  };

  const onSubmit = async (data) => {
    const body = {
      ...data,
      sowStartDate: moment(data.sowStartDate).format('YYYY-MM-DD'),
      sowEndDate: moment(data.sowEndDate).format('YYYY-MM-DD'),
    };
    edit === 'add' &&
      (await addPosition({
        body,
        reset,
        setEdit,
        navigate,
        setDuplicatePositionModal,
      }));
    edit === 'edit' &&
      (await updatedPosition({
        id,
        body,
        reset,
        setEdit,
        fetchData,
        setDuplicatePositionModal,
      }));
    setDisable(false);
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#673e9b');
    fetchData();
    setPracticeGroupsOptions(
      practiceGroups.map(({ _id, practiceGroup }) => {
        return { value: _id, label: practiceGroup };
      })
    );
    reset({ placementQuantity: 0, clientId: searchParams.get('clientId') });
  }, [id]);

  return (
    <>
      {isDataLoading ? (
        <Loader />
      ) : (
        <div className={style.bgDiv}>
          <form
            id="add-placement"
            onSubmit={(e) => {
              e.preventDefault();
              clearErrors();
              handleSubmit(onSubmit)(e);
            }}
          >
            <div className={style.whiteWrapper}>
              {!edit && (
                <img
                  className={style.imgLogoBack}
                  src={backIcon}
                  alt={'back icon'}
                  onClick={() => {
                    if (!disable) {
                      if (location.state?.from === 'new') navigate(-2);
                      else navigate(-1);
                    }
                  }}
                />
              )}
              <Container>
                <div className={style.flexDiv}>
                  <div className={style.leftDiv}>
                    {!edit && (
                      <div className={style.profileImgContainer}>
                        <img
                          src={backIcon}
                          alt={'back icon'}
                          className={style.imgLogoBack1}
                          onClick={() => {
                            if (!disable) {
                              if (location.state?.from === 'new') navigate(-2);
                              else navigate(-1);
                            }
                          }}
                        />
                      </div>
                    )}
                    <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                      {edit && (
                        <div className={style.userInfoWraper}>
                          <div className={style.editTextSec}>
                            <SelectBox
                              label={'Client Name'}
                              name="clientId"
                              control={control}
                              options={clients}
                              errorMessage={errors?.clientId?.message}
                            />
                          </div>
                          <div className={style.editTextSec}>
                            <TextField
                              label={'Client Status'}
                              value={
                                !_.isEmpty(clientsNameWithStatatus) &&
                                !_.isEmpty(watch('clientId'))
                                  ? clientsNameWithStatatus?.find(
                                      (x) => x?.clientId === watch('clientId')
                                    )?.clientStatus || '-'
                                  : '-'
                              }
                              isDisable
                            />
                          </div>

                          <div className={style.editTextSec}>
                            <DatePicker
                              id="1"
                              isPurple
                              control={control}
                              label="Start Date"
                              name="sowStartDate"
                              placeholder="mm/dd/yyyy"
                              popparBack={style.popper}
                              errorMessage={errors?.sowStartDate?.message}
                            />
                          </div>
                          <div className={style.editTextSec}>
                            <DatePicker
                              label="End Date"
                              name="sowEndDate"
                              id="2"
                              placeholder="mm/dd/yyyy"
                              control={control}
                              isPurple
                              errorMessage={errors?.sowEndDate?.message}
                              popparBack={style.popper1}
                            />
                          </div>
                          <div className={style.editTextSec}>
                            <SelectBox
                              name="practiceGroup"
                              control={control}
                              options={practiceGroupsOptions}
                              label={'Practice Group'}
                              errorMessage={errors?.practiceGroup?.message}
                            />
                          </div>
                          <div className={style.editTextSec}>
                            <TextField
                              type="number"
                              label={'# of Positions'}
                              name="placementQuantity"
                              wraperClass={style.fieldPosition}
                              register={register}
                              errorMessage={errors?.placementQuantity?.message}
                            />
                          </div>
                        </div>
                      )}
                    </PermissionRestrict>
                    {!edit && (
                      <div className={style.userInfoWraper1}>
                        <div className={style.editClassInfo1}>
                          <span style={{ fontSize: '14px', fontWeight: 400 }}>
                            Client Name
                          </span>
                          <b>
                            <span
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                navigate(`/clients/${position?.client?._id}`);
                              }}
                            >
                              {position?.client?.clientName}
                            </span>
                          </b>
                        </div>
                        <div className={style.editClassInfo1}>
                          <span style={{ fontSize: '14px', fontWeight: 400 }}>
                            Client Status
                          </span>
                          <Tags
                            text={
                              position?.client?.clientStatus
                                .split(' ')
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(' ') || '-'
                            }
                            borderColor={
                              CLIENT_STATUS_COLORS[
                                position?.client?.clientStatus
                              ]
                            }
                          />
                        </div>
                        <div className={style.editClassInfo}>
                          <span style={{ fontSize: '14px', fontWeight: 400 }}>
                            Start Date
                          </span>
                          <b>
                            <span>
                              {_.isEmpty(position?.sowStartDate)
                                ? '-'
                                : moment(
                                    position?.sowStartDate?.split('T')?.[0]
                                  ).format('MM/DD/YY')}
                            </span>
                          </b>
                        </div>
                        <div className={style.editClassInfo}>
                          <span style={{ fontSize: '14px', fontWeight: 400 }}>
                            End Date
                          </span>
                          <b>
                            <span>
                              {_.isEmpty(position?.sowEndDate)
                                ? '-'
                                : moment(
                                    position?.sowEndDate?.split?.('T')?.[0]
                                  ).format('MM/DD/YY')}
                            </span>
                          </b>
                        </div>
                        <div className={style.editClassInfo}>
                          <span style={{ fontSize: '14px', fontWeight: 400 }}>
                            Practice Group
                          </span>
                          <b>
                            <span>
                              {_.isEmpty(position?.practiceGroup?.practiceGroup)
                                ? '-'
                                : position?.practiceGroup?.practiceGroup}
                            </span>
                          </b>
                        </div>
                        <div className={style.editClassInfo}>
                          <span style={{ fontSize: '14px', fontWeight: 400 }}>
                            # of Positions
                          </span>
                          <b>
                            <span>{position?.placementQuantity}</span>
                          </b>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.tabs}>
                  <p
                    onClick={() => !disable && setActive(0)}
                    style={{
                      color: active === 0 && '#673e9b',
                      borderBottom: active === 0 && '1px solid #673e9b',
                    }}
                  >
                    Overview
                  </p>
                  <p
                    onClick={() => !disable && setActive(1)}
                    style={{
                      color: active === 1 && '#673e9b',
                      borderBottom: active === 1 && '1px solid #673e9b',
                    }}
                  >
                    Comments ({commentLength || 0})
                  </p>
                </div>
              </Container>
            </div>
          </form>
          {active === 0 && (
            <PositionOverview
              {...{
                id,
                edit,
                watch,
                reset,
                errors,
                active,
                setEdit,
                control,
                register,
                position,
                setDisable,
                handleReset,
                isSubmitting,
              }}
            />
          )}
          <Container>
            {active === 1 && (
              <Comments
                active={active}
                comments={comments}
                isLoading={isLoading}
                setDisable={setDisable}
                setComments={setComments}
                getPositionCommentData={getPositionCommentData}
              />
            )}
          </Container>
        </div>
      )}
      <DuplicatePositionModal
        reset={reset}
        onSubmit={onSubmit}
        duplicatePositionModal={duplicatePositionModal}
        setDuplicatePositionModal={setDuplicatePositionModal}
      />
    </>
  );
};

// export default ProfileTop;

const ProfileTopPositions = (props) => (
  <PositionProvider>
    <ProfileTop {...props} />
  </PositionProvider>
);
export default ProfileTopPositions;

const schema = yup.object({
  clientId: yup
    .string()
    .typeError('Client Name is a required field')
    .required()
    .label('Client Name'),
  sowEndDate: yup
    .date()
    .required()
    .typeError('End Date is a required field')
    .label('End Date'),
  sowStartDate: yup
    .date()
    .required()
    .typeError('Start Date is a required field')
    .label('Start Date'),
  practiceGroup: yup
    .string()
    .typeError('Practice Group is a required field')
    .required()
    .label('Practice Group'),
  locations: yup
    .string()
    .label('City')
    .required('City is is a required field')
    .typeError('City is is a required field'),

  placementQuantity: yup
    .number()
    .min(1)
    .label('# of Positions')
    .required('# of Positions is a required field')
    .typeError('# of Positions is a required field'),

  barStates: yup
    .string()
    .label('Accepted Bars')
    .required('Accepted Bars is a required field')
    .typeError('Accepted Bars is a required field'),
});
