import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import Modal from 'components/modal';
import Button from 'components/button';
import SelectBox from 'components/select-box';
import DatePicker from 'components/date-picker';

import { ATTORNEY_CLIENT_STATUS } from 'constants';
import { addAttorneyClientStatus } from 'services/attorney-profile';

import style from './select-modal.module.scss';

const SelectStatus = ({
  matches,
  setMatches,
  selectStatus,
  setSelectStatus,
  setRemoveStatus,
}) => {
  const {
    reset,
    watch,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  useEffect(() => {
    selectStatus && selectStatus !== true
      ? reset({
          status: selectStatus?.status,
          statusDate: selectStatus?.statusDate
            ? moment(
                moment(selectStatus?.statusDate)
                  .utcOffset(0)
                  .format('YYYY-MM-DD')
              )
            : '',
        })
      : reset({
          status: '',
          statusDate: '',
        });
  }, [selectStatus]);

  const handleStatusUpdate = async ({
    body,
    selectStatus,
    setSelectStatus,
    deletePrev = false,
  }) => {
    const date = new Date(moment(body?.statusDate).format('YYYY-MM-DD'));
    setMatches((prev) => {
      let statusHistory = [];
      let prevStatusHistory =
        prev.matches[selectStatus.index]?.interviews?.clientStatus
          ?.statusHistory || [];
      statusHistory.push(body.status);
      if (
        prev?.matches?.[selectStatus.index]?.interviews?.clientStatus
          ?.statusHistory?.length
      ) {
        statusHistory = [...statusHistory, ...prevStatusHistory];
      }
      if (prev.matches[selectStatus.index].interviews) {
        prev.matches[selectStatus.index].interviews = {
          ...prev?.matches?.[selectStatus.index]?.interviews,
          clientStatus: {
            _id: {
              ...selectStatus?._id,
              clientId: { _id: selectStatus?._id.clientId },
            },
            ...body,
            statusDate: date,
            statusHistory,
          },
        };
      }
      return prev;
    });
    await addAttorneyClientStatus({
      body: {
        ...selectStatus?._id,
        ...body,
        deletePrev,
      },
      setSelectStatus,
    });
  };

  const onSubmit = async (body) => {
    const tempSelectStatus = selectStatus;
    body.statusDate = body.statusDate
      ? moment(body.statusDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    if ('Interviewing' === selectStatus?.statusHistory?.[0]) {
      setSelectStatus(false);
      setRemoveStatus({
        body,
        selectStatus: {
          ...tempSelectStatus,
          status: body?.status,
          statusDate: body?.statusDate + 'T00:00:00.000Z',
        },
        setSelectStatus,
        handleStatusUpdate,
        attorneyName: `${matches?.matches?.[selectStatus?.index]?.firstName} ${
          matches?.matches?.[selectStatus?.index]?.lastName
        }`,
        deletedStatus: selectStatus?.statusHistory?.[0],
      });
    } else {
      await handleStatusUpdate({
        body,
        selectStatus,
        setSelectStatus,
      });
    }
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#673e9b');
  }, []);

  return (
    <div>
      <Modal
        open={selectStatus}
        handleClose={() => setSelectStatus(false)}
        className={style.mainDiv}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className={style.modalTitle}>Select Status</p>
          <div style={{ marginTop: '20px' }}>
            <SelectBox
              name={'status'}
              label={'Status'}
              control={control}
              placeholder={'Select'}
              options={ATTORNEY_CLIENT_STATUS}
              dynamicClass={style.zDynamicState}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <DatePicker
              id="1"
              isPurple
              name="statusDate"
              control={control}
              label="Status Date"
              placeholder="Date"
            />
          </div>
          <div className={style.mainBtnDiv}>
            <Button
              type="submit"
              text={'Confirm'}
              isLoading={isSubmitting}
              btnClass={style.btnClassModal}
              disabled={watch('status') && watch('statusDate') ? false : true}
            />
            <Button
              text="Cancel"
              btnClass={style.btnClassUncheckModal}
              handleClick={() => setSelectStatus(false)}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default SelectStatus;
