import React from 'react';

import { BAR_STATUS_COLORS } from 'constants';

import Button from 'components/button';
import Container from 'components/container';
import Tags from 'components/tags';
import EditAddTable from './edit-table';

import editIcon from 'assets/icons/blue-edit.svg';
import del from 'assets/icons/del-blue.svg';
import plus from 'assets/images/whiteAddIcon.svg';

import Warning from 'assets/icons/del-blue.svg';
import moment from 'moment';
import style from './bar-history.module.scss';
import useHelper from './helper';

import Loader from 'components/loader';
import Modal from 'components/modal';
import NoRecords from 'components/no-records';
import { createNotification } from 'components/react-notification';
import { A02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import { ROLES } from 'permissions/constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BarHistoryForm = () => {
  const navigate = useNavigate();
  const {
    edit,
    barHistory,
    handleDelete,
    onAdd,
    isLoading,
    onEdit,
    setEdit,
    setOpen,
    open,
    setToggle,
    toggle,
  } = useHelper();

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#304059');
  }, []);

  return (
    <div>
      <Container>
        <p className={style.p3}>
          Bar History<span style={{ color: 'red' }}>*</span>
        </p>
        <p className={style.p4}>
          {`Let us know what bar exams you're currently studying for and if you’ve already taken any.`}
        </p>
        {isLoading ? (
          <div className={style.loading}>
            <Loader />
          </div>
        ) : (
          <>
            {barHistory?.length < 1 && toggle != true ? (
              <div className={style.noRecords}>
                <NoRecords
                  icon={true}
                  btnClass={style.noRecordsButton}
                  extraPermissions={[ROLES.ATTORNEY]}
                  btnText={'Add Bar Exam'}
                  onClick={() => setToggle(true)}
                />
              </div>
            ) : (
              <>
                <div className={style.maxWidthClass}>
                  <div className={style.mainTable}>
                    <div className={style.tableHeader}>
                      <p className={style.imgDiv}>Date</p>
                      <p className={style.pTag}>State</p>
                      <p className={style.pTag}>Status</p>
                      <p className={style.pTag}>ID</p>
                      <p className={style.emptyDiv}></p>
                    </div>
                    <div className={style.rowsBody}>
                      <div className={style.innerHeight}>
                        {barHistory?.map((item, index) => (
                          <React.Fragment key={index}>
                            {edit === index ? (
                              <EditAddTable
                                setEditPopUp={setEdit}
                                editPopUp={edit}
                                className={style.gridClass}
                                onFormSubmit={onAdd}
                                data={item}
                                alreadyUsedStatus={barHistory?.map(
                                  (x) => x?.barState
                                )}
                              />
                            ) : (
                              <div className={style.rows} key={index}>
                                <div className={style.imgDiv}>
                                  <p className={style.pTag}>
                                    {moment(item.barTestedDate).format(
                                      'MM/YYYY'
                                    )}
                                  </p>
                                </div>
                                <p className={style.pTag}>
                                  {item.barState || '-'}
                                </p>
                                <div className={style.pTag}>
                                  <Tags
                                    circular={true}
                                    color={BAR_STATUS_COLORS[item.barStatus]}
                                    text={item.barStatus}
                                  ></Tags>
                                </div>
                                <p className={style.pTag}> {item.barId}</p>

                                <div className={style.emptyDiv}>
                                  <img
                                    onClick={() => {
                                      setEdit(index);
                                      setToggle(false);
                                    }}
                                    src={editIcon}
                                    alt=""
                                  />
                                  <img
                                    onClick={() => setOpen(item._id)}
                                    src={del}
                                    alt=""
                                  />
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {toggle === true && (
          <EditAddTable
            setEditPopUp={setToggle}
            editPopUp={toggle}
            className={style.gridClass}
            onFormSubmit={onEdit}
            alreadyUsedStatus={barHistory?.map((x) => x?.barState)}
          />
        )}
        {barHistory?.length > 0 && !toggle && (
          <Button
            text={'Add Bar Exam'}
            btnClass={style.noRecordsButton}
            iconStart={plus}
            handleClick={() => {
              setToggle(true);
              setEdit(null);
            }}
          />
        )}
        <div className={style.buttonDiv}>
          <Button
            text={'Back'}
            handleClick={() => {
              navigate('/');
            }}
            btnClass={style.btnClass2}
          />
          <Button
            text={'Next'}
            handleClick={() => {
              barHistory.length
                ? navigate('/trainingHistoryForm')
                : createNotification(
                    'error',
                    'Error',
                    'Please add at least one bar exam!'
                  );
            }}
            btnClass={style.btnClass}
          />
        </div>
      </Container>
      <Modal open={open} handleClose={() => setOpen(false)}>
        <div className={style.modalHeader}>
          <img src={Warning} height={48} width={48} />
          <h3
            style={{
              marginTop: '16px',
            }}
          >
            Delete Bar Exam
          </h3>
          <p className={style.warningText}>
            {A02_SCREEN_MESSAGES.popups.deleteConfirm.barExam.body}
          </p>
          <Button
            btnClass={style.buttonStyle}
            text={A02_SCREEN_MESSAGES.popups.deleteConfirm.barExam.yes}
            handleClick={handleDelete}
            width="100%"
          />
          <h3 className={style.cancelText} onClick={() => setOpen(false)}>
            {A02_SCREEN_MESSAGES.popups.deleteConfirm.barExam.no}
          </h3>
        </div>
      </Modal>
    </div>
  );
};
export default BarHistoryForm;
