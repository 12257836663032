import React, { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';

import Tags from 'components/tags';
import Button from 'components/button';
import EditBarHistory from './edit-bar-history';
import NoRecords from 'components/no-records';
import Modal from 'components/modal';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { Message } from 'languages';

import { BAR_STATUS_COLORS } from 'constants';
import { ROLES } from 'permissions/constants';
import useHelper from './helper';

import addIcon from 'assets/images/whiteAddIcon.svg';
import editIcon from 'assets/images/greenEditIcon.svg';
import deleteIcon from 'assets/images/GreenDelIcon.svg';
import Warning from 'assets/icons/del-green.svg';
import up from 'assets/images/upIcon.svg';
import down from 'assets/images/downIcon.svg';

import style from './bar-history.module.scss';
import { A02_SCREEN_MESSAGES } from 'data/userFacingMessages';
import ViewTable from './view-table';
import { getAllBarHistories } from 'services/attorney-profile';
import { useSelector } from 'react-redux';

const BarHistory = ({ setDisable }) => {
  const [columns, setColumns] = useState([]);
  const [editTable, setEditTable] = useState(false);

  const {
    id,
    setToggle,
    setEdit,
    barHistory,
    handleDelete,
    setOpen,
    toggle,
    isLoadingAll,
    all,
    setAll,
    open,
    isLoading,
    handleSingleDelete,
    innerColumns,
    setBarHistory,
    getAllHistories,
    edit,
    handleEdit,
    getBarsData,
    viewTable,
    setViewTable,
  } = useHelper({ setEditTable });

  useEffect(() => {
    setColumns([...innerColumns]);
  }, []);

  const barStates = useSelector((state) => state.systemValues.states);

  return (
    <div className={style.wraper}>
      {barHistory?.length < 1 ? (
        !toggle && (
          <NoRecords
            noRecord={
              <Message id="A02_SCREEN_MESSAGES.emptyPlaceholder.barHistory" />
            }
            extraPermissions={[ROLES.ATTORNEY]}
            icon={true}
            btnText={'Add Bar Exam'}
            onClick={() => {
              setToggle(true);
              setDisable(true);
            }}
          />
        )
      ) : (
        <>
          <div className={style.scrollDiv}>
            <div className={style.innerScrollDiv}>
              <div className={style.tblHeader}>
                <span
                  className={style.tblElements}
                  style={{ width: '318px', paddingLeft: '16px' }}
                >
                  Date
                </span>
                <span
                  className={style.tblElements}
                  style={{
                    width: '330px',
                    paddingLeft: '16px',
                  }}
                >
                  State
                </span>
                <span
                  className={style.tblElements}
                  style={{ width: '318px', paddingLeft: '16px' }}
                >
                  Status
                </span>
                <span
                  className={style.tblElements}
                  style={{ width: '318px', paddingLeft: '7px' }}
                >
                  ID
                </span>
                <span
                  className={style.tblElements}
                  style={{ width: '318px' }}
                ></span>
              </div>

              {barHistory?.map((item, index) => {
                return (
                  <Fragment key={item._id}>
                    {index === edit ? (
                      <EditBarHistory
                        alreadyUsedStatus={barHistory?.map((x) => x?.barState)}
                        data={item}
                        setToggle={setToggle}
                        setEdit={setEdit}
                        onSubmit={getBarsData}
                        setDisable={setDisable}
                        barHistory={barHistory}
                        setViewTable={setViewTable}
                      />
                    ) : (
                      <>
                        <div
                          className={style.tblBody}
                          style={{
                            borderRadius:
                              item?.histories?.length < 1
                                ? '5px 5px 5px 5px'
                                : '',
                          }}
                        >
                          <span
                            className={style.tblValues}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              width: '318px',
                            }}
                          >
                            {item?.histories?.length > 0 ? (
                              <div className={style.imgDiv}>
                                {index === viewTable ? (
                                  <img
                                    src={up}
                                    alt=""
                                    className={style.upIcon}
                                    onClick={() => {
                                      setViewTable(false);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  />
                                ) : (
                                  <img
                                    src={down}
                                    alt=""
                                    className={style.upIcon}
                                    onClick={() => {
                                      setViewTable(index);
                                      setEditTable(false);
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                  />
                                )}
                              </div>
                            ) : (
                              <div style={{ marginLeft: '22px' }}></div>
                            )}
                            {moment(
                              item?.barTestedDate?.replace('Z', '')
                            ).format('MM/YYYY')}
                          </span>
                          <div
                            className={`${style.tblValues} ${style.toolTool}`}
                            style={{
                              width: '318px',
                              display: 'flex',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            <p
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '210px',
                              }}
                            >
                              {item?.barState || '-'}
                            </p>
                            {item?.barState?.length > 20 && (
                              <span>{item?.barState}</span>
                            )}
                          </div>
                          <span
                            style={{
                              width: '318px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            className={style.tblValues}
                          >
                            <Tags
                              text={item?.barStatus}
                              color={BAR_STATUS_COLORS[item?.barStatus]}
                              circular={true}
                            />
                          </span>

                          <div
                            className={`${style.tblValues} ${style.toolTool}`}
                            style={{
                              width: '618px',
                              display: 'flex',
                              alignItems: 'center',
                              position: 'relative',
                            }}
                          >
                            <p
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '610px',
                              }}
                            >
                              {item?.barId || '-'}
                            </p>
                            {item?.barId?.length > 20 && (
                              <span>{item?.barId}</span>
                            )}
                          </div>
                          <div
                            className={style.rowIcons}
                            style={{
                              width: '118px',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <PermissionRestrict
                              roles={[
                                ROLES.ADMIN,
                                ROLES.EDITOR,
                                ROLES.ATTORNEY,
                              ]}
                            >
                              <img
                                onClick={() => {
                                  setEdit(index);
                                  setToggle(false);
                                  setDisable(true);
                                }}
                                src={editIcon}
                                width={20}
                                style={{ marginRight: '10px' }}
                              />
                              <img
                                src={deleteIcon}
                                onClick={() => {
                                  setOpen({ id: item._id });
                                  setAll(
                                    item?.histories?.length > 0 ? true : false
                                  );
                                }}
                              />
                            </PermissionRestrict>
                          </div>
                        </div>
                      </>
                    )}
                    {index === viewTable && (
                      <ViewTable
                        attorneys={
                          item?.histories?.map((history) => ({
                            ...history,
                            barTestedDate: moment(
                              history?.barTestedDate?.split('T')?.[0]
                            ).format('L'),
                          })) || []
                        }
                        columns={columns}
                        singleEditMode={true}
                        editTable={editTable}
                        setEditTable={setEditTable}
                        onSaveSubmit={handleEdit}
                        handleSingleDelete={(row) => {
                          setOpen({ id: row?._id });
                        }}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </>
      )}
      {toggle && (
        <EditBarHistory
          setEdit={setEdit}
          setToggle={setToggle}
          onSubmit={getBarsData}
          barHistory={barHistory}
          setDisable={setDisable}
          setViewTable={setViewTable}
          alreadyUsedStatus={barHistory?.map((x) => x?.barState)}
        />
      )}
      {barHistory?.length > 0 && (
        <div style={{ paddingBottom: '20px' }}>
          {!toggle && (
            <PermissionRestrict
              roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.ATTORNEY]}
            >
              <Button
                text={'Add Bar Exam'}
                btnClass={style.btnWraper}
                iconStart={addIcon}
                handleClick={() => {
                  setToggle(true);
                  setDisable(true);
                  setEdit(null);
                }}
              />
            </PermissionRestrict>
          )}
        </div>
      )}
      <Modal
        open={open}
        handleClose={() => {
          setOpen(false);
          setAll(false);
        }}
      >
        <div className={style.modalHeader}>
          <img src={Warning} height={48} width={48} />
          <h3 className={style.deleteText}>Delete Bar Exam</h3>
          {all ? (
            <p>
              Do you want to delete the entire bar history or just the current
              status?
            </p>
          ) : (
            <p>Are you sure you want to delete this record?</p>
          )}
          {all ? (
            <>
              <Button
                btnClass={style.buttonStyle}
                text={'Current Status'}
                handleClick={() => {
                  handleDelete();
                }}
                isLoading={isLoading}
                width="100%"
              />
              <Button
                text={' Entire History'}
                handleClick={() => {
                  handleDelete('all');
                }}
                isLoading={isLoadingAll}
                width="100%"
              />
            </>
          ) : (
            <Button
              btnClass={style.buttonStyle}
              text={A02_SCREEN_MESSAGES.popups.deleteConfirm.barExam.yes}
              handleClick={() => {
                handleDelete();
              }}
              isLoading={isLoading}
              width="100%"
            />
          )}
          <h3
            className={style.cancelText}
            onClick={() => {
              setOpen(false);
              setAll(false);
            }}
          >
            {A02_SCREEN_MESSAGES.popups.deleteConfirm.barExam.no}
          </h3>
        </div>
      </Modal>
    </div>
  );
};

export default BarHistory;
