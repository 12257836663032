import { createNotification } from 'components/react-notification';
import { useClientProfile } from 'contexts/client-profile';
import { useState } from 'react';
import { deleteContact } from 'services/client-contact';

const useHelper = () => {
  const { getContactData, contact } = useClientProfile();
  const [isLoading, setIsLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(false);

  //delete
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteContact(open);
      getContactData();
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
    setOpen(false);
  };

  return {
    isLoading,
    getContactData,
    edit,
    handleDelete,
    open,
    setOpen,
    setEdit,
    contact,
    add,
    setAdd,
  };
};

export default useHelper;
