import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAttoreyProfile } from 'contexts/attorney-profile';
import { getAttorneyComments, deleteComment } from 'services/attorney-profile';
import { createNotification } from 'components/react-notification';

const useHelper = () => {
  const { id } = useParams();
  const { getData, add, active } = useAttoreyProfile();

  const [toggle, setToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    getAttorneyCommentData();
  }, []);

  useEffect(() => {
    setToggle(add);
  }, [add]);

  useEffect(() => {
    if (active === 3) {
      if (!add) {
        setToggle(false);
        setEdit(false);
      }
    }
  }, [active]);

  const getAttorneyCommentData = async () => {
    setIsLoading(true);
    try {
      const res = await getAttorneyComments(id);
      setComments([...res.data.comments]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  //delete
  const handleDelete = () => {
    setIsLoading(true);
    try {
      deleteComment(open);
      setComments([...comments.filter((ele) => ele._id !== open)]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
    getData();
    setOpen(false);
  };

  const onAdd = (value) => {
    let _comments = [...comments];
    _comments.unshift(value);

    setComments(_comments);
  };

  const onEdit = (value) => {
    setComments((prev) => {
      const newArr = [...prev];
      const indexFound = newArr.findIndex((e) => e._id === value._id);
      newArr[indexFound] = value;

      return newArr;
    });
  };

  return {
    toggle,
    handleDelete,
    isLoading,
    comments,
    setToggle,
    open,
    setOpen,
    edit,
    setEdit,
    onAdd,
    onEdit,
  };
};

export default useHelper;
