import arrow from 'assets/black-circle.png';
import leftIcon from 'assets/images/stats-right-lg-icon.png';
import arrowWhite from 'assets/white-circle.png';

import style from './sidebar.module.scss';

const Sidebar = ({
  setOpenSidebar,
  openSidebar,
  textNumber,
  leftIconArrow,
  activeClass,
  inactiveClass,
  sidebarLinks,
  active,
  setActive,
  setOpenArrow,
  openArrow,
}) => {
  const handleActive = (value) => {
    setActive(value);
  };

  return (
    <div className={style.main}>
      <img
        src={leftIconArrow ? leftIconArrow : leftIcon}
        alt=""
        onClick={() => setOpenSidebar((prev) => !prev)}
        className={`${style.showSmall} ${
          openSidebar ? style.leftIcon : style.leftIcon2
        }`}
      />
      <img
        src={leftIconArrow ? leftIconArrow : leftIcon}
        alt=""
        onClick={() => setOpenSidebar((prev) => !prev)}
        className={`${style.hiddenSmall} ${
          openSidebar ? style.leftIcon : style.leftIcon2
        }`}
      />
      {openSidebar ? (
        <div className={style.mainSidebar}>
          <div className={style.head}>
            <p className={style.p}>Stats</p>
          </div>
          <div className={style.innerFirstDiv}>
            {sidebarLinks?.map((ele, index) => (
              <div key={index}>
                <div
                  key={index}
                  className={`${style.inner1} ${ele.class} ${
                    active === ele?.name
                      ? `${style.active} ${activeClass}`
                      : `${style.inactive} ${inactiveClass}`
                  }`}
                  onClick={() => {
                    handleActive(ele.name);
                    setOpenArrow(index);
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {ele.icon && (
                      <img
                        src={
                          (ele.array.includes(active) ||
                            active === ele?.name) &&
                          openArrow === index
                            ? arrowWhite
                            : arrow
                        }
                        alt=""
                        style={{
                          marginRight: '10px',
                          transform:
                            (ele.array.includes(active) ||
                              active === ele?.name) &&
                            openArrow === index
                              ? 'rotate(90deg)'
                              : '',
                          height: '4px',
                          width: '4px',
                        }}
                      />
                    )}
                    <p
                      className={style.p}
                      style={{ color: !active ? '#696F7A' : '' }}
                    >
                      {ele.name}
                    </p>
                  </div>
                  <p
                    className={`${style.textColor} ${textNumber}`}
                    style={{ color: !active ? '#696F7A' : '' }}
                  >
                    {ele.number}
                  </p>
                </div>
                <>
                  {ele.array?.map((arr, ind) => (
                    <div
                      key={ind}
                      className={`${style.inner2}  ${ele.class1} 
                      ${
                        arr.name === active
                          ? `${style.active} ${activeClass}`
                          : `${style.inactive} ${inactiveClass}`
                      }`}
                      onClick={() => {
                        handleActive(arr.name);
                        setOpenArrow(index);
                      }}
                      style={{
                        paddingLeft: arr.showDotIcon ? '34px' : '50px',
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {arr.showDotIcon ? (
                          <img
                            src={arr.name === active ? arrowWhite : arrow}
                            alt=""
                            style={{
                              marginRight: '10px',
                              transform: 'rotate(90deg)',

                              height: '4px',
                              width: '4px',
                            }}
                          />
                        ) : (
                          <span
                            style={{
                              marginRight: '2px',
                              color: arr.name === active ? 'white' : '',
                            }}
                          >
                            -
                          </span>
                        )}
                        <p
                          className={style.p}
                          style={{ color: !active ? '#696F7A' : '' }}
                        >
                          {arr.name}
                        </p>
                      </div>
                      <p
                        className={`${style.textColor} ${textNumber}`}
                        style={{ color: !active ? '#696F7A' : '' }}
                      >
                        {arr.number}
                      </p>
                    </div>
                  ))}
                  {ele.array1?.map((ar1, inde) => (
                    <div
                      key={inde}
                      className={`${style.inner3} ${ele.class1} ${ar1.class}
                      ${
                        ar1.name === active
                          ? `${style.active} ${activeClass}`
                          : `${style.inactive} ${inactiveClass}`
                      }
                      `}
                      onClick={() => {
                        handleActive(ar1.name);
                        setOpenArrow(index);
                      }}
                    >
                      <div className={ar1.labelContainerClass}>
                        {ar1.showDash && (
                          <span
                            style={{
                              marginRight: '9px',
                              color: ar1.name === active ? 'white' : '',
                            }}
                          >
                            -
                          </span>
                        )}
                        <p
                          className={style.p}
                          style={{ color: !active ? '#696F7A' : '' }}
                        >
                          {ar1.name}
                        </p>
                      </div>
                      <p
                        className={`${style.textColor} ${textNumber}`}
                        style={{ color: !active ? '#696F7A' : '' }}
                      >
                        {ar1.number}
                      </p>
                    </div>
                  ))}
                </>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Sidebar;
