import English from 'languages/english.json';
import React from 'react';

export const getMessage = (id = '', language = 'en') => {
  const messages = id
    .split('.')
    .reduce((acc, key) => acc[key], language == 'en' ? English : {});
  return messages[Math.floor(Math.random() * messages.length)];
};

export const Message = React.memo(function Message({ id }) {
  return <>{getMessage(id, 'en')}</>;
});
