import _ from 'lodash';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';

import Button from 'components/button';
import ButtonDropdown from 'components/button-dropdown';
import CustomDatePicker from 'components/custom-date-picker';
import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextField from 'components/textfield';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { CLIENT_STATUS_OPTIONS } from 'constants';
import { ROLES } from 'permissions/constants';
import {
  setCheckedRowsData,
  setPositionTableFilter,
} from 'redux/filters/filters-actions';
import { moveSelctedDropdownStatusToTop } from '../helper';

import arrowDown from 'assets/icons/purple-down.svg';
import searchIcon from 'assets/icons/search-blue.svg';
import cross from 'assets/images/greenCrossIcon.svg';
import plusIcon from 'assets/images/whiteAddIcon.svg';

import moment from 'moment';
import style from './header.module.scss';

const PositionHeader = (
  {
    search,
    filters,
    dateRange,
    unCheckAll,
    checkRowIds,
    setPageSize,
    setDirectoryFilters,
    getDataFromPositionAfterApplyingFilter,
  },
  ref
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { open } = useSelector((state) => state?.statsTable);
  const { practiceGroups } = useSelector((state) => state?.systemValues);
  const { positionTableFilter } = useSelector((state) => state?.pageFilters);
  const { locations } = useSelector((state) => state?.systemValues);
  const currentValue = positionTableFilter?.currentValue || 'Now';
  const { control, reset, watch, handleSubmit } = useForm({
    defaultValues: positionTableFilter,
  });

  const [clientStatusOptions, setCLientStatusOptions] = useState(
    CLIENT_STATUS_OPTIONS?.map((x) => {
      return { ...x, checkbox: true };
    })
  );

  const [practiceGroupsOptions, setPracticeGroupsOptions] = useState(
    practiceGroups?.map((x) => {
      return {
        value: x?.practiceGroup,
        label: x?.practiceGroup,
        checkbox: true,
      };
    })
  );

  const [locationOptions, setLocationOptions] = useState(
    locations &&
      locations?.map((e) => ({
        label: e.location,
        value: e._id,
        checkbox: true,
      }))
  );

  const debouncedSearch = React.useRef(
    _.debounce(async ({ currentValue, newCalculation, filters }) => {
      await getDataFromPositionAfterApplyingFilter({
        filterData: filters,
        currentValue,
        newCalculation,
      });
    }, 300)
  ).current;

  const clickOnCrossIcon = async () => {
    setPageSize(1);
    await getDataFromPositionAfterApplyingFilter({
      filterData: {
        ...filters,
        clientName: '',
      },
      currentValue,
      newCalculation: positionTableFilter?.calculationSelected,
    });
  };

  const onSubmit = async (data) => {
    await getDataFromPositionAfterApplyingFilter({
      filterData: { ...filters, ...data },
      currentValue,
      newCalculation:
        positionTableFilter?.calculationSelected === 'Client Requests'
          ? 'Client Requests'
          : '',
    });
  };

  const clearAllFilter = async (newCurrentValue = '') => {
    unCheckAll();
    dispatch(setCheckedRowsData([], 'POSITION'));
    await getDataFromPositionAfterApplyingFilter({
      filterData: {},
      currentValue: newCurrentValue || currentValue,
      newCalculation: 'Client Requests',
    });
    resetAllValues();
    setPageSize(1);
  };

  useImperativeHandle(ref, () => ({
    async clearAllFiltersWhenNoRowIsMatched(currentValue) {
      await clearAllFilter(currentValue);
    },
  }));

  const handleComparison = () => {
    navigate({
      pathname: '/positions/comparison',
      search: createSearchParams({
        positions: checkRowIds.join(','),
        statuses: 'Placed,Interviewing',
        minDate: watch('sowStartDate') ? watch('sowStartDate') : '',
        maxDate: watch('sowEndDate') ? watch('sowEndDate') : '',
      }).toString(),
    });
  };

  const resetAllValues = () => {
    reset({
      clientName: null,
      sowStartDate: null,
      sowEndDate: null,
      practiceGroup: null,
      clientStatus: null,
    });
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#673e9b');
    document.documentElement.style.setProperty(
      '--primary-color-of-column-change',
      '#673e9b'
    );
    document.documentElement.style.setProperty(
      '--primary-color-of-stats',
      '#673e9b'
    );
  }, []);

  useEffect(() => {
    _.isEmpty(filters) ? resetAllValues() : reset(filters);
  }, [filters]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    setCLientStatusOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('clientStatus') ?? [],
        CLIENT_STATUS_OPTIONS?.map((x) => {
          return { ...x, checkbox: true };
        })
      ),
    ]);
  }, [watch('clientStatus')]);

  useEffect(() => {
    setPracticeGroupsOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('practiceGroup') ?? [],
        practiceGroups?.map((x) => {
          return {
            value: x?.practiceGroup,
            label: x?.practiceGroup,
            checkbox: true,
          };
        })
      ),
    ]);
    setLocationOptions([
      ...moveSelctedDropdownStatusToTop(
        watch('locations') ?? [],
        locations &&
          locations?.map((e) => ({
            label: e.location,
            value: e._id,
            checkbox: true,
          }))
      ),
    ]);
  }, [watch('practiceGroup'), watch('locations')]);

  return (
    <div>
      <div className={style.mainHeader}>
        <div className={style.innerFlex}>
          <p className={style.attorney}>Positions</p>
          <div className={style.btns}>
            <ButtonDropdown
              btnBorder={style.btnDropdown}
              currentValue={currentValue}
              onChange={(value) => {
                setPageSize(1);
                setDirectoryFilters((prev) => ({
                  ...prev,
                  page: 1,
                  currentValue: value,
                  extraParams: {
                    showLoaderOnFullScreen: false,
                  },
                }));
                value !== 'Custom' &&
                  dispatch(
                    setPositionTableFilter({
                      ...positionTableFilter,
                      currentValue: value,
                      dateRange: [null, null],
                    })
                  );
              }}
              options={[
                'Now',
                'Cumulative',
                {
                  Component: CustomDatePicker,
                  props: {
                    key: 'p-custom',
                    isPurple: true,
                    value: dateRange,
                    onChange: (newDateRange) => {
                      // this was causing the date range one day before selection issue can be removed if everything is working fine

                      //   newDateRange = [
                      //     newDateRange?.[0]
                      //       ? moment(newDateRange?.[0])?.format('YYYY-MM-DD')
                      //       : null,
                      //     newDateRange?.[1]
                      //       ? moment(newDateRange[1])?.format('YYYY-MM-DD')
                      //       : null,
                      //   ];

                      dispatch(
                        setPositionTableFilter({
                          ...positionTableFilter,
                          currentValue: 'Custom',
                          dateRange: newDateRange,
                        })
                      );
                      if (newDateRange[1]) {
                        setPageSize(1);
                        setDirectoryFilters((prev) => ({
                          ...prev,
                          page: 1,
                          currentValue: 'Custom',
                          dateRange: newDateRange,
                          extraParams: {
                            showLoaderOnFullScreen: false,
                          },
                        }));
                      }
                    },
                  },
                },
              ]}
              dateRange={dateRange}
              arrowDown={arrowDown}
            />
            <Button
              text={'Comparison Reports'}
              type="button"
              handleClick={handleComparison}
              btnClass={style.btnClassComparison}
              disabled={checkRowIds?.length == 0 ? true : false}
            />
            <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
              <Button
                text={'Add Placement'}
                iconStart={plusIcon}
                btnClass={style.btnClass}
                type="button"
                handleClick={() => {
                  navigate('/positions/add');
                }}
              />
            </PermissionRestrict>
          </div>
        </div>

        <div className={style.grid}>
          <div className={style.textField}>
            <TextField
              value={search}
              onChange={(e) => {
                const { value: clientName } = e.target;
                dispatch(
                  setPositionTableFilter({
                    ...positionTableFilter,
                    filters: { ...filters, clientName },
                  })
                );
                debouncedSearch({
                  currentValue,
                  filters: { ...filters, clientName },
                  newCalculation: positionTableFilter?.calculationSelected,
                });
              }}
              icon={!_.isEmpty(watch('clientName')) ? cross : searchIcon}
              placeholder="Search"
              className={style.field}
              label="Search"
              onClick={clickOnCrossIcon}
            />
          </div>
          <div className={style.datePicker}>
            <DatePicker
              label="Start Date"
              name="sowStartDate"
              id="1"
              placeholder="Now"
              control={control}
              inputClass={style.date}
              maxDate={watch('sowEndDate')}
              isPurple
            />
          </div>
          <div className={style.datePicker}>
            <DatePicker
              label="End Date"
              name="sowEndDate"
              id="2"
              placeholder="Now"
              minDate={watch('sowStartDate')}
              control={control}
              inputClass={style.date}
              isPurple
              {...(open && { popparBack: style.endDatePopper })}
            />
          </div>
          <div className={style.statusBar}>
            <SelectBox
              name="practiceGroup"
              control={control}
              options={practiceGroupsOptions}
              label={'Practice Group'}
              placeholder={'Select'}
              showSelected
              isMulti
              showNumber
              numberBadgeColor={'#673E9B'}
            />
          </div>
          <div className={style.statusBar}>
            <SelectBox
              options={locationOptions}
              label={'City'}
              name={'locations'}
              control={control}
              numberBadgeColor={'#673E9B'}
              showNumber
              showSelected
              isMulti
            />
          </div>
          <div className={style.statusBar}>
            <SelectBox
              name="clientStatus"
              numberBadgeColor={'#673E9B'}
              control={control}
              options={clientStatusOptions}
              label={'Status'}
              isMulti
              showNumber
              currentValue={watch('clientStatus') || []}
              placeholder={'Select'}
              showSelected
            />
          </div>

          <div className={style.resetDiv}>
            <Button
              text={'Reset'}
              type="button"
              handleClick={() => {
                clearAllFilter();
              }}
              btnClass={style.check}
              style={{ marginRight: '10px', marginLeft: '10px' }}
            />
            <Button
              text={'Apply'}
              type="button"
              btnClass={style.apply}
              handleClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(React.forwardRef(PositionHeader));

const practiceGroup = [
  {
    value: 'Filled',
    label: 'Filled',
  },

  {
    value: 'UnFilled',
    label: 'UnFilled',
  },
];
