import React, { useEffect } from 'react';

import Button from 'components/button';
import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import Tags from 'components/tags';
import TextField from 'components/textfield/index';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { ROLES } from 'permissions/constants';

import crossIcon from 'assets/icons/cross-pink.svg';
import backIcon from 'assets/icons/pink-arrow-left.svg';
import editIcon from 'assets/images/Edit.svg';
import backIconGrey from 'assets/images/backIconGrey.svg';
import dateIcon from 'assets/images/blueDateIcon.svg';
import saveIcon from 'assets/images/save.svg';

import {
  CLIENT_STATUS_COLORS,
  CLIENT_STATUS_OPTIONS,
  CLIENT_TYPE_OPTIONS,
} from 'constants';
import { useClientProfile } from 'contexts/client-profile';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import style from './header.module.scss';

const HeaderSection = ({ clientHistory, setDisable, disable }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const {
    edit,
    watch,
    reset,
    active,
    errors,
    control,
    setEdit,
    register,
    isSubmitting,
  } = useClientProfile();
  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#a93e71');
  }, []);

  return (
    <div className={style.mainDiv}>
      <div className={style.wraper}>
        {!edit && (
          <img
            className={style.imgLogoBack}
            src={backIcon}
            alt={'back icon'}
            onClick={() => {
              if (!disable) {
                if (location.state?.from === 'new') navigate(-2);
                else navigate(-1);
              }
            }}
          />
        )}
        <div className={style.container}>
          <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <div className={style.profileImgContainer}>
              {!edit && (
                <img
                  className={style.imgLogoBack1}
                  src={!disable ? backIcon : backIconGrey}
                  alt={'back icon'}
                  onClick={() => {
                    if (!disable) {
                      if (location.state?.from === 'new') navigate(-2);
                      else navigate(-1);
                    }
                  }}
                />
              )}
            </div>
            {edit && (
              <form className={style.userInfoWraper}>
                <div className={style.editTextSec}>
                  <TextField
                    label={'Client Name'}
                    name="clientName"
                    register={register}
                    errorMessage={errors?.clientName?.message}
                  />
                </div>
                <div className={style.editTextSec}>
                  <SelectBox
                    label={'Client Status'}
                    placeholder={'Select'}
                    options={CLIENT_STATUS_OPTIONS}
                    control={control}
                    name="clientStatus"
                    errorMessage={errors?.clientStatus?.message}
                  />
                </div>
                <div className={style.editTextSec}>
                  <SelectBox
                    name="clientType"
                    control={control}
                    register={register}
                    options={CLIENT_TYPE_OPTIONS}
                    label={'Client Type'}
                  />
                </div>
                <div className={style.editTextSec}>
                  <SelectBox
                    label={'AmLaw50'}
                    name="clientAmLaw50"
                    placeholder="Select One"
                    control={control}
                    options={amLawOptions}
                    hideIcon={true}
                    errorMessage={errors?.clientAmLaw50?.message}
                  />
                </div>

                <div className={style.datePickerDiv}>
                  <div className={style.editTextSec}>
                    <DatePicker
                      label="Contract Start Date"
                      name={'contractDateStart'}
                      id="1"
                      placeholder="mm/dd/yyyy"
                      dateIcon={dateIcon}
                      control={control}
                      errorMessage={errors?.contractDateStart?.message}
                      isPink
                    />
                  </div>
                  <div className={style.editTextSec}>
                    <DatePicker
                      id="2"
                      name={'contractDateEnd'}
                      label="Contract End Date"
                      placeholder="mm/dd/yyyy"
                      dateIcon={dateIcon}
                      control={control}
                      ReactDatePicker={style.pinkTheme}
                      errorMessage={errors?.contractDateEnd?.message}
                      isPink
                    />
                  </div>
                </div>
              </form>
            )}
            {!edit && (
              <div className={style.userInfoWraper1}>
                <div className={style.editClassInfo}>
                  <span className={style.labelspan}>Client Name</span>
                  <span className={style.valueSpan}>
                    <span>{clientHistory?.clientName}</span>
                  </span>
                </div>
                <div className={style.editClassInfo}>
                  <span className={style.labelspan}>Client Status</span>
                  <Tags
                    text={
                      !clientHistory?.clientStatus
                        ? '-'
                        : clientHistory?.clientStatus
                            .split(' ')
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(' ')
                    }
                    borderColor={
                      CLIENT_STATUS_COLORS[clientHistory?.clientStatus]
                    }
                  />
                </div>
                <div className={style.editClassInfo}>
                  <span className={style.labelspan}>Client Type</span>
                  <span className={style.valueSpan}>
                    <span>
                      {!clientHistory?.clientType
                        ? '-'
                        : clientHistory?.clientType}
                    </span>
                  </span>
                </div>
                <div className={style.editClassInfo}>
                  <span className={style.labelspan}>AmLaw50</span>
                  <span className={style.valueSpan}>
                    <span>
                      {clientHistory?.clientAmLaw50 === null
                        ? '-'
                        : clientHistory?.clientAmLaw50
                        ? 'Yes'
                        : 'No'}
                    </span>
                  </span>
                </div>
                <div className={style.editClassInfo}>
                  <span className={style.labelspan}>Contract Start Date</span>
                  <span className={style.valueSpan}>
                    <span>
                      {!clientHistory?.contractDateStart
                        ? '-'
                        : moment(
                            clientHistory?.contractDateStart?.split('T')?.[0]
                          ).format('MM/DD/YYYY')}
                    </span>
                  </span>
                </div>{' '}
                <div className={style.editClassInfo}>
                  <span className={style.labelspan}>Contract End Date</span>
                  <span className={style.valueSpan}>
                    <span>
                      {!clientHistory?.contractDateEnd
                        ? '-'
                        : moment(
                            clientHistory?.contractDateEnd?.split('T')?.[0]
                          ).format('MM/DD/YYYY')}
                    </span>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={style.buttonsDiv}>
            {edit ? (
              <div className={style.buttonSection}>
                <Button
                  text={'Cancel'}
                  iconStart={crossIcon}
                  btnClass={style.cancelBtn}
                  className={style.btnText}
                  handleClick={() => {
                    setEdit(false);
                    setDisable(false);
                    id === 'add' && navigate(-1);
                  }}
                />
                <Button
                  text={'Save'}
                  iconStart={saveIcon}
                  type="submit"
                  btnClass={style.btnClass}
                  isLoading={isSubmitting}
                  width="100px"
                />
              </div>
            ) : (
              <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
                {active == 0 && (
                  <Button
                    text={'Edit'}
                    iconStart={editIcon}
                    btnClass={style.btnClass}
                    handleClick={() => {
                      reset();
                      setEdit(true);
                      setDisable(true);
                    }}
                  />
                )}
              </PermissionRestrict>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSection;
const amLawOptions = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];
