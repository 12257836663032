import React, { useEffect, useLayoutEffect, useState } from 'react';

import Button from 'components/button';
import Container from 'components/container';
import NoRecords from 'components/no-records';
import TextField from 'components/textfield';
import SettingsTable from './users-table';
import EditAddTable from './users-table/edit-add';

import searchIcon from 'assets/icons/search-blue.svg';
import edit from 'assets/images/Edit.svg';

import style from './setting.module.scss';

const Settings = () => {
  const [editPopUp, setEditPopUp] = useState(false);
  const [userData, setUserData] = useState([]);
  const [search, setSearch] = useState('');

  useLayoutEffect(() => {
    if (editPopUp) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [editPopUp]);

  const onFormSubmit = (user) => {
    setUserData((prev) => {
      const newArr = [...prev];
      const findIndex = newArr.findIndex((oldUser) => oldUser._id == user._id);
      if (findIndex >= 0) {
        newArr[findIndex] = user;
      } else {
        newArr.push(user);
      }
      return newArr;
    });
  };

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', '#304059');
  }, []);

  return (
    <div className={style.mainHeight}>
      <Container>
        <div style={{ position: 'relative' }}>
          <div className={style.main}>
            <div className={style.header}>
              <p>User Directory</p>
              <div className={style.btnDiv}>
                <Button
                  text="Add New User"
                  iconStart={edit}
                  handleClick={() => {
                    setEditPopUp(true);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={style.textField}>
            <TextField
              icon={searchIcon}
              placeholder="Search"
              className={style.field}
              value={search}
              id="field1"
              name="field1"
              type="text"
              autocomplete="new-password"
              onChange={(e) => setSearch(e.target.value)}
              onClear={() => setSearch('')}
              searchClearToggle
            />
          </div>

          {userData.length == 0 && (
            <NoRecords icon noRecord={'No Records'} hideButton />
          )}
          <div hidden={userData.length == 0}>
            <SettingsTable
              userData={userData}
              setUserData={setUserData}
              editPopUp={editPopUp}
              setEditPopUp={setEditPopUp}
              onFormSubmit={onFormSubmit}
              search={search}
            />

            {typeof editPopUp === 'boolean' && editPopUp && (
              <div className={style.addUserWrapper}>
                <EditAddTable
                  setEditPopUp={setEditPopUp}
                  editPopUp={editPopUp}
                  className={style.gridClass}
                  classNameMain={style.gridWrapperClass}
                  onFormSubmit={onFormSubmit}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Settings;
