/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { createNotification } from 'components/react-notification';
import { userService } from 'services/users';

import Button from 'components/button';
import TextField from 'components/textfield';

import eyeOpen from 'assets/icons/Hide.svg';
import mainLogo from 'assets/icons/Legal-logo.svg';
import eyeClose from 'assets/icons/Show.svg';
import loginLogo from 'assets/icons/auth.png';

import style from './resetPassword.module.scss';

function ResetPassword() {
  const [search] = useSearchParams();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const navigation = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { password } = data;
    try {
      const result = await userService.resetPassword({
        token: search.get('token'),
        password: password,
      });
      if (result?.status === 201) {
        createNotification('success', 'Success', result?.data?.message, 3500);
        navigation('/login');
      }
    } catch (err) {
      createNotification(
        'error',
        'Login Error',
        err?.response?.data?.msg ||
          err?.response?.data?.error ||
          err?.message ||
          'Server Error',
        3500
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={style.mainDiv}>
        <div className={style.leftInnerDiv}>
          <img src={mainLogo} alt="" className={style.logo} />
          <h2>Create a Password</h2>
          <TextField
            label="Password"
            className={style.field}
            name="password"
            placeholder="Enter Your Password"
            register={register}
            errorMessage={errors?.password?.message}
            wraperClass={style.textFieldWraper}
            onClick={() => setPasswordVisible(!passwordVisible)}
            icon={passwordVisible ? eyeClose : eyeOpen}
            type={passwordVisible ? 'text' : 'password'}
            iconClass={style.eyeIcon}
          />
          <TextField
            label="Confirm Password"
            className={style.field}
            name="confirmPassword"
            iconClass={style.eyeIcon}
            placeholder="Enter Your Confirming Password"
            register={register}
            errorMessage={errors?.confirmPassword?.message}
            wraperClass={style.textFieldWraper}
            onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
            icon={confirmPasswordVisible ? eyeClose : eyeOpen}
            type={confirmPasswordVisible ? 'text' : 'password'}
          />

          <div className={style.buttonDiv}>
            <Button
              type={'submit'}
              text="Confirm"
              btnClass={style.loginButton}
              isLoading={isSubmitting}
            />
          </div>
        </div>
        <div className={style.rightInnerDiv}>
          <img src={loginLogo} alt="" />
        </div>
      </div>
    </form>
  );
}

export default ResetPassword;

const schema = yup
  .object({
    password: yup
      .string()
      .required('Password is required ')
      .min(4, 'Must be at least 4 characters'),
    confirmPassword: yup
      .string()
      .required('Both passwords are required ')
      .min(4, 'Must be at least 4 characters')
      .oneOf([yup.ref('password')], 'Passwords should match'),
  })
  .required();
