import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/button';
import PermissionRestrict from 'hoc/PermissionRestrict';

import { ROLES } from 'permissions/constants';

import search from 'assets/icons/no-record.svg';
import plusIcon from 'assets/images/whiteAddIcon.svg';
import style from './add.module.scss';

const AddPosition = ({ modalHeight = '600px', showLabel = true }) => {
  return (
    <div className={style.mainAddAttorney}>
      {showLabel && <p className={style.attorney}>Positions</p>}

      <div className={style.innerAdd} style={{ height: modalHeight }}>
        <img src={search} alt="" className={style.img} />
        <p className={style.record}>No records</p>
        <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
          <Link to="/positions/add" style={{ textDecoration: 'none' }}>
            <Button
              text={'Add Placement'}
              iconStart={plusIcon}
              btnClass={style.btnClass}
            />
          </Link>
        </PermissionRestrict>
      </div>
    </div>
  );
};

export default AddPosition;
