import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import TextField from 'components/textfield';
import PermissionRestrict from 'hoc/PermissionRestrict';

import delIcon from 'assets/icons/del-purple.svg';
import Tags from 'components/tags';
import {
  CLIENT_STATUS_OPTIONS,
  CLIENT_TYPE_OPTIONS,
  clientTypeOptionsKeys,
} from 'constants';
import { ROLES } from 'permissions/constants';
import { exportPositionsCSV } from 'services/positions';
import style from './positions.module.scss';

export const getDefaultColumnOfPositionsTable = (
  setDelModal,
  setDeleteRowId,
  practiceGroups,
  locations
) => {
  const defaultColumns = [
    {
      name: 'Client Name',
      key: 'clientName',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '246px', height: '40px' },
      widthInEditMode: { width: '250px' },
      showPointer: true,
      displayIcon: true,
      sortKey: 'clientName',
      render: (
        { value, inputName, register, errorMessage, row },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode ? (
              <TextField
                name={inputName}
                register={register}
                errorMessage={errorMessage}
              />
            ) : (
              // <div className={style.imgDiv}>
              //   <p className={style.userName}>{value}</p>
              // </div>
              <div className={style.rounded}>
                <span className={style?.divElipsisName}>
                  <div style={{ minwidth: '300px ' }}>{value}</div>
                </span>
                {value && value.length > 10 && (
                  <div className={style.tooltip}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>{value}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },

    {
      name: 'Client Status',
      key: 'clientStatus',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '155px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      sortKey: 'clientStatus',
      render: ({ value }, column, isEditMode) => {
        return (
          <>
            {isEditMode ? (
              ''
            ) : (
              <Tags
                key="tags"
                text={value
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')}
                color={
                  CLIENT_STATUS_OPTIONS?.find(
                    (findColor) => findColor?.value === value
                  )?.color
                }
              />
            )}
          </>
        );
      },
    },
    {
      name: 'Practice Group',
      key: 'practiceGroup',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      sortKey: 'practiceGroup',

      render: (
        { value, inputName, control, errorMessage },
        column,
        isEditMode
      ) => (
        <>
          {isEditMode ? (
            <SelectBox
              errorMessage={errorMessage && 'Practice Group is required'}
              name={inputName}
              control={control}
              options={practiceGroups?.map((x) => {
                return {
                  value: x?.practiceGroup,
                  label: x?.practiceGroup,
                };
              })}
              isEditMode={isEditMode}
              placeholder={'Select'}
            />
          ) : (
            // <span>{value || '-'}</span>
            <div className={style.rounded}>
              <span className={style?.divElipsis}>
                <div>{value}</div>
              </span>
              {value && value.length > 10 && (
                <div className={style.tooltip}>
                  <div className={style.tooltipChild}>
                    <p className={style.tooltipText}>{value}</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      name: 'Start Date',
      key: 'sowStartDate',
      type: 'date',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      displayIcon: true,
      showPointer: true,
      sortKey: 'sowStartDate',

      render: (
        { value, errorMessage, control, row, inputName },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode ? (
              <DatePicker
                id={row?._id}
                control={control}
                name={inputName}
                errorMessage={errorMessage}
                isPurple
              />
            ) : (
              <span>{!value ? '-' : moment(value).format('MM/DD/YYYY')}</span>
            )}
          </>
        );
      },
    },
    {
      name: 'End Date',
      key: 'sowEndDate',
      type: 'date',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      sortKey: 'sowEndDate',

      render: (
        { value, errorMessage, control, row, inputName },
        column,
        isEditMode
      ) => (
        <>
          {isEditMode ? (
            <DatePicker
              id={row?._id}
              control={control}
              name={inputName}
              errorMessage={errorMessage}
              isPurple
            />
          ) : (
            <span>
              <span>{!value ? '-' : moment(value).format('MM/DD/YYYY')}</span>
            </span>
          )}
        </>
      ),
    },
    {
      name: 'Placements',
      key: 'placementQuantity',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      sortKey: 'totalPlacedAttorneys',
      render: (
        { value, inputName, control, register, errorMessage },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode ? (
              <TextField
                name={inputName}
                register={register}
                type="number"
                errorMessage={errorMessage}
                onKeyDown={(e) => {
                  if (e?.key === '-') return e.preventDefault();
                }}
              />
            ) : (
              <span>{value}</span>
            )}
          </>
        );
      },
    },
    {
      name: 'Open Positions',
      key: 'openPosition',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      sortKey: 'openPosition',

      render: ({ row, value, inputName, control }, column, isEditMode) => (
        <>
          {isEditMode ? (
            <SelectBox
              name={inputName}
              control={control}
              isEditMode={isEditMode}
            />
          ) : (
            <span>{parseInt(row?.openPositions || 0)}</span>
          )}
        </>
      ),
    },
    {
      name: 'Interviewing',
      key: 'totalInterViewing',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      sort: 'totalInterViewing',
      render: ({ row, value, inputName, control }, column, isEditMode) => (
        <>
          {isEditMode ? (
            <SelectBox
              name={inputName}
              control={control}
              isEditMode={isEditMode}
            />
          ) : (
            <span>{value}</span>
          )}
        </>
      ),
    },
    {
      name: 'City',
      key: 'locationName',
      type: 'text',
      editable: false,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      sort: 'locationName',
      render: ({ row, value, inputName, control }, column, isEditMode) => {
        const locationOptions =
          locations &&
          locations?.map((e) => ({
            label: e.location,
            value: e.location,
          }));
        return (
          <>
            {isEditMode ? (
              <SelectBox
                name={inputName}
                control={control}
                isEditMode={isEditMode}
                options={locationOptions}
              />
            ) : (
              // <div className={style.divElipsis}>
              //   <span>{value}</span>
              // </div>
              <div className={style.rounded}>
                <span className={style?.divElipsis}>
                  <div>{value}</div>
                </span>
                {value && value.length > 10 && (
                  <div className={style.tooltip}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>{value}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      name: 'Type',
      key: 'clientType',
      type: 'text',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      sortKey: 'clientType',
      render: (
        { value, inputName, control, watch, errorMessage },
        column,
        isEditMode
      ) => (
        <>
          {isEditMode ? (
            <SelectBox
              name={inputName}
              control={control}
              options={CLIENT_TYPE_OPTIONS}
              numberBadgeColor={'#A93E71'}
              placeholder={'Select'}
              isEditMode={isEditMode}
              errorMessage={errorMessage}
            />
          ) : (
            <span>
              {clientTypeOptionsKeys?.find((x) => x?.label === value)?.value}
            </span>
          )}
        </>
      ),
    },
    {
      name: '',
      key: 'actions',
      hidden: false,
      type: 'text',
      editable: false,
      widthAndHeight: { width: '56px', height: '58px' },
      widthInEditMode: { width: '56px' },
      render: ({ row, navigate }, column, isEditMode) => {
        return (
          <>
            <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
              <img
                src={delIcon}
                alt=""
                className={style.imgDel}
                onClick={() => {
                  setDeleteRowId(row?._id);
                  setDelModal(true);
                }}
              />
            </PermissionRestrict>
          </>
        );
      },
    },
  ];
  return defaultColumns;
};

export const getSideBarLinksWithCalculation = (calculations) => {
  return [
    {
      name: 'Client Requests',
      number: calculations?.allPositions,
      class: style.gap20,
    },
    {
      name: 'Total Positions',
      number: calculations?.positionsPlacements,
      class: style.mainElement,
      array: [
        {
          name: 'Placements',
          number: calculations?.totalPlacedAttorneys,
          showDotIcon: true,
          multiDots: true,
        },
        {
          name: 'Open Positions',
          number: calculations?.openPositions,
          showDotIcon: true,
          multiDots: true,
        },
      ],
      array1: [
        {
          name: 'Positions Interviewing',
          number: calculations?.positionsInterviewing,
          showDash: true,
          class: style.ar1,
          labelContainerClass: style.labelContainerClass,
        },
        {
          name: 'Positions Not Addressed',
          number:
            calculations?.openPositions - calculations?.positionsInterviewing <
            0
              ? 0
              : calculations?.openPositions -
                calculations?.positionsInterviewing,
          showDash: true,
          class: style.ar1,
          labelContainerClass: style.labelContainerClass,
        },
      ],
    },
  ];
};

export const checkAllTheValuesInFilterIsEmpty = (filter) => {
  if (
    _.size(_.flatten(Object.values(filter)?.filter((x) => x))?.filter((y) => y))
  )
    return false;
  else return true;
};

export const moveSelctedDropdownStatusToTop = (selectedValue, options) => {
  const deleteShowLineFromDefualtOptionsArray = options?.filter(
    (option) => option?.label !== 'showLine' && option?.value !== 'showLine'
  );
  const checkSelectedValue = _.isEmpty(selectedValue)
    ? []
    : !_.isArray(selectedValue)
    ? [selectedValue]
    : selectedValue;
  return !_.isEmpty(selectedValue)
    ? [
        ...deleteShowLineFromDefualtOptionsArray.filter((item) =>
          checkSelectedValue?.includes(item.value)
        ),
        { label: 'showLine', value: 'showLine', isdisabled: true },
        ...deleteShowLineFromDefualtOptionsArray.filter(
          (item) => !checkSelectedValue?.includes(item.value)
        ),
      ]
    : [
        ...deleteShowLineFromDefualtOptionsArray.filter((item) =>
          checkSelectedValue?.includes(item.value)
        ),
        ...deleteShowLineFromDefualtOptionsArray.filter(
          (item) => !checkSelectedValue?.includes(item.value)
        ),
      ];
};

export const downloadPositionsCSV = async (
  rows,
  sortBy,
  orderBy,
  filters,
  currentValue,
  dateRange,
  columns
) => {
  const arrToExport = [
    ...(rows?.filter((x) => x?.checked) ?? []),
    ...(rows?.filter((y) => !y?.checked) ?? []),
  ];
  const checkedRowIds =
    arrToExport?.filter((x) => x?.checked)?.map((y) => y?._id) ?? [];

  const response = await exportPositionsCSV(
    checkedRowIds,
    sortBy,
    orderBy,
    filters,
    currentValue,
    dateRange,
    columns.filter((x) => x?.hidden === false && x?.name).map((x) => x?.name)
  );

  const fileURL = window.URL.createObjectURL(new Blob([response?.data]));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  const currentDate = moment().format('MMM DD, YYYY HH:mm:ss a');
  fileLink.setAttribute('download', `Position Directory [${currentDate}].csv`);
  fileLink.setAttribute('target', '_blank');
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
};
