import { useDropzone } from 'react-dropzone';

import { formatBytes } from 'utils/helpers';

import fileIcon from 'assets/icons/green-file.svg';
import uploadFile from 'assets/icons/upload.svg';
import cross from 'assets/images/greenCrossIcon.svg';

import style from 'components/dropzone/dropzone.module.scss';
import { useController } from 'react-hook-form';

export default function Dropzone({
  control,
  name,
  error,
  showProgress,
  progress,
  uploadIcon = '',
  cFileIcon = '',
  cCrossIcon = '',
}) {
  const {
    field: { value, onChange },
  } = useController({
    control,
    name,
  });

  const onDrop = (acceptedFiles) => {
    onChange(acceptedFiles[0]);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <div {...getRootProps()}>
        <div className={style.uploadFileDiv}>
          <img src={uploadIcon || uploadFile} alt="" />
          <p className={style.green}>Drag and drop your file here or</p>
          <p className={style.blue}>click here to select from directory</p>
        </div>

        <input {...getInputProps()} />
      </div>
      {value && (
        <div
          style={{
            position: 'relative',
          }}
        >
          <div
            key={value.name}
            className={style.borderDiv}
            style={showProgress ? { paddingBottom: 25 } : {}}
          >
            <div className={style.flex}>
              <img src={cFileIcon || fileIcon} alt="" />
              <div>
                <p> {value.name}</p>
                <p>( {formatBytes(value.size, 2)})</p>
              </div>
            </div>
            <img src={cCrossIcon || cross} onClick={() => onChange(null)} />
          </div>
          {showProgress && (
            <div className={style.progressWrapper}>
              <div className={style.progressbar}>
                <div
                  style={{
                    width: `${progress}%`,
                  }}
                  className={style.progress}
                ></div>
              </div>
            </div>
          )}
        </div>
      )}
      {error && <span className={style.errorMessage}>{error}</span>}
    </>
  );
}
