import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './routes';

import getStore from 'redux/store';

import RegisterAppDispatch from 'components/registerAppDispatch';

const { persistor, store } = getStore;

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RegisterAppDispatch />
        <Router />
      </PersistGate>
    </Provider>
  );
}
export default App;
