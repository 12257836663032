import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Button from 'components/button';
import TextField from 'components/textfield';

import { addUserPositionWeights } from 'services/positions';
import { removeKeys } from 'utils/helpers';

import cross from 'assets/icons/close-purple.svg';
import crossGray from 'assets/icons/cross-gray.svg';
import edit from 'assets/icons/edit-purple.svg';

import style from './weight.module.scss';

const MyAlgoWeights = ({ userWeights, setAlgoWeight, handleResetGenerate }) => {
  const { id } = useParams();
  const { systemValues, user } = useSelector((state) => state);
  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const [editValue, setEditValue] = useState(false);
  const [editTable, setEditTable] = useState(false);

  const onSubmit = async (data) => {
    Object.keys(data).forEach((x) => {
      data[x] = data[x] || 0;
    });
    await addUserPositionWeights({
      data,
      setAlgoWeight,
      positionId: id,
      handleResetGenerate,
      userId: user?.userData?._id,
    });
  };

  const setWeightsToSystem = () => {
    const weights = { ...systemValues?.weights };
    removeKeys(weights, [
      'createdAt',
      'discarded',
      'isSystem',
      'positionId',
      'updatedAt',
      'userId',
      '__v',
      '_id',
    ]);
    reset(weights);
  };

  useEffect(() => {
    const data = { ...userWeights };
    if (Object.keys(data).length) {
      delete data?._id;
      reset(data);
    } else {
      setWeightsToSystem();
    }
  }, []);

  return (
    <div className={style.bgWeight}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={style.cross}>
          <img src={cross} alt="" onClick={() => setAlgoWeight(false)} />
        </div>
        <h1>My Algorithm Weights</h1>
        <div className={style.table}>
          <div className={style.innerTable}>
            <div className={style.header}>
              <p>Name</p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <p>System Weights</p>
                <p style={{ marginLeft: '20px' }}>My Client Weights</p>
              </div>
            </div>
            <div className={style.rows}>
              <div>
                {rows.map(({ name, eleName }, index) => (
                  <div className={style.tableBody} key={index}>
                    <p>{name}</p>
                    <div
                      style={{
                        width: '48%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                      }}
                    >
                      <p
                        style={{
                          marginLeft: editTable === index ? '18px' : '0px',
                        }}
                      >
                        {systemValues?.weights?.[eleName]}%
                      </p>
                      <p
                        style={{
                          marginLeft: editTable === index ? '78px' : '48px',
                          color:
                            watch(eleName) > systemValues?.weights?.[eleName]
                              ? '#30A25D'
                              : watch(eleName) <
                                systemValues?.weights?.[eleName]
                              ? '#CE4343'
                              : '#696F7A',
                        }}
                      >
                        {editTable === index ? (
                          <>
                            <TextField
                              type="number"
                              name={eleName}
                              value={
                                watch(eleName) && watch(eleName).length < 3
                                  ? watch(eleName)
                                  : ''
                              }
                              register={register}
                              wraperClass={style.field}
                              className={style.inputField}
                            />
                            <p style={{ margin: '3px 5px 0 1px' }}>%</p>
                          </>
                        ) : (
                          `${watch(eleName) > -1 ? watch(eleName) + '%' : '-'}`
                        )}
                        {editTable === index ? (
                          <>
                            <p
                              className={style.okBtn}
                              onClick={() => {
                                setEditTable(false);
                              }}
                            >
                              OK
                            </p>
                            <img
                              src={crossGray}
                              className={style.crossIcon}
                              alt=""
                              onClick={() => {
                                editValue && reset(editValue);
                                editValue && setEditValue(false);
                                setEditTable(false);
                              }}
                            />
                          </>
                        ) : (
                          <img
                            alt=""
                            src={edit}
                            className={style.img}
                            onClick={() => {
                              setEditValue({ ...watch() });
                              setEditTable(index);
                            }}
                          />
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className={style.btnDiv}>
          <Button
            handleClick={setWeightsToSystem}
            text="Reset to System Weights"
            type="button"
            btnClass={style.btnClassUncheckModal}
          />
          <Button
            type="submit"
            isLoading={isSubmitting}
            text={'Regenerate Matches'}
            btnClass={style.btnClassModal}
          />
        </div>
      </form>
    </div>
  );
};

export default MyAlgoWeights;

const rows = [
  {
    name: 'Attorney Location',
    eleName: 'location',
    weight: '25%',
    clientWeight: 25,
  },
  {
    name: 'Attorney Status',
    eleName: 'status',
    weight: '25%',
    clientWeight: 25,
  },
  {
    name: 'Attorney Bar',
    eleName: 'bar',
    weight: '25%',
    clientWeight: 25,
  },
  {
    name: 'Attorney Practice Group',
    eleName: 'practiceGroup',
    weight: '25%',
    clientWeight: 25,
  },
];
