/* eslint-disable no-unused-vars */
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import editStyle from '../main-page.module.scss';
import readStyle from '../main-page-read.module.scss';
import HistoryTab from 'pages/attorney-profile/history-section';
import EmptyHistory from 'pages/attorney-profile/empty-history';

import { ATTORNEY_CLIENT_STATUS_COLORS, BAR_STATUS_COLORS } from 'constants';

import moment from 'moment';
import OverViewEditMode from './overview-edit-mode';
import { useAttoreyProfile } from 'contexts/attorney-profile';
import CommentsWidget from './comments-widget';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { ROLES } from 'permissions/constants';
import ellipse from 'assets/icons/ellipse.svg';

const OverView = ({ id }) => {
  const [isHovering, setIsHovering] = useState(false);

  const { attorney, edit, setActive, setAdd } = useAttoreyProfile();

  const {
    attorneyStartDate,
    attorneyEndDate,
    attorneyBirthdate,
    attorneyLawSchool,
    attorneyGPA,
    attorneyAmLaw50,
    attorneyDemo,
    attorneyGender,
    attorneyParkerScore,
    attorneyWritingScore,
    attorneyClassRank,
    attorneyPracticeGroup,
    attorneyLocation,
    attorneyGradYear,
  } = attorney.attorney;

  const {
    attorneyBars,
    attorneyComments,
    attorneyClientsHistory,
    attorneyTrainingHistory,
  } = attorney;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  //Use different style for edit and read mode
  const style = useMemo(() => (edit ? editStyle : readStyle), [edit]);

  return edit ? (
    <OverViewEditMode id={id} />
  ) : (
    <div className={style.overViewWraper}>
      <div className={style.sectionOne}>
        <h3
          style={{
            color: '#39695B',
            margin: '0px',
            padding: '10px 0px',
          }}
        >
          {/* Attorney Profile */}
        </h3>
        <div className={style.profileSection}>
          <div className={style.profileSectionWraper}>
            <PermissionRestrict
              roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}
            >
              <div className={style.datesSection}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Start Date</span>
                  <span className={style.valueTag}>
                    {attorneyStartDate
                      ? moment(attorneyStartDate).format('MM/DD/YYYY')
                      : '-'}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>End Date</span>
                  <span className={style.valueTag}>
                    {attorneyEndDate
                      ? moment(attorneyEndDate).format('MM/DD/YYYY')
                      : '-'}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Birth Month/Day</span>
                  <span className={style.valueTag}>
                    {attorneyBirthdate
                      ? moment(attorneyBirthdate).format('MM/DD')
                      : '-'}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>AmLaw50</span>
                  <span className={style.valueTag}>
                    {attorneyAmLaw50 === null
                      ? '-'
                      : attorneyAmLaw50
                      ? 'Yes'
                      : 'No'}
                  </span>
                </div>
              </div>
              <div className={style.educationSection}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Law School</span>
                  <span
                    className={style.valueTag}
                    style={{ overflowWrap: 'anywhere' }}
                  >
                    {attorneyLawSchool ? attorneyLawSchool : '-'}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Grad Year</span>
                  <span className={style.valueTag}>
                    {attorneyGradYear ? attorneyGradYear : '-'}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>GPA</span>
                  <span className={style.valueTag}>
                    {attorneyGPA ? `${attorneyGPA}` : '-'}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Class Rank</span>
                  <span className={style.valueTag}>
                    {attorneyClassRank ? attorneyClassRank : '-'}
                  </span>
                </div>
              </div>
              <div className={style.educationSection}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Demo</span>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: ' ellipsis ',
                    }}
                    className={style.hoverDemoText}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    <b>
                      {attorneyDemo?.join(', ')
                        ? attorneyDemo?.join(', ')
                        : '-'}
                    </b>
                  </span>

                  {isHovering ? (
                    <span
                      className={style.hovered}
                      style={{
                        position: 'absolute',
                        padding: 5,
                        marginTop: 50,
                        backgroundColor: '#ffffff',
                        borderRadius: 8,
                        color: '#696F7A',
                        filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1))',
                      }}
                    >
                      {attorneyDemo.join(', ') ? attorneyDemo.join(', ') : '-'}
                    </span>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Gender</span>
                  <span className={style.valueTag}>
                    {attorneyGender ? attorneyGender : '-'}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Parker Score</span>
                  <span className={style.valueTag}>
                    {attorneyParkerScore ? attorneyParkerScore : '-'}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Writing Score</span>
                  <span className={style.valueTag}>
                    {attorneyWritingScore ? `${attorneyWritingScore}%` : '-'}
                  </span>
                </div>
              </div>
            </PermissionRestrict>
            <PermissionRestrict roles={[ROLES.ATTORNEY]}>
              <div className={style.datesSectionAttorney}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Demo</span>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: ' ellipsis ',
                    }}
                    className={style.hoverDemoAttorneyText}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    <b>
                      {attorneyDemo?.join(', ')
                        ? attorneyDemo?.join(', ')
                        : '-'}
                    </b>
                  </span>

                  {isHovering ? (
                    <text
                      style={{
                        position: 'absolute',
                        padding: 5,
                        marginTop: 50,
                        backgroundColor: '#ffffff',
                        borderRadius: 8,
                        color: '#696F7A',
                        filter: 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1))',
                      }}
                    >
                      {attorneyDemo.join(', ') ? attorneyDemo.join(', ') : '-'}
                    </text>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Gender</span>
                  <span className={style.valueTag}>
                    {attorneyGender ? attorneyGender : '-'}
                  </span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span className={style.labelTag}>Class Rank</span>
                  <span className={style.valueTag}>
                    {attorneyClassRank ? attorneyClassRank : '-'}
                  </span>
                </div>
              </div>
            </PermissionRestrict>
          </div>
        </div>

        <h3 style={{ color: '#39695B', margin: '20px 0px' }}>
          Placement Preferences
        </h3>
        <div className={style.profileSection}>
          <div className={style.profileSectionWraper}>
            <h4 style={{ color: '#39695B' }}>Practice Group</h4>
            <div
              className={style.datesSection}
              style={{ marginTop: '15px', marginLeft: '1px' }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={style.labelTag}>1st Choice</span>
                <span className={style.valueTag}>
                  {' '}
                  {attorneyPracticeGroup[0] ? attorneyPracticeGroup[0] : '-'}
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={style.labelTag}>2nd Choice</span>
                <span className={style.valueTag}>
                  {' '}
                  {attorneyPracticeGroup[1] ? attorneyPracticeGroup[1] : '-'}
                </span>
              </div>
            </div>

            <h4 style={{ color: '#39695B', marginTop: '30px' }}> Location</h4>
            <div className={style.educationSection}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '1px',
                }}
              >
                <span className={style.labelTag}>1st Choice (Current)</span>
                <span className={style.valueTag}>
                  {attorneyLocation[0] ? attorneyLocation[0] : '-'}
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={style.labelTag}>2nd Choice</span>
                <span className={style.valueTag}>
                  {attorneyLocation[1] ? attorneyLocation[1] : '-'}
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span className={style.labelTag}>3rd Choice</span>
                <span className={style.valueTag}>
                  {attorneyLocation[2] ? attorneyLocation[2] : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.sectiontwo}>
        <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}>
          <div className={style.barHistoryHeader}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span className={style.sideBarTag}>Bar History</span>
              <span className={style.count}>{attorney?.meta?.totalBars}</span>
            </div>
            {attorneyBars?.length > 0 && (
              <span
                className={style.seeAllSpan}
                style={{ color: '#39695B' }}
                onClick={() => setActive(1)}
              >
                See all
              </span>
            )}
          </div>
          {attorneyBars?.length < 2 ? (
            <>
              {attorneyBars?.map((ele, index) => (
                <HistoryTab
                  key={index}
                  title={ele.barState}
                  value={
                    <>
                      {moment(ele.barTestedDate).format('MM/YYYY')}{' '}
                      {ele.barId && (
                        <>
                          <img src={ellipse} className={readStyle.ellipse} />{' '}
                          ID: {ele.barId}
                        </>
                      )}
                    </>
                  }
                  tagColor={BAR_STATUS_COLORS[ele.barStatus]}
                  tagText={ele.barStatus}
                />
              ))}
              <EmptyHistory
                btnText={'Add Bar Exam'}
                isSmall={attorneyBars?.length}
                handleClick={() => {
                  setActive(1);
                  setAdd(true);
                }}
              />
            </>
          ) : (
            <>
              {attorneyBars?.map((ele, index) => (
                <HistoryTab
                  key={index}
                  title={ele.barState}
                  value={
                    <>
                      {moment(ele.barTestedDate).format('MM/YYYY')}{' '}
                      {ele.barId && (
                        <>
                          <img src={ellipse} className={readStyle.ellipse} />{' '}
                          ID: {ele.barId}
                        </>
                      )}
                    </>
                  }
                  tagColor={BAR_STATUS_COLORS[ele.barStatus]}
                  tagText={ele.barStatus}
                />
              ))}
            </>
          )}
        </PermissionRestrict>
        <PermissionRestrict roles={[ROLES.ATTORNEY]}>
          <div className={style.barHistoryHeader}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span className={style.sideBarTag}>Bar History</span>
              <span className={style.count}>{attorney?.meta?.totalBars}</span>
            </div>
            {attorneyBars?.length > 0 && (
              <span
                className={style.seeAllSpan}
                style={{ color: '#39695B' }}
                onClick={() => setActive(1)}
              >
                See all
              </span>
            )}
          </div>
          {attorneyBars?.length < 2 ? (
            <>
              {attorneyBars?.map((ele, index) => (
                <HistoryTab
                  key={index}
                  title={ele.barState}
                  value={
                    <>
                      {moment(ele.barTestedDate).format('MM/YYYY')}{' '}
                      {ele.barId && (
                        <>
                          <img src={ellipse} className={readStyle.ellipse} />{' '}
                          ID: {ele.barId}
                        </>
                      )}
                    </>
                  }
                  tagColor={BAR_STATUS_COLORS[ele.barStatus]}
                  tagText={ele.barStatus}
                />
              ))}
              <EmptyHistory
                btnText={'Add Bar Exam'}
                isSmall={attorneyBars?.length}
                handleClick={() => {
                  setActive(1);
                  setAdd(true);
                }}
              />
            </>
          ) : (
            <>
              {attorneyBars?.map((ele, index) => (
                <HistoryTab
                  key={index}
                  title={ele.barState}
                  value={
                    <>
                      {moment(ele.barTestedDate).format('MM/YYYY')}{' '}
                      {ele.barId && (
                        <>
                          <img src={ellipse} className={readStyle.ellipse} />{' '}
                          ID: {ele.barId}
                        </>
                      )}
                    </>
                  }
                  tagColor={BAR_STATUS_COLORS[ele.barStatus]}
                  tagText={ele.barStatus}
                />
              ))}
            </>
          )}
        </PermissionRestrict>

        <PermissionRestrict roles={[ROLES.ATTORNEY]}>
          <div className={style.barHistoryHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className={style.sideBarTag}>Training History</span>
              <span className={style.count}>
                {attorney?.meta?.totalTrainings}
              </span>
            </div>

            {attorneyTrainingHistory?.length > 0 && (
              <span
                className={style.seeAllSpan}
                style={{ color: '#39695B' }}
                onClick={() => setActive(2)}
              >
                See all
              </span>
            )}
          </div>
          {attorneyTrainingHistory?.length < 2 ? (
            <>
              {attorneyTrainingHistory?.map((ele, index) => (
                <HistoryTab
                  dateMode={true}
                  key={index}
                  value={ele.courseName}
                  tagColor={'transparent'}
                  trainingWidget={true}
                  tagText={
                    !ele.courseCompletedDate
                      ? '-'
                      : moment(ele.courseCompletedDate).format('MM/DD/YYYY')
                  }
                />
              ))}
              <EmptyHistory
                handleClick={() => {
                  setActive(2);
                  setAdd(true);
                }}
                btnText={'Add Training'}
                isSmall={attorneyTrainingHistory?.length}
              />
            </>
          ) : (
            <>
              {attorneyTrainingHistory?.map((ele, index) => (
                <HistoryTab
                  dateMode={true}
                  key={index}
                  value={ele.courseName}
                  trainingWidget={true}
                  tagColor={'transparent'}
                  tagText={
                    !ele.courseCompletedDate
                      ? '-'
                      : moment(ele.courseCompletedDate).format('MM/DD/YYYY')
                  }
                />
              ))}
            </>
          )}
        </PermissionRestrict>
        <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER]}>
          <div className={style.barHistoryHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className={style.sideBarTag}>Client History</span>
              <span className={style.count}>
                {attorney?.meta?.totalClientHistory}
              </span>
            </div>

            {attorneyClientsHistory?.length > 0 && (
              <span
                className={style.seeAllSpan}
                style={{ color: '#39695B' }}
                onClick={() => setActive(2)}
              >
                See all
              </span>
            )}
          </div>
          {attorneyClientsHistory?.length < 2 ? (
            <>
              {attorneyClientsHistory?.map((ele, index) => (
                <HistoryTab
                  key={index}
                  title={
                    <Link
                      style={{ color: '#39695b' }}
                      to={`/clients/${attorneyClientsHistory[0]?.clientId}`}
                    >
                      {attorneyClientsHistory[0]?.clientName}
                    </Link>
                  }
                  value={moment(attorneyClientsHistory[0]?.statusDate).format(
                    'MM/DD/YYYY'
                  )}
                  tagColor={
                    ATTORNEY_CLIENT_STATUS_COLORS[
                      attorneyClientsHistory[0]?.status
                    ]
                  }
                  tagText={attorneyClientsHistory[0]?.status}
                />
              ))}
              <EmptyHistory
                handleClick={() => {
                  setActive(2);
                  setAdd(true);
                }}
                btnText={'Add Client History'}
                isSmall={attorneyClientsHistory?.length}
              />
            </>
          ) : (
            <>
              <HistoryTab
                title={
                  <Link
                    style={{ color: '#39695b' }}
                    to={`/clients/${attorneyClientsHistory[0]?.clientId}`}
                  >
                    {attorneyClientsHistory[0]?.clientName}
                  </Link>
                }
                value={moment(attorneyClientsHistory[0]?.statusDate).format(
                  'MM/DD/YYYY'
                )}
                tagColor={
                  ATTORNEY_CLIENT_STATUS_COLORS[
                    attorneyClientsHistory[0]?.status
                  ]
                }
                tagText={attorneyClientsHistory[0]?.status}
              />
              <HistoryTab
                title={
                  <Link
                    style={{ color: '#39695b' }}
                    to={`/clients/${attorneyClientsHistory[1]?.clientId}`}
                  >
                    {attorneyClientsHistory[1]?.clientName}
                  </Link>
                }
                value={moment(attorneyClientsHistory[1]?.statusDate).format(
                  'MM/DD/YYYY'
                )}
                tagColor={
                  ATTORNEY_CLIENT_STATUS_COLORS[
                    attorneyClientsHistory[1]?.status
                  ]
                }
                tagText={attorneyClientsHistory[1]?.status}
              />
            </>
          )}
          <div className={style.barHistoryHeader}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className={style.sideBarTag}>Comments</span>
              <span className={style.count}>
                {attorney?.meta?.totalComments}
              </span>
            </div>
            {attorney?.meta?.totalComments > 0 && (
              <span
                className={style.seeAllSpan}
                style={{ color: '#39695B' }}
                onClick={() => setActive(3)}
              >
                See all
              </span>
            )}
          </div>
          <div style={{ paddingBottom: '20px' }}>
            {!attorneyComments?.length > 0 ? (
              <EmptyHistory
                handleClick={() => {
                  setActive(3);
                  setAdd(true);
                }}
                isSmall={attorneyComments?.length}
                btnText={'Add Comment'}
              />
            ) : (
              <>
                <CommentsWidget />
              </>
            )}
          </div>
        </PermissionRestrict>
      </div>
    </div>
  );
};

export default OverView;
