import Checkbox from 'components/checkbox';
import GenericTable from 'components/generic-table';
import _ from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import style from './directory-table.module.scss';

const AttorneyTable = (
  {
    page,
    rows,
    order,
    sortBy,
    filters,
    noMatch,
    loading,
    openFilter,
    columns,
    editTable,
    getNextData,
    updateAttorneyData,
    formId = 'hook-form',
    setCheckedRowData = () => {},
    getSelectedSorting = () => {},
  },
  ref
) => {
  const navigate = useNavigate();

  const [dataSource, setDataSource] = useState([...rows]);
  const [checkedArray, setCheckedArray] = useState([]);

  useImperativeHandle(ref, () => ({
    async clearAllCheckRows() {
      setCheckedArray([]);
    },
  }));

  const checkedArrayMap = checkedArray.reduce((acc, curr) => {
    acc[curr._id] = true;
    return acc;
  }, {});

  const arrToRender = React.useMemo(
    () =>
      dataSource?.filter((x) => x?.checked)?.length === rows.length
        ? [...dataSource]
        : page === 1 && noMatch
        ? dataSource?.map((x) => {
            if (checkedArrayMap[x?._id]) return { ...x, checked: true };
            else return x;
          })
        : !noMatch && _.size(dataSource?.filter((x) => x?.checked)) > 0
        ? [
            ...(dataSource?.filter((y) => y?.checked) ?? []),
            ...(dataSource?.filter((y) => !y?.checked) ?? []),
          ]
        : [...checkedArray, ...(dataSource?.filter((y) => !y?.checked) ?? [])],
    [dataSource, checkedArray]
  );
  useEffect(() => {
    setDataSource([...rows]);
  }, [JSON.stringify(rows)]);

  const redirectToAttorneyPage = ({ _id }) => {
    navigate('/attorneys/' + _id);
  };

  const clickOnTableRow = (checked, attorneyRowId) => {
    const selectedRowIndex = dataSource.findIndex(
      (selectedRow) => selectedRow._id === attorneyRowId
    );

    const copy = [...dataSource];
    copy[selectedRowIndex] = { ...copy[selectedRowIndex], checked };

    setDataSource([...copy]);

    let _checkedArray = [...checkedArray];
    if (checked) {
      _checkedArray = [..._checkedArray, copy[selectedRowIndex]];
      setCheckedArray(_checkedArray);
    } else {
      _checkedArray = [...checkedArray];
      _checkedArray = _checkedArray.filter((x) => x?._id !== attorneyRowId);
      setCheckedArray(_checkedArray);
    }
    setCheckedRowData(_checkedArray, copy);
  };

  const checkedAllRows = (checkAllRows) => {
    const dataSourceWithChecked = dataSource?.map((x) => {
      return { ...x, checked: checkAllRows };
    });
    setDataSource([...dataSourceWithChecked]);
    setCheckedRowData([...dataSourceWithChecked]);
    setCheckedArray([]);
  };

  return (
    <div>
      <div className={style.tableWidth}>
        <GenericTable
          ref={ref}
          columns={
            !editTable
              ? columns?.filter((x) => x?.key !== 'actions' && !x?.hidden)
              : columns
          }
          dataSource={arrToRender}
          isEditMode={editTable}
          onSaveSubmit={(updatedRows) => {
            updateAttorneyData(updatedRows);
            setDataSource([...updatedRows]);
          }}
          formId={formId}
          schema={schema}
          onSort={(sort) => getSelectedSorting(sort)}
          renderNoDataFound={() => (
            <tr>
              <td colSpan={columns.length}>No Data Found</td>
            </tr>
          )}
          onScrollBottom={async (showLoader) => {
            await getNextData(showLoader);
          }}
          onRowClick={redirectToAttorneyPage}
          selectable={true}
          renderSelector={(selectedRowId, selectedRowChecked) => (
            <>
              <Checkbox
                containerClass={style.checkboxContainer}
                handleChange={(e) => {
                  clickOnTableRow(e?.target?.checked, selectedRowId);
                }}
                checked={selectedRowChecked}
              />
            </>
          )}
          classes={{
            test: style.test,
            table: style.table,
            thead: style.thead,
            th: style.th,
            tableBody: style.tableRow,
            tableOnLoading: style.genericTableOnLoading,
            tableOffLoading: style.genericTableOffLoading,
          }}
          checkedAllRows={checkedAllRows}
          loading={loading}
          sortBy={sortBy}
          order={order}
        />
      </div>
    </div>
  );
};

export default React.memo(forwardRef(AttorneyTable));

const schema = Yup.object({
  data: Yup.array().of(Yup.object({})),
});
