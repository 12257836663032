import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import Avatar from 'components/avatar';
import DatePicker from 'components/date-picker';
import SelectBox from 'components/select-box';
import Tags from 'components/tags';
import TextField from 'components/textfield';
import PermissionRestrict from 'hoc/PermissionRestrict';
import OpenLiStatusModalOnEditMode from './OpenLiStatusModalOnEditMode';

import { ROLES } from 'permissions/constants';
import {
  exportAttorneysDirectoryToCSV,
  getAttorneysDirectory,
} from 'services/attorny-directory-table';

import delIcon from 'assets/icons/del-green.svg';
import editIcon from 'assets/images/greenEditIcon.svg';
import style from './directory.module.scss';

import {
  ATTRONEY_STATUS_OPTIONS,
  BAR_STATUS_OPTIONS,
  DEMO_OPTIONS,
  GENDER_OPTIONS,
} from '../../constants';

export const getDefaultColumnOfAttorneyTable = (
  allClientName,
  clientNameWithPositions,
  setDelModal,
  locations,
  lawSchools,
  setDeleteRowId
) => {
  const defaultColumns = [
    {
      name: 'First Name',
      key: 'firstName',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '170px', height: '58px' },
      widthInEditMode: { width: '250px' },
      displayIcon: true,
      showPointer: true,

      render: (
        { value, inputName, register, errorMessage, row },
        column,
        isEditMode
      ) => {
        const tooltipVisible = value?.length > 12;
        return (
          <>
            {isEditMode ? (
              <TextField
                name={inputName}
                register={register}
                errorMessage={errorMessage}
              />
            ) : (
              <div className={style.imgDiv}>
                <Avatar
                  circleSize={32}
                  className={style.avatar}
                  icon={row.profileImage}
                  initials={`${row?.firstName || ''} ${row.lastName || ''}`}
                />
                <div className={`${tooltipVisible ? style.rounded : ''}`}>
                  <p className={style.userName}>{value}</p>
                  <div className={style.tooltip}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>{value}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      },
    },
    {
      name: 'Last Name',
      key: 'lastName',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '170px', height: '58px' },
      widthInEditMode: { width: '250px' },
      displayIcon: true,
      showPointer: true,
      render: (
        { value, inputName, register, errorMessage },
        column,
        isEditMode
      ) => {
        const tooltipVisible = value?.length > 18;

        return (
          <>
            {isEditMode ? (
              <TextField
                name={inputName}
                register={register}
                errorMessage={errorMessage}
              />
            ) : (
              <div className={`${tooltipVisible ? style.rounded : ''}`}>
                <p className={style.userName1}>{value}</p>
                <div className={style.tooltip}>
                  <div className={style.tooltipChild}>
                    <p className={style.tooltipText}>{value}</p>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      },
    },
    {
      name: 'LI Status',
      key: 'liStatus',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '170px', height: '58px' },
      widthInEditMode: { width: '245px' },
      displayIcon: true,
      showToolTipOnUnEditableField: false,
      showPointer: true,

      render: (
        {
          value,
          inputName,
          register,
          errorMessage,
          row,
          isDisable,
          control,
          watch,
          setValue,
          id,
        },
        column,
        isEditMode,
        showToolTipOnUnEditableField
      ) => (
        <>
          {isEditMode ? (
            <>
              <OpenLiStatusModalOnEditMode
                previousLiStatus={value}
                currentLiStatus={watch(inputName)}
                inputName={inputName}
                clientNames={allClientName}
                clientNamesWithPositions={clientNameWithPositions}
                defaultLiStatus={value}
                attorneyId={id}
                setLiStausValue={(value) => {
                  setValue(inputName, value);
                }}
              />

              <SelectBox
                name={inputName}
                control={control}
                badge
                options={ATTRONEY_STATUS_OPTIONS}
                placeholder={'Select'}
                numberBadgeColor={'#39695b'}
                dynamicClass={style.zDynamicState4}
                currentValue={watch(inputName)}
                isEditMode={isEditMode}
              />
            </>
          ) : (
            <div className={style.rounded}>
              <Tags
                key="tags"
                text={getLiStatusWithClientnames(row)}
                color={
                  getLiStatusWithClientnames(row)?.includes('Unplaced')
                    ? '#000'
                    : ATTRONEY_STATUS_OPTIONS?.find(
                        (findColor) =>
                          findColor?.label === (value || row?.statuses?.status)
                      )?.color
                }
              />
              {liStatusWithClientNameToolTip(row, showToolTipOnUnEditableField)}
            </div>
          )}
        </>
      ),
    },
    {
      name: 'LI Start Date',
      key: 'attorneyStartDate',
      type: 'date',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      displayIcon: true,
      showPointer: true,

      render: (
        { value, control, row, errorMessage, inputName },
        column,
        isEditMode
      ) => {
        value = value?.split('T')?.[0];
        return (
          <>
            {isEditMode ? (
              <DatePicker
                id={row?._id}
                control={control}
                name={inputName}
                errorMessage={errorMessage}
                reactDatePickerClass={style.dateZ}
              />
            ) : (
              <span>
                {_.isNull(value) || _.isUndefined(value)
                  ? '-'
                  : moment(value).format('MM/DD/YYYY')}
              </span>
            )}
          </>
        );
      },
    },
    {
      name: 'Placement Date',
      key: 'placementDate',
      type: 'date',
      editable: false,
      hidden: false,
      sortKey: 'placementDate.statusDate',
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },

      displayIcon: true,
      showPointer: true,

      render: ({ value, control, row, isDisable }, column, isEditMode) => (
        <>
          {isEditMode ? (
            <DatePicker
              id={row?._id}
              defaultVal={
                _.isEmpty(value) || _.isUndefined(value) || _.isNull(value)
                  ? null
                  : new Date(value)
              }
              control={control}
              name={'placementDate'}
              isDisable={isDisable}
            />
          ) : (
            <span>
              {_.isNull(value?.statusDate) || _.isUndefined(value?.statusDate)
                ? '-'
                : moment(value?.statusDate).format('MM/DD/YYYY')}
            </span>
          )}
        </>
      ),
    },
    {
      name: 'Demo',
      key: 'attorneyDemo',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '250px' },
      displayIcon: true,
      showPointer: true,
      render: (
        { value, errorMessage, control, inputName, watch },
        column,
        isEditMode
      ) => {
        const demoOptionsArray = DEMO_OPTIONS?.map((x) => {
          return { ...x, checkbox: true };
        });
        return (
          <>
            {isEditMode ? (
              <SelectBox
                name={inputName}
                control={control}
                options={moveSelctedDropdownStatusToTop(
                  (watch && watch(inputName)) ?? [],
                  demoOptionsArray
                )}
                numberBadgeColor={'#39695B'}
                dynamicClass={style.zDynamicState4}
                errorMessage={errorMessage}
                isMulti
                badge
                showNumber
                showSelected
                isEditMode={isEditMode}
              />
            ) : (
              <div className={style.rounded}>
                <span className={style?.attorneyColumnEllipsis}>
                  <div>{value?.join(', ')} </div>
                </span>
                {value?.join(', ') && value?.join(', ').length > 15 && (
                  <div className={style.tooltip}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>
                        {value?.map((demoValue, index) => (
                          <React.Fragment key={index}>
                            {demoValue}
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      name: 'Gender',
      key: 'attorneyGender',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      render: (
        { value, inputName, control, errorMessage, watch },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode ? (
              <SelectBox
                name={inputName}
                control={control}
                options={GENDER_OPTIONS}
                numberBadgeColor={'#39695b'}
                errorMessage={errorMessage}
                isEditMode={isEditMode}
              />
            ) : (
              // <span>{value}</span>
              <div className={style.rounded}>
                <span className={style?.attorneyColumnEllipsis}>
                  <div>{value} </div>
                </span>
                {value && value.length > 15 && (
                  <div className={style.tooltip}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>{value}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      name: 'City',
      key: 'attorneyLocation',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      showToolTipOnUnEditableField: false,
      render: (
        { value, inputName, control, errorMessage, watch },
        column,
        isEditMode,
        showToolTipOnUnEditableField
      ) => {
        const locationOptions =
          locations &&
          locations?.map((e) => ({
            label: e.location,
            value: e.location,
          }));

        return (
          <>
            {isEditMode ? (
              <SelectBox
                name={inputName}
                control={control}
                options={locationOptions}
                numberBadgeColor={'#39695b'}
                dynamicClass={style.zDynamicState4}
                errorMessage={errorMessage}
                isEditMode={isEditMode}
              />
            ) : (
              <div className={style.rounded}>
                <span className={style?.attorneyColumnEllipsis}>
                  <div>{value} </div>
                </span>
                {value && value.length > 15 && (
                  <div className={style.tooltip}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>{value}</p>
                    </div>
                  </div>
                )}
                {showToolTipOnUnEditableField && (
                  <div className={style.tooltip} style={{ width: '200px' }}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>
                        This field is accessible when the selected date is
                        “Now”.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      name: 'School',
      key: 'attorneyLawSchool',
      type: 'text',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      render: (
        { value, inputName, control, errorMessage, watch },
        column,
        isEditMode
      ) => {
        const schoolOptions =
          lawSchools &&
          lawSchools?.map((e) => ({
            label: e,
            value: e,
          }));

        return (
          <>
            {isEditMode ? (
              <SelectBox
                name={inputName}
                control={control}
                options={schoolOptions}
                numberBadgeColor={'#39695b'}
                dynamicClass={style.zDynamicState4}
                errorMessage={errorMessage}
                isEditMode={isEditMode}
              />
            ) : (
              <div className={style.rounded}>
                <span className={style?.attorneyColumnEllipsis}>
                  <div>{value} </div>
                </span>
                {value && value.length > 10 && (
                  <div className={style.tooltip}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>{value}</p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      name: 'Grad Year',
      key: 'attorneyGradYear',
      type: 'date',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      displayIcon: true,
      render: (
        { value, errorMessage, control, inputName },
        column,
        isEditMode
      ) => (
        <>
          {isEditMode ? (
            <DatePicker
              control={control}
              name={inputName}
              errorMessage={errorMessage}
              showYearPicker={true}
              placeholder={'YYYY'}
            />
          ) : (
            <span>
              {_.isDate(value) ? moment(value).format('YYYY') : value}
            </span>
          )}
        </>
      ),
    },
    {
      name: 'Parker Score',
      key: 'attorneyParkerScore',
      type: 'text',
      hidden: false,
      editable: true,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      displayIcon: true,
      render: (
        { value, inputName, register, errorMessage },
        column,
        isEditMode
      ) => (
        <>
          {isEditMode ? (
            <TextField
              name={inputName}
              register={register}
              errorMessage={errorMessage}
              type="number"
            />
          ) : (
            <span>{value}</span>
          )}
        </>
      ),
    },
    {
      name: 'Bar Location',
      key: 'barLocations',
      type: 'text',
      hidden: false,
      editable: false,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      displayIcon: true,
      showToolTipOnUnEditableField: false,
      showPointer: true,
      render: (
        { value, inputName, register, errorMessage },
        column,
        isEditMode,
        showToolTipOnUnEditableField
      ) => {
        const systemBarStatesAsArray = _.flatten(
          value?.map((barLocation) => barLocation?.docs?.barState?.barState)
        );

        const systemBarStatedConvertToEllipses = systemBarStatesAsArray;
        return (
          <>
            {isEditMode ? (
              <TextField
                name={inputName}
                register={register}
                errorMessage={errorMessage}
              />
            ) : (
              <div className={style.rounded}>
                <span className={style?.attorneyColumnEllipsis}>
                  <div>{systemBarStatedConvertToEllipses.join(', ')} </div>
                </span>
                {_.size(systemBarStatedConvertToEllipses) > 1 && (
                  <div className={style.tooltip}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>
                        {systemBarStatedConvertToEllipses?.map(
                          (sysytemBarState, index) => (
                            <React.Fragment key={index}>
                              {sysytemBarState}
                              <br />
                            </React.Fragment>
                          )
                        )}
                      </p>
                    </div>
                  </div>
                )}

                {showToolTipOnUnEditableField && (
                  <div className={style.tooltip} style={{ width: '200px' }}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>
                        This field is accessible when the selected date is
                        “Now”.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },
    {
      name: 'Last Bar Status Date',
      key: 'lastBarStatus.barTestedDate',
      sortKey: 'lastBarStatus.barTestedDate',
      type: 'text',
      hidden: false,
      editable: true,
      displayIcon: true,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      render: (
        { value, inputName, control, errorMessage, watch },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode ? (
              <DatePicker
                id={inputName}
                name={inputName}
                monthYear={true}
                control={control}
                placeholder="mm/yyyy"
                errorMessage={errorMessage}
                popparBack={style.lastBarTestedDate}
                isDisable={watch && watch(inputName) ? false : true}
                defaultVal={
                  _.isEmpty(value) || _.isUndefined(value) || _.isNull(value)
                    ? null
                    : new Date(value)
                }
              />
            ) : (
              <span>
                {_.isNull(watch(inputName)) || _.isUndefined(watch(inputName))
                  ? '-'
                  : moment(watch(inputName)).format('MM/YYYY')}
              </span>
            )}
          </>
        );
      },
    },
    {
      name: 'Last Bar Status',
      key: 'lastBarStatus.barStatus',
      sortKey: 'lastBarStatus.barStatus',
      type: 'text',
      hidden: false,
      editable: true,
      displayIcon: true,
      widthAndHeight: { width: '130px', height: '58px' },
      widthInEditMode: { width: '180px' },
      showPointer: true,
      render: (
        { inputName, control, errorMessage, watch },
        column,
        isEditMode,
        showToolTipOnUnEditableField
      ) => {
        let preSelectedValueOfLastBarStatus = (watch && watch(inputName)) ?? [];
        const value = preSelectedValueOfLastBarStatus;
        preSelectedValueOfLastBarStatus = _.isObject(
          preSelectedValueOfLastBarStatus
        )
          ? preSelectedValueOfLastBarStatus?.barStatus
          : preSelectedValueOfLastBarStatus;
        return (
          <>
            {isEditMode ? (
              <SelectBox
                disabled={watch && watch(inputName) ? false : true}
                name={inputName}
                control={control}
                options={BAR_STATUS_OPTIONS}
                numberBadgeColor={'#39695b'}
                dynamicClass={style.zDynamicState4}
                errorMessage={errorMessage}
                isEditMode={isEditMode}
              />
            ) : value ? (
              <div className={style.rounded}>
                <Tags
                  key="tags"
                  circular
                  text={value}
                  color={
                    BAR_STATUS_OPTIONS?.find(
                      (findColor) => findColor?.label === value
                    )?.color
                  }
                />
                {showToolTipOnUnEditableField && (
                  <div className={style.tooltip} style={{ width: '200px' }}>
                    <div className={style.tooltipChild}>
                      <p className={style.tooltipText}>
                        This field is accessible when the selected date is
                        “Now”.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      name: '',
      key: 'actions',
      hidden: false,
      type: 'text',
      editable: true,
      widthAndHeight: { width: '56px', height: '58px' },
      widthInEditMode: { width: '56px' },

      displayIcon: false,
      render: ({ row, navigate }, column, isEditMode) => {
        return isEditMode ? (
          <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
            <img
              src={delIcon}
              alt=""
              className={style.imgDel}
              onClick={() => {
                setDeleteRowId(row?._id);
                setDelModal(true);
              }}
            />
          </PermissionRestrict>
        ) : (
          <PermissionRestrict roles={[ROLES.ADMIN, ROLES.EDITOR]}>
            <img
              src={editIcon}
              alt=""
              className={style.imgDel}
              onClick={() => setDelModal(true)}
            />
          </PermissionRestrict>
        );
      },
    },
  ];
  return defaultColumns;
};

const getLiStatusWithClientnames = (row) => {
  if (
    ((row?.statuses?.status?.status ?? row?.liStatus) === 'Interviewing_P' ||
      (row?.statuses?.status?.status ?? row?.liStatus) === 'Interviewing_LI') &&
    _.size(row?.relativeClients) > 0
  ) {
    return `Interviewing (${_.size(row?.relativeClients)}) `;
  } else if (
    ((row?.statuses?.status?.status ?? row?.liStatus) === 'Not Interviewing' ||
      (row?.statuses?.status?.status ?? row?.liStatus) === 'Interviewing_LI') &&
    _.size(row?.relativeClients) > 0
  ) {
    return `Unplaced ${
      _.size(row?.relativeClients) > 1
        ? `(${_.size(row?.relativeClients)})`
        : ''
    }`;
  } else if (
    (row?.statuses?.status?.status ?? row?.liStatus) === 'Placed' &&
    _.size(row?.relativeClients) > 0
  ) {
    return row?.relativeClients && row?.relativeClients[0]?.clientName;
  } else {
    return row?.statuses?.status?.status ?? row?.liStatus ?? '';
  }
};

const liStatusWithClientNameToolTip = (
  row,
  showToolTipOnUnEditableField = false
) => {
  return showToolTipOnUnEditableField ? (
    <>
      <div className={style.tooltip} style={{ width: '200px' }}>
        <div className={style.tooltipChild}>
          <p className={style.tooltipText}>
            This field is accessible when the selected date is “Now”.
          </p>
        </div>
      </div>
    </>
  ) : (
    _.size(row?.relativeClients) > 0 && (
      <div className={style.tooltip}>
        <div className={style.tooltipChild}>
          {row?.relativeClients?.map((rowValue) => (
            <p className={style.tooltipText} key={rowValue?._id}>
              <span style={{ fontWeight: '600' }}>{rowValue?.clientName}</span>
              {row?.statuses?.status?.status ?? row?.liStatus !== 'Placed' ? (
                <span style={{ marginLeft: '5px' }}>
                  [
                  {_.isNull(rowValue?.statusDate) ||
                  _.isUndefined(rowValue?.statusDate)
                    ? '-'
                    : moment(rowValue?.statusDate).format('MM/DD/YYYY')}
                  ]
                </span>
              ) : (
                ''
              )}
            </p>
          ))}
        </div>
      </div>
    )
  );
};

export const getSideBarLinksWithCalculation = (calculations, currentValue) => {
  return [
    {
      name: 'All',
      number: calculations.allAttorneys,
      class: style.mainElement,
    },
    {
      name: 'LI Attorneys',
      number: calculations.liAttorneys,
      class: style.mainElement,
      icon: true,
      array: [
        {
          name: 'Placed',
          number: calculations.placedlineAttorney,
        },
        {
          name: 'Unplaced',
          number: calculations.unplacedlineAttorney,
        },
      ],
      array1: [
        {
          name: 'Interviewing_LI',
          number: calculations.interviewingLIAttorney,
        },
        {
          name: 'Not Interviewing',
          number: calculations.notInterviewingAttorney,
          class: style.gap20,
        },
      ],
    },

    {
      name: `Pipeline${currentValue === 'Cumulative' ? ' (Total)' : ''}`,
      number: calculations.pipelineAttorney,
      icon: true,
      class: style.mainElement,
      array:
        currentValue === 'Cumulative'
          ? [
              {
                name: 'Pipeline (Pure)',
                number: calculations.pipelinePureAttorney,
              },
              {
                name: 'Interviewing_P',
                number: calculations.interviewingPAttorney,
              },
            ]
          : [
              {
                name: 'Interviewing_P',
                number: calculations.interviewingPAttorney,
              },
            ],
    },

    {
      name: `Returned (Cumulative)`,
      number: calculations.returnedAttorney,
      class: style.innerThirdDiv,
    },
    {
      name: `Converted (Cumulative)`,
      number: calculations.convertedAttorney,
      class: style.mainElement,
    },
    {
      name: 'Bar Fails (1)',
      number: calculations.attorneys1BarFails,
      class: style.innerSecondDiv,
    },
    {
      name: 'Bar Fails (2)',
      number: calculations.attorneys2BarFails,
      class: style.mainElement,
    },
  ];
};

export const downloadAttorneyDirectoryIntoCsv = async (
  data,
  sort,
  calculationSelected,
  currentValue,
  dateRange,
  filters,
  columns
) => {
  const arrToExport = [
    ...(data?.filter((x) => x?.checked) ?? []),
    ...(data?.filter((y) => !y?.checked) ?? []),
  ];
  const checkedRowIds =
    arrToExport?.filter((x) => x?.checked)?.map((y) => y?._id) ?? [];

  const response = await exportAttorneysDirectoryToCSV(
    checkedRowIds,
    sort,
    calculationSelected,
    currentValue,
    dateRange,
    filters,
    columns.filter((x) => x?.hidden === false && x?.name).map((x) => x?.name)
  );

  const fileURL = window.URL.createObjectURL(new Blob([response?.data]));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  const currentDate = moment().format('MMM DD, YYYY HH:mm:ss a');
  fileLink.setAttribute('download', `Attorney Directory-${currentDate}.csv`);
  fileLink.setAttribute('target', '_blank');
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
};

export const getAllAttorneysData = async (
  pageSize,
  page,
  sortBy,
  order,
  all,
  calculationType,
  queryType,
  dateRange,
  filters,
  checkRowIds,
  attorneyDirectoryChecked
) => {
  try {
    const getAllAttorneysData = await getAttorneysDirectory({
      pageSize,
      page,
      sortBy,
      order,
      all,
      calculationType,
      queryType,
      filters,
      dateRange: {
        startDate:
          dateRange[0] && moment(dateRange[0]).format('YYYY-MM-DDTHH:mm:ss'),
        endDate:
          dateRange[1] && moment(dateRange[1]).format('YYYY-MM-DDTHH:mm:ss'),
      },
      checkRowIds: checkRowIds.join(',') ?? '',
    });
    const result = getAllAttorneysData?.data?.attorneyDirectory ?? [];
    const attorneyArrayWithCustomCheckField = result?.map?.((x) => {
      return {
        ...x,
        checked:
          x?._id && attorneyDirectoryChecked.includes(x?._id) ? true : false,
      };
    });

    return {
      attorneyArrayWithCustomCheckField,
      totalPages: getAllAttorneysData?.data?.totalPages,
      currentPage: getAllAttorneysData?.data?.currentPage,
      totalDocuments: getAllAttorneysData?.data?.totalDocuments ?? 0,
    };
  } catch (err) {
    console.error('error', err);
  }
};

export const moveSelctedDropdownStatusToTop = (selectedValue, options) => {
  const deleteShowLineFromDefualtOptionsArray = options?.filter(
    (option) => option?.label !== 'showLine' && option?.value !== 'showLine'
  );
  const checkSelectedValue = _.isEmpty(selectedValue)
    ? []
    : !_.isArray(selectedValue)
    ? [selectedValue]
    : selectedValue;
  return !_.isEmpty(selectedValue)
    ? [
        ...deleteShowLineFromDefualtOptionsArray.filter((item) =>
          checkSelectedValue?.includes(item.value)
        ),
        { label: 'showLine', value: 'showLine', isdisabled: true },
        ...deleteShowLineFromDefualtOptionsArray.filter(
          (item) => !checkSelectedValue?.includes(item.value)
        ),
      ]
    : [
        ...deleteShowLineFromDefualtOptionsArray.filter((item) =>
          checkSelectedValue?.includes(item.value)
        ),
        ...deleteShowLineFromDefualtOptionsArray.filter(
          (item) => !checkSelectedValue?.includes(item.value)
        ),
      ];
};

export const getUpdateAndCreateLastBarStatusArray = (updatedRows, data) => {
  const updateBarHistory = [];
  const createBarHistory = [];

  const _updatedRows = [...updatedRows].reduce((acc, curr) => {
    acc[curr._id] = curr;
    return acc;
  }, {});

  for (let i of data) {
    const updatedRowValue = { ..._updatedRows[i?._id] };
    if (updatedRowValue?.lastBarStatus?.barTestedDate) {
      updatedRowValue.lastBarStatus.barTestedDate = moment(
        updatedRowValue?.lastBarStatus?.barTestedDate
      ).format('YYYY-MM-DD');
    }
    if (
      !_.isEmpty(updatedRowValue?.lastBarStatus?.barStatus) &&
      updatedRowValue?.lastBarStatus?.barTestedDate &&
      !_.isEmpty(updatedRowValue?.lastBarStatus?.barTestedDate) &&
      !_.isEmpty(i?.lastBarStatus)
    ) {
      if (
        updatedRowValue?.lastBarStatus?.barStatus !==
          i?.lastBarStatus?.barStatus ||
        updatedRowValue?.lastBarStatus?.barTestedDate !==
          moment(i?.lastBarStatus?.barTestedDate).format('YYYY-MM-DD')
      ) {
        updateBarHistory.push({
          barHistoryId: i?.lastBarStatus?._id,
          attoryneyId: i?._id,
          barStatus: updatedRowValue?.lastBarStatus?.barStatus,
          barTestedDate: updatedRowValue?.lastBarStatus?.barTestedDate,
        });
      }
    } else if (
      !_.isEmpty(updatedRowValue?.lastBarStatus?.barStatus) &&
      updatedRowValue?.lastBarStatus?.barTestedDate &&
      !_.isEmpty(updatedRowValue?.lastBarStatus?.barTestedDate) &&
      _.isEmpty(i?.lastBarStatus)
    ) {
      createBarHistory.push({
        attorneyId: i?._id,
        barStatus: updatedRowValue?.lastBarStatus?.barStatus,
        barTestedDate: updatedRowValue?.lastBarStatus?.barTestedDate,
      });
    }
  }
  return {
    createBarHistory,
    updateBarHistory,
  };
};

export const checkAllTheValuesInFilterIsEmpty = (filter) => {
  return Object.values(filter).every(
    (x) =>
      x === null ||
      x === '' ||
      _.size(x) == 0 ||
      _.size(x?.filter((y) => y)) == 0
  );
};
