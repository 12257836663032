export const ERROR_MESSAGES = { nothingToDisplay: ['nothingToDisplay'] };
export const DELETION_MESSAGES = {
  confirmDelete: ['confirmDelete'],
  cancelDelete: ['cancelDelete'],
};
export const EMPTY_PLACEHOLDER_MESSAGES = { barHistory: ['barHistory'] };

export const A01_SCREEN_MESSAGES = {
  popups: {
    deleteConfirm: {
      attorneyRecord: {
        body: ['Delete Attorney'],
        yes: ["Yes, I'm sure."],
        no: ['Whoops, no, cancel this.'],
      },
    },
  },
};

export const C01_SCREEN_MESSAGES = {
  popups: {
    deleteConfirm: {
      attorneyRecord: {
        body: ['Delete Client'],
        yes: ["Yes, I'm sure."],
        no: ['Whoops, no, cancel this.'],
      },
    },
  },
};

export const P01_SCREEN_MESSAGES = {
  popups: {
    deleteConfirm: {
      attorneyRecord: {
        body: ['Delete Position'],
        yes: ["Yes, I'm sure."],
        no: ['Whoops, no, cancel this.'],
      },
    },
  },
};

export const A02_SCREEN_MESSAGES = {
  emptyPlaceholder: {
    barHistory: [
      "It's 5:00pm somewhere.",
      'Time to raise the bar around here.',
    ],
    clientHistory: ['Put this kid to work already!'],
    trainingHistory: ['Nothing to see here...'],
    comments: [
      'Literally, no comment. ',
      "I can't think of anything to say either...",
    ],
  },
  popups: {
    deleteConfirm: {
      comment: {
        body: ['Are you sure you want to delete this comment?'],
        yes: ['Yes, buh bye!'],
        no: ['No, really, just no.'],
      },
      barExam: {
        body: ['Are you sure you want to delete this record?'],
        yes: ['Yes, such a shame.'],
        no: ['NO, leave me alone!'],
      },
      clientHistory: {
        body: ['Are you sure you want to delete this record?'],
        yes: ['Yes, another one bites the dust.'],
        no: ["NOOO! Don't do it!!"],
      },
      trainingHistory: {
        body: ['Are you sure you want to delete this record?'],
        yes: ['Yes, sadly, yes....'],
        no: ['Err oops! Nope!'],
      },
      attorneyRecord: {
        body: ['Are you sure you want to delete this record?'],
        yes: ["Yes, get 'em outta here!"],
        no: ["Nah, we like 'em. Keep 'em."],
      },
    },
  },
};

export const P02_SCREEN_MESSAGES = {
  loader: [
    'Please wait while we generate the most awesome matches for you… (still faster than a Windows update!)',
    'Please wait while we pick a terrific starting lineup for you….while you wait, what did the chicken say to the egg?',
  ],
  emptyPlaceholder: {
    overview: ['Batter up! ⚾️'],
    comments: ['This is a whole lotta empty...👀'],
  },
  popups: {
    deleteConfirm: {
      comment: {
        body: ['Are you sure you want to delete this comment?'],
        yes: ['Yes, redact it!'],
        no: ['🤔...nah...leave it...'],
      },
      attorney: {
        body: ['Are you sure you want to delete this record?'],
        yes: ["Yes, they're no Babe Ruth."],
        no: ['Well, no; I believe in second chances.'],
      },
      placementRecord: {
        body: ['Are you sure you want to delete this record?'],
        yes: ["Yep, send 'em all back to the dugout."],
        no: ['Whoops!', 'Was a bit too trigger-happy.'],
      },
    },
    resetModal: {
      body: ['Reset the Ordering of Attorneys'],
      desc: [
        'Are you sure you want to reset the ordering of attorneys to the initial order?',
      ],
      yes: ["Yes, I'm sure."],
      no: ["Whoops, no I'm not."],
    },
  },
};

export const C02_SCREEN_MESSAGES = {
  emptyPlaceholder: {
    overview: ['No Record!'],
    contact: ['We must know SOMEONE there.'],
    comments: ['They must be happy with us...'],
  },
  popups: {
    deleteConfirm: {
      comment: {
        body: ['Are you sure you want to delete this comment?'],
        yes: ['Yes, buh bye!'],
        no: ['No, really, just no.'],
      },
      contact: {
        body: ['Are you sure you want to delete this contact?'],
        yes: ['Yes, off with its head!'],
        no: ['No, save them.'],
      },
      client: {
        body: ['Are you sure you want to delete this record?'],
        yes: ['Yes, sniffles, yes...'],
        no: ['No, goodness gracious, no!'],
      },
      placementRecord: {
        body: [
          'If you delete this placement, you will also be deleting any related attorney matches.',
        ],
        yes: ["Yep, send 'em all back to the dugout."],
        no: ['Whoops!', 'Was a bit too trigger-happy.'],
      },
    },
  },
};

export const M02_SCREEN_MESSAGES = {
  emptySpace: [
    'Nothing to see here, move along…',
    'Can I interest you in our extended car warranty?',
    'Psssst.... looking for some lawyers? Check under their desks.',
    'Keep calm and carry on.',
  ],
};

export const U01_SCREEN_MESSAGES = {
  popups: {
    deleteConfirm: {
      profile: {
        body: ['Are you sure you want to delete this profile?'],
        yes: ['Yes, it was nice while it lasted.'],
        no: ['Whoops, not so quick!'],
      },
    },
  },
};
