import { combineReducers } from 'redux';

import AuthReducer from './auth/auth-reducers';
import userReducers from './user/user-reducers';
import attorneyReducers from './attorney/attorney-reducers';
import systemValuesReducer from './system-values/system-values-reducer';
import statsTableReducer from './stats-table/stats-table-reducers';
import filterReducer from './filters/filter-reducers';

const rootReducer = combineReducers({
  auth: AuthReducer,
  user: userReducers,
  attorney: attorneyReducers,
  systemValues: systemValuesReducer,
  statsTable: statsTableReducer,
  pageFilters: filterReducer,
});

export default rootReducer;
