import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import saveIcon from 'assets/images/save.svg';
import style from './client-history.module.scss';

import { useAttoreyProfile } from 'contexts/attorney-profile';
import {
  getAttorneyClientHistory,
  deleteClientHistory,
  getClientName,
  getClientPosition,
  setAttorneyClientHistory,
} from 'services/attorney-profile';
import { createNotification } from 'components/react-notification';
import Tags from 'components/tags';
import moment from 'moment';
import { ATTORNEY_CLIENT_STATUS_COLORS } from 'constants';
import { ATTORNEY_CLIENT_STATUS } from 'constants';
import PermissionRestrict from 'hoc/PermissionRestrict';
import { ROLES } from 'permissions/constants';
import SelectBox from 'components/select-box';
import DatePicker from 'components/date-picker';
import greenCrossIcon from 'assets/images/greenCrossIcon.svg';
import Button from 'components/button';
import TextArea from 'components/textarea';

const useHelper = () => {
  const { id } = useParams();
  const { getData, add, active } = useAttoreyProfile();

  const [viewTable, setViewTable] = useState(false);
  const [columns, setColumns] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [all, setAll] = useState();

  const [clientHistory, setClientHistory] = useState([]);
  const [clientNames, setClientNames] = useState([]);
  const [clientPosition, setClientPosition] = useState([]);

  const innerColumns = [
    {
      name: 'Client Name',
      key: 'clientName',
      type: 'text',
      editable: true,
      hidden: false,
      displayIcon: true,
      widthAndHeight: { width: '265px', height: '58px' },
      widthInEditMode: { width: '200px' },
      render: (
        { value, inputName, register, errorMessage, watch, row },
        column,
        isEditMode
      ) => {
        return (
          <p
            style={{ fontSize: '14px', color: '#363636', paddingLeft: '10px' }}
          >
            {row?.clientName}
          </p>
        );
      },
    },
    {
      name: 'Position',
      key: 'position',
      type: 'text',
      editable: true,
      hidden: false,
      displayIcon: true,
      widthAndHeight: { width: '265px', height: '58px' },
      widthInEditMode: { width: '246px' },
      render: (
        { value, inputName, register, errorMessage, row },
        column,
        isEditMode
      ) => {
        return (
          <p
            style={{ fontSize: '14px', color: '#363636', paddingLeft: '10px' }}
          >
            {row?.positionName}
          </p>
        );
      },
    },
    {
      name: 'Client Status',
      key: 'status',
      type: 'text',
      editable: true,
      hidden: false,
      displayIcon: true,
      widthAndHeight: { width: '265px', height: '58px' },
      widthInEditMode: { width: '246px' },
      render: (
        {
          value,
          inputName,
          control,
          watch,
          index,
          register,
          errorMessage,
          row,
        },
        column,
        isEditMode
      ) => {
        return isEditMode === index ? (
          // <div style={{ width: '265px' }}>
          <SelectBox
            name={inputName}
            label={'Client Status'}
            placeholder={'Select'}
            options={ATTORNEY_CLIENT_STATUS}
            control={control}
            errorMessage={errorMessage}
          />
        ) : (
          // </div>
          <div style={{ marginLeft: '-4px', paddingLeft: '10px' }}>
            {row?.status ? (
              <Tags
                circular={true}
                text={row?.status}
                color={ATTORNEY_CLIENT_STATUS_COLORS[row?.status]}
              />
            ) : (
              '-'
            )}
          </div>
        );
      },
    },
    {
      name: 'Status Date',
      key: 'statusDate',
      type: 'text',
      editable: true,
      hidden: false,
      displayIcon: true,
      widthAndHeight: { width: '265px', height: '58px' },
      widthInEditMode: { width: '246px' },
      render: (
        { value, inputName, index, register, control, errorMessage, row },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode === index ? (
              <DatePicker
                id={row?._id}
                label="Status Date"
                name={inputName}
                placeholder="mm/dd/yyyy"
                control={control}
                errorMessage={errorMessage}
                className={style.datepickerZindex}
              />
            ) : (
              <p
                style={{
                  fontSize: '14px',
                  color: '#363636',
                  paddingLeft: '10px',
                }}
              >
                {row?.statusDate
                  ? moment(row?.statusDate).format('MM/DD/YYYY')
                  : '-'}
              </p>
            )}
          </>
        );
      },
    },
    {
      name: 'Feedback',
      key: 'feedback',
      type: 'text',
      editable: true,
      hidden: false,
      displayIcon: true,
      widthAndHeight: { width: '450px', height: '58px' },
      widthInEditMode: { width: '450px' },
      render: (
        { value, inputName, register, index, errorMessage, row },
        column,
        isEditMode
      ) => {
        return (
          <>
            {isEditMode === index ? (
              <div style={{ maxWidth: '542px' }}>
                <TextArea
                  label={'Feedback'}
                  name={`data[${index}].feedback`}
                  placeholder={'Start typing'}
                  register={register}
                  errorMessage={errorMessage}
                  rows={1}
                />
              </div>
            ) : (
              <p
                style={{
                  fontSize: '14px',
                  color: '#363636',
                  whiteSpace: 'pre-wrap',
                  paddingLeft: '10px',
                }}
              >
                {row?.feedback}
              </p>
            )}
          </>
        );
      },
    },
    {
      name: '',
      key: 'action',
      type: 'text',
      editable: true,
      hidden: false,
      widthAndHeight: { width: '182px', height: '58px' },
      widthInEditMode: { width: '575px' },
      render: ({ index, editRowLoading }, column, isEditMode) => {
        return (
          <PermissionRestrict
            roles={[ROLES.ADMIN, ROLES.EDITOR, ROLES.ATTORNEY]}
          >
            {isEditMode === index ? (
              <div
                style={{
                  display: 'flex',
                  position: 'relative',
                  justifyContent: 'flex-end',
                  marginTop: '18px',
                }}
              >
                <img
                  style={{
                    cursor: 'pointer',
                    marginRight: '0px',
                  }}
                  width={25}
                  onClick={() => {
                    setEditTable(false);
                  }}
                  src={greenCrossIcon}
                />
                <Button
                  btnClass={style.saveBtn}
                  text={'Save'}
                  type="submit"
                  form="hook-formClient"
                  isLoading={editRowLoading}
                  iconStart={saveIcon}
                />
              </div>
            ) : (
              <p> </p>
            )}
          </PermissionRestrict>
        );
      },
    },
  ];

  useEffect(() => {
    getClientData();
    getClientNameData();
    getClientPositionData();
  }, []);

  useEffect(() => {
    setToggle(add);
  }, [add]);

  useEffect(() => {
    if (active === 2) {
      if (!add) {
        setToggle(false);
        setEdit(false);
      }
    }
  }, [active]);

  const getClientData = async () => {
    setIsLoading(true);
    try {
      const res = await getAttorneyClientHistory(id);
      setClientHistory([...res.data.history]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  //Client names
  const getClientNameData = async () => {
    setIsLoading(true);
    try {
      const res = await getClientName(id);
      setClientNames([...res.data.clientData]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  const getClientPositionData = async () => {
    setIsLoading(true);
    try {
      const res = await getClientPosition();
      setClientPosition([...res.data]);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setIsLoading(false);
  };

  const handleEdit = async (data) => {
    const { attorneyId, status, statusDate, feedback, clientId, positionId } =
      data;

    try {
      const res = await setAttorneyClientHistory(
        {
          status,
          feedback,
          positionId: positionId?._id,
          statusDate: moment(statusDate).format('YYYY-MM-DD'),
          attorneyId,
          edit: true,
          clientId: positionId.clientId,
        },
        data?._id
      );

      if (res.status === 200) {
        onEdit && onEdit();
        await getClientData();
        setEditTable(false);
        setEdit(null);
        setToggle(null);
      }
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.msg || err?.message || 'Server Error'
      );
      console.error('err', err);
    }
    setToggle(false);
  };

  //delete
  const handleDelete = async (type = null) => {
    try {
      if (type === 'all') {
        setIsLoadingAll(true);
        await deleteClientHistory({
          attorneyId: clientHistory[all?.index]?.attorneyId,
          clientId: clientHistory[all?.index]?.clientId,
          positionId: clientHistory[all?.index]?.positionId?._id,
        });
        setIsLoadingAll(false);
        getData();
        setAll(false);
        setOpen(false);
      } else {
        setIsLoading(true);
        await deleteClientHistory({ id: open.id });
        if (open.outerId) {
          setClientHistory((prev) => {
            const currentIndex = prev.findIndex((x) => x._id === open.outerId);
            prev[currentIndex].histories = prev[currentIndex]?.histories.filter(
              (x) => x._id !== open.id
            );
            return prev;
          });
          setIsLoading(false);
        } else {
          await getClientData();
        }
      }
      setViewTable(false);
    } catch (err) {
      createNotification(
        'error',
        'Error',
        err?.response?.data?.error || err?.message || 'Server Error'
      );
      console.error(err);
    }
    setAll(false);
    await getClientData();
    getData();
    setOpen(false);
  };
  //
  const onAdd = () => {
    getClientData();
  };

  const onEdit = async () => {
    setViewTable(false);
    await getClientData();
  };

  return {
    edit,
    open,
    onAdd,
    toggle,
    onEdit,
    setOpen,
    columns,
    setEdit,
    setToggle,
    isLoading,
    editTable,
    viewTable,
    all,
    setAll,
    setColumns,
    handleEdit,
    clientNames,
    handleDelete,
    setEditTable,
    innerColumns,
    setViewTable,
    clientHistory,
    getClientData,
    isLoadingAll,
    setIsLoadingAll,
    clientPosition,
    setClientPosition,
    deleteClientHistory,
  };
};

export default useHelper;
